/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query AllCases {\n    counter(id: 0) {\n      cases\n      casesRuled\n    }\n  }\n": types.AllCasesDocument,
    "\n  fragment DisputeDetails on Dispute {\n    id\n    arbitrated {\n      id\n    }\n    court {\n      id\n      policy\n      feeForJuror\n      timesPerPeriod\n    }\n    period\n    lastPeriodChange\n  }\n": types.DisputeDetailsFragmentDoc,
    "\n  query CasesPageWhere($skip: Int, $where: Dispute_filter, $orderDirection: OrderDirection, $first: Int) {\n    disputes(first: $first, skip: $skip, orderBy: lastPeriodChange, orderDirection: $orderDirection, where: $where) {\n      ...DisputeDetails\n    }\n  }\n": types.CasesPageWhereDocument,
    "\n  query CasesPage($skip: Int, $orderDirection: OrderDirection, $first: Int) {\n    disputes(first: $first, skip: $skip, orderBy: lastPeriodChange, orderDirection: $orderDirection) {\n      ...DisputeDetails\n    }\n  }\n": types.CasesPageDocument,
    "\n  query MyCases($id: ID!, $skip: Int, $orderDirection: OrderDirection) {\n    user(id: $id) {\n      disputes(first: 3, skip: $skip, orderBy: lastPeriodChange, orderDirection: $orderDirection) {\n        ...DisputeDetails\n      }\n    }\n  }\n": types.MyCasesDocument,
    "\n  query myCasesPageWhere($id: ID!, $skip: Int, $where: Dispute_filter, $orderDirection: OrderDirection) {\n    user(id: $id) {\n      disputes(first: 3, skip: $skip, orderBy: lastPeriodChange, orderDirection: $orderDirection, where: $where) {\n        ...DisputeDetails\n      }\n    }\n  }\n": types.MyCasesPageWhereDocument,
    "\n  query ClassicAppeal($disputeID: ID!, $orderBy: DisputeKitDispute_orderBy, $orderDirection: OrderDirection) {\n    dispute(id: $disputeID) {\n      period\n      court {\n        id\n        timesPerPeriod\n      }\n      arbitrated {\n        id\n      }\n      lastPeriodChange\n      disputeKitDispute(orderBy: $orderBy, orderDirection: $orderDirection) {\n        id\n        currentLocalRoundIndex\n        localRounds {\n          ... on ClassicRound {\n            winningChoice\n            paidFees\n            fundedChoices\n          }\n        }\n      }\n    }\n  }\n": types.ClassicAppealDocument,
    "\n  query Counter {\n    counter(id: \"0\") {\n      id\n      cases\n      casesRuled\n      casesVoting\n      casesAppealing\n      stakedPNK\n      redistributedPNK\n      paidETH\n      activeJurors\n    }\n  }\n": types.CounterDocument,
    "\n  query CourtDetails($id: ID!) {\n    court(id: $id) {\n      policy\n      minStake\n      alpha\n      numberDisputes\n      numberClosedDisputes\n      numberAppealingDisputes\n      numberStakedJurors\n      stake\n      paidETH\n      paidPNK\n      timesPerPeriod\n    }\n  }\n": types.CourtDetailsDocument,
    "\n  query CourtPolicyURI($courtID: ID!) {\n    court(id: $courtID) {\n      policy\n    }\n  }\n": types.CourtPolicyUriDocument,
    "\n  query CourtTree {\n    court(id: \"1\") {\n      name\n      id\n      children(orderBy: name) {\n        name\n        id\n        children {\n          name\n          id\n          children {\n            name\n            id\n            children {\n              name\n              id\n              children {\n                name\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": types.CourtTreeDocument,
    "\n  query DisputeDetails($disputeID: ID!) {\n    dispute(id: $disputeID) {\n      court {\n        id\n        timesPerPeriod\n        hiddenVotes\n        feeForJuror\n      }\n      arbitrated {\n        id\n      }\n      period\n      ruled\n      lastPeriodChange\n      currentRuling\n      overridden\n      tied\n      currentRound {\n        id\n      }\n      currentRoundIndex\n    }\n  }\n": types.DisputeDetailsDocument,
    "\n  query DisputeTemplate($id: ID!) {\n    disputeTemplate(id: $id) {\n      id\n      templateTag\n      templateData\n      templateDataMappings\n    }\n  }\n": types.DisputeTemplateDocument,
    "\n  query Draw($address: String, $disputeID: String, $roundID: String) {\n    draws(first: 1000, where: { dispute: $disputeID, juror: $address, round: $roundID }) {\n      voteIDNum\n      vote {\n        ... on ClassicVote {\n          commit\n          commited\n        }\n      }\n    }\n  }\n": types.DrawDocument,
    "\n  query Evidences($evidenceGroupID: String) {\n    evidences(where: { evidenceGroup: $evidenceGroupID }, orderBy: id, orderDirection: asc) {\n      id\n      evidence\n      sender {\n        id\n      }\n    }\n  }\n": types.EvidencesDocument,
    "\n  query HomePage($timeframe: ID) {\n    disputes(first: 3) {\n      id\n    }\n    counters(where: { id_gt: $timeframe }) {\n      id\n      stakedPNK\n      paidETH\n      redistributedPNK\n      activeJurors\n      cases\n    }\n  }\n": types.HomePageDocument,
    "\n  query JurorStakeDetails($userId: String) {\n    jurorTokensPerCourts(where: { juror: $userId }) {\n      court {\n        id\n        name\n      }\n      staked\n      locked\n    }\n  }\n": types.JurorStakeDetailsDocument,
    "\n  query TopUsersByCoherenceScore($first: Int!, $orderBy: User_orderBy, $orderDirection: OrderDirection) {\n    users(first: $first, orderBy: $orderBy, orderDirection: $orderDirection) {\n      id\n      coherenceScore\n      totalCoherent\n      totalResolvedDisputes\n    }\n  }\n": types.TopUsersByCoherenceScoreDocument,
    "\n  fragment UserDetails on User {\n    totalDisputes\n    totalResolvedDisputes\n    totalAppealingDisputes\n    totalCoherent\n    coherenceScore\n    tokens {\n      court {\n        id\n        name\n      }\n    }\n    shifts {\n      pnkAmount\n      ethAmount\n    }\n  }\n": types.UserDetailsFragmentDoc,
    "\n  query User($address: ID!) {\n    user(id: $address) {\n      disputes(orderBy: lastPeriodChange) {\n        id\n      }\n      ...UserDetails\n    }\n  }\n": types.UserDocument,
    "\n  query UserDisputeFilter($address: ID!, $where: Dispute_filter) {\n    user(id: $address) {\n      disputes(orderBy: lastPeriodChange, where: $where) {\n        id\n      }\n      ...UserDetails\n    }\n  }\n": types.UserDisputeFilterDocument,
    "\n  query VotingHistory($disputeID: ID!) {\n    dispute(id: $disputeID) {\n      id\n      rounds {\n        nbVotes\n        court {\n          id\n          name\n        }\n      }\n      disputeKitDispute {\n        localRounds {\n          ... on ClassicRound {\n            winningChoice\n            totalVoted\n            justifications {\n              id\n              juror {\n                id\n              }\n              choice\n              reference\n            }\n          }\n        }\n      }\n    }\n  }\n": types.VotingHistoryDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AllCases {\n    counter(id: 0) {\n      cases\n      casesRuled\n    }\n  }\n"): (typeof documents)["\n  query AllCases {\n    counter(id: 0) {\n      cases\n      casesRuled\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment DisputeDetails on Dispute {\n    id\n    arbitrated {\n      id\n    }\n    court {\n      id\n      policy\n      feeForJuror\n      timesPerPeriod\n    }\n    period\n    lastPeriodChange\n  }\n"): (typeof documents)["\n  fragment DisputeDetails on Dispute {\n    id\n    arbitrated {\n      id\n    }\n    court {\n      id\n      policy\n      feeForJuror\n      timesPerPeriod\n    }\n    period\n    lastPeriodChange\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CasesPageWhere($skip: Int, $where: Dispute_filter, $orderDirection: OrderDirection, $first: Int) {\n    disputes(first: $first, skip: $skip, orderBy: lastPeriodChange, orderDirection: $orderDirection, where: $where) {\n      ...DisputeDetails\n    }\n  }\n"): (typeof documents)["\n  query CasesPageWhere($skip: Int, $where: Dispute_filter, $orderDirection: OrderDirection, $first: Int) {\n    disputes(first: $first, skip: $skip, orderBy: lastPeriodChange, orderDirection: $orderDirection, where: $where) {\n      ...DisputeDetails\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CasesPage($skip: Int, $orderDirection: OrderDirection, $first: Int) {\n    disputes(first: $first, skip: $skip, orderBy: lastPeriodChange, orderDirection: $orderDirection) {\n      ...DisputeDetails\n    }\n  }\n"): (typeof documents)["\n  query CasesPage($skip: Int, $orderDirection: OrderDirection, $first: Int) {\n    disputes(first: $first, skip: $skip, orderBy: lastPeriodChange, orderDirection: $orderDirection) {\n      ...DisputeDetails\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MyCases($id: ID!, $skip: Int, $orderDirection: OrderDirection) {\n    user(id: $id) {\n      disputes(first: 3, skip: $skip, orderBy: lastPeriodChange, orderDirection: $orderDirection) {\n        ...DisputeDetails\n      }\n    }\n  }\n"): (typeof documents)["\n  query MyCases($id: ID!, $skip: Int, $orderDirection: OrderDirection) {\n    user(id: $id) {\n      disputes(first: 3, skip: $skip, orderBy: lastPeriodChange, orderDirection: $orderDirection) {\n        ...DisputeDetails\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query myCasesPageWhere($id: ID!, $skip: Int, $where: Dispute_filter, $orderDirection: OrderDirection) {\n    user(id: $id) {\n      disputes(first: 3, skip: $skip, orderBy: lastPeriodChange, orderDirection: $orderDirection, where: $where) {\n        ...DisputeDetails\n      }\n    }\n  }\n"): (typeof documents)["\n  query myCasesPageWhere($id: ID!, $skip: Int, $where: Dispute_filter, $orderDirection: OrderDirection) {\n    user(id: $id) {\n      disputes(first: 3, skip: $skip, orderBy: lastPeriodChange, orderDirection: $orderDirection, where: $where) {\n        ...DisputeDetails\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ClassicAppeal($disputeID: ID!, $orderBy: DisputeKitDispute_orderBy, $orderDirection: OrderDirection) {\n    dispute(id: $disputeID) {\n      period\n      court {\n        id\n        timesPerPeriod\n      }\n      arbitrated {\n        id\n      }\n      lastPeriodChange\n      disputeKitDispute(orderBy: $orderBy, orderDirection: $orderDirection) {\n        id\n        currentLocalRoundIndex\n        localRounds {\n          ... on ClassicRound {\n            winningChoice\n            paidFees\n            fundedChoices\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ClassicAppeal($disputeID: ID!, $orderBy: DisputeKitDispute_orderBy, $orderDirection: OrderDirection) {\n    dispute(id: $disputeID) {\n      period\n      court {\n        id\n        timesPerPeriod\n      }\n      arbitrated {\n        id\n      }\n      lastPeriodChange\n      disputeKitDispute(orderBy: $orderBy, orderDirection: $orderDirection) {\n        id\n        currentLocalRoundIndex\n        localRounds {\n          ... on ClassicRound {\n            winningChoice\n            paidFees\n            fundedChoices\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Counter {\n    counter(id: \"0\") {\n      id\n      cases\n      casesRuled\n      casesVoting\n      casesAppealing\n      stakedPNK\n      redistributedPNK\n      paidETH\n      activeJurors\n    }\n  }\n"): (typeof documents)["\n  query Counter {\n    counter(id: \"0\") {\n      id\n      cases\n      casesRuled\n      casesVoting\n      casesAppealing\n      stakedPNK\n      redistributedPNK\n      paidETH\n      activeJurors\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CourtDetails($id: ID!) {\n    court(id: $id) {\n      policy\n      minStake\n      alpha\n      numberDisputes\n      numberClosedDisputes\n      numberAppealingDisputes\n      numberStakedJurors\n      stake\n      paidETH\n      paidPNK\n      timesPerPeriod\n    }\n  }\n"): (typeof documents)["\n  query CourtDetails($id: ID!) {\n    court(id: $id) {\n      policy\n      minStake\n      alpha\n      numberDisputes\n      numberClosedDisputes\n      numberAppealingDisputes\n      numberStakedJurors\n      stake\n      paidETH\n      paidPNK\n      timesPerPeriod\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CourtPolicyURI($courtID: ID!) {\n    court(id: $courtID) {\n      policy\n    }\n  }\n"): (typeof documents)["\n  query CourtPolicyURI($courtID: ID!) {\n    court(id: $courtID) {\n      policy\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CourtTree {\n    court(id: \"1\") {\n      name\n      id\n      children(orderBy: name) {\n        name\n        id\n        children {\n          name\n          id\n          children {\n            name\n            id\n            children {\n              name\n              id\n              children {\n                name\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query CourtTree {\n    court(id: \"1\") {\n      name\n      id\n      children(orderBy: name) {\n        name\n        id\n        children {\n          name\n          id\n          children {\n            name\n            id\n            children {\n              name\n              id\n              children {\n                name\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query DisputeDetails($disputeID: ID!) {\n    dispute(id: $disputeID) {\n      court {\n        id\n        timesPerPeriod\n        hiddenVotes\n        feeForJuror\n      }\n      arbitrated {\n        id\n      }\n      period\n      ruled\n      lastPeriodChange\n      currentRuling\n      overridden\n      tied\n      currentRound {\n        id\n      }\n      currentRoundIndex\n    }\n  }\n"): (typeof documents)["\n  query DisputeDetails($disputeID: ID!) {\n    dispute(id: $disputeID) {\n      court {\n        id\n        timesPerPeriod\n        hiddenVotes\n        feeForJuror\n      }\n      arbitrated {\n        id\n      }\n      period\n      ruled\n      lastPeriodChange\n      currentRuling\n      overridden\n      tied\n      currentRound {\n        id\n      }\n      currentRoundIndex\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query DisputeTemplate($id: ID!) {\n    disputeTemplate(id: $id) {\n      id\n      templateTag\n      templateData\n      templateDataMappings\n    }\n  }\n"): (typeof documents)["\n  query DisputeTemplate($id: ID!) {\n    disputeTemplate(id: $id) {\n      id\n      templateTag\n      templateData\n      templateDataMappings\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Draw($address: String, $disputeID: String, $roundID: String) {\n    draws(first: 1000, where: { dispute: $disputeID, juror: $address, round: $roundID }) {\n      voteIDNum\n      vote {\n        ... on ClassicVote {\n          commit\n          commited\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Draw($address: String, $disputeID: String, $roundID: String) {\n    draws(first: 1000, where: { dispute: $disputeID, juror: $address, round: $roundID }) {\n      voteIDNum\n      vote {\n        ... on ClassicVote {\n          commit\n          commited\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Evidences($evidenceGroupID: String) {\n    evidences(where: { evidenceGroup: $evidenceGroupID }, orderBy: id, orderDirection: asc) {\n      id\n      evidence\n      sender {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query Evidences($evidenceGroupID: String) {\n    evidences(where: { evidenceGroup: $evidenceGroupID }, orderBy: id, orderDirection: asc) {\n      id\n      evidence\n      sender {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query HomePage($timeframe: ID) {\n    disputes(first: 3) {\n      id\n    }\n    counters(where: { id_gt: $timeframe }) {\n      id\n      stakedPNK\n      paidETH\n      redistributedPNK\n      activeJurors\n      cases\n    }\n  }\n"): (typeof documents)["\n  query HomePage($timeframe: ID) {\n    disputes(first: 3) {\n      id\n    }\n    counters(where: { id_gt: $timeframe }) {\n      id\n      stakedPNK\n      paidETH\n      redistributedPNK\n      activeJurors\n      cases\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query JurorStakeDetails($userId: String) {\n    jurorTokensPerCourts(where: { juror: $userId }) {\n      court {\n        id\n        name\n      }\n      staked\n      locked\n    }\n  }\n"): (typeof documents)["\n  query JurorStakeDetails($userId: String) {\n    jurorTokensPerCourts(where: { juror: $userId }) {\n      court {\n        id\n        name\n      }\n      staked\n      locked\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query TopUsersByCoherenceScore($first: Int!, $orderBy: User_orderBy, $orderDirection: OrderDirection) {\n    users(first: $first, orderBy: $orderBy, orderDirection: $orderDirection) {\n      id\n      coherenceScore\n      totalCoherent\n      totalResolvedDisputes\n    }\n  }\n"): (typeof documents)["\n  query TopUsersByCoherenceScore($first: Int!, $orderBy: User_orderBy, $orderDirection: OrderDirection) {\n    users(first: $first, orderBy: $orderBy, orderDirection: $orderDirection) {\n      id\n      coherenceScore\n      totalCoherent\n      totalResolvedDisputes\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment UserDetails on User {\n    totalDisputes\n    totalResolvedDisputes\n    totalAppealingDisputes\n    totalCoherent\n    coherenceScore\n    tokens {\n      court {\n        id\n        name\n      }\n    }\n    shifts {\n      pnkAmount\n      ethAmount\n    }\n  }\n"): (typeof documents)["\n  fragment UserDetails on User {\n    totalDisputes\n    totalResolvedDisputes\n    totalAppealingDisputes\n    totalCoherent\n    coherenceScore\n    tokens {\n      court {\n        id\n        name\n      }\n    }\n    shifts {\n      pnkAmount\n      ethAmount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query User($address: ID!) {\n    user(id: $address) {\n      disputes(orderBy: lastPeriodChange) {\n        id\n      }\n      ...UserDetails\n    }\n  }\n"): (typeof documents)["\n  query User($address: ID!) {\n    user(id: $address) {\n      disputes(orderBy: lastPeriodChange) {\n        id\n      }\n      ...UserDetails\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UserDisputeFilter($address: ID!, $where: Dispute_filter) {\n    user(id: $address) {\n      disputes(orderBy: lastPeriodChange, where: $where) {\n        id\n      }\n      ...UserDetails\n    }\n  }\n"): (typeof documents)["\n  query UserDisputeFilter($address: ID!, $where: Dispute_filter) {\n    user(id: $address) {\n      disputes(orderBy: lastPeriodChange, where: $where) {\n        id\n      }\n      ...UserDetails\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query VotingHistory($disputeID: ID!) {\n    dispute(id: $disputeID) {\n      id\n      rounds {\n        nbVotes\n        court {\n          id\n          name\n        }\n      }\n      disputeKitDispute {\n        localRounds {\n          ... on ClassicRound {\n            winningChoice\n            totalVoted\n            justifications {\n              id\n              juror {\n                id\n              }\n              choice\n              reference\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query VotingHistory($disputeID: ID!) {\n    dispute(id: $disputeID) {\n      id\n      rounds {\n        nbVotes\n        court {\n          id\n          name\n        }\n      }\n      disputeKitDispute {\n        localRounds {\n          ... on ClassicRound {\n            winningChoice\n            totalVoted\n            justifications {\n              id\n              juror {\n                id\n              }\n              choice\n              reference\n            }\n          }\n        }\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;
import {
  useContractRead,
  UseContractReadConfig,
  useContractWrite,
  Address,
  UseContractWriteConfig,
  usePrepareContractWrite,
  UsePrepareContractWriteConfig,
  useContractEvent,
  UseContractEventConfig,
} from 'wagmi'
import { ReadContractResult, WriteContractMode, PrepareWriteContractResult } from 'wagmi/actions'

import {
  getContract,
  GetContractArgs,
  readContract,
  ReadContractConfig,
  writeContract,
  WriteContractArgs,
  WriteContractPreparedArgs,
  WriteContractUnpreparedArgs,
  prepareWriteContract,
  PrepareWriteContractConfig,
  watchContractEvent,
  WatchContractEventConfig,
  WatchContractEventCallback,
} from 'wagmi/actions'

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ArbitrableExample
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const arbitrableExampleABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_arbitrator', internalType: 'contract IArbitratorV2', type: 'address' },
      { name: '_templateData', internalType: 'string', type: 'string' },
      { name: '_templateDataMappings', internalType: 'string', type: 'string' },
      { name: '_arbitratorExtraData', internalType: 'bytes', type: 'bytes' },
      { name: '_templateRegistry', internalType: 'contract IDisputeTemplateRegistry', type: 'address' },
      { name: '_weth', internalType: 'contract IERC20', type: 'address' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: '_action', internalType: 'string', type: 'string', indexed: true }],
    name: 'Action',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_arbitrator', internalType: 'contract IArbitratorV2', type: 'address', indexed: true },
      { name: '_arbitrableDisputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_externalDisputeID', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_templateId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_templateUri', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'DisputeRequest',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_arbitrator', internalType: 'contract IArbitratorV2', type: 'address', indexed: true },
      { name: '_disputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_ruling', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Ruling',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'arbitrator',
    outputs: [{ name: '', internalType: 'contract IArbitratorV2', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'arbitratorExtraData',
    outputs: [{ name: '', internalType: 'bytes', type: 'bytes' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_arbitrator', internalType: 'contract IArbitratorV2', type: 'address' }],
    name: 'changeArbitrator',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_arbitratorExtraData', internalType: 'bytes', type: 'bytes' }],
    name: 'changeArbitratorExtraData',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_templateData', internalType: 'string', type: 'string' },
      { name: '_templateDataMappings', internalType: 'string', type: 'string' },
    ],
    name: 'changeDisputeTemplate',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_templateRegistry', internalType: 'contract IDisputeTemplateRegistry', type: 'address' }],
    name: 'changeTemplateRegistry',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_action', internalType: 'string', type: 'string' },
      { name: '_feeInWeth', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'createDispute',
    outputs: [{ name: 'disputeID', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [{ name: '_action', internalType: 'string', type: 'string' }],
    name: 'createDispute',
    outputs: [{ name: 'disputeID', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'disputes',
    outputs: [
      { name: 'isRuled', internalType: 'bool', type: 'bool' },
      { name: 'ruling', internalType: 'uint256', type: 'uint256' },
      { name: 'numberOfRulingOptions', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'externalIDtoLocalID',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'governor',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_externalDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_ruling', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'rule',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'templateId',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'templateRegistry',
    outputs: [{ name: '', internalType: 'contract IDisputeTemplateRegistry', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'weth',
    outputs: [{ name: '', internalType: 'contract IERC20', type: 'address' }],
  },
] as const

/**
 *
 */
export const arbitrableExampleAddress = {
  421614: '0xE22500Fa27f696d06702367246bd17Bd2C8a4c5d',
} as const

/**
 *
 */
export const arbitrableExampleConfig = { address: arbitrableExampleAddress, abi: arbitrableExampleABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// BlockHashRNG
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const blockHashRngABI = [
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'randomNumbers',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_block', internalType: 'uint256', type: 'uint256' }],
    name: 'receiveRandomness',
    outputs: [{ name: 'randomNumber', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_block', internalType: 'uint256', type: 'uint256' }],
    name: 'requestRandomness',
    outputs: [],
  },
] as const

/**
 *
 */
export const blockHashRngAddress = {
  421614: '0x991d2df165670b9cac3B022f4B68D65b664222ea',
} as const

/**
 *
 */
export const blockHashRngConfig = { address: blockHashRngAddress, abi: blockHashRngABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// DAI
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const daiABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_name', internalType: 'string', type: 'string' },
      { name: '_symbol', internalType: 'string', type: 'string' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'subtractedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'addedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const

/**
 *
 */
export const daiAddress = {
  421614: '0xc34aeFEa232956542C5b2f2EE55fD5c378B35c03',
} as const

/**
 *
 */
export const daiConfig = { address: daiAddress, abi: daiABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// DAIFaucet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const daiFaucetABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [{ name: '_token', internalType: 'contract IERC20', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'amount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'balance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_amount', internalType: 'uint256', type: 'uint256' }],
    name: 'changeAmount',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_governor', internalType: 'address', type: 'address' }],
    name: 'changeGovernor',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'governor',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'request', outputs: [] },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'token',
    outputs: [{ name: '', internalType: 'contract IERC20', type: 'address' }],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'withdraw', outputs: [] },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'withdrewAlready',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const

/**
 *
 */
export const daiFaucetAddress = {
  421614: '0x1Fa58B52326488D62A406E71DBaD839560e810fF',
} as const

/**
 *
 */
export const daiFaucetConfig = { address: daiFaucetAddress, abi: daiFaucetABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// DisputeKitClassic
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const disputeKitClassicABI = [
  { stateMutability: 'payable', type: 'fallback' },
  { stateMutability: 'payable', type: 'receive' },
  { type: 'error', inputs: [], name: 'AlreadyInitialized' },
  { type: 'error', inputs: [], name: 'FailedDelegateCall' },
  {
    type: 'error',
    inputs: [{ name: 'implementation', internalType: 'address', type: 'address' }],
    name: 'InvalidImplementation',
  },
  { type: 'error', inputs: [], name: 'NotInitializing' },
  { type: 'error', inputs: [], name: 'UUPSUnauthorizedCallContext' },
  {
    type: 'error',
    inputs: [{ name: 'slot', internalType: 'bytes32', type: 'bytes32' }],
    name: 'UUPSUnsupportedProxiableUUID',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_coreRoundID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_choice', internalType: 'uint256', type: 'uint256', indexed: true },
    ],
    name: 'ChoiceFunded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_juror', internalType: 'address', type: 'address', indexed: true },
      { name: '_voteIDs', internalType: 'uint256[]', type: 'uint256[]', indexed: false },
      { name: '_commit', internalType: 'bytes32', type: 'bytes32', indexed: false },
    ],
    name: 'CommitCast',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_coreRoundID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_choice', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_contributor', internalType: 'address', type: 'address', indexed: true },
      { name: '_amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Contribution',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_numberOfChoices', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_extraData', internalType: 'bytes', type: 'bytes', indexed: false },
    ],
    name: 'DisputeCreation',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'version', internalType: 'uint64', type: 'uint64', indexed: false }],
    name: 'Initialized',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'newImplementation', internalType: 'address', type: 'address', indexed: true }],
    name: 'Upgraded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_juror', internalType: 'address', type: 'address', indexed: true },
      { name: '_voteIDs', internalType: 'uint256[]', type: 'uint256[]', indexed: false },
      { name: '_choice', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_justification', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'VoteCast',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_coreRoundID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_choice', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_contributor', internalType: 'address', type: 'address', indexed: true },
      { name: '_amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Withdrawal',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'LOSER_APPEAL_PERIOD_MULTIPLIER',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'LOSER_STAKE_MULTIPLIER',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'ONE_BASIS_POINT',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'WINNER_STAKE_MULTIPLIER',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' }],
    name: 'areCommitsAllCast',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' }],
    name: 'areVotesAllCast',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_voteIDs', internalType: 'uint256[]', type: 'uint256[]' },
      { name: '_commit', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'castCommit',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_voteIDs', internalType: 'uint256[]', type: 'uint256[]' },
      { name: '_choice', internalType: 'uint256', type: 'uint256' },
      { name: '_salt', internalType: 'uint256', type: 'uint256' },
      { name: '_justification', internalType: 'string', type: 'string' },
    ],
    name: 'castVote',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_core', internalType: 'address', type: 'address' }],
    name: 'changeCore',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_governor', internalType: 'address payable', type: 'address' }],
    name: 'changeGovernor',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'core',
    outputs: [{ name: '', internalType: 'contract KlerosCore', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'coreDisputeIDToLocal',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_numberOfChoices', internalType: 'uint256', type: 'uint256' },
      { name: '_extraData', internalType: 'bytes', type: 'bytes' },
      { name: '_nbVotes', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'createDispute',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' }],
    name: 'currentRuling',
    outputs: [
      { name: 'ruling', internalType: 'uint256', type: 'uint256' },
      { name: 'tied', internalType: 'bool', type: 'bool' },
      { name: 'overridden', internalType: 'bool', type: 'bool' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'disputes',
    outputs: [
      { name: 'numberOfChoices', internalType: 'uint256', type: 'uint256' },
      { name: 'jumped', internalType: 'bool', type: 'bool' },
      { name: 'extraData', internalType: 'bytes', type: 'bytes' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_nonce', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'draw',
    outputs: [{ name: 'drawnAddress', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_destination', internalType: 'address', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'executeGovernorProposal',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_choice', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'fundAppeal',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_coreRoundID', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getCoherentCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_coreRoundID', internalType: 'uint256', type: 'uint256' },
      { name: '_voteID', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getDegreeOfCoherence',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' }],
    name: 'getFundedChoices',
    outputs: [{ name: 'fundedChoices', internalType: 'uint256[]', type: 'uint256[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_coreRoundID', internalType: 'uint256', type: 'uint256' },
      { name: '_choice', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getRoundInfo',
    outputs: [
      { name: 'winningChoice', internalType: 'uint256', type: 'uint256' },
      { name: 'tied', internalType: 'bool', type: 'bool' },
      { name: 'totalVoted', internalType: 'uint256', type: 'uint256' },
      { name: 'totalCommited', internalType: 'uint256', type: 'uint256' },
      { name: 'nbVoters', internalType: 'uint256', type: 'uint256' },
      { name: 'choiceCount', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_coreRoundID', internalType: 'uint256', type: 'uint256' },
      { name: '_voteID', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getVoteInfo',
    outputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'commit', internalType: 'bytes32', type: 'bytes32' },
      { name: 'choice', internalType: 'uint256', type: 'uint256' },
      { name: 'voted', internalType: 'bool', type: 'bool' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'governor',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_governor', internalType: 'address', type: 'address' },
      { name: '_core', internalType: 'contract KlerosCore', type: 'address' },
    ],
    name: 'initialize',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_coreRoundID', internalType: 'uint256', type: 'uint256' },
      { name: '_voteID', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'isVoteActive',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'proxiableUUID',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'upgradeToAndCall',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_beneficiary', internalType: 'address payable', type: 'address' },
      { name: '_coreRoundID', internalType: 'uint256', type: 'uint256' },
      { name: '_choice', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'withdrawFeesAndRewards',
    outputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_implementation', internalType: 'address', type: 'address' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
  },
] as const

/**
 *
 */
export const disputeKitClassicAddress = {
  421614: '0x8078C2A3bf93f6f69BDD4D38233E7e219eA1914e',
} as const

/**
 *
 */
export const disputeKitClassicConfig = { address: disputeKitClassicAddress, abi: disputeKitClassicABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// DisputeKitClassic_Implementation
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const disputeKitClassicImplementationABI = [
  { stateMutability: 'nonpayable', type: 'constructor', inputs: [] },
  { type: 'error', inputs: [], name: 'AlreadyInitialized' },
  { type: 'error', inputs: [], name: 'FailedDelegateCall' },
  {
    type: 'error',
    inputs: [{ name: 'implementation', internalType: 'address', type: 'address' }],
    name: 'InvalidImplementation',
  },
  { type: 'error', inputs: [], name: 'NotInitializing' },
  { type: 'error', inputs: [], name: 'UUPSUnauthorizedCallContext' },
  {
    type: 'error',
    inputs: [{ name: 'slot', internalType: 'bytes32', type: 'bytes32' }],
    name: 'UUPSUnsupportedProxiableUUID',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_coreRoundID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_choice', internalType: 'uint256', type: 'uint256', indexed: true },
    ],
    name: 'ChoiceFunded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_juror', internalType: 'address', type: 'address', indexed: true },
      { name: '_voteIDs', internalType: 'uint256[]', type: 'uint256[]', indexed: false },
      { name: '_commit', internalType: 'bytes32', type: 'bytes32', indexed: false },
    ],
    name: 'CommitCast',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_coreRoundID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_choice', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_contributor', internalType: 'address', type: 'address', indexed: true },
      { name: '_amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Contribution',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_numberOfChoices', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_extraData', internalType: 'bytes', type: 'bytes', indexed: false },
    ],
    name: 'DisputeCreation',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'version', internalType: 'uint64', type: 'uint64', indexed: false }],
    name: 'Initialized',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'newImplementation', internalType: 'address', type: 'address', indexed: true }],
    name: 'Upgraded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_juror', internalType: 'address', type: 'address', indexed: true },
      { name: '_voteIDs', internalType: 'uint256[]', type: 'uint256[]', indexed: false },
      { name: '_choice', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_justification', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'VoteCast',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_coreRoundID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_choice', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_contributor', internalType: 'address', type: 'address', indexed: true },
      { name: '_amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Withdrawal',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'LOSER_APPEAL_PERIOD_MULTIPLIER',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'LOSER_STAKE_MULTIPLIER',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'ONE_BASIS_POINT',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'WINNER_STAKE_MULTIPLIER',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' }],
    name: 'areCommitsAllCast',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' }],
    name: 'areVotesAllCast',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_voteIDs', internalType: 'uint256[]', type: 'uint256[]' },
      { name: '_commit', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'castCommit',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_voteIDs', internalType: 'uint256[]', type: 'uint256[]' },
      { name: '_choice', internalType: 'uint256', type: 'uint256' },
      { name: '_salt', internalType: 'uint256', type: 'uint256' },
      { name: '_justification', internalType: 'string', type: 'string' },
    ],
    name: 'castVote',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_core', internalType: 'address', type: 'address' }],
    name: 'changeCore',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_governor', internalType: 'address payable', type: 'address' }],
    name: 'changeGovernor',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'core',
    outputs: [{ name: '', internalType: 'contract KlerosCore', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'coreDisputeIDToLocal',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_numberOfChoices', internalType: 'uint256', type: 'uint256' },
      { name: '_extraData', internalType: 'bytes', type: 'bytes' },
      { name: '_nbVotes', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'createDispute',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' }],
    name: 'currentRuling',
    outputs: [
      { name: 'ruling', internalType: 'uint256', type: 'uint256' },
      { name: 'tied', internalType: 'bool', type: 'bool' },
      { name: 'overridden', internalType: 'bool', type: 'bool' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'disputes',
    outputs: [
      { name: 'numberOfChoices', internalType: 'uint256', type: 'uint256' },
      { name: 'jumped', internalType: 'bool', type: 'bool' },
      { name: 'extraData', internalType: 'bytes', type: 'bytes' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_nonce', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'draw',
    outputs: [{ name: 'drawnAddress', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_destination', internalType: 'address', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'executeGovernorProposal',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_choice', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'fundAppeal',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_coreRoundID', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getCoherentCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_coreRoundID', internalType: 'uint256', type: 'uint256' },
      { name: '_voteID', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getDegreeOfCoherence',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' }],
    name: 'getFundedChoices',
    outputs: [{ name: 'fundedChoices', internalType: 'uint256[]', type: 'uint256[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_coreRoundID', internalType: 'uint256', type: 'uint256' },
      { name: '_choice', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getRoundInfo',
    outputs: [
      { name: 'winningChoice', internalType: 'uint256', type: 'uint256' },
      { name: 'tied', internalType: 'bool', type: 'bool' },
      { name: 'totalVoted', internalType: 'uint256', type: 'uint256' },
      { name: 'totalCommited', internalType: 'uint256', type: 'uint256' },
      { name: 'nbVoters', internalType: 'uint256', type: 'uint256' },
      { name: 'choiceCount', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_coreRoundID', internalType: 'uint256', type: 'uint256' },
      { name: '_voteID', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getVoteInfo',
    outputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'commit', internalType: 'bytes32', type: 'bytes32' },
      { name: 'choice', internalType: 'uint256', type: 'uint256' },
      { name: 'voted', internalType: 'bool', type: 'bool' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'governor',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_governor', internalType: 'address', type: 'address' },
      { name: '_core', internalType: 'contract KlerosCore', type: 'address' },
    ],
    name: 'initialize',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_coreRoundID', internalType: 'uint256', type: 'uint256' },
      { name: '_voteID', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'isVoteActive',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'proxiableUUID',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'upgradeToAndCall',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_beneficiary', internalType: 'address payable', type: 'address' },
      { name: '_coreRoundID', internalType: 'uint256', type: 'uint256' },
      { name: '_choice', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'withdrawFeesAndRewards',
    outputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256' }],
  },
] as const

/**
 *
 */
export const disputeKitClassicImplementationAddress = {
  421614: '0x2507018D785CE92115CfebE0d92CC496C42e99b7',
} as const

/**
 *
 */
export const disputeKitClassicImplementationConfig = {
  address: disputeKitClassicImplementationAddress,
  abi: disputeKitClassicImplementationABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// DisputeKitClassic_Proxy
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const disputeKitClassicProxyABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_implementation', internalType: 'address', type: 'address' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
  },
  { stateMutability: 'payable', type: 'fallback' },
  { stateMutability: 'payable', type: 'receive' },
] as const

/**
 *
 */
export const disputeKitClassicProxyAddress = {
  421614: '0x8078C2A3bf93f6f69BDD4D38233E7e219eA1914e',
} as const

/**
 *
 */
export const disputeKitClassicProxyConfig = {
  address: disputeKitClassicProxyAddress,
  abi: disputeKitClassicProxyABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// DisputeResolver
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const disputeResolverABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_arbitrator', internalType: 'contract IArbitratorV2', type: 'address' },
      { name: '_templateRegistry', internalType: 'contract IDisputeTemplateRegistry', type: 'address' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_arbitrator', internalType: 'contract IArbitratorV2', type: 'address', indexed: true },
      { name: '_arbitrableDisputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_externalDisputeID', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_templateId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_templateUri', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'DisputeRequest',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_arbitrator', internalType: 'contract IArbitratorV2', type: 'address', indexed: true },
      { name: '_disputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_ruling', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Ruling',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'arbitrator',
    outputs: [{ name: '', internalType: 'contract IArbitratorV2', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'arbitratorDisputeIDToLocalID',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_arbitrator', internalType: 'contract IArbitratorV2', type: 'address' }],
    name: 'changeArbitrator',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_governor', internalType: 'address', type: 'address' }],
    name: 'changeGovernor',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_templateRegistry', internalType: 'contract IDisputeTemplateRegistry', type: 'address' }],
    name: 'changeTemplateRegistry',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: '_arbitratorExtraData', internalType: 'bytes', type: 'bytes' },
      { name: '_disputeTemplate', internalType: 'string', type: 'string' },
      { name: '_disputeTemplateDataMappings', internalType: 'string', type: 'string' },
      { name: '_numberOfRulingOptions', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'createDisputeForTemplate',
    outputs: [{ name: 'disputeID', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: '_arbitratorExtraData', internalType: 'bytes', type: 'bytes' },
      { name: '_disputeTemplateUri', internalType: 'string', type: 'string' },
      { name: '_numberOfRulingOptions', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'createDisputeForTemplateUri',
    outputs: [{ name: 'disputeID', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'disputes',
    outputs: [
      { name: 'arbitratorExtraData', internalType: 'bytes', type: 'bytes' },
      { name: 'isRuled', internalType: 'bool', type: 'bool' },
      { name: 'ruling', internalType: 'uint256', type: 'uint256' },
      { name: 'numberOfRulingOptions', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'governor',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_externalDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_ruling', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'rule',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'templateRegistry',
    outputs: [{ name: '', internalType: 'contract IDisputeTemplateRegistry', type: 'address' }],
  },
] as const

/**
 *
 */
export const disputeResolverAddress = {
  421614: '0x48e052B4A6dC4F30e90930F1CeaAFd83b3981EB3',
} as const

/**
 *
 */
export const disputeResolverConfig = { address: disputeResolverAddress, abi: disputeResolverABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// DisputeTemplateRegistry
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const disputeTemplateRegistryABI = [
  { stateMutability: 'payable', type: 'fallback' },
  { stateMutability: 'payable', type: 'receive' },
  { type: 'error', inputs: [], name: 'AlreadyInitialized' },
  { type: 'error', inputs: [], name: 'FailedDelegateCall' },
  {
    type: 'error',
    inputs: [{ name: 'implementation', internalType: 'address', type: 'address' }],
    name: 'InvalidImplementation',
  },
  { type: 'error', inputs: [], name: 'NotInitializing' },
  { type: 'error', inputs: [], name: 'UUPSUnauthorizedCallContext' },
  {
    type: 'error',
    inputs: [{ name: 'slot', internalType: 'bytes32', type: 'bytes32' }],
    name: 'UUPSUnsupportedProxiableUUID',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_templateId', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_templateTag', internalType: 'string', type: 'string', indexed: true },
      { name: '_templateData', internalType: 'string', type: 'string', indexed: false },
      { name: '_templateDataMappings', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'DisputeTemplate',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'version', internalType: 'uint64', type: 'uint64', indexed: false }],
    name: 'Initialized',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'newImplementation', internalType: 'address', type: 'address', indexed: true }],
    name: 'Upgraded',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_governor', internalType: 'address', type: 'address' }],
    name: 'changeGovernor',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'governor',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_governor', internalType: 'address', type: 'address' }],
    name: 'initialize',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'proxiableUUID',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_templateTag', internalType: 'string', type: 'string' },
      { name: '_templateData', internalType: 'string', type: 'string' },
      { name: '_templateDataMappings', internalType: 'string', type: 'string' },
    ],
    name: 'setDisputeTemplate',
    outputs: [{ name: 'templateId', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'templates',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'upgradeToAndCall',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_implementation', internalType: 'address', type: 'address' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
  },
] as const

/**
 *
 */
export const disputeTemplateRegistryAddress = {
  421614: '0x553dcbF6aB3aE06a1064b5200Df1B5A9fB403d3c',
} as const

/**
 *
 */
export const disputeTemplateRegistryConfig = {
  address: disputeTemplateRegistryAddress,
  abi: disputeTemplateRegistryABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// DisputeTemplateRegistry_Implementation
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const disputeTemplateRegistryImplementationABI = [
  { stateMutability: 'nonpayable', type: 'constructor', inputs: [] },
  { type: 'error', inputs: [], name: 'AlreadyInitialized' },
  { type: 'error', inputs: [], name: 'FailedDelegateCall' },
  {
    type: 'error',
    inputs: [{ name: 'implementation', internalType: 'address', type: 'address' }],
    name: 'InvalidImplementation',
  },
  { type: 'error', inputs: [], name: 'NotInitializing' },
  { type: 'error', inputs: [], name: 'UUPSUnauthorizedCallContext' },
  {
    type: 'error',
    inputs: [{ name: 'slot', internalType: 'bytes32', type: 'bytes32' }],
    name: 'UUPSUnsupportedProxiableUUID',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_templateId', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_templateTag', internalType: 'string', type: 'string', indexed: true },
      { name: '_templateData', internalType: 'string', type: 'string', indexed: false },
      { name: '_templateDataMappings', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'DisputeTemplate',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'version', internalType: 'uint64', type: 'uint64', indexed: false }],
    name: 'Initialized',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'newImplementation', internalType: 'address', type: 'address', indexed: true }],
    name: 'Upgraded',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_governor', internalType: 'address', type: 'address' }],
    name: 'changeGovernor',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'governor',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_governor', internalType: 'address', type: 'address' }],
    name: 'initialize',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'proxiableUUID',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_templateTag', internalType: 'string', type: 'string' },
      { name: '_templateData', internalType: 'string', type: 'string' },
      { name: '_templateDataMappings', internalType: 'string', type: 'string' },
    ],
    name: 'setDisputeTemplate',
    outputs: [{ name: 'templateId', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'templates',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'upgradeToAndCall',
    outputs: [],
  },
] as const

/**
 *
 */
export const disputeTemplateRegistryImplementationAddress = {
  421614: '0x15E5964C7751dF8563eA4bC000301582C79BC454',
} as const

/**
 *
 */
export const disputeTemplateRegistryImplementationConfig = {
  address: disputeTemplateRegistryImplementationAddress,
  abi: disputeTemplateRegistryImplementationABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// DisputeTemplateRegistry_Proxy
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const disputeTemplateRegistryProxyABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_implementation', internalType: 'address', type: 'address' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
  },
  { stateMutability: 'payable', type: 'fallback' },
  { stateMutability: 'payable', type: 'receive' },
] as const

/**
 *
 */
export const disputeTemplateRegistryProxyAddress = {
  421614: '0x553dcbF6aB3aE06a1064b5200Df1B5A9fB403d3c',
} as const

/**
 *
 */
export const disputeTemplateRegistryProxyConfig = {
  address: disputeTemplateRegistryProxyAddress,
  abi: disputeTemplateRegistryProxyABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Escrow
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const escrowABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_arbitrator', internalType: 'contract IArbitratorV2', type: 'address' },
      { name: '_arbitratorExtraData', internalType: 'bytes', type: 'bytes' },
      { name: '_templateData', internalType: 'string', type: 'string' },
      { name: '_templateDataMappings', internalType: 'string', type: 'string' },
      { name: '_templateRegistry', internalType: 'contract IDisputeTemplateRegistry', type: 'address' },
      { name: '_feeTimeout', internalType: 'uint256', type: 'uint256' },
    ],
  },
  { type: 'error', inputs: [], name: 'ArbitratorOnly' },
  { type: 'error', inputs: [], name: 'BuyerFeeNotCoverArbitrationCosts' },
  { type: 'error', inputs: [], name: 'BuyerOnly' },
  { type: 'error', inputs: [], name: 'DeadlineNotPassed' },
  { type: 'error', inputs: [], name: 'DisputeAlreadyCreatedOrTransactionAlreadyExecuted' },
  { type: 'error', inputs: [], name: 'DisputeAlreadyResolved' },
  { type: 'error', inputs: [], name: 'GovernorOnly' },
  { type: 'error', inputs: [], name: 'InvalidRuling' },
  { type: 'error', inputs: [], name: 'MaximumPaymentAmountExceeded' },
  { type: 'error', inputs: [], name: 'NotWaitingForBuyerFees' },
  { type: 'error', inputs: [], name: 'NotWaitingForSellerFees' },
  { type: 'error', inputs: [], name: 'SellerFeeNotCoverArbitrationCosts' },
  { type: 'error', inputs: [], name: 'SellerOnly' },
  { type: 'error', inputs: [], name: 'TimeoutNotPassed' },
  { type: 'error', inputs: [], name: 'TransactionDisputed' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_arbitrator', internalType: 'contract IArbitratorV2', type: 'address', indexed: true },
      { name: '_arbitrableDisputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_externalDisputeID', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_templateId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_templateUri', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'DisputeRequest',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_transactionID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_party', internalType: 'enum Escrow.Party', type: 'uint8', indexed: false },
    ],
    name: 'HasToPayFee',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_transactionID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_amount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_party', internalType: 'address', type: 'address', indexed: false },
    ],
    name: 'Payment',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_arbitrator', internalType: 'contract IArbitratorV2', type: 'address', indexed: true },
      { name: '_disputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_ruling', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Ruling',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_transactionID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_buyer', internalType: 'address', type: 'address', indexed: true },
      { name: '_seller', internalType: 'address', type: 'address', indexed: true },
      { name: '_amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'TransactionCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_transactionID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_resolution', internalType: 'enum Escrow.Resolution', type: 'uint8', indexed: true },
    ],
    name: 'TransactionResolved',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'AMOUNT_OF_CHOICES',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'arbitrator',
    outputs: [{ name: '', internalType: 'contract IArbitratorV2', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'arbitratorExtraData',
    outputs: [{ name: '', internalType: 'bytes', type: 'bytes' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_arbitrator', internalType: 'contract IArbitratorV2', type: 'address' }],
    name: 'changeArbitrator',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_arbitratorExtraData', internalType: 'bytes', type: 'bytes' }],
    name: 'changeArbitratorExtraData',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_templateData', internalType: 'string', type: 'string' },
      { name: '_templateDataMappings', internalType: 'string', type: 'string' },
    ],
    name: 'changeDisputeTemplate',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_templateRegistry', internalType: 'contract IDisputeTemplateRegistry', type: 'address' }],
    name: 'changeTemplateRegistry',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: '_timeoutPayment', internalType: 'uint256', type: 'uint256' },
      { name: '_seller', internalType: 'address payable', type: 'address' },
      { name: '_templateData', internalType: 'string', type: 'string' },
      { name: '_templateDataMappings', internalType: 'string', type: 'string' },
    ],
    name: 'createTransaction',
    outputs: [{ name: 'transactionID', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'disputeIDtoTransactionID',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_transactionID', internalType: 'uint256', type: 'uint256' }],
    name: 'executeTransaction',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'feeTimeout',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getCountTransactions',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'governor',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_transactionID', internalType: 'uint256', type: 'uint256' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'pay',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [{ name: '_transactionID', internalType: 'uint256', type: 'uint256' }],
    name: 'payArbitrationFeeByBuyer',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [{ name: '_transactionID', internalType: 'uint256', type: 'uint256' }],
    name: 'payArbitrationFeeBySeller',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_transactionID', internalType: 'uint256', type: 'uint256' },
      { name: '_amountReimbursed', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'reimburse',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_disputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_ruling', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'rule',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'templateId',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'templateRegistry',
    outputs: [{ name: '', internalType: 'contract IDisputeTemplateRegistry', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_transactionID', internalType: 'uint256', type: 'uint256' }],
    name: 'timeOutByBuyer',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_transactionID', internalType: 'uint256', type: 'uint256' }],
    name: 'timeOutBySeller',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'transactions',
    outputs: [
      { name: 'buyer', internalType: 'address payable', type: 'address' },
      { name: 'seller', internalType: 'address payable', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
      { name: 'disputeID', internalType: 'uint256', type: 'uint256' },
      { name: 'buyerFee', internalType: 'uint256', type: 'uint256' },
      { name: 'sellerFee', internalType: 'uint256', type: 'uint256' },
      { name: 'lastFeePaymentTime', internalType: 'uint256', type: 'uint256' },
      { name: 'templateData', internalType: 'string', type: 'string' },
      { name: 'templateDataMappings', internalType: 'string', type: 'string' },
      { name: 'status', internalType: 'enum Escrow.Status', type: 'uint8' },
    ],
  },
] as const

/**
 *
 */
export const escrowAddress = {
  421614: '0xF1a7Cd3115F5852966430f8E3877D2221F074A2e',
} as const

/**
 *
 */
export const escrowConfig = { address: escrowAddress, abi: escrowABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// EvidenceModule
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const evidenceModuleABI = [
  { stateMutability: 'payable', type: 'fallback' },
  { stateMutability: 'payable', type: 'receive' },
  { type: 'error', inputs: [], name: 'AlreadyInitialized' },
  { type: 'error', inputs: [], name: 'FailedDelegateCall' },
  {
    type: 'error',
    inputs: [{ name: 'implementation', internalType: 'address', type: 'address' }],
    name: 'InvalidImplementation',
  },
  { type: 'error', inputs: [], name: 'NotInitializing' },
  { type: 'error', inputs: [], name: 'UUPSUnauthorizedCallContext' },
  {
    type: 'error',
    inputs: [{ name: 'slot', internalType: 'bytes32', type: 'bytes32' }],
    name: 'UUPSUnsupportedProxiableUUID',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_externalDisputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_party', internalType: 'address', type: 'address', indexed: true },
      { name: '_evidence', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'Evidence',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'version', internalType: 'uint64', type: 'uint64', indexed: false }],
    name: 'Initialized',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'newImplementation', internalType: 'address', type: 'address', indexed: true }],
    name: 'Upgraded',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'governor',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_governor', internalType: 'address', type: 'address' }],
    name: 'initialize',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'proxiableUUID',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_externalDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_evidence', internalType: 'string', type: 'string' },
    ],
    name: 'submitEvidence',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'upgradeToAndCall',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_implementation', internalType: 'address', type: 'address' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
  },
] as const

/**
 *
 */
export const evidenceModuleAddress = {
  421614: '0xE4066AE16685F66e30fb22e932B67E49220095c0',
} as const

/**
 *
 */
export const evidenceModuleConfig = { address: evidenceModuleAddress, abi: evidenceModuleABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// EvidenceModule_Implementation
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const evidenceModuleImplementationABI = [
  { stateMutability: 'nonpayable', type: 'constructor', inputs: [] },
  { type: 'error', inputs: [], name: 'AlreadyInitialized' },
  { type: 'error', inputs: [], name: 'FailedDelegateCall' },
  {
    type: 'error',
    inputs: [{ name: 'implementation', internalType: 'address', type: 'address' }],
    name: 'InvalidImplementation',
  },
  { type: 'error', inputs: [], name: 'NotInitializing' },
  { type: 'error', inputs: [], name: 'UUPSUnauthorizedCallContext' },
  {
    type: 'error',
    inputs: [{ name: 'slot', internalType: 'bytes32', type: 'bytes32' }],
    name: 'UUPSUnsupportedProxiableUUID',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_externalDisputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_party', internalType: 'address', type: 'address', indexed: true },
      { name: '_evidence', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'Evidence',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'version', internalType: 'uint64', type: 'uint64', indexed: false }],
    name: 'Initialized',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'newImplementation', internalType: 'address', type: 'address', indexed: true }],
    name: 'Upgraded',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'governor',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_governor', internalType: 'address', type: 'address' }],
    name: 'initialize',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'proxiableUUID',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_externalDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_evidence', internalType: 'string', type: 'string' },
    ],
    name: 'submitEvidence',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'upgradeToAndCall',
    outputs: [],
  },
] as const

/**
 *
 */
export const evidenceModuleImplementationAddress = {
  421614: '0xD8609345DEe222051337b3A8335581Cc630Df2E9',
} as const

/**
 *
 */
export const evidenceModuleImplementationConfig = {
  address: evidenceModuleImplementationAddress,
  abi: evidenceModuleImplementationABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// EvidenceModule_Proxy
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const evidenceModuleProxyABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_implementation', internalType: 'address', type: 'address' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
  },
  { stateMutability: 'payable', type: 'fallback' },
  { stateMutability: 'payable', type: 'receive' },
] as const

/**
 *
 */
export const evidenceModuleProxyAddress = {
  421614: '0xE4066AE16685F66e30fb22e932B67E49220095c0',
} as const

/**
 *
 */
export const evidenceModuleProxyConfig = { address: evidenceModuleProxyAddress, abi: evidenceModuleProxyABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IArbitrableV2
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const iArbitrableV2ABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_arbitrator', internalType: 'contract IArbitratorV2', type: 'address', indexed: true },
      { name: '_arbitrableDisputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_externalDisputeID', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_templateId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_templateUri', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'DisputeRequest',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_arbitrator', internalType: 'contract IArbitratorV2', type: 'address', indexed: true },
      { name: '_disputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_ruling', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Ruling',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_disputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_ruling', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'rule',
    outputs: [],
  },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IHomeGateway
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const iHomeGatewayABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_arbitrator', internalType: 'contract IArbitratorV2', type: 'address', indexed: false },
      { name: '_arbitrableChainId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_arbitrable', internalType: 'address', type: 'address', indexed: true },
      { name: '_arbitrableDisputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_arbitratorDisputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_externalDisputeID', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_templateId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_templateUri', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'CrossChainDisputeIncoming',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_arbitrator', internalType: 'contract IArbitratorV2', type: 'address', indexed: true },
      { name: '_arbitrableDisputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_externalDisputeID', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_templateId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_templateUri', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'DisputeRequest',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_arbitrator', internalType: 'contract IArbitratorV2', type: 'address', indexed: true },
      { name: '_disputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_ruling', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Ruling',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_disputeHash', internalType: 'bytes32', type: 'bytes32' }],
    name: 'disputeHashToHomeID',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'feeToken',
    outputs: [{ name: '', internalType: 'contract IERC20', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'foreignChainID',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'foreignGateway',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'receiverGateway',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: '_params',
        internalType: 'struct IHomeGateway.RelayCreateDisputeParams',
        type: 'tuple',
        components: [
          { name: 'foreignBlockHash', internalType: 'bytes32', type: 'bytes32' },
          { name: 'foreignChainID', internalType: 'uint256', type: 'uint256' },
          { name: 'foreignArbitrable', internalType: 'address', type: 'address' },
          { name: 'foreignDisputeID', internalType: 'uint256', type: 'uint256' },
          { name: 'externalDisputeID', internalType: 'uint256', type: 'uint256' },
          { name: 'templateId', internalType: 'uint256', type: 'uint256' },
          { name: 'templateUri', internalType: 'string', type: 'string' },
          { name: 'choices', internalType: 'uint256', type: 'uint256' },
          { name: 'extraData', internalType: 'bytes', type: 'bytes' },
        ],
      },
      { name: '_feeAmount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'relayCreateDispute',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      {
        name: '_params',
        internalType: 'struct IHomeGateway.RelayCreateDisputeParams',
        type: 'tuple',
        components: [
          { name: 'foreignBlockHash', internalType: 'bytes32', type: 'bytes32' },
          { name: 'foreignChainID', internalType: 'uint256', type: 'uint256' },
          { name: 'foreignArbitrable', internalType: 'address', type: 'address' },
          { name: 'foreignDisputeID', internalType: 'uint256', type: 'uint256' },
          { name: 'externalDisputeID', internalType: 'uint256', type: 'uint256' },
          { name: 'templateId', internalType: 'uint256', type: 'uint256' },
          { name: 'templateUri', internalType: 'string', type: 'string' },
          { name: 'choices', internalType: 'uint256', type: 'uint256' },
          { name: 'extraData', internalType: 'bytes', type: 'bytes' },
        ],
      },
    ],
    name: 'relayCreateDispute',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_disputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_ruling', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'rule',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'veaInbox',
    outputs: [{ name: '', internalType: 'contract IVeaInbox', type: 'address' }],
  },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// KlerosCore
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const klerosCoreABI = [
  { stateMutability: 'payable', type: 'fallback' },
  { stateMutability: 'payable', type: 'receive' },
  { type: 'error', inputs: [], name: 'AlreadyInitialized' },
  { type: 'error', inputs: [], name: 'AppealFeesNotEnough' },
  { type: 'error', inputs: [], name: 'AppealPeriodNotPassed' },
  { type: 'error', inputs: [], name: 'ArbitrationFeesNotEnough' },
  { type: 'error', inputs: [], name: 'ArraysLengthMismatch' },
  { type: 'error', inputs: [], name: 'CannotDisableClassicDK' },
  { type: 'error', inputs: [], name: 'CommitPeriodNotPassed' },
  { type: 'error', inputs: [], name: 'DepthLevelMax' },
  { type: 'error', inputs: [], name: 'DisputeKitNotSupportedByCourt' },
  { type: 'error', inputs: [], name: 'DisputeKitOnly' },
  { type: 'error', inputs: [], name: 'DisputeNotAppealable' },
  { type: 'error', inputs: [], name: 'DisputePeriodIsFinal' },
  { type: 'error', inputs: [], name: 'DisputeStillDrawing' },
  { type: 'error', inputs: [], name: 'EvidenceNotPassedAndNotAppeal' },
  { type: 'error', inputs: [], name: 'FailedDelegateCall' },
  { type: 'error', inputs: [], name: 'GovernorOnly' },
  { type: 'error', inputs: [], name: 'InvalidDisputKitParent' },
  { type: 'error', inputs: [], name: 'InvalidForkingCourtAsParent' },
  {
    type: 'error',
    inputs: [{ name: 'implementation', internalType: 'address', type: 'address' }],
    name: 'InvalidImplementation',
  },
  { type: 'error', inputs: [], name: 'MinStakeLowerThanParentCourt' },
  { type: 'error', inputs: [], name: 'MustSupportDisputeKitClassic' },
  { type: 'error', inputs: [], name: 'NotEvidencePeriod' },
  { type: 'error', inputs: [], name: 'NotExecutionPeriod' },
  { type: 'error', inputs: [], name: 'NotInitializing' },
  { type: 'error', inputs: [], name: 'RulingAlreadyExecuted' },
  { type: 'error', inputs: [], name: 'SortitionModuleOnly' },
  { type: 'error', inputs: [], name: 'StakingFailed' },
  { type: 'error', inputs: [], name: 'TokenNotAccepted' },
  { type: 'error', inputs: [], name: 'TransferFailed' },
  { type: 'error', inputs: [], name: 'UUPSUnauthorizedCallContext' },
  {
    type: 'error',
    inputs: [{ name: 'slot', internalType: 'bytes32', type: 'bytes32' }],
    name: 'UUPSUnsupportedProxiableUUID',
  },
  { type: 'error', inputs: [], name: 'UnsuccessfulCall' },
  { type: 'error', inputs: [], name: 'UnsupportedDisputeKit' },
  { type: 'error', inputs: [], name: 'VotePeriodNotPassed' },
  { type: 'error', inputs: [], name: 'WrongDisputeKitIndex' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_token', internalType: 'contract IERC20', type: 'address', indexed: true },
      { name: '_accepted', internalType: 'bool', type: 'bool', indexed: true },
    ],
    name: 'AcceptedFeeToken',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_disputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_arbitrable', internalType: 'contract IArbitrableV2', type: 'address', indexed: true },
    ],
    name: 'AppealDecision',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_disputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_arbitrable', internalType: 'contract IArbitrableV2', type: 'address', indexed: true },
    ],
    name: 'AppealPossible',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_courtID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_parent', internalType: 'uint96', type: 'uint96', indexed: true },
      { name: '_hiddenVotes', internalType: 'bool', type: 'bool', indexed: false },
      { name: '_minStake', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_alpha', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_feeForJuror', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_jurorsForCourtJump', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_timesPerPeriod', internalType: 'uint256[4]', type: 'uint256[4]', indexed: false },
      { name: '_supportedDisputeKits', internalType: 'uint256[]', type: 'uint256[]', indexed: false },
    ],
    name: 'CourtCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_disputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_roundID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_fromCourtID', internalType: 'uint96', type: 'uint96', indexed: true },
      { name: '_toCourtID', internalType: 'uint96', type: 'uint96', indexed: false },
    ],
    name: 'CourtJump',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_courtID', internalType: 'uint96', type: 'uint96', indexed: true },
      { name: '_hiddenVotes', internalType: 'bool', type: 'bool', indexed: false },
      { name: '_minStake', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_alpha', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_feeForJuror', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_jurorsForCourtJump', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_timesPerPeriod', internalType: 'uint256[4]', type: 'uint256[4]', indexed: false },
    ],
    name: 'CourtModified',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_disputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_arbitrable', internalType: 'contract IArbitrableV2', type: 'address', indexed: true },
    ],
    name: 'DisputeCreation',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_disputeKitID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_disputeKitAddress', internalType: 'contract IDisputeKit', type: 'address', indexed: true },
    ],
    name: 'DisputeKitCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_courtID', internalType: 'uint96', type: 'uint96', indexed: true },
      { name: '_disputeKitID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_enable', internalType: 'bool', type: 'bool', indexed: true },
    ],
    name: 'DisputeKitEnabled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_disputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_roundID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_fromDisputeKitID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_toDisputeKitID', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'DisputeKitJump',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_address', internalType: 'address', type: 'address', indexed: true },
      { name: '_disputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_roundID', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_voteID', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Draw',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'version', internalType: 'uint64', type: 'uint64', indexed: false }],
    name: 'Initialized',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_disputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_roundID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_pnkAmount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_feeAmount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_feeToken', internalType: 'contract IERC20', type: 'address', indexed: false },
    ],
    name: 'LeftoverRewardSent',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_feeToken', internalType: 'contract IERC20', type: 'address', indexed: true },
      { name: '_rateInEth', internalType: 'uint64', type: 'uint64', indexed: false },
      { name: '_rateDecimals', internalType: 'uint8', type: 'uint8', indexed: false },
    ],
    name: 'NewCurrencyRate',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_disputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_period', internalType: 'enum KlerosCore.Period', type: 'uint8', indexed: false },
    ],
    name: 'NewPeriod',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_arbitrable', internalType: 'contract IArbitrableV2', type: 'address', indexed: true },
      { name: '_disputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_ruling', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Ruling',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_account', internalType: 'address', type: 'address', indexed: true },
      { name: '_disputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_roundID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_degreeOfCoherency', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_pnkAmount', internalType: 'int256', type: 'int256', indexed: false },
      { name: '_feeAmount', internalType: 'int256', type: 'int256', indexed: false },
      { name: '_feeToken', internalType: 'contract IERC20', type: 'address', indexed: false },
    ],
    name: 'TokenAndETHShift',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'newImplementation', internalType: 'address', type: 'address', indexed: true }],
    name: 'Upgraded',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_disputeKitAddress', internalType: 'contract IDisputeKit', type: 'address' }],
    name: 'addNewDisputeKit',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: '_disputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_numberOfChoices', internalType: 'uint256', type: 'uint256' },
      { name: '_extraData', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'appeal',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_disputeID', internalType: 'uint256', type: 'uint256' }],
    name: 'appealCost',
    outputs: [{ name: 'cost', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_disputeID', internalType: 'uint256', type: 'uint256' }],
    name: 'appealPeriod',
    outputs: [
      { name: 'start', internalType: 'uint256', type: 'uint256' },
      { name: 'end', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_extraData', internalType: 'bytes', type: 'bytes' },
      { name: '_feeToken', internalType: 'contract IERC20', type: 'address' },
    ],
    name: 'arbitrationCost',
    outputs: [{ name: 'cost', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_extraData', internalType: 'bytes', type: 'bytes' }],
    name: 'arbitrationCost',
    outputs: [{ name: 'cost', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_feeToken', internalType: 'contract IERC20', type: 'address' },
      { name: '_accepted', internalType: 'bool', type: 'bool' },
    ],
    name: 'changeAcceptedFeeTokens',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_courtID', internalType: 'uint96', type: 'uint96' },
      { name: '_hiddenVotes', internalType: 'bool', type: 'bool' },
      { name: '_minStake', internalType: 'uint256', type: 'uint256' },
      { name: '_alpha', internalType: 'uint256', type: 'uint256' },
      { name: '_feeForJuror', internalType: 'uint256', type: 'uint256' },
      { name: '_jurorsForCourtJump', internalType: 'uint256', type: 'uint256' },
      { name: '_timesPerPeriod', internalType: 'uint256[4]', type: 'uint256[4]' },
    ],
    name: 'changeCourtParameters',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_feeToken', internalType: 'contract IERC20', type: 'address' },
      { name: '_rateInEth', internalType: 'uint64', type: 'uint64' },
      { name: '_rateDecimals', internalType: 'uint8', type: 'uint8' },
    ],
    name: 'changeCurrencyRates',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_governor', internalType: 'address payable', type: 'address' }],
    name: 'changeGovernor',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_jurorProsecutionModule', internalType: 'address', type: 'address' }],
    name: 'changeJurorProsecutionModule',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_pinakion', internalType: 'contract IERC20', type: 'address' }],
    name: 'changePinakion',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_sortitionModule', internalType: 'contract ISortitionModule', type: 'address' }],
    name: 'changeSortitionModule',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_toToken', internalType: 'contract IERC20', type: 'address' },
      { name: '_amountInEth', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'convertEthToTokenAmount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'courts',
    outputs: [
      { name: 'parent', internalType: 'uint96', type: 'uint96' },
      { name: 'hiddenVotes', internalType: 'bool', type: 'bool' },
      { name: 'minStake', internalType: 'uint256', type: 'uint256' },
      { name: 'alpha', internalType: 'uint256', type: 'uint256' },
      { name: 'feeForJuror', internalType: 'uint256', type: 'uint256' },
      { name: 'jurorsForCourtJump', internalType: 'uint256', type: 'uint256' },
      { name: 'disabled', internalType: 'bool', type: 'bool' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_parent', internalType: 'uint96', type: 'uint96' },
      { name: '_hiddenVotes', internalType: 'bool', type: 'bool' },
      { name: '_minStake', internalType: 'uint256', type: 'uint256' },
      { name: '_alpha', internalType: 'uint256', type: 'uint256' },
      { name: '_feeForJuror', internalType: 'uint256', type: 'uint256' },
      { name: '_jurorsForCourtJump', internalType: 'uint256', type: 'uint256' },
      { name: '_timesPerPeriod', internalType: 'uint256[4]', type: 'uint256[4]' },
      { name: '_sortitionExtraData', internalType: 'bytes', type: 'bytes' },
      { name: '_supportedDisputeKits', internalType: 'uint256[]', type: 'uint256[]' },
    ],
    name: 'createCourt',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: '_numberOfChoices', internalType: 'uint256', type: 'uint256' },
      { name: '_extraData', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'createDispute',
    outputs: [{ name: 'disputeID', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_numberOfChoices', internalType: 'uint256', type: 'uint256' },
      { name: '_extraData', internalType: 'bytes', type: 'bytes' },
      { name: '_feeToken', internalType: 'contract IERC20', type: 'address' },
      { name: '_feeAmount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'createDispute',
    outputs: [{ name: 'disputeID', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'contract IERC20', type: 'address' }],
    name: 'currencyRates',
    outputs: [
      { name: 'feePaymentAccepted', internalType: 'bool', type: 'bool' },
      { name: 'rateInEth', internalType: 'uint64', type: 'uint64' },
      { name: 'rateDecimals', internalType: 'uint8', type: 'uint8' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_disputeID', internalType: 'uint256', type: 'uint256' }],
    name: 'currentRuling',
    outputs: [
      { name: 'ruling', internalType: 'uint256', type: 'uint256' },
      { name: 'tied', internalType: 'bool', type: 'bool' },
      { name: 'overridden', internalType: 'bool', type: 'bool' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'disputeKits',
    outputs: [{ name: '', internalType: 'contract IDisputeKit', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'disputes',
    outputs: [
      { name: 'courtID', internalType: 'uint96', type: 'uint96' },
      { name: 'arbitrated', internalType: 'contract IArbitrableV2', type: 'address' },
      { name: 'period', internalType: 'enum KlerosCore.Period', type: 'uint8' },
      { name: 'ruled', internalType: 'bool', type: 'bool' },
      { name: 'lastPeriodChange', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_disputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_iterations', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'draw',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_courtID', internalType: 'uint96', type: 'uint96' },
      { name: '_disputeKitIDs', internalType: 'uint256[]', type: 'uint256[]' },
      { name: '_enable', internalType: 'bool', type: 'bool' },
    ],
    name: 'enableDisputeKits',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_disputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_round', internalType: 'uint256', type: 'uint256' },
      { name: '_iterations', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'execute',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_destination', internalType: 'address', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'executeGovernorProposal',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_disputeID', internalType: 'uint256', type: 'uint256' }],
    name: 'executeRuling',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getDisputeKitsLength',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_disputeID', internalType: 'uint256', type: 'uint256' }],
    name: 'getNumberOfRounds',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_disputeID', internalType: 'uint256', type: 'uint256' }],
    name: 'getNumberOfVotes',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_disputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_round', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getRoundInfo',
    outputs: [
      {
        name: '',
        internalType: 'struct KlerosCore.Round',
        type: 'tuple',
        components: [
          { name: 'disputeKitID', internalType: 'uint256', type: 'uint256' },
          { name: 'pnkAtStakePerJuror', internalType: 'uint256', type: 'uint256' },
          { name: 'totalFeesForJurors', internalType: 'uint256', type: 'uint256' },
          { name: 'nbVotes', internalType: 'uint256', type: 'uint256' },
          { name: 'repartitions', internalType: 'uint256', type: 'uint256' },
          { name: 'pnkPenalties', internalType: 'uint256', type: 'uint256' },
          { name: 'drawnJurors', internalType: 'address[]', type: 'address[]' },
          { name: 'sumFeeRewardPaid', internalType: 'uint256', type: 'uint256' },
          { name: 'sumPnkRewardPaid', internalType: 'uint256', type: 'uint256' },
          { name: 'feeToken', internalType: 'contract IERC20', type: 'address' },
          { name: 'drawIterations', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_courtID', internalType: 'uint96', type: 'uint96' }],
    name: 'getTimesPerPeriod',
    outputs: [{ name: 'timesPerPeriod', internalType: 'uint256[4]', type: 'uint256[4]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'governor',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_governor', internalType: 'address', type: 'address' },
      { name: '_pinakion', internalType: 'contract IERC20', type: 'address' },
      { name: '_jurorProsecutionModule', internalType: 'address', type: 'address' },
      { name: '_disputeKit', internalType: 'contract IDisputeKit', type: 'address' },
      { name: '_hiddenVotes', internalType: 'bool', type: 'bool' },
      { name: '_courtParameters', internalType: 'uint256[4]', type: 'uint256[4]' },
      { name: '_timesPerPeriod', internalType: 'uint256[4]', type: 'uint256[4]' },
      { name: '_sortitionExtraData', internalType: 'bytes', type: 'bytes' },
      { name: '_sortitionModuleAddress', internalType: 'contract ISortitionModule', type: 'address' },
    ],
    name: 'initialize',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_disputeID', internalType: 'uint256', type: 'uint256' }],
    name: 'isDisputeKitJumping',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_courtID', internalType: 'uint96', type: 'uint96' },
      { name: '_disputeKitID', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'isSupported',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'jurorProsecutionModule',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_disputeID', internalType: 'uint256', type: 'uint256' }],
    name: 'passPeriod',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'pinakion',
    outputs: [{ name: '', internalType: 'contract IERC20', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'proxiableUUID',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_courtID', internalType: 'uint96', type: 'uint96' },
      { name: '_newStake', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'setStake',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_account', internalType: 'address', type: 'address' },
      { name: '_courtID', internalType: 'uint96', type: 'uint96' },
      { name: '_newStake', internalType: 'uint256', type: 'uint256' },
      { name: '_alreadyTransferred', internalType: 'bool', type: 'bool' },
    ],
    name: 'setStakeBySortitionModule',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'sortitionModule',
    outputs: [{ name: '', internalType: 'contract ISortitionModule', type: 'address' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'upgradeToAndCall',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_implementation', internalType: 'address', type: 'address' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
  },
] as const

/**
 *
 */
export const klerosCoreAddress = {
  421614: '0x33d0b8879368acD8ca868e656Ade97bB97b90468',
} as const

/**
 *
 */
export const klerosCoreConfig = { address: klerosCoreAddress, abi: klerosCoreABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// KlerosCore_Implementation
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const klerosCoreImplementationABI = [
  { stateMutability: 'nonpayable', type: 'constructor', inputs: [] },
  { type: 'error', inputs: [], name: 'AlreadyInitialized' },
  { type: 'error', inputs: [], name: 'AppealFeesNotEnough' },
  { type: 'error', inputs: [], name: 'AppealPeriodNotPassed' },
  { type: 'error', inputs: [], name: 'ArbitrationFeesNotEnough' },
  { type: 'error', inputs: [], name: 'ArraysLengthMismatch' },
  { type: 'error', inputs: [], name: 'CannotDisableClassicDK' },
  { type: 'error', inputs: [], name: 'CommitPeriodNotPassed' },
  { type: 'error', inputs: [], name: 'DepthLevelMax' },
  { type: 'error', inputs: [], name: 'DisputeKitNotSupportedByCourt' },
  { type: 'error', inputs: [], name: 'DisputeKitOnly' },
  { type: 'error', inputs: [], name: 'DisputeNotAppealable' },
  { type: 'error', inputs: [], name: 'DisputePeriodIsFinal' },
  { type: 'error', inputs: [], name: 'DisputeStillDrawing' },
  { type: 'error', inputs: [], name: 'EvidenceNotPassedAndNotAppeal' },
  { type: 'error', inputs: [], name: 'FailedDelegateCall' },
  { type: 'error', inputs: [], name: 'GovernorOnly' },
  { type: 'error', inputs: [], name: 'InvalidDisputKitParent' },
  { type: 'error', inputs: [], name: 'InvalidForkingCourtAsParent' },
  {
    type: 'error',
    inputs: [{ name: 'implementation', internalType: 'address', type: 'address' }],
    name: 'InvalidImplementation',
  },
  { type: 'error', inputs: [], name: 'MinStakeLowerThanParentCourt' },
  { type: 'error', inputs: [], name: 'MustSupportDisputeKitClassic' },
  { type: 'error', inputs: [], name: 'NotEvidencePeriod' },
  { type: 'error', inputs: [], name: 'NotExecutionPeriod' },
  { type: 'error', inputs: [], name: 'NotInitializing' },
  { type: 'error', inputs: [], name: 'RulingAlreadyExecuted' },
  { type: 'error', inputs: [], name: 'SortitionModuleOnly' },
  { type: 'error', inputs: [], name: 'StakingFailed' },
  { type: 'error', inputs: [], name: 'TokenNotAccepted' },
  { type: 'error', inputs: [], name: 'TransferFailed' },
  { type: 'error', inputs: [], name: 'UUPSUnauthorizedCallContext' },
  {
    type: 'error',
    inputs: [{ name: 'slot', internalType: 'bytes32', type: 'bytes32' }],
    name: 'UUPSUnsupportedProxiableUUID',
  },
  { type: 'error', inputs: [], name: 'UnsuccessfulCall' },
  { type: 'error', inputs: [], name: 'UnsupportedDisputeKit' },
  { type: 'error', inputs: [], name: 'VotePeriodNotPassed' },
  { type: 'error', inputs: [], name: 'WrongDisputeKitIndex' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_token', internalType: 'contract IERC20', type: 'address', indexed: true },
      { name: '_accepted', internalType: 'bool', type: 'bool', indexed: true },
    ],
    name: 'AcceptedFeeToken',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_disputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_arbitrable', internalType: 'contract IArbitrableV2', type: 'address', indexed: true },
    ],
    name: 'AppealDecision',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_disputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_arbitrable', internalType: 'contract IArbitrableV2', type: 'address', indexed: true },
    ],
    name: 'AppealPossible',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_courtID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_parent', internalType: 'uint96', type: 'uint96', indexed: true },
      { name: '_hiddenVotes', internalType: 'bool', type: 'bool', indexed: false },
      { name: '_minStake', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_alpha', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_feeForJuror', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_jurorsForCourtJump', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_timesPerPeriod', internalType: 'uint256[4]', type: 'uint256[4]', indexed: false },
      { name: '_supportedDisputeKits', internalType: 'uint256[]', type: 'uint256[]', indexed: false },
    ],
    name: 'CourtCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_disputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_roundID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_fromCourtID', internalType: 'uint96', type: 'uint96', indexed: true },
      { name: '_toCourtID', internalType: 'uint96', type: 'uint96', indexed: false },
    ],
    name: 'CourtJump',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_courtID', internalType: 'uint96', type: 'uint96', indexed: true },
      { name: '_hiddenVotes', internalType: 'bool', type: 'bool', indexed: false },
      { name: '_minStake', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_alpha', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_feeForJuror', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_jurorsForCourtJump', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_timesPerPeriod', internalType: 'uint256[4]', type: 'uint256[4]', indexed: false },
    ],
    name: 'CourtModified',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_disputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_arbitrable', internalType: 'contract IArbitrableV2', type: 'address', indexed: true },
    ],
    name: 'DisputeCreation',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_disputeKitID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_disputeKitAddress', internalType: 'contract IDisputeKit', type: 'address', indexed: true },
    ],
    name: 'DisputeKitCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_courtID', internalType: 'uint96', type: 'uint96', indexed: true },
      { name: '_disputeKitID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_enable', internalType: 'bool', type: 'bool', indexed: true },
    ],
    name: 'DisputeKitEnabled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_disputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_roundID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_fromDisputeKitID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_toDisputeKitID', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'DisputeKitJump',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_address', internalType: 'address', type: 'address', indexed: true },
      { name: '_disputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_roundID', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_voteID', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Draw',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'version', internalType: 'uint64', type: 'uint64', indexed: false }],
    name: 'Initialized',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_disputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_roundID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_pnkAmount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_feeAmount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_feeToken', internalType: 'contract IERC20', type: 'address', indexed: false },
    ],
    name: 'LeftoverRewardSent',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_feeToken', internalType: 'contract IERC20', type: 'address', indexed: true },
      { name: '_rateInEth', internalType: 'uint64', type: 'uint64', indexed: false },
      { name: '_rateDecimals', internalType: 'uint8', type: 'uint8', indexed: false },
    ],
    name: 'NewCurrencyRate',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_disputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_period', internalType: 'enum KlerosCore.Period', type: 'uint8', indexed: false },
    ],
    name: 'NewPeriod',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_arbitrable', internalType: 'contract IArbitrableV2', type: 'address', indexed: true },
      { name: '_disputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_ruling', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Ruling',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_account', internalType: 'address', type: 'address', indexed: true },
      { name: '_disputeID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_roundID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_degreeOfCoherency', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_pnkAmount', internalType: 'int256', type: 'int256', indexed: false },
      { name: '_feeAmount', internalType: 'int256', type: 'int256', indexed: false },
      { name: '_feeToken', internalType: 'contract IERC20', type: 'address', indexed: false },
    ],
    name: 'TokenAndETHShift',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'newImplementation', internalType: 'address', type: 'address', indexed: true }],
    name: 'Upgraded',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_disputeKitAddress', internalType: 'contract IDisputeKit', type: 'address' }],
    name: 'addNewDisputeKit',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: '_disputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_numberOfChoices', internalType: 'uint256', type: 'uint256' },
      { name: '_extraData', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'appeal',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_disputeID', internalType: 'uint256', type: 'uint256' }],
    name: 'appealCost',
    outputs: [{ name: 'cost', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_disputeID', internalType: 'uint256', type: 'uint256' }],
    name: 'appealPeriod',
    outputs: [
      { name: 'start', internalType: 'uint256', type: 'uint256' },
      { name: 'end', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_extraData', internalType: 'bytes', type: 'bytes' },
      { name: '_feeToken', internalType: 'contract IERC20', type: 'address' },
    ],
    name: 'arbitrationCost',
    outputs: [{ name: 'cost', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_extraData', internalType: 'bytes', type: 'bytes' }],
    name: 'arbitrationCost',
    outputs: [{ name: 'cost', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_feeToken', internalType: 'contract IERC20', type: 'address' },
      { name: '_accepted', internalType: 'bool', type: 'bool' },
    ],
    name: 'changeAcceptedFeeTokens',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_courtID', internalType: 'uint96', type: 'uint96' },
      { name: '_hiddenVotes', internalType: 'bool', type: 'bool' },
      { name: '_minStake', internalType: 'uint256', type: 'uint256' },
      { name: '_alpha', internalType: 'uint256', type: 'uint256' },
      { name: '_feeForJuror', internalType: 'uint256', type: 'uint256' },
      { name: '_jurorsForCourtJump', internalType: 'uint256', type: 'uint256' },
      { name: '_timesPerPeriod', internalType: 'uint256[4]', type: 'uint256[4]' },
    ],
    name: 'changeCourtParameters',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_feeToken', internalType: 'contract IERC20', type: 'address' },
      { name: '_rateInEth', internalType: 'uint64', type: 'uint64' },
      { name: '_rateDecimals', internalType: 'uint8', type: 'uint8' },
    ],
    name: 'changeCurrencyRates',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_governor', internalType: 'address payable', type: 'address' }],
    name: 'changeGovernor',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_jurorProsecutionModule', internalType: 'address', type: 'address' }],
    name: 'changeJurorProsecutionModule',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_pinakion', internalType: 'contract IERC20', type: 'address' }],
    name: 'changePinakion',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_sortitionModule', internalType: 'contract ISortitionModule', type: 'address' }],
    name: 'changeSortitionModule',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_toToken', internalType: 'contract IERC20', type: 'address' },
      { name: '_amountInEth', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'convertEthToTokenAmount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'courts',
    outputs: [
      { name: 'parent', internalType: 'uint96', type: 'uint96' },
      { name: 'hiddenVotes', internalType: 'bool', type: 'bool' },
      { name: 'minStake', internalType: 'uint256', type: 'uint256' },
      { name: 'alpha', internalType: 'uint256', type: 'uint256' },
      { name: 'feeForJuror', internalType: 'uint256', type: 'uint256' },
      { name: 'jurorsForCourtJump', internalType: 'uint256', type: 'uint256' },
      { name: 'disabled', internalType: 'bool', type: 'bool' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_parent', internalType: 'uint96', type: 'uint96' },
      { name: '_hiddenVotes', internalType: 'bool', type: 'bool' },
      { name: '_minStake', internalType: 'uint256', type: 'uint256' },
      { name: '_alpha', internalType: 'uint256', type: 'uint256' },
      { name: '_feeForJuror', internalType: 'uint256', type: 'uint256' },
      { name: '_jurorsForCourtJump', internalType: 'uint256', type: 'uint256' },
      { name: '_timesPerPeriod', internalType: 'uint256[4]', type: 'uint256[4]' },
      { name: '_sortitionExtraData', internalType: 'bytes', type: 'bytes' },
      { name: '_supportedDisputeKits', internalType: 'uint256[]', type: 'uint256[]' },
    ],
    name: 'createCourt',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: '_numberOfChoices', internalType: 'uint256', type: 'uint256' },
      { name: '_extraData', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'createDispute',
    outputs: [{ name: 'disputeID', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_numberOfChoices', internalType: 'uint256', type: 'uint256' },
      { name: '_extraData', internalType: 'bytes', type: 'bytes' },
      { name: '_feeToken', internalType: 'contract IERC20', type: 'address' },
      { name: '_feeAmount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'createDispute',
    outputs: [{ name: 'disputeID', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'contract IERC20', type: 'address' }],
    name: 'currencyRates',
    outputs: [
      { name: 'feePaymentAccepted', internalType: 'bool', type: 'bool' },
      { name: 'rateInEth', internalType: 'uint64', type: 'uint64' },
      { name: 'rateDecimals', internalType: 'uint8', type: 'uint8' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_disputeID', internalType: 'uint256', type: 'uint256' }],
    name: 'currentRuling',
    outputs: [
      { name: 'ruling', internalType: 'uint256', type: 'uint256' },
      { name: 'tied', internalType: 'bool', type: 'bool' },
      { name: 'overridden', internalType: 'bool', type: 'bool' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'disputeKits',
    outputs: [{ name: '', internalType: 'contract IDisputeKit', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'disputes',
    outputs: [
      { name: 'courtID', internalType: 'uint96', type: 'uint96' },
      { name: 'arbitrated', internalType: 'contract IArbitrableV2', type: 'address' },
      { name: 'period', internalType: 'enum KlerosCore.Period', type: 'uint8' },
      { name: 'ruled', internalType: 'bool', type: 'bool' },
      { name: 'lastPeriodChange', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_disputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_iterations', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'draw',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_courtID', internalType: 'uint96', type: 'uint96' },
      { name: '_disputeKitIDs', internalType: 'uint256[]', type: 'uint256[]' },
      { name: '_enable', internalType: 'bool', type: 'bool' },
    ],
    name: 'enableDisputeKits',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_disputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_round', internalType: 'uint256', type: 'uint256' },
      { name: '_iterations', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'execute',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_destination', internalType: 'address', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'executeGovernorProposal',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_disputeID', internalType: 'uint256', type: 'uint256' }],
    name: 'executeRuling',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getDisputeKitsLength',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_disputeID', internalType: 'uint256', type: 'uint256' }],
    name: 'getNumberOfRounds',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_disputeID', internalType: 'uint256', type: 'uint256' }],
    name: 'getNumberOfVotes',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_disputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_round', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getRoundInfo',
    outputs: [
      {
        name: '',
        internalType: 'struct KlerosCore.Round',
        type: 'tuple',
        components: [
          { name: 'disputeKitID', internalType: 'uint256', type: 'uint256' },
          { name: 'pnkAtStakePerJuror', internalType: 'uint256', type: 'uint256' },
          { name: 'totalFeesForJurors', internalType: 'uint256', type: 'uint256' },
          { name: 'nbVotes', internalType: 'uint256', type: 'uint256' },
          { name: 'repartitions', internalType: 'uint256', type: 'uint256' },
          { name: 'pnkPenalties', internalType: 'uint256', type: 'uint256' },
          { name: 'drawnJurors', internalType: 'address[]', type: 'address[]' },
          { name: 'sumFeeRewardPaid', internalType: 'uint256', type: 'uint256' },
          { name: 'sumPnkRewardPaid', internalType: 'uint256', type: 'uint256' },
          { name: 'feeToken', internalType: 'contract IERC20', type: 'address' },
          { name: 'drawIterations', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_courtID', internalType: 'uint96', type: 'uint96' }],
    name: 'getTimesPerPeriod',
    outputs: [{ name: 'timesPerPeriod', internalType: 'uint256[4]', type: 'uint256[4]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'governor',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_governor', internalType: 'address', type: 'address' },
      { name: '_pinakion', internalType: 'contract IERC20', type: 'address' },
      { name: '_jurorProsecutionModule', internalType: 'address', type: 'address' },
      { name: '_disputeKit', internalType: 'contract IDisputeKit', type: 'address' },
      { name: '_hiddenVotes', internalType: 'bool', type: 'bool' },
      { name: '_courtParameters', internalType: 'uint256[4]', type: 'uint256[4]' },
      { name: '_timesPerPeriod', internalType: 'uint256[4]', type: 'uint256[4]' },
      { name: '_sortitionExtraData', internalType: 'bytes', type: 'bytes' },
      { name: '_sortitionModuleAddress', internalType: 'contract ISortitionModule', type: 'address' },
    ],
    name: 'initialize',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_disputeID', internalType: 'uint256', type: 'uint256' }],
    name: 'isDisputeKitJumping',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_courtID', internalType: 'uint96', type: 'uint96' },
      { name: '_disputeKitID', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'isSupported',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'jurorProsecutionModule',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_disputeID', internalType: 'uint256', type: 'uint256' }],
    name: 'passPeriod',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'pinakion',
    outputs: [{ name: '', internalType: 'contract IERC20', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'proxiableUUID',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_courtID', internalType: 'uint96', type: 'uint96' },
      { name: '_newStake', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'setStake',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_account', internalType: 'address', type: 'address' },
      { name: '_courtID', internalType: 'uint96', type: 'uint96' },
      { name: '_newStake', internalType: 'uint256', type: 'uint256' },
      { name: '_alreadyTransferred', internalType: 'bool', type: 'bool' },
    ],
    name: 'setStakeBySortitionModule',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'sortitionModule',
    outputs: [{ name: '', internalType: 'contract ISortitionModule', type: 'address' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'upgradeToAndCall',
    outputs: [],
  },
] as const

/**
 *
 */
export const klerosCoreImplementationAddress = {
  421614: '0x6FDc191b55a03e840b36793e433A932EeCEa40BE',
} as const

/**
 *
 */
export const klerosCoreImplementationConfig = {
  address: klerosCoreImplementationAddress,
  abi: klerosCoreImplementationABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// KlerosCore_Proxy
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const klerosCoreProxyABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_implementation', internalType: 'address', type: 'address' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
  },
  { stateMutability: 'payable', type: 'fallback' },
  { stateMutability: 'payable', type: 'receive' },
] as const

/**
 *
 */
export const klerosCoreProxyAddress = {
  421614: '0x33d0b8879368acD8ca868e656Ade97bB97b90468',
} as const

/**
 *
 */
export const klerosCoreProxyConfig = { address: klerosCoreProxyAddress, abi: klerosCoreProxyABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// PNK
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const pnkABI = [
  { stateMutability: 'nonpayable', type: 'constructor', inputs: [] },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'subtractedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'addedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const

/**
 *
 */
export const pnkAddress = {
  421614: '0x34B944D42cAcfC8266955D07A80181D2054aa225',
} as const

/**
 *
 */
export const pnkConfig = { address: pnkAddress, abi: pnkABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// PNKFaucet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const pnkFaucetABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [{ name: '_token', internalType: 'contract IERC20', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'amount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'balance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_amount', internalType: 'uint256', type: 'uint256' }],
    name: 'changeAmount',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_governor', internalType: 'address', type: 'address' }],
    name: 'changeGovernor',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'governor',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'request', outputs: [] },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'token',
    outputs: [{ name: '', internalType: 'contract IERC20', type: 'address' }],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'withdraw', outputs: [] },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'withdrewAlready',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const

/**
 *
 */
export const pnkFaucetAddress = {
  421614: '0x0273512759B5E80031725332da12E91E9F8Bf667',
} as const

/**
 *
 */
export const pnkFaucetConfig = { address: pnkFaucetAddress, abi: pnkFaucetABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// PinakionV2
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const pinakionV2ABI = [
  { stateMutability: 'nonpayable', type: 'constructor', inputs: [] },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'subtractedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'addedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const

/**
 *
 */
export const pinakionV2Address = {
  421614: '0x34B944D42cAcfC8266955D07A80181D2054aa225',
} as const

/**
 *
 */
export const pinakionV2Config = { address: pinakionV2Address, abi: pinakionV2ABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// PolicyRegistry
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const policyRegistryABI = [
  { stateMutability: 'payable', type: 'fallback' },
  { stateMutability: 'payable', type: 'receive' },
  { type: 'error', inputs: [], name: 'AlreadyInitialized' },
  { type: 'error', inputs: [], name: 'FailedDelegateCall' },
  {
    type: 'error',
    inputs: [{ name: 'implementation', internalType: 'address', type: 'address' }],
    name: 'InvalidImplementation',
  },
  { type: 'error', inputs: [], name: 'NotInitializing' },
  { type: 'error', inputs: [], name: 'UUPSUnauthorizedCallContext' },
  {
    type: 'error',
    inputs: [{ name: 'slot', internalType: 'bytes32', type: 'bytes32' }],
    name: 'UUPSUnsupportedProxiableUUID',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'version', internalType: 'uint64', type: 'uint64', indexed: false }],
    name: 'Initialized',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_courtID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_courtName', internalType: 'string', type: 'string', indexed: false },
      { name: '_policy', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'PolicyUpdate',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'newImplementation', internalType: 'address', type: 'address', indexed: true }],
    name: 'Upgraded',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_governor', internalType: 'address', type: 'address' }],
    name: 'changeGovernor',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'governor',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_governor', internalType: 'address', type: 'address' }],
    name: 'initialize',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'policies',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'proxiableUUID',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_courtID', internalType: 'uint256', type: 'uint256' },
      { name: '_courtName', internalType: 'string', type: 'string' },
      { name: '_policy', internalType: 'string', type: 'string' },
    ],
    name: 'setPolicy',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'upgradeToAndCall',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_implementation', internalType: 'address', type: 'address' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
  },
] as const

/**
 *
 */
export const policyRegistryAddress = {
  421614: '0xb177AC8827146AC74C412688c6b10676ca170096',
} as const

/**
 *
 */
export const policyRegistryConfig = { address: policyRegistryAddress, abi: policyRegistryABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// PolicyRegistry_Implementation
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const policyRegistryImplementationABI = [
  { stateMutability: 'nonpayable', type: 'constructor', inputs: [] },
  { type: 'error', inputs: [], name: 'AlreadyInitialized' },
  { type: 'error', inputs: [], name: 'FailedDelegateCall' },
  {
    type: 'error',
    inputs: [{ name: 'implementation', internalType: 'address', type: 'address' }],
    name: 'InvalidImplementation',
  },
  { type: 'error', inputs: [], name: 'NotInitializing' },
  { type: 'error', inputs: [], name: 'UUPSUnauthorizedCallContext' },
  {
    type: 'error',
    inputs: [{ name: 'slot', internalType: 'bytes32', type: 'bytes32' }],
    name: 'UUPSUnsupportedProxiableUUID',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'version', internalType: 'uint64', type: 'uint64', indexed: false }],
    name: 'Initialized',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_courtID', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: '_courtName', internalType: 'string', type: 'string', indexed: false },
      { name: '_policy', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'PolicyUpdate',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'newImplementation', internalType: 'address', type: 'address', indexed: true }],
    name: 'Upgraded',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_governor', internalType: 'address', type: 'address' }],
    name: 'changeGovernor',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'governor',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_governor', internalType: 'address', type: 'address' }],
    name: 'initialize',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'policies',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'proxiableUUID',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_courtID', internalType: 'uint256', type: 'uint256' },
      { name: '_courtName', internalType: 'string', type: 'string' },
      { name: '_policy', internalType: 'string', type: 'string' },
    ],
    name: 'setPolicy',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'upgradeToAndCall',
    outputs: [],
  },
] as const

/**
 *
 */
export const policyRegistryImplementationAddress = {
  421614: '0xd543D50dcba2c3E067296210D64c8F91206Df908',
} as const

/**
 *
 */
export const policyRegistryImplementationConfig = {
  address: policyRegistryImplementationAddress,
  abi: policyRegistryImplementationABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// PolicyRegistry_Proxy
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const policyRegistryProxyABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_implementation', internalType: 'address', type: 'address' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
  },
  { stateMutability: 'payable', type: 'fallback' },
  { stateMutability: 'payable', type: 'receive' },
] as const

/**
 *
 */
export const policyRegistryProxyAddress = {
  421614: '0xb177AC8827146AC74C412688c6b10676ca170096',
} as const

/**
 *
 */
export const policyRegistryProxyConfig = { address: policyRegistryProxyAddress, abi: policyRegistryProxyABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// RandomizerOracle
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const randomizerOracleABI = [] as const

/**
 *
 */
export const randomizerOracleAddress = {
  421614: '0xE775D7fde1d0D09ae627C0131040012ccBcC4b9b',
} as const

/**
 *
 */
export const randomizerOracleConfig = { address: randomizerOracleAddress, abi: randomizerOracleABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// RandomizerRNG
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const randomizerRngABI = [
  { stateMutability: 'payable', type: 'fallback' },
  { stateMutability: 'payable', type: 'receive' },
  { type: 'error', inputs: [], name: 'AlreadyInitialized' },
  { type: 'error', inputs: [], name: 'FailedDelegateCall' },
  {
    type: 'error',
    inputs: [{ name: 'implementation', internalType: 'address', type: 'address' }],
    name: 'InvalidImplementation',
  },
  { type: 'error', inputs: [], name: 'NotInitializing' },
  { type: 'error', inputs: [], name: 'UUPSUnauthorizedCallContext' },
  {
    type: 'error',
    inputs: [{ name: 'slot', internalType: 'bytes32', type: 'bytes32' }],
    name: 'UUPSUnsupportedProxiableUUID',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'version', internalType: 'uint64', type: 'uint64', indexed: false }],
    name: 'Initialized',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'newImplementation', internalType: 'address', type: 'address', indexed: true }],
    name: 'Upgraded',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'callbackGasLimit',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_governor', internalType: 'address', type: 'address' }],
    name: 'changeGovernor',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'governor',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_randomizer', internalType: 'contract IRandomizer', type: 'address' },
      { name: '_governor', internalType: 'address', type: 'address' },
    ],
    name: 'initialize',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'proxiableUUID',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'randomNumbers',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'randomizer',
    outputs: [{ name: '', internalType: 'contract IRandomizer', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_id', internalType: 'uint256', type: 'uint256' },
      { name: '_value', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'randomizerCallback',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_amount', internalType: 'uint256', type: 'uint256' }],
    name: 'randomizerWithdraw',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'receiveRandomness',
    outputs: [{ name: 'randomNumber', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'requestRandomness',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'requesterToID',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_callbackGasLimit', internalType: 'uint256', type: 'uint256' }],
    name: 'setCallbackGasLimit',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_randomizer', internalType: 'address', type: 'address' }],
    name: 'setRandomizer',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'upgradeToAndCall',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_implementation', internalType: 'address', type: 'address' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
  },
] as const

/**
 *
 */
export const randomizerRngAddress = {
  421614: '0xaE7F3AcA5c1E40D5E51eE61e20929bbDA0CAf4DC',
} as const

/**
 *
 */
export const randomizerRngConfig = { address: randomizerRngAddress, abi: randomizerRngABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// RandomizerRNG_Implementation
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const randomizerRngImplementationABI = [
  { stateMutability: 'nonpayable', type: 'constructor', inputs: [] },
  { type: 'error', inputs: [], name: 'AlreadyInitialized' },
  { type: 'error', inputs: [], name: 'FailedDelegateCall' },
  {
    type: 'error',
    inputs: [{ name: 'implementation', internalType: 'address', type: 'address' }],
    name: 'InvalidImplementation',
  },
  { type: 'error', inputs: [], name: 'NotInitializing' },
  { type: 'error', inputs: [], name: 'UUPSUnauthorizedCallContext' },
  {
    type: 'error',
    inputs: [{ name: 'slot', internalType: 'bytes32', type: 'bytes32' }],
    name: 'UUPSUnsupportedProxiableUUID',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'version', internalType: 'uint64', type: 'uint64', indexed: false }],
    name: 'Initialized',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'newImplementation', internalType: 'address', type: 'address', indexed: true }],
    name: 'Upgraded',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'callbackGasLimit',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_governor', internalType: 'address', type: 'address' }],
    name: 'changeGovernor',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'governor',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_randomizer', internalType: 'contract IRandomizer', type: 'address' },
      { name: '_governor', internalType: 'address', type: 'address' },
    ],
    name: 'initialize',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'proxiableUUID',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'randomNumbers',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'randomizer',
    outputs: [{ name: '', internalType: 'contract IRandomizer', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_id', internalType: 'uint256', type: 'uint256' },
      { name: '_value', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'randomizerCallback',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_amount', internalType: 'uint256', type: 'uint256' }],
    name: 'randomizerWithdraw',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'receiveRandomness',
    outputs: [{ name: 'randomNumber', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'requestRandomness',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'requesterToID',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_callbackGasLimit', internalType: 'uint256', type: 'uint256' }],
    name: 'setCallbackGasLimit',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_randomizer', internalType: 'address', type: 'address' }],
    name: 'setRandomizer',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'upgradeToAndCall',
    outputs: [],
  },
] as const

/**
 *
 */
export const randomizerRngImplementationAddress = {
  421614: '0x121F321f8F803fb88A895b969D6E26C672121149',
} as const

/**
 *
 */
export const randomizerRngImplementationConfig = {
  address: randomizerRngImplementationAddress,
  abi: randomizerRngImplementationABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// RandomizerRNG_Proxy
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const randomizerRngProxyABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_implementation', internalType: 'address', type: 'address' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
  },
  { stateMutability: 'payable', type: 'fallback' },
  { stateMutability: 'payable', type: 'receive' },
] as const

/**
 *
 */
export const randomizerRngProxyAddress = {
  421614: '0xaE7F3AcA5c1E40D5E51eE61e20929bbDA0CAf4DC',
} as const

/**
 *
 */
export const randomizerRngProxyConfig = { address: randomizerRngProxyAddress, abi: randomizerRngProxyABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// SortitionModule
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const sortitionModuleABI = [
  { stateMutability: 'payable', type: 'fallback' },
  { stateMutability: 'payable', type: 'receive' },
  { type: 'error', inputs: [], name: 'AlreadyInitialized' },
  { type: 'error', inputs: [], name: 'FailedDelegateCall' },
  {
    type: 'error',
    inputs: [{ name: 'implementation', internalType: 'address', type: 'address' }],
    name: 'InvalidImplementation',
  },
  { type: 'error', inputs: [], name: 'NotInitializing' },
  { type: 'error', inputs: [], name: 'UUPSUnauthorizedCallContext' },
  {
    type: 'error',
    inputs: [{ name: 'slot', internalType: 'bytes32', type: 'bytes32' }],
    name: 'UUPSUnsupportedProxiableUUID',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'version', internalType: 'uint64', type: 'uint64', indexed: false }],
    name: 'Initialized',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: '_phase', internalType: 'enum ISortitionModule.Phase', type: 'uint8', indexed: false }],
    name: 'NewPhase',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_address', internalType: 'address', type: 'address', indexed: true },
      { name: '_courtID', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'StakeDelayedAlreadyTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_address', internalType: 'address', type: 'address', indexed: true },
      { name: '_courtID', internalType: 'uint96', type: 'uint96', indexed: true },
      { name: '_amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'StakeDelayedAlreadyTransferredWithdrawn',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_address', internalType: 'address', type: 'address', indexed: true },
      { name: '_courtID', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'StakeDelayedNotTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_address', internalType: 'address', type: 'address', indexed: true },
      { name: '_relativeAmount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_unlock', internalType: 'bool', type: 'bool', indexed: false },
    ],
    name: 'StakeLocked',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_address', internalType: 'address', type: 'address', indexed: true },
      { name: '_courtID', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'StakeSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'newImplementation', internalType: 'address', type: 'address', indexed: true }],
    name: 'Upgraded',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DEFAULT_K',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'MAX_STAKE_PATHS',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_maxDrawingTime', internalType: 'uint256', type: 'uint256' }],
    name: 'changeMaxDrawingTime',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_minStakingTime', internalType: 'uint256', type: 'uint256' }],
    name: 'changeMinStakingTime',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_rng', internalType: 'contract RNG', type: 'address' },
      { name: '_rngLookahead', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'changeRandomNumberGenerator',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'core',
    outputs: [{ name: '', internalType: 'contract KlerosCore', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'createDisputeHook',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_key', internalType: 'bytes32', type: 'bytes32' },
      { name: '_extraData', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'createTree',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'delayedStakeReadIndex',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'delayedStakeWriteIndex',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'delayedStakes',
    outputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'courtID', internalType: 'uint96', type: 'uint96' },
      { name: 'stake', internalType: 'uint256', type: 'uint256' },
      { name: 'alreadyTransferred', internalType: 'bool', type: 'bool' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'disputesWithoutJurors',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_key', internalType: 'bytes32', type: 'bytes32' },
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_nonce', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'draw',
    outputs: [{ name: 'drawnAddress', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_iterations', internalType: 'uint256', type: 'uint256' }],
    name: 'executeDelayedStakes',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_juror', internalType: 'address', type: 'address' },
      { name: '_courtID', internalType: 'uint96', type: 'uint96' },
    ],
    name: 'getJurorBalance',
    outputs: [
      { name: 'totalStaked', internalType: 'uint256', type: 'uint256' },
      { name: 'totalLocked', internalType: 'uint256', type: 'uint256' },
      { name: 'stakedInCourt', internalType: 'uint256', type: 'uint256' },
      { name: 'nbCourts', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_juror', internalType: 'address', type: 'address' }],
    name: 'getJurorCourtIDs',
    outputs: [{ name: '', internalType: 'uint96[]', type: 'uint96[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'governor',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_governor', internalType: 'address', type: 'address' },
      { name: '_core', internalType: 'contract KlerosCore', type: 'address' },
      { name: '_minStakingTime', internalType: 'uint256', type: 'uint256' },
      { name: '_maxDrawingTime', internalType: 'uint256', type: 'uint256' },
      { name: '_rng', internalType: 'contract RNG', type: 'address' },
      { name: '_rngLookahead', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'initialize',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_juror', internalType: 'address', type: 'address' }],
    name: 'isJurorStaked',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'jurors',
    outputs: [
      { name: 'stakedPnk', internalType: 'uint256', type: 'uint256' },
      { name: 'lockedPnk', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'lastPhaseChange',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint96', type: 'uint96' },
    ],
    name: 'latestDelayedStakeIndex',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_account', internalType: 'address', type: 'address' },
      { name: '_relativeAmount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'lockStake',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'maxDrawingTime',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'minStakingTime',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_randomNumber', internalType: 'uint256', type: 'uint256' }],
    name: 'notifyRandomNumber',
    outputs: [],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'passPhase', outputs: [] },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_account', internalType: 'address', type: 'address' },
      { name: '_relativeAmount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'penalizeStake',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'phase',
    outputs: [{ name: '', internalType: 'enum ISortitionModule.Phase', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'postDrawHook',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'proxiableUUID',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'randomNumber',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'randomNumberRequestBlock',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'rng',
    outputs: [{ name: '', internalType: 'contract RNG', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'rngLookahead',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_account', internalType: 'address', type: 'address' }],
    name: 'setJurorInactive',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_account', internalType: 'address', type: 'address' },
      { name: '_courtID', internalType: 'uint96', type: 'uint96' },
      { name: '_newStake', internalType: 'uint256', type: 'uint256' },
      { name: '_alreadyTransferred', internalType: 'bool', type: 'bool' },
    ],
    name: 'setStake',
    outputs: [
      { name: 'pnkDeposit', internalType: 'uint256', type: 'uint256' },
      { name: 'pnkWithdrawal', internalType: 'uint256', type: 'uint256' },
      { name: 'succeeded', internalType: 'bool', type: 'bool' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_key', internalType: 'bytes32', type: 'bytes32' },
      { name: '_ID', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'stakeOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_juror', internalType: 'address', type: 'address' },
      { name: '_courtID', internalType: 'uint96', type: 'uint96' },
    ],
    name: 'stakeOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_account', internalType: 'address', type: 'address' },
      { name: '_relativeAmount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'unlockStake',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'upgradeToAndCall',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_implementation', internalType: 'address', type: 'address' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
  },
] as const

/**
 *
 */
export const sortitionModuleAddress = {
  421614: '0x3645F9e08D80E47c82aD9E33fCB4EA703822C831',
} as const

/**
 *
 */
export const sortitionModuleConfig = { address: sortitionModuleAddress, abi: sortitionModuleABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// SortitionModule_Implementation
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const sortitionModuleImplementationABI = [
  { stateMutability: 'nonpayable', type: 'constructor', inputs: [] },
  { type: 'error', inputs: [], name: 'AlreadyInitialized' },
  { type: 'error', inputs: [], name: 'FailedDelegateCall' },
  {
    type: 'error',
    inputs: [{ name: 'implementation', internalType: 'address', type: 'address' }],
    name: 'InvalidImplementation',
  },
  { type: 'error', inputs: [], name: 'NotInitializing' },
  { type: 'error', inputs: [], name: 'UUPSUnauthorizedCallContext' },
  {
    type: 'error',
    inputs: [{ name: 'slot', internalType: 'bytes32', type: 'bytes32' }],
    name: 'UUPSUnsupportedProxiableUUID',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'version', internalType: 'uint64', type: 'uint64', indexed: false }],
    name: 'Initialized',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: '_phase', internalType: 'enum ISortitionModule.Phase', type: 'uint8', indexed: false }],
    name: 'NewPhase',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_address', internalType: 'address', type: 'address', indexed: true },
      { name: '_courtID', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'StakeDelayedAlreadyTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_address', internalType: 'address', type: 'address', indexed: true },
      { name: '_courtID', internalType: 'uint96', type: 'uint96', indexed: true },
      { name: '_amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'StakeDelayedAlreadyTransferredWithdrawn',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_address', internalType: 'address', type: 'address', indexed: true },
      { name: '_courtID', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'StakeDelayedNotTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_address', internalType: 'address', type: 'address', indexed: true },
      { name: '_relativeAmount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_unlock', internalType: 'bool', type: 'bool', indexed: false },
    ],
    name: 'StakeLocked',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_address', internalType: 'address', type: 'address', indexed: true },
      { name: '_courtID', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'StakeSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'newImplementation', internalType: 'address', type: 'address', indexed: true }],
    name: 'Upgraded',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DEFAULT_K',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'MAX_STAKE_PATHS',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_maxDrawingTime', internalType: 'uint256', type: 'uint256' }],
    name: 'changeMaxDrawingTime',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_minStakingTime', internalType: 'uint256', type: 'uint256' }],
    name: 'changeMinStakingTime',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_rng', internalType: 'contract RNG', type: 'address' },
      { name: '_rngLookahead', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'changeRandomNumberGenerator',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'core',
    outputs: [{ name: '', internalType: 'contract KlerosCore', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'createDisputeHook',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_key', internalType: 'bytes32', type: 'bytes32' },
      { name: '_extraData', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'createTree',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'delayedStakeReadIndex',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'delayedStakeWriteIndex',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'delayedStakes',
    outputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'courtID', internalType: 'uint96', type: 'uint96' },
      { name: 'stake', internalType: 'uint256', type: 'uint256' },
      { name: 'alreadyTransferred', internalType: 'bool', type: 'bool' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'disputesWithoutJurors',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_key', internalType: 'bytes32', type: 'bytes32' },
      { name: '_coreDisputeID', internalType: 'uint256', type: 'uint256' },
      { name: '_nonce', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'draw',
    outputs: [{ name: 'drawnAddress', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_iterations', internalType: 'uint256', type: 'uint256' }],
    name: 'executeDelayedStakes',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_juror', internalType: 'address', type: 'address' },
      { name: '_courtID', internalType: 'uint96', type: 'uint96' },
    ],
    name: 'getJurorBalance',
    outputs: [
      { name: 'totalStaked', internalType: 'uint256', type: 'uint256' },
      { name: 'totalLocked', internalType: 'uint256', type: 'uint256' },
      { name: 'stakedInCourt', internalType: 'uint256', type: 'uint256' },
      { name: 'nbCourts', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_juror', internalType: 'address', type: 'address' }],
    name: 'getJurorCourtIDs',
    outputs: [{ name: '', internalType: 'uint96[]', type: 'uint96[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'governor',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_governor', internalType: 'address', type: 'address' },
      { name: '_core', internalType: 'contract KlerosCore', type: 'address' },
      { name: '_minStakingTime', internalType: 'uint256', type: 'uint256' },
      { name: '_maxDrawingTime', internalType: 'uint256', type: 'uint256' },
      { name: '_rng', internalType: 'contract RNG', type: 'address' },
      { name: '_rngLookahead', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'initialize',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_juror', internalType: 'address', type: 'address' }],
    name: 'isJurorStaked',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'jurors',
    outputs: [
      { name: 'stakedPnk', internalType: 'uint256', type: 'uint256' },
      { name: 'lockedPnk', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'lastPhaseChange',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint96', type: 'uint96' },
    ],
    name: 'latestDelayedStakeIndex',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_account', internalType: 'address', type: 'address' },
      { name: '_relativeAmount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'lockStake',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'maxDrawingTime',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'minStakingTime',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_randomNumber', internalType: 'uint256', type: 'uint256' }],
    name: 'notifyRandomNumber',
    outputs: [],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'passPhase', outputs: [] },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_account', internalType: 'address', type: 'address' },
      { name: '_relativeAmount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'penalizeStake',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'phase',
    outputs: [{ name: '', internalType: 'enum ISortitionModule.Phase', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'postDrawHook',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'proxiableUUID',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'randomNumber',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'randomNumberRequestBlock',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'rng',
    outputs: [{ name: '', internalType: 'contract RNG', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'rngLookahead',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_account', internalType: 'address', type: 'address' }],
    name: 'setJurorInactive',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_account', internalType: 'address', type: 'address' },
      { name: '_courtID', internalType: 'uint96', type: 'uint96' },
      { name: '_newStake', internalType: 'uint256', type: 'uint256' },
      { name: '_alreadyTransferred', internalType: 'bool', type: 'bool' },
    ],
    name: 'setStake',
    outputs: [
      { name: 'pnkDeposit', internalType: 'uint256', type: 'uint256' },
      { name: 'pnkWithdrawal', internalType: 'uint256', type: 'uint256' },
      { name: 'succeeded', internalType: 'bool', type: 'bool' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_key', internalType: 'bytes32', type: 'bytes32' },
      { name: '_ID', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'stakeOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_juror', internalType: 'address', type: 'address' },
      { name: '_courtID', internalType: 'uint96', type: 'uint96' },
    ],
    name: 'stakeOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_account', internalType: 'address', type: 'address' },
      { name: '_relativeAmount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'unlockStake',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'upgradeToAndCall',
    outputs: [],
  },
] as const

/**
 *
 */
export const sortitionModuleImplementationAddress = {
  421614: '0xAf48e32f89339438572a04455b1C4B2fF1659c8f',
} as const

/**
 *
 */
export const sortitionModuleImplementationConfig = {
  address: sortitionModuleImplementationAddress,
  abi: sortitionModuleImplementationABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// SortitionModule_Proxy
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const sortitionModuleProxyABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_implementation', internalType: 'address', type: 'address' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
  },
  { stateMutability: 'payable', type: 'fallback' },
  { stateMutability: 'payable', type: 'receive' },
] as const

/**
 *
 */
export const sortitionModuleProxyAddress = {
  421614: '0x3645F9e08D80E47c82aD9E33fCB4EA703822C831',
} as const

/**
 *
 */
export const sortitionModuleProxyConfig = {
  address: sortitionModuleProxyAddress,
  abi: sortitionModuleProxyABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// WETH
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const wethABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_name', internalType: 'string', type: 'string' },
      { name: '_symbol', internalType: 'string', type: 'string' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'subtractedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'addedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const

/**
 *
 */
export const wethAddress = {
  421614: '0xAEE953CC26DbDeA52beBE3F97f281981f2B9d511',
} as const

/**
 *
 */
export const wethConfig = { address: wethAddress, abi: wethABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// WETHFaucet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 */
export const wethFaucetABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [{ name: '_token', internalType: 'contract IERC20', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'amount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'balance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_amount', internalType: 'uint256', type: 'uint256' }],
    name: 'changeAmount',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_governor', internalType: 'address', type: 'address' }],
    name: 'changeGovernor',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'governor',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'request', outputs: [] },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'token',
    outputs: [{ name: '', internalType: 'contract IERC20', type: 'address' }],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'withdraw', outputs: [] },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'withdrewAlready',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const

/**
 *
 */
export const wethFaucetAddress = {
  421614: '0x922B84134e41BC5c9EDE7D5EFCE22Ba3D0e71835',
} as const

/**
 *
 */
export const wethFaucetConfig = { address: wethFaucetAddress, abi: wethFaucetABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// React
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link arbitrableExampleABI}__.
 *
 *
 */
export function useArbitrableExampleRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof arbitrableExampleABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof arbitrableExampleABI, TFunctionName, TSelectData>, 'abi' | 'address'> & {
    chainId?: keyof typeof arbitrableExampleAddress
  } = {} as any
) {
  return useContractRead({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    ...config,
  } as UseContractReadConfig<typeof arbitrableExampleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link arbitrableExampleABI}__ and `functionName` set to `"arbitrator"`.
 *
 *
 */
export function useArbitrableExampleArbitrator<
  TFunctionName extends 'arbitrator',
  TSelectData = ReadContractResult<typeof arbitrableExampleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof arbitrableExampleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof arbitrableExampleAddress } = {} as any
) {
  return useContractRead({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    functionName: 'arbitrator',
    ...config,
  } as UseContractReadConfig<typeof arbitrableExampleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link arbitrableExampleABI}__ and `functionName` set to `"arbitratorExtraData"`.
 *
 *
 */
export function useArbitrableExampleArbitratorExtraData<
  TFunctionName extends 'arbitratorExtraData',
  TSelectData = ReadContractResult<typeof arbitrableExampleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof arbitrableExampleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof arbitrableExampleAddress } = {} as any
) {
  return useContractRead({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    functionName: 'arbitratorExtraData',
    ...config,
  } as UseContractReadConfig<typeof arbitrableExampleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link arbitrableExampleABI}__ and `functionName` set to `"disputes"`.
 *
 *
 */
export function useArbitrableExampleDisputes<
  TFunctionName extends 'disputes',
  TSelectData = ReadContractResult<typeof arbitrableExampleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof arbitrableExampleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof arbitrableExampleAddress } = {} as any
) {
  return useContractRead({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    functionName: 'disputes',
    ...config,
  } as UseContractReadConfig<typeof arbitrableExampleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link arbitrableExampleABI}__ and `functionName` set to `"externalIDtoLocalID"`.
 *
 *
 */
export function useArbitrableExampleExternalIDtoLocalId<
  TFunctionName extends 'externalIDtoLocalID',
  TSelectData = ReadContractResult<typeof arbitrableExampleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof arbitrableExampleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof arbitrableExampleAddress } = {} as any
) {
  return useContractRead({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    functionName: 'externalIDtoLocalID',
    ...config,
  } as UseContractReadConfig<typeof arbitrableExampleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link arbitrableExampleABI}__ and `functionName` set to `"governor"`.
 *
 *
 */
export function useArbitrableExampleGovernor<
  TFunctionName extends 'governor',
  TSelectData = ReadContractResult<typeof arbitrableExampleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof arbitrableExampleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof arbitrableExampleAddress } = {} as any
) {
  return useContractRead({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    functionName: 'governor',
    ...config,
  } as UseContractReadConfig<typeof arbitrableExampleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link arbitrableExampleABI}__ and `functionName` set to `"templateId"`.
 *
 *
 */
export function useArbitrableExampleTemplateId<
  TFunctionName extends 'templateId',
  TSelectData = ReadContractResult<typeof arbitrableExampleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof arbitrableExampleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof arbitrableExampleAddress } = {} as any
) {
  return useContractRead({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    functionName: 'templateId',
    ...config,
  } as UseContractReadConfig<typeof arbitrableExampleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link arbitrableExampleABI}__ and `functionName` set to `"templateRegistry"`.
 *
 *
 */
export function useArbitrableExampleTemplateRegistry<
  TFunctionName extends 'templateRegistry',
  TSelectData = ReadContractResult<typeof arbitrableExampleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof arbitrableExampleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof arbitrableExampleAddress } = {} as any
) {
  return useContractRead({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    functionName: 'templateRegistry',
    ...config,
  } as UseContractReadConfig<typeof arbitrableExampleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link arbitrableExampleABI}__ and `functionName` set to `"weth"`.
 *
 *
 */
export function useArbitrableExampleWeth<
  TFunctionName extends 'weth',
  TSelectData = ReadContractResult<typeof arbitrableExampleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof arbitrableExampleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof arbitrableExampleAddress } = {} as any
) {
  return useContractRead({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    functionName: 'weth',
    ...config,
  } as UseContractReadConfig<typeof arbitrableExampleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link arbitrableExampleABI}__.
 *
 *
 */
export function useArbitrableExampleWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof arbitrableExampleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof arbitrableExampleABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof arbitrableExampleABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof arbitrableExampleABI, TFunctionName, TMode>({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link arbitrableExampleABI}__ and `functionName` set to `"changeArbitrator"`.
 *
 *
 */
export function useArbitrableExampleChangeArbitrator<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof arbitrableExampleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof arbitrableExampleABI, 'changeArbitrator'>['request']['abi'],
        'changeArbitrator',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeArbitrator' }
    : UseContractWriteConfig<typeof arbitrableExampleABI, 'changeArbitrator', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeArbitrator'
      } = {} as any
) {
  return useContractWrite<typeof arbitrableExampleABI, 'changeArbitrator', TMode>({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    functionName: 'changeArbitrator',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link arbitrableExampleABI}__ and `functionName` set to `"changeArbitratorExtraData"`.
 *
 *
 */
export function useArbitrableExampleChangeArbitratorExtraData<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof arbitrableExampleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof arbitrableExampleABI, 'changeArbitratorExtraData'>['request']['abi'],
        'changeArbitratorExtraData',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeArbitratorExtraData' }
    : UseContractWriteConfig<typeof arbitrableExampleABI, 'changeArbitratorExtraData', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeArbitratorExtraData'
      } = {} as any
) {
  return useContractWrite<typeof arbitrableExampleABI, 'changeArbitratorExtraData', TMode>({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    functionName: 'changeArbitratorExtraData',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link arbitrableExampleABI}__ and `functionName` set to `"changeDisputeTemplate"`.
 *
 *
 */
export function useArbitrableExampleChangeDisputeTemplate<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof arbitrableExampleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof arbitrableExampleABI, 'changeDisputeTemplate'>['request']['abi'],
        'changeDisputeTemplate',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeDisputeTemplate' }
    : UseContractWriteConfig<typeof arbitrableExampleABI, 'changeDisputeTemplate', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeDisputeTemplate'
      } = {} as any
) {
  return useContractWrite<typeof arbitrableExampleABI, 'changeDisputeTemplate', TMode>({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    functionName: 'changeDisputeTemplate',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link arbitrableExampleABI}__ and `functionName` set to `"changeTemplateRegistry"`.
 *
 *
 */
export function useArbitrableExampleChangeTemplateRegistry<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof arbitrableExampleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof arbitrableExampleABI, 'changeTemplateRegistry'>['request']['abi'],
        'changeTemplateRegistry',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeTemplateRegistry' }
    : UseContractWriteConfig<typeof arbitrableExampleABI, 'changeTemplateRegistry', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeTemplateRegistry'
      } = {} as any
) {
  return useContractWrite<typeof arbitrableExampleABI, 'changeTemplateRegistry', TMode>({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    functionName: 'changeTemplateRegistry',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link arbitrableExampleABI}__ and `functionName` set to `"createDispute"`.
 *
 *
 */
export function useArbitrableExampleCreateDispute<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof arbitrableExampleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof arbitrableExampleABI, 'createDispute'>['request']['abi'],
        'createDispute',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'createDispute' }
    : UseContractWriteConfig<typeof arbitrableExampleABI, 'createDispute', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'createDispute'
      } = {} as any
) {
  return useContractWrite<typeof arbitrableExampleABI, 'createDispute', TMode>({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    functionName: 'createDispute',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link arbitrableExampleABI}__ and `functionName` set to `"rule"`.
 *
 *
 */
export function useArbitrableExampleRule<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof arbitrableExampleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof arbitrableExampleABI, 'rule'>['request']['abi'],
        'rule',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'rule' }
    : UseContractWriteConfig<typeof arbitrableExampleABI, 'rule', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'rule'
      } = {} as any
) {
  return useContractWrite<typeof arbitrableExampleABI, 'rule', TMode>({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    functionName: 'rule',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link arbitrableExampleABI}__.
 *
 *
 */
export function usePrepareArbitrableExampleWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof arbitrableExampleABI, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof arbitrableExampleAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    ...config,
  } as UsePrepareContractWriteConfig<typeof arbitrableExampleABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link arbitrableExampleABI}__ and `functionName` set to `"changeArbitrator"`.
 *
 *
 */
export function usePrepareArbitrableExampleChangeArbitrator(
  config: Omit<
    UsePrepareContractWriteConfig<typeof arbitrableExampleABI, 'changeArbitrator'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof arbitrableExampleAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    functionName: 'changeArbitrator',
    ...config,
  } as UsePrepareContractWriteConfig<typeof arbitrableExampleABI, 'changeArbitrator'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link arbitrableExampleABI}__ and `functionName` set to `"changeArbitratorExtraData"`.
 *
 *
 */
export function usePrepareArbitrableExampleChangeArbitratorExtraData(
  config: Omit<
    UsePrepareContractWriteConfig<typeof arbitrableExampleABI, 'changeArbitratorExtraData'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof arbitrableExampleAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    functionName: 'changeArbitratorExtraData',
    ...config,
  } as UsePrepareContractWriteConfig<typeof arbitrableExampleABI, 'changeArbitratorExtraData'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link arbitrableExampleABI}__ and `functionName` set to `"changeDisputeTemplate"`.
 *
 *
 */
export function usePrepareArbitrableExampleChangeDisputeTemplate(
  config: Omit<
    UsePrepareContractWriteConfig<typeof arbitrableExampleABI, 'changeDisputeTemplate'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof arbitrableExampleAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    functionName: 'changeDisputeTemplate',
    ...config,
  } as UsePrepareContractWriteConfig<typeof arbitrableExampleABI, 'changeDisputeTemplate'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link arbitrableExampleABI}__ and `functionName` set to `"changeTemplateRegistry"`.
 *
 *
 */
export function usePrepareArbitrableExampleChangeTemplateRegistry(
  config: Omit<
    UsePrepareContractWriteConfig<typeof arbitrableExampleABI, 'changeTemplateRegistry'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof arbitrableExampleAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    functionName: 'changeTemplateRegistry',
    ...config,
  } as UsePrepareContractWriteConfig<typeof arbitrableExampleABI, 'changeTemplateRegistry'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link arbitrableExampleABI}__ and `functionName` set to `"createDispute"`.
 *
 *
 */
export function usePrepareArbitrableExampleCreateDispute(
  config: Omit<
    UsePrepareContractWriteConfig<typeof arbitrableExampleABI, 'createDispute'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof arbitrableExampleAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    functionName: 'createDispute',
    ...config,
  } as UsePrepareContractWriteConfig<typeof arbitrableExampleABI, 'createDispute'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link arbitrableExampleABI}__ and `functionName` set to `"rule"`.
 *
 *
 */
export function usePrepareArbitrableExampleRule(
  config: Omit<
    UsePrepareContractWriteConfig<typeof arbitrableExampleABI, 'rule'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof arbitrableExampleAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    functionName: 'rule',
    ...config,
  } as UsePrepareContractWriteConfig<typeof arbitrableExampleABI, 'rule'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link arbitrableExampleABI}__.
 *
 *
 */
export function useArbitrableExampleEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof arbitrableExampleABI, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof arbitrableExampleAddress
  } = {} as any
) {
  return useContractEvent({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    ...config,
  } as UseContractEventConfig<typeof arbitrableExampleABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link arbitrableExampleABI}__ and `eventName` set to `"Action"`.
 *
 *
 */
export function useArbitrableExampleActionEvent(
  config: Omit<UseContractEventConfig<typeof arbitrableExampleABI, 'Action'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof arbitrableExampleAddress
  } = {} as any
) {
  return useContractEvent({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    eventName: 'Action',
    ...config,
  } as UseContractEventConfig<typeof arbitrableExampleABI, 'Action'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link arbitrableExampleABI}__ and `eventName` set to `"DisputeRequest"`.
 *
 *
 */
export function useArbitrableExampleDisputeRequestEvent(
  config: Omit<
    UseContractEventConfig<typeof arbitrableExampleABI, 'DisputeRequest'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof arbitrableExampleAddress } = {} as any
) {
  return useContractEvent({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    eventName: 'DisputeRequest',
    ...config,
  } as UseContractEventConfig<typeof arbitrableExampleABI, 'DisputeRequest'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link arbitrableExampleABI}__ and `eventName` set to `"Ruling"`.
 *
 *
 */
export function useArbitrableExampleRulingEvent(
  config: Omit<UseContractEventConfig<typeof arbitrableExampleABI, 'Ruling'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof arbitrableExampleAddress
  } = {} as any
) {
  return useContractEvent({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    eventName: 'Ruling',
    ...config,
  } as UseContractEventConfig<typeof arbitrableExampleABI, 'Ruling'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link blockHashRngABI}__.
 *
 *
 */
export function useBlockHashRngRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof blockHashRngABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof blockHashRngABI, TFunctionName, TSelectData>, 'abi' | 'address'> & {
    chainId?: keyof typeof blockHashRngAddress
  } = {} as any
) {
  return useContractRead({
    abi: blockHashRngABI,
    address: blockHashRngAddress[421614],
    ...config,
  } as UseContractReadConfig<typeof blockHashRngABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link blockHashRngABI}__ and `functionName` set to `"randomNumbers"`.
 *
 *
 */
export function useBlockHashRngRandomNumbers<
  TFunctionName extends 'randomNumbers',
  TSelectData = ReadContractResult<typeof blockHashRngABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof blockHashRngABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof blockHashRngAddress } = {} as any
) {
  return useContractRead({
    abi: blockHashRngABI,
    address: blockHashRngAddress[421614],
    functionName: 'randomNumbers',
    ...config,
  } as UseContractReadConfig<typeof blockHashRngABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link blockHashRngABI}__.
 *
 *
 */
export function useBlockHashRngWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof blockHashRngAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof blockHashRngABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof blockHashRngABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof blockHashRngABI, TFunctionName, TMode>({
    abi: blockHashRngABI,
    address: blockHashRngAddress[421614],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link blockHashRngABI}__ and `functionName` set to `"receiveRandomness"`.
 *
 *
 */
export function useBlockHashRngReceiveRandomness<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof blockHashRngAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof blockHashRngABI, 'receiveRandomness'>['request']['abi'],
        'receiveRandomness',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'receiveRandomness' }
    : UseContractWriteConfig<typeof blockHashRngABI, 'receiveRandomness', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'receiveRandomness'
      } = {} as any
) {
  return useContractWrite<typeof blockHashRngABI, 'receiveRandomness', TMode>({
    abi: blockHashRngABI,
    address: blockHashRngAddress[421614],
    functionName: 'receiveRandomness',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link blockHashRngABI}__ and `functionName` set to `"requestRandomness"`.
 *
 *
 */
export function useBlockHashRngRequestRandomness<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof blockHashRngAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof blockHashRngABI, 'requestRandomness'>['request']['abi'],
        'requestRandomness',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'requestRandomness' }
    : UseContractWriteConfig<typeof blockHashRngABI, 'requestRandomness', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'requestRandomness'
      } = {} as any
) {
  return useContractWrite<typeof blockHashRngABI, 'requestRandomness', TMode>({
    abi: blockHashRngABI,
    address: blockHashRngAddress[421614],
    functionName: 'requestRandomness',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link blockHashRngABI}__.
 *
 *
 */
export function usePrepareBlockHashRngWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof blockHashRngABI, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof blockHashRngAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: blockHashRngABI,
    address: blockHashRngAddress[421614],
    ...config,
  } as UsePrepareContractWriteConfig<typeof blockHashRngABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link blockHashRngABI}__ and `functionName` set to `"receiveRandomness"`.
 *
 *
 */
export function usePrepareBlockHashRngReceiveRandomness(
  config: Omit<
    UsePrepareContractWriteConfig<typeof blockHashRngABI, 'receiveRandomness'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof blockHashRngAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: blockHashRngABI,
    address: blockHashRngAddress[421614],
    functionName: 'receiveRandomness',
    ...config,
  } as UsePrepareContractWriteConfig<typeof blockHashRngABI, 'receiveRandomness'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link blockHashRngABI}__ and `functionName` set to `"requestRandomness"`.
 *
 *
 */
export function usePrepareBlockHashRngRequestRandomness(
  config: Omit<
    UsePrepareContractWriteConfig<typeof blockHashRngABI, 'requestRandomness'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof blockHashRngAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: blockHashRngABI,
    address: blockHashRngAddress[421614],
    functionName: 'requestRandomness',
    ...config,
  } as UsePrepareContractWriteConfig<typeof blockHashRngABI, 'requestRandomness'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link daiABI}__.
 *
 *
 */
export function useDaiRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof daiABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof daiABI, TFunctionName, TSelectData>, 'abi' | 'address'> & {
    chainId?: keyof typeof daiAddress
  } = {} as any
) {
  return useContractRead({ abi: daiABI, address: daiAddress[421614], ...config } as UseContractReadConfig<
    typeof daiABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link daiABI}__ and `functionName` set to `"allowance"`.
 *
 *
 */
export function useDaiAllowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof daiABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof daiABI, TFunctionName, TSelectData>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof daiAddress
  } = {} as any
) {
  return useContractRead({
    abi: daiABI,
    address: daiAddress[421614],
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<typeof daiABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link daiABI}__ and `functionName` set to `"balanceOf"`.
 *
 *
 */
export function useDaiBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof daiABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof daiABI, TFunctionName, TSelectData>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof daiAddress
  } = {} as any
) {
  return useContractRead({
    abi: daiABI,
    address: daiAddress[421614],
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof daiABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link daiABI}__ and `functionName` set to `"decimals"`.
 *
 *
 */
export function useDaiDecimals<
  TFunctionName extends 'decimals',
  TSelectData = ReadContractResult<typeof daiABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof daiABI, TFunctionName, TSelectData>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof daiAddress
  } = {} as any
) {
  return useContractRead({
    abi: daiABI,
    address: daiAddress[421614],
    functionName: 'decimals',
    ...config,
  } as UseContractReadConfig<typeof daiABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link daiABI}__ and `functionName` set to `"name"`.
 *
 *
 */
export function useDaiName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof daiABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof daiABI, TFunctionName, TSelectData>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof daiAddress
  } = {} as any
) {
  return useContractRead({
    abi: daiABI,
    address: daiAddress[421614],
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof daiABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link daiABI}__ and `functionName` set to `"symbol"`.
 *
 *
 */
export function useDaiSymbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof daiABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof daiABI, TFunctionName, TSelectData>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof daiAddress
  } = {} as any
) {
  return useContractRead({
    abi: daiABI,
    address: daiAddress[421614],
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<typeof daiABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link daiABI}__ and `functionName` set to `"totalSupply"`.
 *
 *
 */
export function useDaiTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof daiABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof daiABI, TFunctionName, TSelectData>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof daiAddress
  } = {} as any
) {
  return useContractRead({
    abi: daiABI,
    address: daiAddress[421614],
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof daiABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link daiABI}__.
 *
 *
 */
export function useDaiWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof daiAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof daiABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof daiABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof daiABI, TFunctionName, TMode>({
    abi: daiABI,
    address: daiAddress[421614],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link daiABI}__ and `functionName` set to `"approve"`.
 *
 *
 */
export function useDaiApprove<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof daiAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof daiABI, 'approve'>['request']['abi'],
        'approve',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'approve' }
    : UseContractWriteConfig<typeof daiABI, 'approve', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'approve'
      } = {} as any
) {
  return useContractWrite<typeof daiABI, 'approve', TMode>({
    abi: daiABI,
    address: daiAddress[421614],
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link daiABI}__ and `functionName` set to `"decreaseAllowance"`.
 *
 *
 */
export function useDaiDecreaseAllowance<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof daiAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof daiABI, 'decreaseAllowance'>['request']['abi'],
        'decreaseAllowance',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'decreaseAllowance' }
    : UseContractWriteConfig<typeof daiABI, 'decreaseAllowance', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'decreaseAllowance'
      } = {} as any
) {
  return useContractWrite<typeof daiABI, 'decreaseAllowance', TMode>({
    abi: daiABI,
    address: daiAddress[421614],
    functionName: 'decreaseAllowance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link daiABI}__ and `functionName` set to `"increaseAllowance"`.
 *
 *
 */
export function useDaiIncreaseAllowance<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof daiAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof daiABI, 'increaseAllowance'>['request']['abi'],
        'increaseAllowance',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'increaseAllowance' }
    : UseContractWriteConfig<typeof daiABI, 'increaseAllowance', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'increaseAllowance'
      } = {} as any
) {
  return useContractWrite<typeof daiABI, 'increaseAllowance', TMode>({
    abi: daiABI,
    address: daiAddress[421614],
    functionName: 'increaseAllowance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link daiABI}__ and `functionName` set to `"transfer"`.
 *
 *
 */
export function useDaiTransfer<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof daiAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof daiABI, 'transfer'>['request']['abi'],
        'transfer',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'transfer' }
    : UseContractWriteConfig<typeof daiABI, 'transfer', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'transfer'
      } = {} as any
) {
  return useContractWrite<typeof daiABI, 'transfer', TMode>({
    abi: daiABI,
    address: daiAddress[421614],
    functionName: 'transfer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link daiABI}__ and `functionName` set to `"transferFrom"`.
 *
 *
 */
export function useDaiTransferFrom<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof daiAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof daiABI, 'transferFrom'>['request']['abi'],
        'transferFrom',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'transferFrom' }
    : UseContractWriteConfig<typeof daiABI, 'transferFrom', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'transferFrom'
      } = {} as any
) {
  return useContractWrite<typeof daiABI, 'transferFrom', TMode>({
    abi: daiABI,
    address: daiAddress[421614],
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link daiABI}__.
 *
 *
 */
export function usePrepareDaiWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof daiABI, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof daiAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: daiABI,
    address: daiAddress[421614],
    ...config,
  } as UsePrepareContractWriteConfig<typeof daiABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link daiABI}__ and `functionName` set to `"approve"`.
 *
 *
 */
export function usePrepareDaiApprove(
  config: Omit<UsePrepareContractWriteConfig<typeof daiABI, 'approve'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof daiAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: daiABI,
    address: daiAddress[421614],
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof daiABI, 'approve'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link daiABI}__ and `functionName` set to `"decreaseAllowance"`.
 *
 *
 */
export function usePrepareDaiDecreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof daiABI, 'decreaseAllowance'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof daiAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: daiABI,
    address: daiAddress[421614],
    functionName: 'decreaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof daiABI, 'decreaseAllowance'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link daiABI}__ and `functionName` set to `"increaseAllowance"`.
 *
 *
 */
export function usePrepareDaiIncreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof daiABI, 'increaseAllowance'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof daiAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: daiABI,
    address: daiAddress[421614],
    functionName: 'increaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof daiABI, 'increaseAllowance'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link daiABI}__ and `functionName` set to `"transfer"`.
 *
 *
 */
export function usePrepareDaiTransfer(
  config: Omit<UsePrepareContractWriteConfig<typeof daiABI, 'transfer'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof daiAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: daiABI,
    address: daiAddress[421614],
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof daiABI, 'transfer'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link daiABI}__ and `functionName` set to `"transferFrom"`.
 *
 *
 */
export function usePrepareDaiTransferFrom(
  config: Omit<UsePrepareContractWriteConfig<typeof daiABI, 'transferFrom'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof daiAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: daiABI,
    address: daiAddress[421614],
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof daiABI, 'transferFrom'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link daiABI}__.
 *
 *
 */
export function useDaiEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof daiABI, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof daiAddress
  } = {} as any
) {
  return useContractEvent({ abi: daiABI, address: daiAddress[421614], ...config } as UseContractEventConfig<
    typeof daiABI,
    TEventName
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link daiABI}__ and `eventName` set to `"Approval"`.
 *
 *
 */
export function useDaiApprovalEvent(
  config: Omit<UseContractEventConfig<typeof daiABI, 'Approval'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof daiAddress
  } = {} as any
) {
  return useContractEvent({
    abi: daiABI,
    address: daiAddress[421614],
    eventName: 'Approval',
    ...config,
  } as UseContractEventConfig<typeof daiABI, 'Approval'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link daiABI}__ and `eventName` set to `"Transfer"`.
 *
 *
 */
export function useDaiTransferEvent(
  config: Omit<UseContractEventConfig<typeof daiABI, 'Transfer'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof daiAddress
  } = {} as any
) {
  return useContractEvent({
    abi: daiABI,
    address: daiAddress[421614],
    eventName: 'Transfer',
    ...config,
  } as UseContractEventConfig<typeof daiABI, 'Transfer'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link daiFaucetABI}__.
 *
 *
 */
export function useDaiFaucetRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof daiFaucetABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof daiFaucetABI, TFunctionName, TSelectData>, 'abi' | 'address'> & {
    chainId?: keyof typeof daiFaucetAddress
  } = {} as any
) {
  return useContractRead({ abi: daiFaucetABI, address: daiFaucetAddress[421614], ...config } as UseContractReadConfig<
    typeof daiFaucetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link daiFaucetABI}__ and `functionName` set to `"amount"`.
 *
 *
 */
export function useDaiFaucetAmount<
  TFunctionName extends 'amount',
  TSelectData = ReadContractResult<typeof daiFaucetABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof daiFaucetABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof daiFaucetAddress } = {} as any
) {
  return useContractRead({
    abi: daiFaucetABI,
    address: daiFaucetAddress[421614],
    functionName: 'amount',
    ...config,
  } as UseContractReadConfig<typeof daiFaucetABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link daiFaucetABI}__ and `functionName` set to `"balance"`.
 *
 *
 */
export function useDaiFaucetBalance<
  TFunctionName extends 'balance',
  TSelectData = ReadContractResult<typeof daiFaucetABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof daiFaucetABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof daiFaucetAddress } = {} as any
) {
  return useContractRead({
    abi: daiFaucetABI,
    address: daiFaucetAddress[421614],
    functionName: 'balance',
    ...config,
  } as UseContractReadConfig<typeof daiFaucetABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link daiFaucetABI}__ and `functionName` set to `"governor"`.
 *
 *
 */
export function useDaiFaucetGovernor<
  TFunctionName extends 'governor',
  TSelectData = ReadContractResult<typeof daiFaucetABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof daiFaucetABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof daiFaucetAddress } = {} as any
) {
  return useContractRead({
    abi: daiFaucetABI,
    address: daiFaucetAddress[421614],
    functionName: 'governor',
    ...config,
  } as UseContractReadConfig<typeof daiFaucetABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link daiFaucetABI}__ and `functionName` set to `"token"`.
 *
 *
 */
export function useDaiFaucetToken<
  TFunctionName extends 'token',
  TSelectData = ReadContractResult<typeof daiFaucetABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof daiFaucetABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof daiFaucetAddress } = {} as any
) {
  return useContractRead({
    abi: daiFaucetABI,
    address: daiFaucetAddress[421614],
    functionName: 'token',
    ...config,
  } as UseContractReadConfig<typeof daiFaucetABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link daiFaucetABI}__ and `functionName` set to `"withdrewAlready"`.
 *
 *
 */
export function useDaiFaucetWithdrewAlready<
  TFunctionName extends 'withdrewAlready',
  TSelectData = ReadContractResult<typeof daiFaucetABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof daiFaucetABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof daiFaucetAddress } = {} as any
) {
  return useContractRead({
    abi: daiFaucetABI,
    address: daiFaucetAddress[421614],
    functionName: 'withdrewAlready',
    ...config,
  } as UseContractReadConfig<typeof daiFaucetABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link daiFaucetABI}__.
 *
 *
 */
export function useDaiFaucetWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof daiFaucetAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof daiFaucetABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof daiFaucetABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof daiFaucetABI, TFunctionName, TMode>({
    abi: daiFaucetABI,
    address: daiFaucetAddress[421614],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link daiFaucetABI}__ and `functionName` set to `"changeAmount"`.
 *
 *
 */
export function useDaiFaucetChangeAmount<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof daiFaucetAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof daiFaucetABI, 'changeAmount'>['request']['abi'],
        'changeAmount',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeAmount' }
    : UseContractWriteConfig<typeof daiFaucetABI, 'changeAmount', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeAmount'
      } = {} as any
) {
  return useContractWrite<typeof daiFaucetABI, 'changeAmount', TMode>({
    abi: daiFaucetABI,
    address: daiFaucetAddress[421614],
    functionName: 'changeAmount',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link daiFaucetABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function useDaiFaucetChangeGovernor<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof daiFaucetAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof daiFaucetABI, 'changeGovernor'>['request']['abi'],
        'changeGovernor',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeGovernor' }
    : UseContractWriteConfig<typeof daiFaucetABI, 'changeGovernor', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeGovernor'
      } = {} as any
) {
  return useContractWrite<typeof daiFaucetABI, 'changeGovernor', TMode>({
    abi: daiFaucetABI,
    address: daiFaucetAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link daiFaucetABI}__ and `functionName` set to `"request"`.
 *
 *
 */
export function useDaiFaucetRequest<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof daiFaucetAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof daiFaucetABI, 'request'>['request']['abi'],
        'request',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'request' }
    : UseContractWriteConfig<typeof daiFaucetABI, 'request', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'request'
      } = {} as any
) {
  return useContractWrite<typeof daiFaucetABI, 'request', TMode>({
    abi: daiFaucetABI,
    address: daiFaucetAddress[421614],
    functionName: 'request',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link daiFaucetABI}__ and `functionName` set to `"withdraw"`.
 *
 *
 */
export function useDaiFaucetWithdraw<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof daiFaucetAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof daiFaucetABI, 'withdraw'>['request']['abi'],
        'withdraw',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'withdraw' }
    : UseContractWriteConfig<typeof daiFaucetABI, 'withdraw', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'withdraw'
      } = {} as any
) {
  return useContractWrite<typeof daiFaucetABI, 'withdraw', TMode>({
    abi: daiFaucetABI,
    address: daiFaucetAddress[421614],
    functionName: 'withdraw',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link daiFaucetABI}__.
 *
 *
 */
export function usePrepareDaiFaucetWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof daiFaucetABI, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof daiFaucetAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: daiFaucetABI,
    address: daiFaucetAddress[421614],
    ...config,
  } as UsePrepareContractWriteConfig<typeof daiFaucetABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link daiFaucetABI}__ and `functionName` set to `"changeAmount"`.
 *
 *
 */
export function usePrepareDaiFaucetChangeAmount(
  config: Omit<
    UsePrepareContractWriteConfig<typeof daiFaucetABI, 'changeAmount'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof daiFaucetAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: daiFaucetABI,
    address: daiFaucetAddress[421614],
    functionName: 'changeAmount',
    ...config,
  } as UsePrepareContractWriteConfig<typeof daiFaucetABI, 'changeAmount'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link daiFaucetABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function usePrepareDaiFaucetChangeGovernor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof daiFaucetABI, 'changeGovernor'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof daiFaucetAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: daiFaucetABI,
    address: daiFaucetAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as UsePrepareContractWriteConfig<typeof daiFaucetABI, 'changeGovernor'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link daiFaucetABI}__ and `functionName` set to `"request"`.
 *
 *
 */
export function usePrepareDaiFaucetRequest(
  config: Omit<UsePrepareContractWriteConfig<typeof daiFaucetABI, 'request'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof daiFaucetAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: daiFaucetABI,
    address: daiFaucetAddress[421614],
    functionName: 'request',
    ...config,
  } as UsePrepareContractWriteConfig<typeof daiFaucetABI, 'request'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link daiFaucetABI}__ and `functionName` set to `"withdraw"`.
 *
 *
 */
export function usePrepareDaiFaucetWithdraw(
  config: Omit<UsePrepareContractWriteConfig<typeof daiFaucetABI, 'withdraw'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof daiFaucetAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: daiFaucetABI,
    address: daiFaucetAddress[421614],
    functionName: 'withdraw',
    ...config,
  } as UsePrepareContractWriteConfig<typeof daiFaucetABI, 'withdraw'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicABI}__.
 *
 *
 */
export function useDisputeKitClassicRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof disputeKitClassicABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>, 'abi' | 'address'> & {
    chainId?: keyof typeof disputeKitClassicAddress
  } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"LOSER_APPEAL_PERIOD_MULTIPLIER"`.
 *
 *
 */
export function useDisputeKitClassicLoserAppealPeriodMultiplier<
  TFunctionName extends 'LOSER_APPEAL_PERIOD_MULTIPLIER',
  TSelectData = ReadContractResult<typeof disputeKitClassicABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'LOSER_APPEAL_PERIOD_MULTIPLIER',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"LOSER_STAKE_MULTIPLIER"`.
 *
 *
 */
export function useDisputeKitClassicLoserStakeMultiplier<
  TFunctionName extends 'LOSER_STAKE_MULTIPLIER',
  TSelectData = ReadContractResult<typeof disputeKitClassicABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'LOSER_STAKE_MULTIPLIER',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"ONE_BASIS_POINT"`.
 *
 *
 */
export function useDisputeKitClassicOneBasisPoint<
  TFunctionName extends 'ONE_BASIS_POINT',
  TSelectData = ReadContractResult<typeof disputeKitClassicABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'ONE_BASIS_POINT',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"WINNER_STAKE_MULTIPLIER"`.
 *
 *
 */
export function useDisputeKitClassicWinnerStakeMultiplier<
  TFunctionName extends 'WINNER_STAKE_MULTIPLIER',
  TSelectData = ReadContractResult<typeof disputeKitClassicABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'WINNER_STAKE_MULTIPLIER',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"areCommitsAllCast"`.
 *
 *
 */
export function useDisputeKitClassicAreCommitsAllCast<
  TFunctionName extends 'areCommitsAllCast',
  TSelectData = ReadContractResult<typeof disputeKitClassicABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'areCommitsAllCast',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"areVotesAllCast"`.
 *
 *
 */
export function useDisputeKitClassicAreVotesAllCast<
  TFunctionName extends 'areVotesAllCast',
  TSelectData = ReadContractResult<typeof disputeKitClassicABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'areVotesAllCast',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"core"`.
 *
 *
 */
export function useDisputeKitClassicCore<
  TFunctionName extends 'core',
  TSelectData = ReadContractResult<typeof disputeKitClassicABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'core',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"coreDisputeIDToLocal"`.
 *
 *
 */
export function useDisputeKitClassicCoreDisputeIdToLocal<
  TFunctionName extends 'coreDisputeIDToLocal',
  TSelectData = ReadContractResult<typeof disputeKitClassicABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'coreDisputeIDToLocal',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"currentRuling"`.
 *
 *
 */
export function useDisputeKitClassicCurrentRuling<
  TFunctionName extends 'currentRuling',
  TSelectData = ReadContractResult<typeof disputeKitClassicABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'currentRuling',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"disputes"`.
 *
 *
 */
export function useDisputeKitClassicDisputes<
  TFunctionName extends 'disputes',
  TSelectData = ReadContractResult<typeof disputeKitClassicABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'disputes',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"getCoherentCount"`.
 *
 *
 */
export function useDisputeKitClassicGetCoherentCount<
  TFunctionName extends 'getCoherentCount',
  TSelectData = ReadContractResult<typeof disputeKitClassicABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'getCoherentCount',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"getDegreeOfCoherence"`.
 *
 *
 */
export function useDisputeKitClassicGetDegreeOfCoherence<
  TFunctionName extends 'getDegreeOfCoherence',
  TSelectData = ReadContractResult<typeof disputeKitClassicABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'getDegreeOfCoherence',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"getFundedChoices"`.
 *
 *
 */
export function useDisputeKitClassicGetFundedChoices<
  TFunctionName extends 'getFundedChoices',
  TSelectData = ReadContractResult<typeof disputeKitClassicABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'getFundedChoices',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"getRoundInfo"`.
 *
 *
 */
export function useDisputeKitClassicGetRoundInfo<
  TFunctionName extends 'getRoundInfo',
  TSelectData = ReadContractResult<typeof disputeKitClassicABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'getRoundInfo',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"getVoteInfo"`.
 *
 *
 */
export function useDisputeKitClassicGetVoteInfo<
  TFunctionName extends 'getVoteInfo',
  TSelectData = ReadContractResult<typeof disputeKitClassicABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'getVoteInfo',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"governor"`.
 *
 *
 */
export function useDisputeKitClassicGovernor<
  TFunctionName extends 'governor',
  TSelectData = ReadContractResult<typeof disputeKitClassicABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'governor',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"isVoteActive"`.
 *
 *
 */
export function useDisputeKitClassicIsVoteActive<
  TFunctionName extends 'isVoteActive',
  TSelectData = ReadContractResult<typeof disputeKitClassicABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'isVoteActive',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"proxiableUUID"`.
 *
 *
 */
export function useDisputeKitClassicProxiableUuid<
  TFunctionName extends 'proxiableUUID',
  TSelectData = ReadContractResult<typeof disputeKitClassicABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'proxiableUUID',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeKitClassicABI}__.
 *
 *
 */
export function useDisputeKitClassicWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeKitClassicAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeKitClassicABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof disputeKitClassicABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof disputeKitClassicABI, TFunctionName, TMode>({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"castCommit"`.
 *
 *
 */
export function useDisputeKitClassicCastCommit<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeKitClassicAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeKitClassicABI, 'castCommit'>['request']['abi'],
        'castCommit',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'castCommit' }
    : UseContractWriteConfig<typeof disputeKitClassicABI, 'castCommit', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'castCommit'
      } = {} as any
) {
  return useContractWrite<typeof disputeKitClassicABI, 'castCommit', TMode>({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'castCommit',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"castVote"`.
 *
 *
 */
export function useDisputeKitClassicCastVote<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeKitClassicAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeKitClassicABI, 'castVote'>['request']['abi'],
        'castVote',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'castVote' }
    : UseContractWriteConfig<typeof disputeKitClassicABI, 'castVote', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'castVote'
      } = {} as any
) {
  return useContractWrite<typeof disputeKitClassicABI, 'castVote', TMode>({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'castVote',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"changeCore"`.
 *
 *
 */
export function useDisputeKitClassicChangeCore<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeKitClassicAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeKitClassicABI, 'changeCore'>['request']['abi'],
        'changeCore',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeCore' }
    : UseContractWriteConfig<typeof disputeKitClassicABI, 'changeCore', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeCore'
      } = {} as any
) {
  return useContractWrite<typeof disputeKitClassicABI, 'changeCore', TMode>({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'changeCore',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function useDisputeKitClassicChangeGovernor<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeKitClassicAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeKitClassicABI, 'changeGovernor'>['request']['abi'],
        'changeGovernor',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeGovernor' }
    : UseContractWriteConfig<typeof disputeKitClassicABI, 'changeGovernor', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeGovernor'
      } = {} as any
) {
  return useContractWrite<typeof disputeKitClassicABI, 'changeGovernor', TMode>({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"createDispute"`.
 *
 *
 */
export function useDisputeKitClassicCreateDispute<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeKitClassicAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeKitClassicABI, 'createDispute'>['request']['abi'],
        'createDispute',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'createDispute' }
    : UseContractWriteConfig<typeof disputeKitClassicABI, 'createDispute', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'createDispute'
      } = {} as any
) {
  return useContractWrite<typeof disputeKitClassicABI, 'createDispute', TMode>({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'createDispute',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"draw"`.
 *
 *
 */
export function useDisputeKitClassicDraw<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeKitClassicAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeKitClassicABI, 'draw'>['request']['abi'],
        'draw',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'draw' }
    : UseContractWriteConfig<typeof disputeKitClassicABI, 'draw', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'draw'
      } = {} as any
) {
  return useContractWrite<typeof disputeKitClassicABI, 'draw', TMode>({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'draw',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"executeGovernorProposal"`.
 *
 *
 */
export function useDisputeKitClassicExecuteGovernorProposal<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeKitClassicAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeKitClassicABI, 'executeGovernorProposal'>['request']['abi'],
        'executeGovernorProposal',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'executeGovernorProposal' }
    : UseContractWriteConfig<typeof disputeKitClassicABI, 'executeGovernorProposal', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'executeGovernorProposal'
      } = {} as any
) {
  return useContractWrite<typeof disputeKitClassicABI, 'executeGovernorProposal', TMode>({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'executeGovernorProposal',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"fundAppeal"`.
 *
 *
 */
export function useDisputeKitClassicFundAppeal<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeKitClassicAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeKitClassicABI, 'fundAppeal'>['request']['abi'],
        'fundAppeal',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'fundAppeal' }
    : UseContractWriteConfig<typeof disputeKitClassicABI, 'fundAppeal', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'fundAppeal'
      } = {} as any
) {
  return useContractWrite<typeof disputeKitClassicABI, 'fundAppeal', TMode>({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'fundAppeal',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function useDisputeKitClassicInitialize<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeKitClassicAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeKitClassicABI, 'initialize'>['request']['abi'],
        'initialize',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'initialize' }
    : UseContractWriteConfig<typeof disputeKitClassicABI, 'initialize', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'initialize'
      } = {} as any
) {
  return useContractWrite<typeof disputeKitClassicABI, 'initialize', TMode>({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'initialize',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function useDisputeKitClassicUpgradeToAndCall<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeKitClassicAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeKitClassicABI, 'upgradeToAndCall'>['request']['abi'],
        'upgradeToAndCall',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'upgradeToAndCall' }
    : UseContractWriteConfig<typeof disputeKitClassicABI, 'upgradeToAndCall', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'upgradeToAndCall'
      } = {} as any
) {
  return useContractWrite<typeof disputeKitClassicABI, 'upgradeToAndCall', TMode>({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"withdrawFeesAndRewards"`.
 *
 *
 */
export function useDisputeKitClassicWithdrawFeesAndRewards<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeKitClassicAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeKitClassicABI, 'withdrawFeesAndRewards'>['request']['abi'],
        'withdrawFeesAndRewards',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'withdrawFeesAndRewards' }
    : UseContractWriteConfig<typeof disputeKitClassicABI, 'withdrawFeesAndRewards', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'withdrawFeesAndRewards'
      } = {} as any
) {
  return useContractWrite<typeof disputeKitClassicABI, 'withdrawFeesAndRewards', TMode>({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'withdrawFeesAndRewards',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeKitClassicABI}__.
 *
 *
 */
export function usePrepareDisputeKitClassicWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof disputeKitClassicABI, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof disputeKitClassicAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeKitClassicABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"castCommit"`.
 *
 *
 */
export function usePrepareDisputeKitClassicCastCommit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeKitClassicABI, 'castCommit'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'castCommit',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeKitClassicABI, 'castCommit'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"castVote"`.
 *
 *
 */
export function usePrepareDisputeKitClassicCastVote(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeKitClassicABI, 'castVote'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'castVote',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeKitClassicABI, 'castVote'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"changeCore"`.
 *
 *
 */
export function usePrepareDisputeKitClassicChangeCore(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeKitClassicABI, 'changeCore'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'changeCore',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeKitClassicABI, 'changeCore'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function usePrepareDisputeKitClassicChangeGovernor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeKitClassicABI, 'changeGovernor'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeKitClassicABI, 'changeGovernor'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"createDispute"`.
 *
 *
 */
export function usePrepareDisputeKitClassicCreateDispute(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeKitClassicABI, 'createDispute'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'createDispute',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeKitClassicABI, 'createDispute'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"draw"`.
 *
 *
 */
export function usePrepareDisputeKitClassicDraw(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeKitClassicABI, 'draw'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'draw',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeKitClassicABI, 'draw'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"executeGovernorProposal"`.
 *
 *
 */
export function usePrepareDisputeKitClassicExecuteGovernorProposal(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeKitClassicABI, 'executeGovernorProposal'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'executeGovernorProposal',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeKitClassicABI, 'executeGovernorProposal'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"fundAppeal"`.
 *
 *
 */
export function usePrepareDisputeKitClassicFundAppeal(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeKitClassicABI, 'fundAppeal'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'fundAppeal',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeKitClassicABI, 'fundAppeal'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function usePrepareDisputeKitClassicInitialize(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeKitClassicABI, 'initialize'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'initialize',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeKitClassicABI, 'initialize'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function usePrepareDisputeKitClassicUpgradeToAndCall(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeKitClassicABI, 'upgradeToAndCall'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeKitClassicABI, 'upgradeToAndCall'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeKitClassicABI}__ and `functionName` set to `"withdrawFeesAndRewards"`.
 *
 *
 */
export function usePrepareDisputeKitClassicWithdrawFeesAndRewards(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeKitClassicABI, 'withdrawFeesAndRewards'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    functionName: 'withdrawFeesAndRewards',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeKitClassicABI, 'withdrawFeesAndRewards'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeKitClassicABI}__.
 *
 *
 */
export function useDisputeKitClassicEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof disputeKitClassicABI, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof disputeKitClassicAddress
  } = {} as any
) {
  return useContractEvent({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    ...config,
  } as UseContractEventConfig<typeof disputeKitClassicABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeKitClassicABI}__ and `eventName` set to `"ChoiceFunded"`.
 *
 *
 */
export function useDisputeKitClassicChoiceFundedEvent(
  config: Omit<UseContractEventConfig<typeof disputeKitClassicABI, 'ChoiceFunded'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof disputeKitClassicAddress
  } = {} as any
) {
  return useContractEvent({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    eventName: 'ChoiceFunded',
    ...config,
  } as UseContractEventConfig<typeof disputeKitClassicABI, 'ChoiceFunded'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeKitClassicABI}__ and `eventName` set to `"CommitCast"`.
 *
 *
 */
export function useDisputeKitClassicCommitCastEvent(
  config: Omit<UseContractEventConfig<typeof disputeKitClassicABI, 'CommitCast'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof disputeKitClassicAddress
  } = {} as any
) {
  return useContractEvent({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    eventName: 'CommitCast',
    ...config,
  } as UseContractEventConfig<typeof disputeKitClassicABI, 'CommitCast'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeKitClassicABI}__ and `eventName` set to `"Contribution"`.
 *
 *
 */
export function useDisputeKitClassicContributionEvent(
  config: Omit<UseContractEventConfig<typeof disputeKitClassicABI, 'Contribution'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof disputeKitClassicAddress
  } = {} as any
) {
  return useContractEvent({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    eventName: 'Contribution',
    ...config,
  } as UseContractEventConfig<typeof disputeKitClassicABI, 'Contribution'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeKitClassicABI}__ and `eventName` set to `"DisputeCreation"`.
 *
 *
 */
export function useDisputeKitClassicDisputeCreationEvent(
  config: Omit<
    UseContractEventConfig<typeof disputeKitClassicABI, 'DisputeCreation'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof disputeKitClassicAddress } = {} as any
) {
  return useContractEvent({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    eventName: 'DisputeCreation',
    ...config,
  } as UseContractEventConfig<typeof disputeKitClassicABI, 'DisputeCreation'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeKitClassicABI}__ and `eventName` set to `"Initialized"`.
 *
 *
 */
export function useDisputeKitClassicInitializedEvent(
  config: Omit<UseContractEventConfig<typeof disputeKitClassicABI, 'Initialized'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof disputeKitClassicAddress
  } = {} as any
) {
  return useContractEvent({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    eventName: 'Initialized',
    ...config,
  } as UseContractEventConfig<typeof disputeKitClassicABI, 'Initialized'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeKitClassicABI}__ and `eventName` set to `"Upgraded"`.
 *
 *
 */
export function useDisputeKitClassicUpgradedEvent(
  config: Omit<UseContractEventConfig<typeof disputeKitClassicABI, 'Upgraded'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof disputeKitClassicAddress
  } = {} as any
) {
  return useContractEvent({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    eventName: 'Upgraded',
    ...config,
  } as UseContractEventConfig<typeof disputeKitClassicABI, 'Upgraded'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeKitClassicABI}__ and `eventName` set to `"VoteCast"`.
 *
 *
 */
export function useDisputeKitClassicVoteCastEvent(
  config: Omit<UseContractEventConfig<typeof disputeKitClassicABI, 'VoteCast'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof disputeKitClassicAddress
  } = {} as any
) {
  return useContractEvent({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    eventName: 'VoteCast',
    ...config,
  } as UseContractEventConfig<typeof disputeKitClassicABI, 'VoteCast'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeKitClassicABI}__ and `eventName` set to `"Withdrawal"`.
 *
 *
 */
export function useDisputeKitClassicWithdrawalEvent(
  config: Omit<UseContractEventConfig<typeof disputeKitClassicABI, 'Withdrawal'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof disputeKitClassicAddress
  } = {} as any
) {
  return useContractEvent({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    eventName: 'Withdrawal',
    ...config,
  } as UseContractEventConfig<typeof disputeKitClassicABI, 'Withdrawal'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__.
 *
 *
 */
export function useDisputeKitClassicImplementationRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof disputeKitClassicImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"LOSER_APPEAL_PERIOD_MULTIPLIER"`.
 *
 *
 */
export function useDisputeKitClassicImplementationLoserAppealPeriodMultiplier<
  TFunctionName extends 'LOSER_APPEAL_PERIOD_MULTIPLIER',
  TSelectData = ReadContractResult<typeof disputeKitClassicImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'LOSER_APPEAL_PERIOD_MULTIPLIER',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"LOSER_STAKE_MULTIPLIER"`.
 *
 *
 */
export function useDisputeKitClassicImplementationLoserStakeMultiplier<
  TFunctionName extends 'LOSER_STAKE_MULTIPLIER',
  TSelectData = ReadContractResult<typeof disputeKitClassicImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'LOSER_STAKE_MULTIPLIER',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"ONE_BASIS_POINT"`.
 *
 *
 */
export function useDisputeKitClassicImplementationOneBasisPoint<
  TFunctionName extends 'ONE_BASIS_POINT',
  TSelectData = ReadContractResult<typeof disputeKitClassicImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'ONE_BASIS_POINT',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"WINNER_STAKE_MULTIPLIER"`.
 *
 *
 */
export function useDisputeKitClassicImplementationWinnerStakeMultiplier<
  TFunctionName extends 'WINNER_STAKE_MULTIPLIER',
  TSelectData = ReadContractResult<typeof disputeKitClassicImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'WINNER_STAKE_MULTIPLIER',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"areCommitsAllCast"`.
 *
 *
 */
export function useDisputeKitClassicImplementationAreCommitsAllCast<
  TFunctionName extends 'areCommitsAllCast',
  TSelectData = ReadContractResult<typeof disputeKitClassicImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'areCommitsAllCast',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"areVotesAllCast"`.
 *
 *
 */
export function useDisputeKitClassicImplementationAreVotesAllCast<
  TFunctionName extends 'areVotesAllCast',
  TSelectData = ReadContractResult<typeof disputeKitClassicImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'areVotesAllCast',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"core"`.
 *
 *
 */
export function useDisputeKitClassicImplementationCore<
  TFunctionName extends 'core',
  TSelectData = ReadContractResult<typeof disputeKitClassicImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'core',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"coreDisputeIDToLocal"`.
 *
 *
 */
export function useDisputeKitClassicImplementationCoreDisputeIdToLocal<
  TFunctionName extends 'coreDisputeIDToLocal',
  TSelectData = ReadContractResult<typeof disputeKitClassicImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'coreDisputeIDToLocal',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"currentRuling"`.
 *
 *
 */
export function useDisputeKitClassicImplementationCurrentRuling<
  TFunctionName extends 'currentRuling',
  TSelectData = ReadContractResult<typeof disputeKitClassicImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'currentRuling',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"disputes"`.
 *
 *
 */
export function useDisputeKitClassicImplementationDisputes<
  TFunctionName extends 'disputes',
  TSelectData = ReadContractResult<typeof disputeKitClassicImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'disputes',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"getCoherentCount"`.
 *
 *
 */
export function useDisputeKitClassicImplementationGetCoherentCount<
  TFunctionName extends 'getCoherentCount',
  TSelectData = ReadContractResult<typeof disputeKitClassicImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'getCoherentCount',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"getDegreeOfCoherence"`.
 *
 *
 */
export function useDisputeKitClassicImplementationGetDegreeOfCoherence<
  TFunctionName extends 'getDegreeOfCoherence',
  TSelectData = ReadContractResult<typeof disputeKitClassicImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'getDegreeOfCoherence',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"getFundedChoices"`.
 *
 *
 */
export function useDisputeKitClassicImplementationGetFundedChoices<
  TFunctionName extends 'getFundedChoices',
  TSelectData = ReadContractResult<typeof disputeKitClassicImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'getFundedChoices',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"getRoundInfo"`.
 *
 *
 */
export function useDisputeKitClassicImplementationGetRoundInfo<
  TFunctionName extends 'getRoundInfo',
  TSelectData = ReadContractResult<typeof disputeKitClassicImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'getRoundInfo',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"getVoteInfo"`.
 *
 *
 */
export function useDisputeKitClassicImplementationGetVoteInfo<
  TFunctionName extends 'getVoteInfo',
  TSelectData = ReadContractResult<typeof disputeKitClassicImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'getVoteInfo',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"governor"`.
 *
 *
 */
export function useDisputeKitClassicImplementationGovernor<
  TFunctionName extends 'governor',
  TSelectData = ReadContractResult<typeof disputeKitClassicImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'governor',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"isVoteActive"`.
 *
 *
 */
export function useDisputeKitClassicImplementationIsVoteActive<
  TFunctionName extends 'isVoteActive',
  TSelectData = ReadContractResult<typeof disputeKitClassicImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'isVoteActive',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"proxiableUUID"`.
 *
 *
 */
export function useDisputeKitClassicImplementationProxiableUuid<
  TFunctionName extends 'proxiableUUID',
  TSelectData = ReadContractResult<typeof disputeKitClassicImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'proxiableUUID',
    ...config,
  } as UseContractReadConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__.
 *
 *
 */
export function useDisputeKitClassicImplementationWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeKitClassicImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeKitClassicImplementationABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof disputeKitClassicImplementationABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof disputeKitClassicImplementationABI, TFunctionName, TMode>({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"castCommit"`.
 *
 *
 */
export function useDisputeKitClassicImplementationCastCommit<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeKitClassicImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeKitClassicImplementationABI, 'castCommit'>['request']['abi'],
        'castCommit',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'castCommit' }
    : UseContractWriteConfig<typeof disputeKitClassicImplementationABI, 'castCommit', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'castCommit'
      } = {} as any
) {
  return useContractWrite<typeof disputeKitClassicImplementationABI, 'castCommit', TMode>({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'castCommit',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"castVote"`.
 *
 *
 */
export function useDisputeKitClassicImplementationCastVote<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeKitClassicImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeKitClassicImplementationABI, 'castVote'>['request']['abi'],
        'castVote',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'castVote' }
    : UseContractWriteConfig<typeof disputeKitClassicImplementationABI, 'castVote', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'castVote'
      } = {} as any
) {
  return useContractWrite<typeof disputeKitClassicImplementationABI, 'castVote', TMode>({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'castVote',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"changeCore"`.
 *
 *
 */
export function useDisputeKitClassicImplementationChangeCore<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeKitClassicImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeKitClassicImplementationABI, 'changeCore'>['request']['abi'],
        'changeCore',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeCore' }
    : UseContractWriteConfig<typeof disputeKitClassicImplementationABI, 'changeCore', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeCore'
      } = {} as any
) {
  return useContractWrite<typeof disputeKitClassicImplementationABI, 'changeCore', TMode>({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'changeCore',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function useDisputeKitClassicImplementationChangeGovernor<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeKitClassicImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeKitClassicImplementationABI, 'changeGovernor'>['request']['abi'],
        'changeGovernor',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeGovernor' }
    : UseContractWriteConfig<typeof disputeKitClassicImplementationABI, 'changeGovernor', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeGovernor'
      } = {} as any
) {
  return useContractWrite<typeof disputeKitClassicImplementationABI, 'changeGovernor', TMode>({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"createDispute"`.
 *
 *
 */
export function useDisputeKitClassicImplementationCreateDispute<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeKitClassicImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeKitClassicImplementationABI, 'createDispute'>['request']['abi'],
        'createDispute',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'createDispute' }
    : UseContractWriteConfig<typeof disputeKitClassicImplementationABI, 'createDispute', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'createDispute'
      } = {} as any
) {
  return useContractWrite<typeof disputeKitClassicImplementationABI, 'createDispute', TMode>({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'createDispute',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"draw"`.
 *
 *
 */
export function useDisputeKitClassicImplementationDraw<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeKitClassicImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeKitClassicImplementationABI, 'draw'>['request']['abi'],
        'draw',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'draw' }
    : UseContractWriteConfig<typeof disputeKitClassicImplementationABI, 'draw', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'draw'
      } = {} as any
) {
  return useContractWrite<typeof disputeKitClassicImplementationABI, 'draw', TMode>({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'draw',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"executeGovernorProposal"`.
 *
 *
 */
export function useDisputeKitClassicImplementationExecuteGovernorProposal<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeKitClassicImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof disputeKitClassicImplementationABI,
          'executeGovernorProposal'
        >['request']['abi'],
        'executeGovernorProposal',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'executeGovernorProposal' }
    : UseContractWriteConfig<typeof disputeKitClassicImplementationABI, 'executeGovernorProposal', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'executeGovernorProposal'
      } = {} as any
) {
  return useContractWrite<typeof disputeKitClassicImplementationABI, 'executeGovernorProposal', TMode>({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'executeGovernorProposal',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"fundAppeal"`.
 *
 *
 */
export function useDisputeKitClassicImplementationFundAppeal<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeKitClassicImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeKitClassicImplementationABI, 'fundAppeal'>['request']['abi'],
        'fundAppeal',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'fundAppeal' }
    : UseContractWriteConfig<typeof disputeKitClassicImplementationABI, 'fundAppeal', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'fundAppeal'
      } = {} as any
) {
  return useContractWrite<typeof disputeKitClassicImplementationABI, 'fundAppeal', TMode>({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'fundAppeal',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function useDisputeKitClassicImplementationInitialize<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeKitClassicImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeKitClassicImplementationABI, 'initialize'>['request']['abi'],
        'initialize',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'initialize' }
    : UseContractWriteConfig<typeof disputeKitClassicImplementationABI, 'initialize', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'initialize'
      } = {} as any
) {
  return useContractWrite<typeof disputeKitClassicImplementationABI, 'initialize', TMode>({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'initialize',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function useDisputeKitClassicImplementationUpgradeToAndCall<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeKitClassicImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeKitClassicImplementationABI, 'upgradeToAndCall'>['request']['abi'],
        'upgradeToAndCall',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'upgradeToAndCall' }
    : UseContractWriteConfig<typeof disputeKitClassicImplementationABI, 'upgradeToAndCall', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'upgradeToAndCall'
      } = {} as any
) {
  return useContractWrite<typeof disputeKitClassicImplementationABI, 'upgradeToAndCall', TMode>({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"withdrawFeesAndRewards"`.
 *
 *
 */
export function useDisputeKitClassicImplementationWithdrawFeesAndRewards<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeKitClassicImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof disputeKitClassicImplementationABI,
          'withdrawFeesAndRewards'
        >['request']['abi'],
        'withdrawFeesAndRewards',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'withdrawFeesAndRewards' }
    : UseContractWriteConfig<typeof disputeKitClassicImplementationABI, 'withdrawFeesAndRewards', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'withdrawFeesAndRewards'
      } = {} as any
) {
  return useContractWrite<typeof disputeKitClassicImplementationABI, 'withdrawFeesAndRewards', TMode>({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'withdrawFeesAndRewards',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__.
 *
 *
 */
export function usePrepareDisputeKitClassicImplementationWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeKitClassicImplementationABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeKitClassicImplementationABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"castCommit"`.
 *
 *
 */
export function usePrepareDisputeKitClassicImplementationCastCommit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeKitClassicImplementationABI, 'castCommit'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'castCommit',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeKitClassicImplementationABI, 'castCommit'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"castVote"`.
 *
 *
 */
export function usePrepareDisputeKitClassicImplementationCastVote(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeKitClassicImplementationABI, 'castVote'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'castVote',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeKitClassicImplementationABI, 'castVote'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"changeCore"`.
 *
 *
 */
export function usePrepareDisputeKitClassicImplementationChangeCore(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeKitClassicImplementationABI, 'changeCore'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'changeCore',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeKitClassicImplementationABI, 'changeCore'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function usePrepareDisputeKitClassicImplementationChangeGovernor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeKitClassicImplementationABI, 'changeGovernor'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeKitClassicImplementationABI, 'changeGovernor'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"createDispute"`.
 *
 *
 */
export function usePrepareDisputeKitClassicImplementationCreateDispute(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeKitClassicImplementationABI, 'createDispute'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'createDispute',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeKitClassicImplementationABI, 'createDispute'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"draw"`.
 *
 *
 */
export function usePrepareDisputeKitClassicImplementationDraw(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeKitClassicImplementationABI, 'draw'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'draw',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeKitClassicImplementationABI, 'draw'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"executeGovernorProposal"`.
 *
 *
 */
export function usePrepareDisputeKitClassicImplementationExecuteGovernorProposal(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeKitClassicImplementationABI, 'executeGovernorProposal'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'executeGovernorProposal',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeKitClassicImplementationABI, 'executeGovernorProposal'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"fundAppeal"`.
 *
 *
 */
export function usePrepareDisputeKitClassicImplementationFundAppeal(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeKitClassicImplementationABI, 'fundAppeal'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'fundAppeal',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeKitClassicImplementationABI, 'fundAppeal'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function usePrepareDisputeKitClassicImplementationInitialize(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeKitClassicImplementationABI, 'initialize'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'initialize',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeKitClassicImplementationABI, 'initialize'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function usePrepareDisputeKitClassicImplementationUpgradeToAndCall(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeKitClassicImplementationABI, 'upgradeToAndCall'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeKitClassicImplementationABI, 'upgradeToAndCall'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `functionName` set to `"withdrawFeesAndRewards"`.
 *
 *
 */
export function usePrepareDisputeKitClassicImplementationWithdrawFeesAndRewards(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeKitClassicImplementationABI, 'withdrawFeesAndRewards'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    functionName: 'withdrawFeesAndRewards',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeKitClassicImplementationABI, 'withdrawFeesAndRewards'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__.
 *
 *
 */
export function useDisputeKitClassicImplementationEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof disputeKitClassicImplementationABI, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof disputeKitClassicImplementationAddress
  } = {} as any
) {
  return useContractEvent({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    ...config,
  } as UseContractEventConfig<typeof disputeKitClassicImplementationABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `eventName` set to `"ChoiceFunded"`.
 *
 *
 */
export function useDisputeKitClassicImplementationChoiceFundedEvent(
  config: Omit<
    UseContractEventConfig<typeof disputeKitClassicImplementationABI, 'ChoiceFunded'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    eventName: 'ChoiceFunded',
    ...config,
  } as UseContractEventConfig<typeof disputeKitClassicImplementationABI, 'ChoiceFunded'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `eventName` set to `"CommitCast"`.
 *
 *
 */
export function useDisputeKitClassicImplementationCommitCastEvent(
  config: Omit<
    UseContractEventConfig<typeof disputeKitClassicImplementationABI, 'CommitCast'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    eventName: 'CommitCast',
    ...config,
  } as UseContractEventConfig<typeof disputeKitClassicImplementationABI, 'CommitCast'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `eventName` set to `"Contribution"`.
 *
 *
 */
export function useDisputeKitClassicImplementationContributionEvent(
  config: Omit<
    UseContractEventConfig<typeof disputeKitClassicImplementationABI, 'Contribution'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    eventName: 'Contribution',
    ...config,
  } as UseContractEventConfig<typeof disputeKitClassicImplementationABI, 'Contribution'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `eventName` set to `"DisputeCreation"`.
 *
 *
 */
export function useDisputeKitClassicImplementationDisputeCreationEvent(
  config: Omit<
    UseContractEventConfig<typeof disputeKitClassicImplementationABI, 'DisputeCreation'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    eventName: 'DisputeCreation',
    ...config,
  } as UseContractEventConfig<typeof disputeKitClassicImplementationABI, 'DisputeCreation'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `eventName` set to `"Initialized"`.
 *
 *
 */
export function useDisputeKitClassicImplementationInitializedEvent(
  config: Omit<
    UseContractEventConfig<typeof disputeKitClassicImplementationABI, 'Initialized'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    eventName: 'Initialized',
    ...config,
  } as UseContractEventConfig<typeof disputeKitClassicImplementationABI, 'Initialized'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `eventName` set to `"Upgraded"`.
 *
 *
 */
export function useDisputeKitClassicImplementationUpgradedEvent(
  config: Omit<
    UseContractEventConfig<typeof disputeKitClassicImplementationABI, 'Upgraded'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    eventName: 'Upgraded',
    ...config,
  } as UseContractEventConfig<typeof disputeKitClassicImplementationABI, 'Upgraded'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `eventName` set to `"VoteCast"`.
 *
 *
 */
export function useDisputeKitClassicImplementationVoteCastEvent(
  config: Omit<
    UseContractEventConfig<typeof disputeKitClassicImplementationABI, 'VoteCast'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    eventName: 'VoteCast',
    ...config,
  } as UseContractEventConfig<typeof disputeKitClassicImplementationABI, 'VoteCast'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__ and `eventName` set to `"Withdrawal"`.
 *
 *
 */
export function useDisputeKitClassicImplementationWithdrawalEvent(
  config: Omit<
    UseContractEventConfig<typeof disputeKitClassicImplementationABI, 'Withdrawal'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof disputeKitClassicImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    eventName: 'Withdrawal',
    ...config,
  } as UseContractEventConfig<typeof disputeKitClassicImplementationABI, 'Withdrawal'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeResolverABI}__.
 *
 *
 */
export function useDisputeResolverRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof disputeResolverABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof disputeResolverABI, TFunctionName, TSelectData>, 'abi' | 'address'> & {
    chainId?: keyof typeof disputeResolverAddress
  } = {} as any
) {
  return useContractRead({
    abi: disputeResolverABI,
    address: disputeResolverAddress[421614],
    ...config,
  } as UseContractReadConfig<typeof disputeResolverABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeResolverABI}__ and `functionName` set to `"arbitrator"`.
 *
 *
 */
export function useDisputeResolverArbitrator<
  TFunctionName extends 'arbitrator',
  TSelectData = ReadContractResult<typeof disputeResolverABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeResolverABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeResolverAddress } = {} as any
) {
  return useContractRead({
    abi: disputeResolverABI,
    address: disputeResolverAddress[421614],
    functionName: 'arbitrator',
    ...config,
  } as UseContractReadConfig<typeof disputeResolverABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeResolverABI}__ and `functionName` set to `"arbitratorDisputeIDToLocalID"`.
 *
 *
 */
export function useDisputeResolverArbitratorDisputeIdToLocalId<
  TFunctionName extends 'arbitratorDisputeIDToLocalID',
  TSelectData = ReadContractResult<typeof disputeResolverABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeResolverABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeResolverAddress } = {} as any
) {
  return useContractRead({
    abi: disputeResolverABI,
    address: disputeResolverAddress[421614],
    functionName: 'arbitratorDisputeIDToLocalID',
    ...config,
  } as UseContractReadConfig<typeof disputeResolverABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeResolverABI}__ and `functionName` set to `"disputes"`.
 *
 *
 */
export function useDisputeResolverDisputes<
  TFunctionName extends 'disputes',
  TSelectData = ReadContractResult<typeof disputeResolverABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeResolverABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeResolverAddress } = {} as any
) {
  return useContractRead({
    abi: disputeResolverABI,
    address: disputeResolverAddress[421614],
    functionName: 'disputes',
    ...config,
  } as UseContractReadConfig<typeof disputeResolverABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeResolverABI}__ and `functionName` set to `"governor"`.
 *
 *
 */
export function useDisputeResolverGovernor<
  TFunctionName extends 'governor',
  TSelectData = ReadContractResult<typeof disputeResolverABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeResolverABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeResolverAddress } = {} as any
) {
  return useContractRead({
    abi: disputeResolverABI,
    address: disputeResolverAddress[421614],
    functionName: 'governor',
    ...config,
  } as UseContractReadConfig<typeof disputeResolverABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeResolverABI}__ and `functionName` set to `"templateRegistry"`.
 *
 *
 */
export function useDisputeResolverTemplateRegistry<
  TFunctionName extends 'templateRegistry',
  TSelectData = ReadContractResult<typeof disputeResolverABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeResolverABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeResolverAddress } = {} as any
) {
  return useContractRead({
    abi: disputeResolverABI,
    address: disputeResolverAddress[421614],
    functionName: 'templateRegistry',
    ...config,
  } as UseContractReadConfig<typeof disputeResolverABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeResolverABI}__.
 *
 *
 */
export function useDisputeResolverWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeResolverAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeResolverABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof disputeResolverABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof disputeResolverABI, TFunctionName, TMode>({
    abi: disputeResolverABI,
    address: disputeResolverAddress[421614],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeResolverABI}__ and `functionName` set to `"changeArbitrator"`.
 *
 *
 */
export function useDisputeResolverChangeArbitrator<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeResolverAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeResolverABI, 'changeArbitrator'>['request']['abi'],
        'changeArbitrator',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeArbitrator' }
    : UseContractWriteConfig<typeof disputeResolverABI, 'changeArbitrator', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeArbitrator'
      } = {} as any
) {
  return useContractWrite<typeof disputeResolverABI, 'changeArbitrator', TMode>({
    abi: disputeResolverABI,
    address: disputeResolverAddress[421614],
    functionName: 'changeArbitrator',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeResolverABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function useDisputeResolverChangeGovernor<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeResolverAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeResolverABI, 'changeGovernor'>['request']['abi'],
        'changeGovernor',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeGovernor' }
    : UseContractWriteConfig<typeof disputeResolverABI, 'changeGovernor', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeGovernor'
      } = {} as any
) {
  return useContractWrite<typeof disputeResolverABI, 'changeGovernor', TMode>({
    abi: disputeResolverABI,
    address: disputeResolverAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeResolverABI}__ and `functionName` set to `"changeTemplateRegistry"`.
 *
 *
 */
export function useDisputeResolverChangeTemplateRegistry<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeResolverAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeResolverABI, 'changeTemplateRegistry'>['request']['abi'],
        'changeTemplateRegistry',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeTemplateRegistry' }
    : UseContractWriteConfig<typeof disputeResolverABI, 'changeTemplateRegistry', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeTemplateRegistry'
      } = {} as any
) {
  return useContractWrite<typeof disputeResolverABI, 'changeTemplateRegistry', TMode>({
    abi: disputeResolverABI,
    address: disputeResolverAddress[421614],
    functionName: 'changeTemplateRegistry',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeResolverABI}__ and `functionName` set to `"createDisputeForTemplate"`.
 *
 *
 */
export function useDisputeResolverCreateDisputeForTemplate<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeResolverAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeResolverABI, 'createDisputeForTemplate'>['request']['abi'],
        'createDisputeForTemplate',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'createDisputeForTemplate' }
    : UseContractWriteConfig<typeof disputeResolverABI, 'createDisputeForTemplate', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'createDisputeForTemplate'
      } = {} as any
) {
  return useContractWrite<typeof disputeResolverABI, 'createDisputeForTemplate', TMode>({
    abi: disputeResolverABI,
    address: disputeResolverAddress[421614],
    functionName: 'createDisputeForTemplate',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeResolverABI}__ and `functionName` set to `"createDisputeForTemplateUri"`.
 *
 *
 */
export function useDisputeResolverCreateDisputeForTemplateUri<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeResolverAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeResolverABI, 'createDisputeForTemplateUri'>['request']['abi'],
        'createDisputeForTemplateUri',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'createDisputeForTemplateUri' }
    : UseContractWriteConfig<typeof disputeResolverABI, 'createDisputeForTemplateUri', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'createDisputeForTemplateUri'
      } = {} as any
) {
  return useContractWrite<typeof disputeResolverABI, 'createDisputeForTemplateUri', TMode>({
    abi: disputeResolverABI,
    address: disputeResolverAddress[421614],
    functionName: 'createDisputeForTemplateUri',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeResolverABI}__ and `functionName` set to `"rule"`.
 *
 *
 */
export function useDisputeResolverRule<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeResolverAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeResolverABI, 'rule'>['request']['abi'],
        'rule',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'rule' }
    : UseContractWriteConfig<typeof disputeResolverABI, 'rule', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'rule'
      } = {} as any
) {
  return useContractWrite<typeof disputeResolverABI, 'rule', TMode>({
    abi: disputeResolverABI,
    address: disputeResolverAddress[421614],
    functionName: 'rule',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeResolverABI}__.
 *
 *
 */
export function usePrepareDisputeResolverWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof disputeResolverABI, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof disputeResolverAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeResolverABI,
    address: disputeResolverAddress[421614],
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeResolverABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeResolverABI}__ and `functionName` set to `"changeArbitrator"`.
 *
 *
 */
export function usePrepareDisputeResolverChangeArbitrator(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeResolverABI, 'changeArbitrator'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeResolverAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeResolverABI,
    address: disputeResolverAddress[421614],
    functionName: 'changeArbitrator',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeResolverABI, 'changeArbitrator'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeResolverABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function usePrepareDisputeResolverChangeGovernor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeResolverABI, 'changeGovernor'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeResolverAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeResolverABI,
    address: disputeResolverAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeResolverABI, 'changeGovernor'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeResolverABI}__ and `functionName` set to `"changeTemplateRegistry"`.
 *
 *
 */
export function usePrepareDisputeResolverChangeTemplateRegistry(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeResolverABI, 'changeTemplateRegistry'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeResolverAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeResolverABI,
    address: disputeResolverAddress[421614],
    functionName: 'changeTemplateRegistry',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeResolverABI, 'changeTemplateRegistry'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeResolverABI}__ and `functionName` set to `"createDisputeForTemplate"`.
 *
 *
 */
export function usePrepareDisputeResolverCreateDisputeForTemplate(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeResolverABI, 'createDisputeForTemplate'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeResolverAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeResolverABI,
    address: disputeResolverAddress[421614],
    functionName: 'createDisputeForTemplate',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeResolverABI, 'createDisputeForTemplate'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeResolverABI}__ and `functionName` set to `"createDisputeForTemplateUri"`.
 *
 *
 */
export function usePrepareDisputeResolverCreateDisputeForTemplateUri(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeResolverABI, 'createDisputeForTemplateUri'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeResolverAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeResolverABI,
    address: disputeResolverAddress[421614],
    functionName: 'createDisputeForTemplateUri',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeResolverABI, 'createDisputeForTemplateUri'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeResolverABI}__ and `functionName` set to `"rule"`.
 *
 *
 */
export function usePrepareDisputeResolverRule(
  config: Omit<UsePrepareContractWriteConfig<typeof disputeResolverABI, 'rule'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof disputeResolverAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeResolverABI,
    address: disputeResolverAddress[421614],
    functionName: 'rule',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeResolverABI, 'rule'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeResolverABI}__.
 *
 *
 */
export function useDisputeResolverEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof disputeResolverABI, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof disputeResolverAddress
  } = {} as any
) {
  return useContractEvent({
    abi: disputeResolverABI,
    address: disputeResolverAddress[421614],
    ...config,
  } as UseContractEventConfig<typeof disputeResolverABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeResolverABI}__ and `eventName` set to `"DisputeRequest"`.
 *
 *
 */
export function useDisputeResolverDisputeRequestEvent(
  config: Omit<UseContractEventConfig<typeof disputeResolverABI, 'DisputeRequest'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof disputeResolverAddress
  } = {} as any
) {
  return useContractEvent({
    abi: disputeResolverABI,
    address: disputeResolverAddress[421614],
    eventName: 'DisputeRequest',
    ...config,
  } as UseContractEventConfig<typeof disputeResolverABI, 'DisputeRequest'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeResolverABI}__ and `eventName` set to `"Ruling"`.
 *
 *
 */
export function useDisputeResolverRulingEvent(
  config: Omit<UseContractEventConfig<typeof disputeResolverABI, 'Ruling'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof disputeResolverAddress
  } = {} as any
) {
  return useContractEvent({
    abi: disputeResolverABI,
    address: disputeResolverAddress[421614],
    eventName: 'Ruling',
    ...config,
  } as UseContractEventConfig<typeof disputeResolverABI, 'Ruling'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeTemplateRegistryABI}__.
 *
 *
 */
export function useDisputeTemplateRegistryRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof disputeTemplateRegistryABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeTemplateRegistryABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof disputeTemplateRegistryAddress } = {} as any
) {
  return useContractRead({
    abi: disputeTemplateRegistryABI,
    address: disputeTemplateRegistryAddress[421614],
    ...config,
  } as UseContractReadConfig<typeof disputeTemplateRegistryABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeTemplateRegistryABI}__ and `functionName` set to `"governor"`.
 *
 *
 */
export function useDisputeTemplateRegistryGovernor<
  TFunctionName extends 'governor',
  TSelectData = ReadContractResult<typeof disputeTemplateRegistryABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeTemplateRegistryABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeTemplateRegistryAddress } = {} as any
) {
  return useContractRead({
    abi: disputeTemplateRegistryABI,
    address: disputeTemplateRegistryAddress[421614],
    functionName: 'governor',
    ...config,
  } as UseContractReadConfig<typeof disputeTemplateRegistryABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeTemplateRegistryABI}__ and `functionName` set to `"proxiableUUID"`.
 *
 *
 */
export function useDisputeTemplateRegistryProxiableUuid<
  TFunctionName extends 'proxiableUUID',
  TSelectData = ReadContractResult<typeof disputeTemplateRegistryABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeTemplateRegistryABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeTemplateRegistryAddress } = {} as any
) {
  return useContractRead({
    abi: disputeTemplateRegistryABI,
    address: disputeTemplateRegistryAddress[421614],
    functionName: 'proxiableUUID',
    ...config,
  } as UseContractReadConfig<typeof disputeTemplateRegistryABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeTemplateRegistryABI}__ and `functionName` set to `"templates"`.
 *
 *
 */
export function useDisputeTemplateRegistryTemplates<
  TFunctionName extends 'templates',
  TSelectData = ReadContractResult<typeof disputeTemplateRegistryABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeTemplateRegistryABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeTemplateRegistryAddress } = {} as any
) {
  return useContractRead({
    abi: disputeTemplateRegistryABI,
    address: disputeTemplateRegistryAddress[421614],
    functionName: 'templates',
    ...config,
  } as UseContractReadConfig<typeof disputeTemplateRegistryABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeTemplateRegistryABI}__.
 *
 *
 */
export function useDisputeTemplateRegistryWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeTemplateRegistryAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeTemplateRegistryABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof disputeTemplateRegistryABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof disputeTemplateRegistryABI, TFunctionName, TMode>({
    abi: disputeTemplateRegistryABI,
    address: disputeTemplateRegistryAddress[421614],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeTemplateRegistryABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function useDisputeTemplateRegistryChangeGovernor<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeTemplateRegistryAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeTemplateRegistryABI, 'changeGovernor'>['request']['abi'],
        'changeGovernor',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeGovernor' }
    : UseContractWriteConfig<typeof disputeTemplateRegistryABI, 'changeGovernor', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeGovernor'
      } = {} as any
) {
  return useContractWrite<typeof disputeTemplateRegistryABI, 'changeGovernor', TMode>({
    abi: disputeTemplateRegistryABI,
    address: disputeTemplateRegistryAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeTemplateRegistryABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function useDisputeTemplateRegistryInitialize<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeTemplateRegistryAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeTemplateRegistryABI, 'initialize'>['request']['abi'],
        'initialize',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'initialize' }
    : UseContractWriteConfig<typeof disputeTemplateRegistryABI, 'initialize', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'initialize'
      } = {} as any
) {
  return useContractWrite<typeof disputeTemplateRegistryABI, 'initialize', TMode>({
    abi: disputeTemplateRegistryABI,
    address: disputeTemplateRegistryAddress[421614],
    functionName: 'initialize',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeTemplateRegistryABI}__ and `functionName` set to `"setDisputeTemplate"`.
 *
 *
 */
export function useDisputeTemplateRegistrySetDisputeTemplate<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeTemplateRegistryAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeTemplateRegistryABI, 'setDisputeTemplate'>['request']['abi'],
        'setDisputeTemplate',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setDisputeTemplate' }
    : UseContractWriteConfig<typeof disputeTemplateRegistryABI, 'setDisputeTemplate', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setDisputeTemplate'
      } = {} as any
) {
  return useContractWrite<typeof disputeTemplateRegistryABI, 'setDisputeTemplate', TMode>({
    abi: disputeTemplateRegistryABI,
    address: disputeTemplateRegistryAddress[421614],
    functionName: 'setDisputeTemplate',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeTemplateRegistryABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function useDisputeTemplateRegistryUpgradeToAndCall<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeTemplateRegistryAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeTemplateRegistryABI, 'upgradeToAndCall'>['request']['abi'],
        'upgradeToAndCall',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'upgradeToAndCall' }
    : UseContractWriteConfig<typeof disputeTemplateRegistryABI, 'upgradeToAndCall', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'upgradeToAndCall'
      } = {} as any
) {
  return useContractWrite<typeof disputeTemplateRegistryABI, 'upgradeToAndCall', TMode>({
    abi: disputeTemplateRegistryABI,
    address: disputeTemplateRegistryAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeTemplateRegistryABI}__.
 *
 *
 */
export function usePrepareDisputeTemplateRegistryWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof disputeTemplateRegistryABI, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof disputeTemplateRegistryAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeTemplateRegistryABI,
    address: disputeTemplateRegistryAddress[421614],
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeTemplateRegistryABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeTemplateRegistryABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function usePrepareDisputeTemplateRegistryChangeGovernor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeTemplateRegistryABI, 'changeGovernor'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeTemplateRegistryAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeTemplateRegistryABI,
    address: disputeTemplateRegistryAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeTemplateRegistryABI, 'changeGovernor'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeTemplateRegistryABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function usePrepareDisputeTemplateRegistryInitialize(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeTemplateRegistryABI, 'initialize'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeTemplateRegistryAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeTemplateRegistryABI,
    address: disputeTemplateRegistryAddress[421614],
    functionName: 'initialize',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeTemplateRegistryABI, 'initialize'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeTemplateRegistryABI}__ and `functionName` set to `"setDisputeTemplate"`.
 *
 *
 */
export function usePrepareDisputeTemplateRegistrySetDisputeTemplate(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeTemplateRegistryABI, 'setDisputeTemplate'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeTemplateRegistryAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeTemplateRegistryABI,
    address: disputeTemplateRegistryAddress[421614],
    functionName: 'setDisputeTemplate',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeTemplateRegistryABI, 'setDisputeTemplate'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeTemplateRegistryABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function usePrepareDisputeTemplateRegistryUpgradeToAndCall(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeTemplateRegistryABI, 'upgradeToAndCall'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeTemplateRegistryAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeTemplateRegistryABI,
    address: disputeTemplateRegistryAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeTemplateRegistryABI, 'upgradeToAndCall'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeTemplateRegistryABI}__.
 *
 *
 */
export function useDisputeTemplateRegistryEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof disputeTemplateRegistryABI, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof disputeTemplateRegistryAddress
  } = {} as any
) {
  return useContractEvent({
    abi: disputeTemplateRegistryABI,
    address: disputeTemplateRegistryAddress[421614],
    ...config,
  } as UseContractEventConfig<typeof disputeTemplateRegistryABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeTemplateRegistryABI}__ and `eventName` set to `"DisputeTemplate"`.
 *
 *
 */
export function useDisputeTemplateRegistryDisputeTemplateEvent(
  config: Omit<
    UseContractEventConfig<typeof disputeTemplateRegistryABI, 'DisputeTemplate'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof disputeTemplateRegistryAddress } = {} as any
) {
  return useContractEvent({
    abi: disputeTemplateRegistryABI,
    address: disputeTemplateRegistryAddress[421614],
    eventName: 'DisputeTemplate',
    ...config,
  } as UseContractEventConfig<typeof disputeTemplateRegistryABI, 'DisputeTemplate'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeTemplateRegistryABI}__ and `eventName` set to `"Initialized"`.
 *
 *
 */
export function useDisputeTemplateRegistryInitializedEvent(
  config: Omit<
    UseContractEventConfig<typeof disputeTemplateRegistryABI, 'Initialized'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof disputeTemplateRegistryAddress } = {} as any
) {
  return useContractEvent({
    abi: disputeTemplateRegistryABI,
    address: disputeTemplateRegistryAddress[421614],
    eventName: 'Initialized',
    ...config,
  } as UseContractEventConfig<typeof disputeTemplateRegistryABI, 'Initialized'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeTemplateRegistryABI}__ and `eventName` set to `"Upgraded"`.
 *
 *
 */
export function useDisputeTemplateRegistryUpgradedEvent(
  config: Omit<
    UseContractEventConfig<typeof disputeTemplateRegistryABI, 'Upgraded'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof disputeTemplateRegistryAddress } = {} as any
) {
  return useContractEvent({
    abi: disputeTemplateRegistryABI,
    address: disputeTemplateRegistryAddress[421614],
    eventName: 'Upgraded',
    ...config,
  } as UseContractEventConfig<typeof disputeTemplateRegistryABI, 'Upgraded'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeTemplateRegistryImplementationABI}__.
 *
 *
 */
export function useDisputeTemplateRegistryImplementationRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof disputeTemplateRegistryImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeTemplateRegistryImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof disputeTemplateRegistryImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: disputeTemplateRegistryImplementationABI,
    address: disputeTemplateRegistryImplementationAddress[421614],
    ...config,
  } as UseContractReadConfig<typeof disputeTemplateRegistryImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeTemplateRegistryImplementationABI}__ and `functionName` set to `"governor"`.
 *
 *
 */
export function useDisputeTemplateRegistryImplementationGovernor<
  TFunctionName extends 'governor',
  TSelectData = ReadContractResult<typeof disputeTemplateRegistryImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeTemplateRegistryImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeTemplateRegistryImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: disputeTemplateRegistryImplementationABI,
    address: disputeTemplateRegistryImplementationAddress[421614],
    functionName: 'governor',
    ...config,
  } as UseContractReadConfig<typeof disputeTemplateRegistryImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeTemplateRegistryImplementationABI}__ and `functionName` set to `"proxiableUUID"`.
 *
 *
 */
export function useDisputeTemplateRegistryImplementationProxiableUuid<
  TFunctionName extends 'proxiableUUID',
  TSelectData = ReadContractResult<typeof disputeTemplateRegistryImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeTemplateRegistryImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeTemplateRegistryImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: disputeTemplateRegistryImplementationABI,
    address: disputeTemplateRegistryImplementationAddress[421614],
    functionName: 'proxiableUUID',
    ...config,
  } as UseContractReadConfig<typeof disputeTemplateRegistryImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link disputeTemplateRegistryImplementationABI}__ and `functionName` set to `"templates"`.
 *
 *
 */
export function useDisputeTemplateRegistryImplementationTemplates<
  TFunctionName extends 'templates',
  TSelectData = ReadContractResult<typeof disputeTemplateRegistryImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof disputeTemplateRegistryImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeTemplateRegistryImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: disputeTemplateRegistryImplementationABI,
    address: disputeTemplateRegistryImplementationAddress[421614],
    functionName: 'templates',
    ...config,
  } as UseContractReadConfig<typeof disputeTemplateRegistryImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeTemplateRegistryImplementationABI}__.
 *
 *
 */
export function useDisputeTemplateRegistryImplementationWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeTemplateRegistryImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeTemplateRegistryImplementationABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof disputeTemplateRegistryImplementationABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof disputeTemplateRegistryImplementationABI, TFunctionName, TMode>({
    abi: disputeTemplateRegistryImplementationABI,
    address: disputeTemplateRegistryImplementationAddress[421614],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeTemplateRegistryImplementationABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function useDisputeTemplateRegistryImplementationChangeGovernor<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeTemplateRegistryImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeTemplateRegistryImplementationABI, 'changeGovernor'>['request']['abi'],
        'changeGovernor',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeGovernor' }
    : UseContractWriteConfig<typeof disputeTemplateRegistryImplementationABI, 'changeGovernor', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeGovernor'
      } = {} as any
) {
  return useContractWrite<typeof disputeTemplateRegistryImplementationABI, 'changeGovernor', TMode>({
    abi: disputeTemplateRegistryImplementationABI,
    address: disputeTemplateRegistryImplementationAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeTemplateRegistryImplementationABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function useDisputeTemplateRegistryImplementationInitialize<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeTemplateRegistryImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof disputeTemplateRegistryImplementationABI, 'initialize'>['request']['abi'],
        'initialize',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'initialize' }
    : UseContractWriteConfig<typeof disputeTemplateRegistryImplementationABI, 'initialize', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'initialize'
      } = {} as any
) {
  return useContractWrite<typeof disputeTemplateRegistryImplementationABI, 'initialize', TMode>({
    abi: disputeTemplateRegistryImplementationABI,
    address: disputeTemplateRegistryImplementationAddress[421614],
    functionName: 'initialize',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeTemplateRegistryImplementationABI}__ and `functionName` set to `"setDisputeTemplate"`.
 *
 *
 */
export function useDisputeTemplateRegistryImplementationSetDisputeTemplate<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeTemplateRegistryImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof disputeTemplateRegistryImplementationABI,
          'setDisputeTemplate'
        >['request']['abi'],
        'setDisputeTemplate',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setDisputeTemplate' }
    : UseContractWriteConfig<typeof disputeTemplateRegistryImplementationABI, 'setDisputeTemplate', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setDisputeTemplate'
      } = {} as any
) {
  return useContractWrite<typeof disputeTemplateRegistryImplementationABI, 'setDisputeTemplate', TMode>({
    abi: disputeTemplateRegistryImplementationABI,
    address: disputeTemplateRegistryImplementationAddress[421614],
    functionName: 'setDisputeTemplate',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link disputeTemplateRegistryImplementationABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function useDisputeTemplateRegistryImplementationUpgradeToAndCall<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof disputeTemplateRegistryImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof disputeTemplateRegistryImplementationABI,
          'upgradeToAndCall'
        >['request']['abi'],
        'upgradeToAndCall',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'upgradeToAndCall' }
    : UseContractWriteConfig<typeof disputeTemplateRegistryImplementationABI, 'upgradeToAndCall', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'upgradeToAndCall'
      } = {} as any
) {
  return useContractWrite<typeof disputeTemplateRegistryImplementationABI, 'upgradeToAndCall', TMode>({
    abi: disputeTemplateRegistryImplementationABI,
    address: disputeTemplateRegistryImplementationAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeTemplateRegistryImplementationABI}__.
 *
 *
 */
export function usePrepareDisputeTemplateRegistryImplementationWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeTemplateRegistryImplementationABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof disputeTemplateRegistryImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeTemplateRegistryImplementationABI,
    address: disputeTemplateRegistryImplementationAddress[421614],
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeTemplateRegistryImplementationABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeTemplateRegistryImplementationABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function usePrepareDisputeTemplateRegistryImplementationChangeGovernor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeTemplateRegistryImplementationABI, 'changeGovernor'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeTemplateRegistryImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeTemplateRegistryImplementationABI,
    address: disputeTemplateRegistryImplementationAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeTemplateRegistryImplementationABI, 'changeGovernor'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeTemplateRegistryImplementationABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function usePrepareDisputeTemplateRegistryImplementationInitialize(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeTemplateRegistryImplementationABI, 'initialize'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeTemplateRegistryImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeTemplateRegistryImplementationABI,
    address: disputeTemplateRegistryImplementationAddress[421614],
    functionName: 'initialize',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeTemplateRegistryImplementationABI, 'initialize'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeTemplateRegistryImplementationABI}__ and `functionName` set to `"setDisputeTemplate"`.
 *
 *
 */
export function usePrepareDisputeTemplateRegistryImplementationSetDisputeTemplate(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeTemplateRegistryImplementationABI, 'setDisputeTemplate'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeTemplateRegistryImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeTemplateRegistryImplementationABI,
    address: disputeTemplateRegistryImplementationAddress[421614],
    functionName: 'setDisputeTemplate',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeTemplateRegistryImplementationABI, 'setDisputeTemplate'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link disputeTemplateRegistryImplementationABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function usePrepareDisputeTemplateRegistryImplementationUpgradeToAndCall(
  config: Omit<
    UsePrepareContractWriteConfig<typeof disputeTemplateRegistryImplementationABI, 'upgradeToAndCall'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof disputeTemplateRegistryImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: disputeTemplateRegistryImplementationABI,
    address: disputeTemplateRegistryImplementationAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as UsePrepareContractWriteConfig<typeof disputeTemplateRegistryImplementationABI, 'upgradeToAndCall'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeTemplateRegistryImplementationABI}__.
 *
 *
 */
export function useDisputeTemplateRegistryImplementationEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof disputeTemplateRegistryImplementationABI, TEventName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof disputeTemplateRegistryImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: disputeTemplateRegistryImplementationABI,
    address: disputeTemplateRegistryImplementationAddress[421614],
    ...config,
  } as UseContractEventConfig<typeof disputeTemplateRegistryImplementationABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeTemplateRegistryImplementationABI}__ and `eventName` set to `"DisputeTemplate"`.
 *
 *
 */
export function useDisputeTemplateRegistryImplementationDisputeTemplateEvent(
  config: Omit<
    UseContractEventConfig<typeof disputeTemplateRegistryImplementationABI, 'DisputeTemplate'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof disputeTemplateRegistryImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: disputeTemplateRegistryImplementationABI,
    address: disputeTemplateRegistryImplementationAddress[421614],
    eventName: 'DisputeTemplate',
    ...config,
  } as UseContractEventConfig<typeof disputeTemplateRegistryImplementationABI, 'DisputeTemplate'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeTemplateRegistryImplementationABI}__ and `eventName` set to `"Initialized"`.
 *
 *
 */
export function useDisputeTemplateRegistryImplementationInitializedEvent(
  config: Omit<
    UseContractEventConfig<typeof disputeTemplateRegistryImplementationABI, 'Initialized'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof disputeTemplateRegistryImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: disputeTemplateRegistryImplementationABI,
    address: disputeTemplateRegistryImplementationAddress[421614],
    eventName: 'Initialized',
    ...config,
  } as UseContractEventConfig<typeof disputeTemplateRegistryImplementationABI, 'Initialized'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link disputeTemplateRegistryImplementationABI}__ and `eventName` set to `"Upgraded"`.
 *
 *
 */
export function useDisputeTemplateRegistryImplementationUpgradedEvent(
  config: Omit<
    UseContractEventConfig<typeof disputeTemplateRegistryImplementationABI, 'Upgraded'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof disputeTemplateRegistryImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: disputeTemplateRegistryImplementationABI,
    address: disputeTemplateRegistryImplementationAddress[421614],
    eventName: 'Upgraded',
    ...config,
  } as UseContractEventConfig<typeof disputeTemplateRegistryImplementationABI, 'Upgraded'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link escrowABI}__.
 *
 *
 */
export function useEscrowRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof escrowABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof escrowABI, TFunctionName, TSelectData>, 'abi' | 'address'> & {
    chainId?: keyof typeof escrowAddress
  } = {} as any
) {
  return useContractRead({ abi: escrowABI, address: escrowAddress[421614], ...config } as UseContractReadConfig<
    typeof escrowABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"AMOUNT_OF_CHOICES"`.
 *
 *
 */
export function useEscrowAmountOfChoices<
  TFunctionName extends 'AMOUNT_OF_CHOICES',
  TSelectData = ReadContractResult<typeof escrowABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof escrowABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof escrowAddress } = {} as any
) {
  return useContractRead({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'AMOUNT_OF_CHOICES',
    ...config,
  } as UseContractReadConfig<typeof escrowABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"arbitrator"`.
 *
 *
 */
export function useEscrowArbitrator<
  TFunctionName extends 'arbitrator',
  TSelectData = ReadContractResult<typeof escrowABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof escrowABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof escrowAddress } = {} as any
) {
  return useContractRead({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'arbitrator',
    ...config,
  } as UseContractReadConfig<typeof escrowABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"arbitratorExtraData"`.
 *
 *
 */
export function useEscrowArbitratorExtraData<
  TFunctionName extends 'arbitratorExtraData',
  TSelectData = ReadContractResult<typeof escrowABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof escrowABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof escrowAddress } = {} as any
) {
  return useContractRead({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'arbitratorExtraData',
    ...config,
  } as UseContractReadConfig<typeof escrowABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"disputeIDtoTransactionID"`.
 *
 *
 */
export function useEscrowDisputeIDtoTransactionId<
  TFunctionName extends 'disputeIDtoTransactionID',
  TSelectData = ReadContractResult<typeof escrowABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof escrowABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof escrowAddress } = {} as any
) {
  return useContractRead({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'disputeIDtoTransactionID',
    ...config,
  } as UseContractReadConfig<typeof escrowABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"feeTimeout"`.
 *
 *
 */
export function useEscrowFeeTimeout<
  TFunctionName extends 'feeTimeout',
  TSelectData = ReadContractResult<typeof escrowABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof escrowABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof escrowAddress } = {} as any
) {
  return useContractRead({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'feeTimeout',
    ...config,
  } as UseContractReadConfig<typeof escrowABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"getCountTransactions"`.
 *
 *
 */
export function useEscrowGetCountTransactions<
  TFunctionName extends 'getCountTransactions',
  TSelectData = ReadContractResult<typeof escrowABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof escrowABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof escrowAddress } = {} as any
) {
  return useContractRead({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'getCountTransactions',
    ...config,
  } as UseContractReadConfig<typeof escrowABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"governor"`.
 *
 *
 */
export function useEscrowGovernor<
  TFunctionName extends 'governor',
  TSelectData = ReadContractResult<typeof escrowABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof escrowABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof escrowAddress } = {} as any
) {
  return useContractRead({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'governor',
    ...config,
  } as UseContractReadConfig<typeof escrowABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"templateId"`.
 *
 *
 */
export function useEscrowTemplateId<
  TFunctionName extends 'templateId',
  TSelectData = ReadContractResult<typeof escrowABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof escrowABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof escrowAddress } = {} as any
) {
  return useContractRead({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'templateId',
    ...config,
  } as UseContractReadConfig<typeof escrowABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"templateRegistry"`.
 *
 *
 */
export function useEscrowTemplateRegistry<
  TFunctionName extends 'templateRegistry',
  TSelectData = ReadContractResult<typeof escrowABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof escrowABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof escrowAddress } = {} as any
) {
  return useContractRead({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'templateRegistry',
    ...config,
  } as UseContractReadConfig<typeof escrowABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"transactions"`.
 *
 *
 */
export function useEscrowTransactions<
  TFunctionName extends 'transactions',
  TSelectData = ReadContractResult<typeof escrowABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof escrowABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof escrowAddress } = {} as any
) {
  return useContractRead({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'transactions',
    ...config,
  } as UseContractReadConfig<typeof escrowABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link escrowABI}__.
 *
 *
 */
export function useEscrowWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof escrowAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof escrowABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof escrowABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof escrowABI, TFunctionName, TMode>({
    abi: escrowABI,
    address: escrowAddress[421614],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"changeArbitrator"`.
 *
 *
 */
export function useEscrowChangeArbitrator<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof escrowAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof escrowABI, 'changeArbitrator'>['request']['abi'],
        'changeArbitrator',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeArbitrator' }
    : UseContractWriteConfig<typeof escrowABI, 'changeArbitrator', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeArbitrator'
      } = {} as any
) {
  return useContractWrite<typeof escrowABI, 'changeArbitrator', TMode>({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'changeArbitrator',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"changeArbitratorExtraData"`.
 *
 *
 */
export function useEscrowChangeArbitratorExtraData<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof escrowAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof escrowABI, 'changeArbitratorExtraData'>['request']['abi'],
        'changeArbitratorExtraData',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeArbitratorExtraData' }
    : UseContractWriteConfig<typeof escrowABI, 'changeArbitratorExtraData', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeArbitratorExtraData'
      } = {} as any
) {
  return useContractWrite<typeof escrowABI, 'changeArbitratorExtraData', TMode>({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'changeArbitratorExtraData',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"changeDisputeTemplate"`.
 *
 *
 */
export function useEscrowChangeDisputeTemplate<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof escrowAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof escrowABI, 'changeDisputeTemplate'>['request']['abi'],
        'changeDisputeTemplate',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeDisputeTemplate' }
    : UseContractWriteConfig<typeof escrowABI, 'changeDisputeTemplate', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeDisputeTemplate'
      } = {} as any
) {
  return useContractWrite<typeof escrowABI, 'changeDisputeTemplate', TMode>({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'changeDisputeTemplate',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"changeTemplateRegistry"`.
 *
 *
 */
export function useEscrowChangeTemplateRegistry<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof escrowAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof escrowABI, 'changeTemplateRegistry'>['request']['abi'],
        'changeTemplateRegistry',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeTemplateRegistry' }
    : UseContractWriteConfig<typeof escrowABI, 'changeTemplateRegistry', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeTemplateRegistry'
      } = {} as any
) {
  return useContractWrite<typeof escrowABI, 'changeTemplateRegistry', TMode>({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'changeTemplateRegistry',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"createTransaction"`.
 *
 *
 */
export function useEscrowCreateTransaction<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof escrowAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof escrowABI, 'createTransaction'>['request']['abi'],
        'createTransaction',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'createTransaction' }
    : UseContractWriteConfig<typeof escrowABI, 'createTransaction', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'createTransaction'
      } = {} as any
) {
  return useContractWrite<typeof escrowABI, 'createTransaction', TMode>({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'createTransaction',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"executeTransaction"`.
 *
 *
 */
export function useEscrowExecuteTransaction<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof escrowAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof escrowABI, 'executeTransaction'>['request']['abi'],
        'executeTransaction',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'executeTransaction' }
    : UseContractWriteConfig<typeof escrowABI, 'executeTransaction', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'executeTransaction'
      } = {} as any
) {
  return useContractWrite<typeof escrowABI, 'executeTransaction', TMode>({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'executeTransaction',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"pay"`.
 *
 *
 */
export function useEscrowPay<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof escrowAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<PrepareWriteContractResult<typeof escrowABI, 'pay'>['request']['abi'], 'pay', TMode> & {
        address?: Address
        chainId?: TChainId
        functionName?: 'pay'
      }
    : UseContractWriteConfig<typeof escrowABI, 'pay', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'pay'
      } = {} as any
) {
  return useContractWrite<typeof escrowABI, 'pay', TMode>({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'pay',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"payArbitrationFeeByBuyer"`.
 *
 *
 */
export function useEscrowPayArbitrationFeeByBuyer<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof escrowAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof escrowABI, 'payArbitrationFeeByBuyer'>['request']['abi'],
        'payArbitrationFeeByBuyer',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'payArbitrationFeeByBuyer' }
    : UseContractWriteConfig<typeof escrowABI, 'payArbitrationFeeByBuyer', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'payArbitrationFeeByBuyer'
      } = {} as any
) {
  return useContractWrite<typeof escrowABI, 'payArbitrationFeeByBuyer', TMode>({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'payArbitrationFeeByBuyer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"payArbitrationFeeBySeller"`.
 *
 *
 */
export function useEscrowPayArbitrationFeeBySeller<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof escrowAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof escrowABI, 'payArbitrationFeeBySeller'>['request']['abi'],
        'payArbitrationFeeBySeller',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'payArbitrationFeeBySeller' }
    : UseContractWriteConfig<typeof escrowABI, 'payArbitrationFeeBySeller', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'payArbitrationFeeBySeller'
      } = {} as any
) {
  return useContractWrite<typeof escrowABI, 'payArbitrationFeeBySeller', TMode>({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'payArbitrationFeeBySeller',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"reimburse"`.
 *
 *
 */
export function useEscrowReimburse<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof escrowAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof escrowABI, 'reimburse'>['request']['abi'],
        'reimburse',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'reimburse' }
    : UseContractWriteConfig<typeof escrowABI, 'reimburse', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'reimburse'
      } = {} as any
) {
  return useContractWrite<typeof escrowABI, 'reimburse', TMode>({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'reimburse',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"rule"`.
 *
 *
 */
export function useEscrowRule<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof escrowAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<PrepareWriteContractResult<typeof escrowABI, 'rule'>['request']['abi'], 'rule', TMode> & {
        address?: Address
        chainId?: TChainId
        functionName?: 'rule'
      }
    : UseContractWriteConfig<typeof escrowABI, 'rule', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'rule'
      } = {} as any
) {
  return useContractWrite<typeof escrowABI, 'rule', TMode>({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'rule',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"timeOutByBuyer"`.
 *
 *
 */
export function useEscrowTimeOutByBuyer<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof escrowAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof escrowABI, 'timeOutByBuyer'>['request']['abi'],
        'timeOutByBuyer',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'timeOutByBuyer' }
    : UseContractWriteConfig<typeof escrowABI, 'timeOutByBuyer', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'timeOutByBuyer'
      } = {} as any
) {
  return useContractWrite<typeof escrowABI, 'timeOutByBuyer', TMode>({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'timeOutByBuyer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"timeOutBySeller"`.
 *
 *
 */
export function useEscrowTimeOutBySeller<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof escrowAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof escrowABI, 'timeOutBySeller'>['request']['abi'],
        'timeOutBySeller',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'timeOutBySeller' }
    : UseContractWriteConfig<typeof escrowABI, 'timeOutBySeller', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'timeOutBySeller'
      } = {} as any
) {
  return useContractWrite<typeof escrowABI, 'timeOutBySeller', TMode>({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'timeOutBySeller',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link escrowABI}__.
 *
 *
 */
export function usePrepareEscrowWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof escrowABI, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof escrowAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: escrowABI,
    address: escrowAddress[421614],
    ...config,
  } as UsePrepareContractWriteConfig<typeof escrowABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"changeArbitrator"`.
 *
 *
 */
export function usePrepareEscrowChangeArbitrator(
  config: Omit<
    UsePrepareContractWriteConfig<typeof escrowABI, 'changeArbitrator'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof escrowAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'changeArbitrator',
    ...config,
  } as UsePrepareContractWriteConfig<typeof escrowABI, 'changeArbitrator'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"changeArbitratorExtraData"`.
 *
 *
 */
export function usePrepareEscrowChangeArbitratorExtraData(
  config: Omit<
    UsePrepareContractWriteConfig<typeof escrowABI, 'changeArbitratorExtraData'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof escrowAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'changeArbitratorExtraData',
    ...config,
  } as UsePrepareContractWriteConfig<typeof escrowABI, 'changeArbitratorExtraData'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"changeDisputeTemplate"`.
 *
 *
 */
export function usePrepareEscrowChangeDisputeTemplate(
  config: Omit<
    UsePrepareContractWriteConfig<typeof escrowABI, 'changeDisputeTemplate'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof escrowAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'changeDisputeTemplate',
    ...config,
  } as UsePrepareContractWriteConfig<typeof escrowABI, 'changeDisputeTemplate'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"changeTemplateRegistry"`.
 *
 *
 */
export function usePrepareEscrowChangeTemplateRegistry(
  config: Omit<
    UsePrepareContractWriteConfig<typeof escrowABI, 'changeTemplateRegistry'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof escrowAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'changeTemplateRegistry',
    ...config,
  } as UsePrepareContractWriteConfig<typeof escrowABI, 'changeTemplateRegistry'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"createTransaction"`.
 *
 *
 */
export function usePrepareEscrowCreateTransaction(
  config: Omit<
    UsePrepareContractWriteConfig<typeof escrowABI, 'createTransaction'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof escrowAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'createTransaction',
    ...config,
  } as UsePrepareContractWriteConfig<typeof escrowABI, 'createTransaction'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"executeTransaction"`.
 *
 *
 */
export function usePrepareEscrowExecuteTransaction(
  config: Omit<
    UsePrepareContractWriteConfig<typeof escrowABI, 'executeTransaction'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof escrowAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'executeTransaction',
    ...config,
  } as UsePrepareContractWriteConfig<typeof escrowABI, 'executeTransaction'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"pay"`.
 *
 *
 */
export function usePrepareEscrowPay(
  config: Omit<UsePrepareContractWriteConfig<typeof escrowABI, 'pay'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof escrowAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'pay',
    ...config,
  } as UsePrepareContractWriteConfig<typeof escrowABI, 'pay'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"payArbitrationFeeByBuyer"`.
 *
 *
 */
export function usePrepareEscrowPayArbitrationFeeByBuyer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof escrowABI, 'payArbitrationFeeByBuyer'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof escrowAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'payArbitrationFeeByBuyer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof escrowABI, 'payArbitrationFeeByBuyer'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"payArbitrationFeeBySeller"`.
 *
 *
 */
export function usePrepareEscrowPayArbitrationFeeBySeller(
  config: Omit<
    UsePrepareContractWriteConfig<typeof escrowABI, 'payArbitrationFeeBySeller'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof escrowAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'payArbitrationFeeBySeller',
    ...config,
  } as UsePrepareContractWriteConfig<typeof escrowABI, 'payArbitrationFeeBySeller'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"reimburse"`.
 *
 *
 */
export function usePrepareEscrowReimburse(
  config: Omit<UsePrepareContractWriteConfig<typeof escrowABI, 'reimburse'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof escrowAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'reimburse',
    ...config,
  } as UsePrepareContractWriteConfig<typeof escrowABI, 'reimburse'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"rule"`.
 *
 *
 */
export function usePrepareEscrowRule(
  config: Omit<UsePrepareContractWriteConfig<typeof escrowABI, 'rule'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof escrowAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'rule',
    ...config,
  } as UsePrepareContractWriteConfig<typeof escrowABI, 'rule'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"timeOutByBuyer"`.
 *
 *
 */
export function usePrepareEscrowTimeOutByBuyer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof escrowABI, 'timeOutByBuyer'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof escrowAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'timeOutByBuyer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof escrowABI, 'timeOutByBuyer'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link escrowABI}__ and `functionName` set to `"timeOutBySeller"`.
 *
 *
 */
export function usePrepareEscrowTimeOutBySeller(
  config: Omit<
    UsePrepareContractWriteConfig<typeof escrowABI, 'timeOutBySeller'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof escrowAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: escrowABI,
    address: escrowAddress[421614],
    functionName: 'timeOutBySeller',
    ...config,
  } as UsePrepareContractWriteConfig<typeof escrowABI, 'timeOutBySeller'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link escrowABI}__.
 *
 *
 */
export function useEscrowEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof escrowABI, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof escrowAddress
  } = {} as any
) {
  return useContractEvent({ abi: escrowABI, address: escrowAddress[421614], ...config } as UseContractEventConfig<
    typeof escrowABI,
    TEventName
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link escrowABI}__ and `eventName` set to `"DisputeRequest"`.
 *
 *
 */
export function useEscrowDisputeRequestEvent(
  config: Omit<UseContractEventConfig<typeof escrowABI, 'DisputeRequest'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof escrowAddress
  } = {} as any
) {
  return useContractEvent({
    abi: escrowABI,
    address: escrowAddress[421614],
    eventName: 'DisputeRequest',
    ...config,
  } as UseContractEventConfig<typeof escrowABI, 'DisputeRequest'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link escrowABI}__ and `eventName` set to `"HasToPayFee"`.
 *
 *
 */
export function useEscrowHasToPayFeeEvent(
  config: Omit<UseContractEventConfig<typeof escrowABI, 'HasToPayFee'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof escrowAddress
  } = {} as any
) {
  return useContractEvent({
    abi: escrowABI,
    address: escrowAddress[421614],
    eventName: 'HasToPayFee',
    ...config,
  } as UseContractEventConfig<typeof escrowABI, 'HasToPayFee'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link escrowABI}__ and `eventName` set to `"Payment"`.
 *
 *
 */
export function useEscrowPaymentEvent(
  config: Omit<UseContractEventConfig<typeof escrowABI, 'Payment'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof escrowAddress
  } = {} as any
) {
  return useContractEvent({
    abi: escrowABI,
    address: escrowAddress[421614],
    eventName: 'Payment',
    ...config,
  } as UseContractEventConfig<typeof escrowABI, 'Payment'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link escrowABI}__ and `eventName` set to `"Ruling"`.
 *
 *
 */
export function useEscrowRulingEvent(
  config: Omit<UseContractEventConfig<typeof escrowABI, 'Ruling'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof escrowAddress
  } = {} as any
) {
  return useContractEvent({
    abi: escrowABI,
    address: escrowAddress[421614],
    eventName: 'Ruling',
    ...config,
  } as UseContractEventConfig<typeof escrowABI, 'Ruling'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link escrowABI}__ and `eventName` set to `"TransactionCreated"`.
 *
 *
 */
export function useEscrowTransactionCreatedEvent(
  config: Omit<UseContractEventConfig<typeof escrowABI, 'TransactionCreated'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof escrowAddress
  } = {} as any
) {
  return useContractEvent({
    abi: escrowABI,
    address: escrowAddress[421614],
    eventName: 'TransactionCreated',
    ...config,
  } as UseContractEventConfig<typeof escrowABI, 'TransactionCreated'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link escrowABI}__ and `eventName` set to `"TransactionResolved"`.
 *
 *
 */
export function useEscrowTransactionResolvedEvent(
  config: Omit<UseContractEventConfig<typeof escrowABI, 'TransactionResolved'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof escrowAddress
  } = {} as any
) {
  return useContractEvent({
    abi: escrowABI,
    address: escrowAddress[421614],
    eventName: 'TransactionResolved',
    ...config,
  } as UseContractEventConfig<typeof escrowABI, 'TransactionResolved'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link evidenceModuleABI}__.
 *
 *
 */
export function useEvidenceModuleRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof evidenceModuleABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof evidenceModuleABI, TFunctionName, TSelectData>, 'abi' | 'address'> & {
    chainId?: keyof typeof evidenceModuleAddress
  } = {} as any
) {
  return useContractRead({
    abi: evidenceModuleABI,
    address: evidenceModuleAddress[421614],
    ...config,
  } as UseContractReadConfig<typeof evidenceModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link evidenceModuleABI}__ and `functionName` set to `"governor"`.
 *
 *
 */
export function useEvidenceModuleGovernor<
  TFunctionName extends 'governor',
  TSelectData = ReadContractResult<typeof evidenceModuleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof evidenceModuleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof evidenceModuleAddress } = {} as any
) {
  return useContractRead({
    abi: evidenceModuleABI,
    address: evidenceModuleAddress[421614],
    functionName: 'governor',
    ...config,
  } as UseContractReadConfig<typeof evidenceModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link evidenceModuleABI}__ and `functionName` set to `"proxiableUUID"`.
 *
 *
 */
export function useEvidenceModuleProxiableUuid<
  TFunctionName extends 'proxiableUUID',
  TSelectData = ReadContractResult<typeof evidenceModuleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof evidenceModuleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof evidenceModuleAddress } = {} as any
) {
  return useContractRead({
    abi: evidenceModuleABI,
    address: evidenceModuleAddress[421614],
    functionName: 'proxiableUUID',
    ...config,
  } as UseContractReadConfig<typeof evidenceModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link evidenceModuleABI}__.
 *
 *
 */
export function useEvidenceModuleWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof evidenceModuleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof evidenceModuleABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof evidenceModuleABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof evidenceModuleABI, TFunctionName, TMode>({
    abi: evidenceModuleABI,
    address: evidenceModuleAddress[421614],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link evidenceModuleABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function useEvidenceModuleInitialize<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof evidenceModuleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof evidenceModuleABI, 'initialize'>['request']['abi'],
        'initialize',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'initialize' }
    : UseContractWriteConfig<typeof evidenceModuleABI, 'initialize', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'initialize'
      } = {} as any
) {
  return useContractWrite<typeof evidenceModuleABI, 'initialize', TMode>({
    abi: evidenceModuleABI,
    address: evidenceModuleAddress[421614],
    functionName: 'initialize',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link evidenceModuleABI}__ and `functionName` set to `"submitEvidence"`.
 *
 *
 */
export function useEvidenceModuleSubmitEvidence<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof evidenceModuleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof evidenceModuleABI, 'submitEvidence'>['request']['abi'],
        'submitEvidence',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'submitEvidence' }
    : UseContractWriteConfig<typeof evidenceModuleABI, 'submitEvidence', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'submitEvidence'
      } = {} as any
) {
  return useContractWrite<typeof evidenceModuleABI, 'submitEvidence', TMode>({
    abi: evidenceModuleABI,
    address: evidenceModuleAddress[421614],
    functionName: 'submitEvidence',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link evidenceModuleABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function useEvidenceModuleUpgradeToAndCall<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof evidenceModuleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof evidenceModuleABI, 'upgradeToAndCall'>['request']['abi'],
        'upgradeToAndCall',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'upgradeToAndCall' }
    : UseContractWriteConfig<typeof evidenceModuleABI, 'upgradeToAndCall', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'upgradeToAndCall'
      } = {} as any
) {
  return useContractWrite<typeof evidenceModuleABI, 'upgradeToAndCall', TMode>({
    abi: evidenceModuleABI,
    address: evidenceModuleAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link evidenceModuleABI}__.
 *
 *
 */
export function usePrepareEvidenceModuleWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof evidenceModuleABI, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof evidenceModuleAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: evidenceModuleABI,
    address: evidenceModuleAddress[421614],
    ...config,
  } as UsePrepareContractWriteConfig<typeof evidenceModuleABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link evidenceModuleABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function usePrepareEvidenceModuleInitialize(
  config: Omit<
    UsePrepareContractWriteConfig<typeof evidenceModuleABI, 'initialize'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof evidenceModuleAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: evidenceModuleABI,
    address: evidenceModuleAddress[421614],
    functionName: 'initialize',
    ...config,
  } as UsePrepareContractWriteConfig<typeof evidenceModuleABI, 'initialize'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link evidenceModuleABI}__ and `functionName` set to `"submitEvidence"`.
 *
 *
 */
export function usePrepareEvidenceModuleSubmitEvidence(
  config: Omit<
    UsePrepareContractWriteConfig<typeof evidenceModuleABI, 'submitEvidence'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof evidenceModuleAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: evidenceModuleABI,
    address: evidenceModuleAddress[421614],
    functionName: 'submitEvidence',
    ...config,
  } as UsePrepareContractWriteConfig<typeof evidenceModuleABI, 'submitEvidence'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link evidenceModuleABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function usePrepareEvidenceModuleUpgradeToAndCall(
  config: Omit<
    UsePrepareContractWriteConfig<typeof evidenceModuleABI, 'upgradeToAndCall'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof evidenceModuleAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: evidenceModuleABI,
    address: evidenceModuleAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as UsePrepareContractWriteConfig<typeof evidenceModuleABI, 'upgradeToAndCall'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link evidenceModuleABI}__.
 *
 *
 */
export function useEvidenceModuleEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof evidenceModuleABI, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof evidenceModuleAddress
  } = {} as any
) {
  return useContractEvent({
    abi: evidenceModuleABI,
    address: evidenceModuleAddress[421614],
    ...config,
  } as UseContractEventConfig<typeof evidenceModuleABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link evidenceModuleABI}__ and `eventName` set to `"Evidence"`.
 *
 *
 */
export function useEvidenceModuleEvidenceEvent(
  config: Omit<UseContractEventConfig<typeof evidenceModuleABI, 'Evidence'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof evidenceModuleAddress
  } = {} as any
) {
  return useContractEvent({
    abi: evidenceModuleABI,
    address: evidenceModuleAddress[421614],
    eventName: 'Evidence',
    ...config,
  } as UseContractEventConfig<typeof evidenceModuleABI, 'Evidence'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link evidenceModuleABI}__ and `eventName` set to `"Initialized"`.
 *
 *
 */
export function useEvidenceModuleInitializedEvent(
  config: Omit<UseContractEventConfig<typeof evidenceModuleABI, 'Initialized'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof evidenceModuleAddress
  } = {} as any
) {
  return useContractEvent({
    abi: evidenceModuleABI,
    address: evidenceModuleAddress[421614],
    eventName: 'Initialized',
    ...config,
  } as UseContractEventConfig<typeof evidenceModuleABI, 'Initialized'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link evidenceModuleABI}__ and `eventName` set to `"Upgraded"`.
 *
 *
 */
export function useEvidenceModuleUpgradedEvent(
  config: Omit<UseContractEventConfig<typeof evidenceModuleABI, 'Upgraded'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof evidenceModuleAddress
  } = {} as any
) {
  return useContractEvent({
    abi: evidenceModuleABI,
    address: evidenceModuleAddress[421614],
    eventName: 'Upgraded',
    ...config,
  } as UseContractEventConfig<typeof evidenceModuleABI, 'Upgraded'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link evidenceModuleImplementationABI}__.
 *
 *
 */
export function useEvidenceModuleImplementationRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof evidenceModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof evidenceModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof evidenceModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: evidenceModuleImplementationABI,
    address: evidenceModuleImplementationAddress[421614],
    ...config,
  } as UseContractReadConfig<typeof evidenceModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link evidenceModuleImplementationABI}__ and `functionName` set to `"governor"`.
 *
 *
 */
export function useEvidenceModuleImplementationGovernor<
  TFunctionName extends 'governor',
  TSelectData = ReadContractResult<typeof evidenceModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof evidenceModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof evidenceModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: evidenceModuleImplementationABI,
    address: evidenceModuleImplementationAddress[421614],
    functionName: 'governor',
    ...config,
  } as UseContractReadConfig<typeof evidenceModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link evidenceModuleImplementationABI}__ and `functionName` set to `"proxiableUUID"`.
 *
 *
 */
export function useEvidenceModuleImplementationProxiableUuid<
  TFunctionName extends 'proxiableUUID',
  TSelectData = ReadContractResult<typeof evidenceModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof evidenceModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof evidenceModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: evidenceModuleImplementationABI,
    address: evidenceModuleImplementationAddress[421614],
    functionName: 'proxiableUUID',
    ...config,
  } as UseContractReadConfig<typeof evidenceModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link evidenceModuleImplementationABI}__.
 *
 *
 */
export function useEvidenceModuleImplementationWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof evidenceModuleImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof evidenceModuleImplementationABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof evidenceModuleImplementationABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof evidenceModuleImplementationABI, TFunctionName, TMode>({
    abi: evidenceModuleImplementationABI,
    address: evidenceModuleImplementationAddress[421614],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link evidenceModuleImplementationABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function useEvidenceModuleImplementationInitialize<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof evidenceModuleImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof evidenceModuleImplementationABI, 'initialize'>['request']['abi'],
        'initialize',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'initialize' }
    : UseContractWriteConfig<typeof evidenceModuleImplementationABI, 'initialize', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'initialize'
      } = {} as any
) {
  return useContractWrite<typeof evidenceModuleImplementationABI, 'initialize', TMode>({
    abi: evidenceModuleImplementationABI,
    address: evidenceModuleImplementationAddress[421614],
    functionName: 'initialize',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link evidenceModuleImplementationABI}__ and `functionName` set to `"submitEvidence"`.
 *
 *
 */
export function useEvidenceModuleImplementationSubmitEvidence<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof evidenceModuleImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof evidenceModuleImplementationABI, 'submitEvidence'>['request']['abi'],
        'submitEvidence',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'submitEvidence' }
    : UseContractWriteConfig<typeof evidenceModuleImplementationABI, 'submitEvidence', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'submitEvidence'
      } = {} as any
) {
  return useContractWrite<typeof evidenceModuleImplementationABI, 'submitEvidence', TMode>({
    abi: evidenceModuleImplementationABI,
    address: evidenceModuleImplementationAddress[421614],
    functionName: 'submitEvidence',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link evidenceModuleImplementationABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function useEvidenceModuleImplementationUpgradeToAndCall<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof evidenceModuleImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof evidenceModuleImplementationABI, 'upgradeToAndCall'>['request']['abi'],
        'upgradeToAndCall',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'upgradeToAndCall' }
    : UseContractWriteConfig<typeof evidenceModuleImplementationABI, 'upgradeToAndCall', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'upgradeToAndCall'
      } = {} as any
) {
  return useContractWrite<typeof evidenceModuleImplementationABI, 'upgradeToAndCall', TMode>({
    abi: evidenceModuleImplementationABI,
    address: evidenceModuleImplementationAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link evidenceModuleImplementationABI}__.
 *
 *
 */
export function usePrepareEvidenceModuleImplementationWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof evidenceModuleImplementationABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof evidenceModuleImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: evidenceModuleImplementationABI,
    address: evidenceModuleImplementationAddress[421614],
    ...config,
  } as UsePrepareContractWriteConfig<typeof evidenceModuleImplementationABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link evidenceModuleImplementationABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function usePrepareEvidenceModuleImplementationInitialize(
  config: Omit<
    UsePrepareContractWriteConfig<typeof evidenceModuleImplementationABI, 'initialize'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof evidenceModuleImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: evidenceModuleImplementationABI,
    address: evidenceModuleImplementationAddress[421614],
    functionName: 'initialize',
    ...config,
  } as UsePrepareContractWriteConfig<typeof evidenceModuleImplementationABI, 'initialize'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link evidenceModuleImplementationABI}__ and `functionName` set to `"submitEvidence"`.
 *
 *
 */
export function usePrepareEvidenceModuleImplementationSubmitEvidence(
  config: Omit<
    UsePrepareContractWriteConfig<typeof evidenceModuleImplementationABI, 'submitEvidence'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof evidenceModuleImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: evidenceModuleImplementationABI,
    address: evidenceModuleImplementationAddress[421614],
    functionName: 'submitEvidence',
    ...config,
  } as UsePrepareContractWriteConfig<typeof evidenceModuleImplementationABI, 'submitEvidence'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link evidenceModuleImplementationABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function usePrepareEvidenceModuleImplementationUpgradeToAndCall(
  config: Omit<
    UsePrepareContractWriteConfig<typeof evidenceModuleImplementationABI, 'upgradeToAndCall'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof evidenceModuleImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: evidenceModuleImplementationABI,
    address: evidenceModuleImplementationAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as UsePrepareContractWriteConfig<typeof evidenceModuleImplementationABI, 'upgradeToAndCall'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link evidenceModuleImplementationABI}__.
 *
 *
 */
export function useEvidenceModuleImplementationEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof evidenceModuleImplementationABI, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof evidenceModuleImplementationAddress
  } = {} as any
) {
  return useContractEvent({
    abi: evidenceModuleImplementationABI,
    address: evidenceModuleImplementationAddress[421614],
    ...config,
  } as UseContractEventConfig<typeof evidenceModuleImplementationABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link evidenceModuleImplementationABI}__ and `eventName` set to `"Evidence"`.
 *
 *
 */
export function useEvidenceModuleImplementationEvidenceEvent(
  config: Omit<
    UseContractEventConfig<typeof evidenceModuleImplementationABI, 'Evidence'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof evidenceModuleImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: evidenceModuleImplementationABI,
    address: evidenceModuleImplementationAddress[421614],
    eventName: 'Evidence',
    ...config,
  } as UseContractEventConfig<typeof evidenceModuleImplementationABI, 'Evidence'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link evidenceModuleImplementationABI}__ and `eventName` set to `"Initialized"`.
 *
 *
 */
export function useEvidenceModuleImplementationInitializedEvent(
  config: Omit<
    UseContractEventConfig<typeof evidenceModuleImplementationABI, 'Initialized'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof evidenceModuleImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: evidenceModuleImplementationABI,
    address: evidenceModuleImplementationAddress[421614],
    eventName: 'Initialized',
    ...config,
  } as UseContractEventConfig<typeof evidenceModuleImplementationABI, 'Initialized'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link evidenceModuleImplementationABI}__ and `eventName` set to `"Upgraded"`.
 *
 *
 */
export function useEvidenceModuleImplementationUpgradedEvent(
  config: Omit<
    UseContractEventConfig<typeof evidenceModuleImplementationABI, 'Upgraded'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof evidenceModuleImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: evidenceModuleImplementationABI,
    address: evidenceModuleImplementationAddress[421614],
    eventName: 'Upgraded',
    ...config,
  } as UseContractEventConfig<typeof evidenceModuleImplementationABI, 'Upgraded'>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iArbitrableV2ABI}__.
 */
export function useIArbitrableV2Write<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iArbitrableV2ABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof iArbitrableV2ABI, TFunctionName, TMode> & {
        abi?: never
      } = {} as any
) {
  return useContractWrite<typeof iArbitrableV2ABI, TFunctionName, TMode>({ abi: iArbitrableV2ABI, ...config } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iArbitrableV2ABI}__ and `functionName` set to `"rule"`.
 */
export function useIArbitrableV2Rule<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iArbitrableV2ABI, 'rule'>['request']['abi'],
        'rule',
        TMode
      > & { functionName?: 'rule' }
    : UseContractWriteConfig<typeof iArbitrableV2ABI, 'rule', TMode> & {
        abi?: never
        functionName?: 'rule'
      } = {} as any
) {
  return useContractWrite<typeof iArbitrableV2ABI, 'rule', TMode>({
    abi: iArbitrableV2ABI,
    functionName: 'rule',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iArbitrableV2ABI}__.
 */
export function usePrepareIArbitrableV2Write<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof iArbitrableV2ABI, TFunctionName>, 'abi'> = {} as any
) {
  return usePrepareContractWrite({ abi: iArbitrableV2ABI, ...config } as UsePrepareContractWriteConfig<
    typeof iArbitrableV2ABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iArbitrableV2ABI}__ and `functionName` set to `"rule"`.
 */
export function usePrepareIArbitrableV2Rule(
  config: Omit<UsePrepareContractWriteConfig<typeof iArbitrableV2ABI, 'rule'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: iArbitrableV2ABI,
    functionName: 'rule',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iArbitrableV2ABI, 'rule'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iArbitrableV2ABI}__.
 */
export function useIArbitrableV2Event<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof iArbitrableV2ABI, TEventName>, 'abi'> = {} as any
) {
  return useContractEvent({ abi: iArbitrableV2ABI, ...config } as UseContractEventConfig<
    typeof iArbitrableV2ABI,
    TEventName
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iArbitrableV2ABI}__ and `eventName` set to `"DisputeRequest"`.
 */
export function useIArbitrableV2DisputeRequestEvent(
  config: Omit<UseContractEventConfig<typeof iArbitrableV2ABI, 'DisputeRequest'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: iArbitrableV2ABI, eventName: 'DisputeRequest', ...config } as UseContractEventConfig<
    typeof iArbitrableV2ABI,
    'DisputeRequest'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iArbitrableV2ABI}__ and `eventName` set to `"Ruling"`.
 */
export function useIArbitrableV2RulingEvent(
  config: Omit<UseContractEventConfig<typeof iArbitrableV2ABI, 'Ruling'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: iArbitrableV2ABI, eventName: 'Ruling', ...config } as UseContractEventConfig<
    typeof iArbitrableV2ABI,
    'Ruling'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iHomeGatewayABI}__.
 */
export function useIHomeGatewayRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof iHomeGatewayABI, TFunctionName>
>(config: Omit<UseContractReadConfig<typeof iHomeGatewayABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: iHomeGatewayABI, ...config } as UseContractReadConfig<
    typeof iHomeGatewayABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iHomeGatewayABI}__ and `functionName` set to `"disputeHashToHomeID"`.
 */
export function useIHomeGatewayDisputeHashToHomeId<
  TFunctionName extends 'disputeHashToHomeID',
  TSelectData = ReadContractResult<typeof iHomeGatewayABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof iHomeGatewayABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: iHomeGatewayABI,
    functionName: 'disputeHashToHomeID',
    ...config,
  } as UseContractReadConfig<typeof iHomeGatewayABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iHomeGatewayABI}__ and `functionName` set to `"feeToken"`.
 */
export function useIHomeGatewayFeeToken<
  TFunctionName extends 'feeToken',
  TSelectData = ReadContractResult<typeof iHomeGatewayABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof iHomeGatewayABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({ abi: iHomeGatewayABI, functionName: 'feeToken', ...config } as UseContractReadConfig<
    typeof iHomeGatewayABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iHomeGatewayABI}__ and `functionName` set to `"foreignChainID"`.
 */
export function useIHomeGatewayForeignChainId<
  TFunctionName extends 'foreignChainID',
  TSelectData = ReadContractResult<typeof iHomeGatewayABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof iHomeGatewayABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({ abi: iHomeGatewayABI, functionName: 'foreignChainID', ...config } as UseContractReadConfig<
    typeof iHomeGatewayABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iHomeGatewayABI}__ and `functionName` set to `"foreignGateway"`.
 */
export function useIHomeGatewayForeignGateway<
  TFunctionName extends 'foreignGateway',
  TSelectData = ReadContractResult<typeof iHomeGatewayABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof iHomeGatewayABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({ abi: iHomeGatewayABI, functionName: 'foreignGateway', ...config } as UseContractReadConfig<
    typeof iHomeGatewayABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iHomeGatewayABI}__ and `functionName` set to `"receiverGateway"`.
 */
export function useIHomeGatewayReceiverGateway<
  TFunctionName extends 'receiverGateway',
  TSelectData = ReadContractResult<typeof iHomeGatewayABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof iHomeGatewayABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({ abi: iHomeGatewayABI, functionName: 'receiverGateway', ...config } as UseContractReadConfig<
    typeof iHomeGatewayABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iHomeGatewayABI}__ and `functionName` set to `"veaInbox"`.
 */
export function useIHomeGatewayVeaInbox<
  TFunctionName extends 'veaInbox',
  TSelectData = ReadContractResult<typeof iHomeGatewayABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof iHomeGatewayABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({ abi: iHomeGatewayABI, functionName: 'veaInbox', ...config } as UseContractReadConfig<
    typeof iHomeGatewayABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iHomeGatewayABI}__.
 */
export function useIHomeGatewayWrite<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iHomeGatewayABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof iHomeGatewayABI, TFunctionName, TMode> & {
        abi?: never
      } = {} as any
) {
  return useContractWrite<typeof iHomeGatewayABI, TFunctionName, TMode>({ abi: iHomeGatewayABI, ...config } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iHomeGatewayABI}__ and `functionName` set to `"relayCreateDispute"`.
 */
export function useIHomeGatewayRelayCreateDispute<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iHomeGatewayABI, 'relayCreateDispute'>['request']['abi'],
        'relayCreateDispute',
        TMode
      > & { functionName?: 'relayCreateDispute' }
    : UseContractWriteConfig<typeof iHomeGatewayABI, 'relayCreateDispute', TMode> & {
        abi?: never
        functionName?: 'relayCreateDispute'
      } = {} as any
) {
  return useContractWrite<typeof iHomeGatewayABI, 'relayCreateDispute', TMode>({
    abi: iHomeGatewayABI,
    functionName: 'relayCreateDispute',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iHomeGatewayABI}__ and `functionName` set to `"rule"`.
 */
export function useIHomeGatewayRule<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iHomeGatewayABI, 'rule'>['request']['abi'],
        'rule',
        TMode
      > & { functionName?: 'rule' }
    : UseContractWriteConfig<typeof iHomeGatewayABI, 'rule', TMode> & {
        abi?: never
        functionName?: 'rule'
      } = {} as any
) {
  return useContractWrite<typeof iHomeGatewayABI, 'rule', TMode>({
    abi: iHomeGatewayABI,
    functionName: 'rule',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iHomeGatewayABI}__.
 */
export function usePrepareIHomeGatewayWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof iHomeGatewayABI, TFunctionName>, 'abi'> = {} as any
) {
  return usePrepareContractWrite({ abi: iHomeGatewayABI, ...config } as UsePrepareContractWriteConfig<
    typeof iHomeGatewayABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iHomeGatewayABI}__ and `functionName` set to `"relayCreateDispute"`.
 */
export function usePrepareIHomeGatewayRelayCreateDispute(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iHomeGatewayABI, 'relayCreateDispute'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: iHomeGatewayABI,
    functionName: 'relayCreateDispute',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iHomeGatewayABI, 'relayCreateDispute'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iHomeGatewayABI}__ and `functionName` set to `"rule"`.
 */
export function usePrepareIHomeGatewayRule(
  config: Omit<UsePrepareContractWriteConfig<typeof iHomeGatewayABI, 'rule'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: iHomeGatewayABI,
    functionName: 'rule',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iHomeGatewayABI, 'rule'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iHomeGatewayABI}__.
 */
export function useIHomeGatewayEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof iHomeGatewayABI, TEventName>, 'abi'> = {} as any
) {
  return useContractEvent({ abi: iHomeGatewayABI, ...config } as UseContractEventConfig<
    typeof iHomeGatewayABI,
    TEventName
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iHomeGatewayABI}__ and `eventName` set to `"CrossChainDisputeIncoming"`.
 */
export function useIHomeGatewayCrossChainDisputeIncomingEvent(
  config: Omit<
    UseContractEventConfig<typeof iHomeGatewayABI, 'CrossChainDisputeIncoming'>,
    'abi' | 'eventName'
  > = {} as any
) {
  return useContractEvent({
    abi: iHomeGatewayABI,
    eventName: 'CrossChainDisputeIncoming',
    ...config,
  } as UseContractEventConfig<typeof iHomeGatewayABI, 'CrossChainDisputeIncoming'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iHomeGatewayABI}__ and `eventName` set to `"DisputeRequest"`.
 */
export function useIHomeGatewayDisputeRequestEvent(
  config: Omit<UseContractEventConfig<typeof iHomeGatewayABI, 'DisputeRequest'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: iHomeGatewayABI, eventName: 'DisputeRequest', ...config } as UseContractEventConfig<
    typeof iHomeGatewayABI,
    'DisputeRequest'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link iHomeGatewayABI}__ and `eventName` set to `"Ruling"`.
 */
export function useIHomeGatewayRulingEvent(
  config: Omit<UseContractEventConfig<typeof iHomeGatewayABI, 'Ruling'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: iHomeGatewayABI, eventName: 'Ruling', ...config } as UseContractEventConfig<
    typeof iHomeGatewayABI,
    'Ruling'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreABI}__.
 *
 *
 */
export function useKlerosCoreRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof klerosCoreABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>, 'abi' | 'address'> & {
    chainId?: keyof typeof klerosCoreAddress
  } = {} as any
) {
  return useContractRead({ abi: klerosCoreABI, address: klerosCoreAddress[421614], ...config } as UseContractReadConfig<
    typeof klerosCoreABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"appealCost"`.
 *
 *
 */
export function useKlerosCoreAppealCost<
  TFunctionName extends 'appealCost',
  TSelectData = ReadContractResult<typeof klerosCoreABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'appealCost',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"appealPeriod"`.
 *
 *
 */
export function useKlerosCoreAppealPeriod<
  TFunctionName extends 'appealPeriod',
  TSelectData = ReadContractResult<typeof klerosCoreABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'appealPeriod',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"arbitrationCost"`.
 *
 *
 */
export function useKlerosCoreArbitrationCost<
  TFunctionName extends 'arbitrationCost',
  TSelectData = ReadContractResult<typeof klerosCoreABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'arbitrationCost',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"convertEthToTokenAmount"`.
 *
 *
 */
export function useKlerosCoreConvertEthToTokenAmount<
  TFunctionName extends 'convertEthToTokenAmount',
  TSelectData = ReadContractResult<typeof klerosCoreABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'convertEthToTokenAmount',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"courts"`.
 *
 *
 */
export function useKlerosCoreCourts<
  TFunctionName extends 'courts',
  TSelectData = ReadContractResult<typeof klerosCoreABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'courts',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"currencyRates"`.
 *
 *
 */
export function useKlerosCoreCurrencyRates<
  TFunctionName extends 'currencyRates',
  TSelectData = ReadContractResult<typeof klerosCoreABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'currencyRates',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"currentRuling"`.
 *
 *
 */
export function useKlerosCoreCurrentRuling<
  TFunctionName extends 'currentRuling',
  TSelectData = ReadContractResult<typeof klerosCoreABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'currentRuling',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"disputeKits"`.
 *
 *
 */
export function useKlerosCoreDisputeKits<
  TFunctionName extends 'disputeKits',
  TSelectData = ReadContractResult<typeof klerosCoreABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'disputeKits',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"disputes"`.
 *
 *
 */
export function useKlerosCoreDisputes<
  TFunctionName extends 'disputes',
  TSelectData = ReadContractResult<typeof klerosCoreABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'disputes',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"getDisputeKitsLength"`.
 *
 *
 */
export function useKlerosCoreGetDisputeKitsLength<
  TFunctionName extends 'getDisputeKitsLength',
  TSelectData = ReadContractResult<typeof klerosCoreABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'getDisputeKitsLength',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"getNumberOfRounds"`.
 *
 *
 */
export function useKlerosCoreGetNumberOfRounds<
  TFunctionName extends 'getNumberOfRounds',
  TSelectData = ReadContractResult<typeof klerosCoreABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'getNumberOfRounds',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"getNumberOfVotes"`.
 *
 *
 */
export function useKlerosCoreGetNumberOfVotes<
  TFunctionName extends 'getNumberOfVotes',
  TSelectData = ReadContractResult<typeof klerosCoreABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'getNumberOfVotes',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"getRoundInfo"`.
 *
 *
 */
export function useKlerosCoreGetRoundInfo<
  TFunctionName extends 'getRoundInfo',
  TSelectData = ReadContractResult<typeof klerosCoreABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'getRoundInfo',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"getTimesPerPeriod"`.
 *
 *
 */
export function useKlerosCoreGetTimesPerPeriod<
  TFunctionName extends 'getTimesPerPeriod',
  TSelectData = ReadContractResult<typeof klerosCoreABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'getTimesPerPeriod',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"governor"`.
 *
 *
 */
export function useKlerosCoreGovernor<
  TFunctionName extends 'governor',
  TSelectData = ReadContractResult<typeof klerosCoreABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'governor',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"isDisputeKitJumping"`.
 *
 *
 */
export function useKlerosCoreIsDisputeKitJumping<
  TFunctionName extends 'isDisputeKitJumping',
  TSelectData = ReadContractResult<typeof klerosCoreABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'isDisputeKitJumping',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"isSupported"`.
 *
 *
 */
export function useKlerosCoreIsSupported<
  TFunctionName extends 'isSupported',
  TSelectData = ReadContractResult<typeof klerosCoreABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'isSupported',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"jurorProsecutionModule"`.
 *
 *
 */
export function useKlerosCoreJurorProsecutionModule<
  TFunctionName extends 'jurorProsecutionModule',
  TSelectData = ReadContractResult<typeof klerosCoreABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'jurorProsecutionModule',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"pinakion"`.
 *
 *
 */
export function useKlerosCorePinakion<
  TFunctionName extends 'pinakion',
  TSelectData = ReadContractResult<typeof klerosCoreABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'pinakion',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"proxiableUUID"`.
 *
 *
 */
export function useKlerosCoreProxiableUuid<
  TFunctionName extends 'proxiableUUID',
  TSelectData = ReadContractResult<typeof klerosCoreABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'proxiableUUID',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"sortitionModule"`.
 *
 *
 */
export function useKlerosCoreSortitionModule<
  TFunctionName extends 'sortitionModule',
  TSelectData = ReadContractResult<typeof klerosCoreABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'sortitionModule',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreABI}__.
 *
 *
 */
export function useKlerosCoreWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof klerosCoreABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreABI, TFunctionName, TMode>({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"addNewDisputeKit"`.
 *
 *
 */
export function useKlerosCoreAddNewDisputeKit<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreABI, 'addNewDisputeKit'>['request']['abi'],
        'addNewDisputeKit',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'addNewDisputeKit' }
    : UseContractWriteConfig<typeof klerosCoreABI, 'addNewDisputeKit', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'addNewDisputeKit'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreABI, 'addNewDisputeKit', TMode>({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'addNewDisputeKit',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"appeal"`.
 *
 *
 */
export function useKlerosCoreAppeal<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreABI, 'appeal'>['request']['abi'],
        'appeal',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'appeal' }
    : UseContractWriteConfig<typeof klerosCoreABI, 'appeal', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'appeal'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreABI, 'appeal', TMode>({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'appeal',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"changeAcceptedFeeTokens"`.
 *
 *
 */
export function useKlerosCoreChangeAcceptedFeeTokens<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreABI, 'changeAcceptedFeeTokens'>['request']['abi'],
        'changeAcceptedFeeTokens',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeAcceptedFeeTokens' }
    : UseContractWriteConfig<typeof klerosCoreABI, 'changeAcceptedFeeTokens', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeAcceptedFeeTokens'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreABI, 'changeAcceptedFeeTokens', TMode>({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'changeAcceptedFeeTokens',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"changeCourtParameters"`.
 *
 *
 */
export function useKlerosCoreChangeCourtParameters<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreABI, 'changeCourtParameters'>['request']['abi'],
        'changeCourtParameters',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeCourtParameters' }
    : UseContractWriteConfig<typeof klerosCoreABI, 'changeCourtParameters', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeCourtParameters'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreABI, 'changeCourtParameters', TMode>({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'changeCourtParameters',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"changeCurrencyRates"`.
 *
 *
 */
export function useKlerosCoreChangeCurrencyRates<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreABI, 'changeCurrencyRates'>['request']['abi'],
        'changeCurrencyRates',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeCurrencyRates' }
    : UseContractWriteConfig<typeof klerosCoreABI, 'changeCurrencyRates', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeCurrencyRates'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreABI, 'changeCurrencyRates', TMode>({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'changeCurrencyRates',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function useKlerosCoreChangeGovernor<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreABI, 'changeGovernor'>['request']['abi'],
        'changeGovernor',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeGovernor' }
    : UseContractWriteConfig<typeof klerosCoreABI, 'changeGovernor', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeGovernor'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreABI, 'changeGovernor', TMode>({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"changeJurorProsecutionModule"`.
 *
 *
 */
export function useKlerosCoreChangeJurorProsecutionModule<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreABI, 'changeJurorProsecutionModule'>['request']['abi'],
        'changeJurorProsecutionModule',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeJurorProsecutionModule' }
    : UseContractWriteConfig<typeof klerosCoreABI, 'changeJurorProsecutionModule', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeJurorProsecutionModule'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreABI, 'changeJurorProsecutionModule', TMode>({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'changeJurorProsecutionModule',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"changePinakion"`.
 *
 *
 */
export function useKlerosCoreChangePinakion<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreABI, 'changePinakion'>['request']['abi'],
        'changePinakion',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changePinakion' }
    : UseContractWriteConfig<typeof klerosCoreABI, 'changePinakion', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changePinakion'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreABI, 'changePinakion', TMode>({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'changePinakion',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"changeSortitionModule"`.
 *
 *
 */
export function useKlerosCoreChangeSortitionModule<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreABI, 'changeSortitionModule'>['request']['abi'],
        'changeSortitionModule',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeSortitionModule' }
    : UseContractWriteConfig<typeof klerosCoreABI, 'changeSortitionModule', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeSortitionModule'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreABI, 'changeSortitionModule', TMode>({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'changeSortitionModule',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"createCourt"`.
 *
 *
 */
export function useKlerosCoreCreateCourt<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreABI, 'createCourt'>['request']['abi'],
        'createCourt',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'createCourt' }
    : UseContractWriteConfig<typeof klerosCoreABI, 'createCourt', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'createCourt'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreABI, 'createCourt', TMode>({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'createCourt',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"createDispute"`.
 *
 *
 */
export function useKlerosCoreCreateDispute<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreABI, 'createDispute'>['request']['abi'],
        'createDispute',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'createDispute' }
    : UseContractWriteConfig<typeof klerosCoreABI, 'createDispute', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'createDispute'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreABI, 'createDispute', TMode>({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'createDispute',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"draw"`.
 *
 *
 */
export function useKlerosCoreDraw<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreABI, 'draw'>['request']['abi'],
        'draw',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'draw' }
    : UseContractWriteConfig<typeof klerosCoreABI, 'draw', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'draw'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreABI, 'draw', TMode>({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'draw',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"enableDisputeKits"`.
 *
 *
 */
export function useKlerosCoreEnableDisputeKits<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreABI, 'enableDisputeKits'>['request']['abi'],
        'enableDisputeKits',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'enableDisputeKits' }
    : UseContractWriteConfig<typeof klerosCoreABI, 'enableDisputeKits', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'enableDisputeKits'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreABI, 'enableDisputeKits', TMode>({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'enableDisputeKits',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"execute"`.
 *
 *
 */
export function useKlerosCoreExecute<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreABI, 'execute'>['request']['abi'],
        'execute',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'execute' }
    : UseContractWriteConfig<typeof klerosCoreABI, 'execute', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'execute'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreABI, 'execute', TMode>({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'execute',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"executeGovernorProposal"`.
 *
 *
 */
export function useKlerosCoreExecuteGovernorProposal<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreABI, 'executeGovernorProposal'>['request']['abi'],
        'executeGovernorProposal',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'executeGovernorProposal' }
    : UseContractWriteConfig<typeof klerosCoreABI, 'executeGovernorProposal', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'executeGovernorProposal'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreABI, 'executeGovernorProposal', TMode>({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'executeGovernorProposal',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"executeRuling"`.
 *
 *
 */
export function useKlerosCoreExecuteRuling<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreABI, 'executeRuling'>['request']['abi'],
        'executeRuling',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'executeRuling' }
    : UseContractWriteConfig<typeof klerosCoreABI, 'executeRuling', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'executeRuling'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreABI, 'executeRuling', TMode>({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'executeRuling',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function useKlerosCoreInitialize<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreABI, 'initialize'>['request']['abi'],
        'initialize',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'initialize' }
    : UseContractWriteConfig<typeof klerosCoreABI, 'initialize', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'initialize'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreABI, 'initialize', TMode>({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'initialize',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"passPeriod"`.
 *
 *
 */
export function useKlerosCorePassPeriod<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreABI, 'passPeriod'>['request']['abi'],
        'passPeriod',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'passPeriod' }
    : UseContractWriteConfig<typeof klerosCoreABI, 'passPeriod', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'passPeriod'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreABI, 'passPeriod', TMode>({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'passPeriod',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"setStake"`.
 *
 *
 */
export function useKlerosCoreSetStake<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreABI, 'setStake'>['request']['abi'],
        'setStake',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setStake' }
    : UseContractWriteConfig<typeof klerosCoreABI, 'setStake', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setStake'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreABI, 'setStake', TMode>({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'setStake',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"setStakeBySortitionModule"`.
 *
 *
 */
export function useKlerosCoreSetStakeBySortitionModule<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreABI, 'setStakeBySortitionModule'>['request']['abi'],
        'setStakeBySortitionModule',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setStakeBySortitionModule' }
    : UseContractWriteConfig<typeof klerosCoreABI, 'setStakeBySortitionModule', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setStakeBySortitionModule'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreABI, 'setStakeBySortitionModule', TMode>({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'setStakeBySortitionModule',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function useKlerosCoreUpgradeToAndCall<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreABI, 'upgradeToAndCall'>['request']['abi'],
        'upgradeToAndCall',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'upgradeToAndCall' }
    : UseContractWriteConfig<typeof klerosCoreABI, 'upgradeToAndCall', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'upgradeToAndCall'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreABI, 'upgradeToAndCall', TMode>({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreABI}__.
 *
 *
 */
export function usePrepareKlerosCoreWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof klerosCoreABI, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof klerosCoreAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"addNewDisputeKit"`.
 *
 *
 */
export function usePrepareKlerosCoreAddNewDisputeKit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreABI, 'addNewDisputeKit'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'addNewDisputeKit',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreABI, 'addNewDisputeKit'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"appeal"`.
 *
 *
 */
export function usePrepareKlerosCoreAppeal(
  config: Omit<UsePrepareContractWriteConfig<typeof klerosCoreABI, 'appeal'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof klerosCoreAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'appeal',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreABI, 'appeal'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"changeAcceptedFeeTokens"`.
 *
 *
 */
export function usePrepareKlerosCoreChangeAcceptedFeeTokens(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreABI, 'changeAcceptedFeeTokens'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'changeAcceptedFeeTokens',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreABI, 'changeAcceptedFeeTokens'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"changeCourtParameters"`.
 *
 *
 */
export function usePrepareKlerosCoreChangeCourtParameters(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreABI, 'changeCourtParameters'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'changeCourtParameters',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreABI, 'changeCourtParameters'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"changeCurrencyRates"`.
 *
 *
 */
export function usePrepareKlerosCoreChangeCurrencyRates(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreABI, 'changeCurrencyRates'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'changeCurrencyRates',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreABI, 'changeCurrencyRates'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function usePrepareKlerosCoreChangeGovernor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreABI, 'changeGovernor'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreABI, 'changeGovernor'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"changeJurorProsecutionModule"`.
 *
 *
 */
export function usePrepareKlerosCoreChangeJurorProsecutionModule(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreABI, 'changeJurorProsecutionModule'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'changeJurorProsecutionModule',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreABI, 'changeJurorProsecutionModule'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"changePinakion"`.
 *
 *
 */
export function usePrepareKlerosCoreChangePinakion(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreABI, 'changePinakion'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'changePinakion',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreABI, 'changePinakion'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"changeSortitionModule"`.
 *
 *
 */
export function usePrepareKlerosCoreChangeSortitionModule(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreABI, 'changeSortitionModule'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'changeSortitionModule',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreABI, 'changeSortitionModule'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"createCourt"`.
 *
 *
 */
export function usePrepareKlerosCoreCreateCourt(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreABI, 'createCourt'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'createCourt',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreABI, 'createCourt'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"createDispute"`.
 *
 *
 */
export function usePrepareKlerosCoreCreateDispute(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreABI, 'createDispute'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'createDispute',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreABI, 'createDispute'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"draw"`.
 *
 *
 */
export function usePrepareKlerosCoreDraw(
  config: Omit<UsePrepareContractWriteConfig<typeof klerosCoreABI, 'draw'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof klerosCoreAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'draw',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreABI, 'draw'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"enableDisputeKits"`.
 *
 *
 */
export function usePrepareKlerosCoreEnableDisputeKits(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreABI, 'enableDisputeKits'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'enableDisputeKits',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreABI, 'enableDisputeKits'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"execute"`.
 *
 *
 */
export function usePrepareKlerosCoreExecute(
  config: Omit<UsePrepareContractWriteConfig<typeof klerosCoreABI, 'execute'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof klerosCoreAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'execute',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreABI, 'execute'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"executeGovernorProposal"`.
 *
 *
 */
export function usePrepareKlerosCoreExecuteGovernorProposal(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreABI, 'executeGovernorProposal'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'executeGovernorProposal',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreABI, 'executeGovernorProposal'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"executeRuling"`.
 *
 *
 */
export function usePrepareKlerosCoreExecuteRuling(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreABI, 'executeRuling'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'executeRuling',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreABI, 'executeRuling'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function usePrepareKlerosCoreInitialize(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreABI, 'initialize'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'initialize',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreABI, 'initialize'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"passPeriod"`.
 *
 *
 */
export function usePrepareKlerosCorePassPeriod(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreABI, 'passPeriod'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'passPeriod',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreABI, 'passPeriod'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"setStake"`.
 *
 *
 */
export function usePrepareKlerosCoreSetStake(
  config: Omit<UsePrepareContractWriteConfig<typeof klerosCoreABI, 'setStake'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof klerosCoreAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'setStake',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreABI, 'setStake'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"setStakeBySortitionModule"`.
 *
 *
 */
export function usePrepareKlerosCoreSetStakeBySortitionModule(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreABI, 'setStakeBySortitionModule'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'setStakeBySortitionModule',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreABI, 'setStakeBySortitionModule'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function usePrepareKlerosCoreUpgradeToAndCall(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreABI, 'upgradeToAndCall'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreABI, 'upgradeToAndCall'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreABI}__.
 *
 *
 */
export function useKlerosCoreEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof klerosCoreABI, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof klerosCoreAddress
  } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    ...config,
  } as UseContractEventConfig<typeof klerosCoreABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreABI}__ and `eventName` set to `"AcceptedFeeToken"`.
 *
 *
 */
export function useKlerosCoreAcceptedFeeTokenEvent(
  config: Omit<UseContractEventConfig<typeof klerosCoreABI, 'AcceptedFeeToken'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof klerosCoreAddress
  } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    eventName: 'AcceptedFeeToken',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreABI, 'AcceptedFeeToken'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreABI}__ and `eventName` set to `"AppealDecision"`.
 *
 *
 */
export function useKlerosCoreAppealDecisionEvent(
  config: Omit<UseContractEventConfig<typeof klerosCoreABI, 'AppealDecision'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof klerosCoreAddress
  } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    eventName: 'AppealDecision',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreABI, 'AppealDecision'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreABI}__ and `eventName` set to `"AppealPossible"`.
 *
 *
 */
export function useKlerosCoreAppealPossibleEvent(
  config: Omit<UseContractEventConfig<typeof klerosCoreABI, 'AppealPossible'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof klerosCoreAddress
  } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    eventName: 'AppealPossible',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreABI, 'AppealPossible'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreABI}__ and `eventName` set to `"CourtCreated"`.
 *
 *
 */
export function useKlerosCoreCourtCreatedEvent(
  config: Omit<UseContractEventConfig<typeof klerosCoreABI, 'CourtCreated'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof klerosCoreAddress
  } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    eventName: 'CourtCreated',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreABI, 'CourtCreated'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreABI}__ and `eventName` set to `"CourtJump"`.
 *
 *
 */
export function useKlerosCoreCourtJumpEvent(
  config: Omit<UseContractEventConfig<typeof klerosCoreABI, 'CourtJump'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof klerosCoreAddress
  } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    eventName: 'CourtJump',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreABI, 'CourtJump'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreABI}__ and `eventName` set to `"CourtModified"`.
 *
 *
 */
export function useKlerosCoreCourtModifiedEvent(
  config: Omit<UseContractEventConfig<typeof klerosCoreABI, 'CourtModified'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof klerosCoreAddress
  } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    eventName: 'CourtModified',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreABI, 'CourtModified'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreABI}__ and `eventName` set to `"DisputeCreation"`.
 *
 *
 */
export function useKlerosCoreDisputeCreationEvent(
  config: Omit<UseContractEventConfig<typeof klerosCoreABI, 'DisputeCreation'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof klerosCoreAddress
  } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    eventName: 'DisputeCreation',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreABI, 'DisputeCreation'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreABI}__ and `eventName` set to `"DisputeKitCreated"`.
 *
 *
 */
export function useKlerosCoreDisputeKitCreatedEvent(
  config: Omit<UseContractEventConfig<typeof klerosCoreABI, 'DisputeKitCreated'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof klerosCoreAddress
  } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    eventName: 'DisputeKitCreated',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreABI, 'DisputeKitCreated'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreABI}__ and `eventName` set to `"DisputeKitEnabled"`.
 *
 *
 */
export function useKlerosCoreDisputeKitEnabledEvent(
  config: Omit<UseContractEventConfig<typeof klerosCoreABI, 'DisputeKitEnabled'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof klerosCoreAddress
  } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    eventName: 'DisputeKitEnabled',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreABI, 'DisputeKitEnabled'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreABI}__ and `eventName` set to `"DisputeKitJump"`.
 *
 *
 */
export function useKlerosCoreDisputeKitJumpEvent(
  config: Omit<UseContractEventConfig<typeof klerosCoreABI, 'DisputeKitJump'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof klerosCoreAddress
  } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    eventName: 'DisputeKitJump',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreABI, 'DisputeKitJump'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreABI}__ and `eventName` set to `"Draw"`.
 *
 *
 */
export function useKlerosCoreDrawEvent(
  config: Omit<UseContractEventConfig<typeof klerosCoreABI, 'Draw'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof klerosCoreAddress
  } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    eventName: 'Draw',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreABI, 'Draw'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreABI}__ and `eventName` set to `"Initialized"`.
 *
 *
 */
export function useKlerosCoreInitializedEvent(
  config: Omit<UseContractEventConfig<typeof klerosCoreABI, 'Initialized'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof klerosCoreAddress
  } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    eventName: 'Initialized',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreABI, 'Initialized'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreABI}__ and `eventName` set to `"LeftoverRewardSent"`.
 *
 *
 */
export function useKlerosCoreLeftoverRewardSentEvent(
  config: Omit<UseContractEventConfig<typeof klerosCoreABI, 'LeftoverRewardSent'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof klerosCoreAddress
  } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    eventName: 'LeftoverRewardSent',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreABI, 'LeftoverRewardSent'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreABI}__ and `eventName` set to `"NewCurrencyRate"`.
 *
 *
 */
export function useKlerosCoreNewCurrencyRateEvent(
  config: Omit<UseContractEventConfig<typeof klerosCoreABI, 'NewCurrencyRate'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof klerosCoreAddress
  } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    eventName: 'NewCurrencyRate',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreABI, 'NewCurrencyRate'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreABI}__ and `eventName` set to `"NewPeriod"`.
 *
 *
 */
export function useKlerosCoreNewPeriodEvent(
  config: Omit<UseContractEventConfig<typeof klerosCoreABI, 'NewPeriod'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof klerosCoreAddress
  } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    eventName: 'NewPeriod',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreABI, 'NewPeriod'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreABI}__ and `eventName` set to `"Ruling"`.
 *
 *
 */
export function useKlerosCoreRulingEvent(
  config: Omit<UseContractEventConfig<typeof klerosCoreABI, 'Ruling'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof klerosCoreAddress
  } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    eventName: 'Ruling',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreABI, 'Ruling'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreABI}__ and `eventName` set to `"TokenAndETHShift"`.
 *
 *
 */
export function useKlerosCoreTokenAndEthShiftEvent(
  config: Omit<UseContractEventConfig<typeof klerosCoreABI, 'TokenAndETHShift'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof klerosCoreAddress
  } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    eventName: 'TokenAndETHShift',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreABI, 'TokenAndETHShift'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreABI}__ and `eventName` set to `"Upgraded"`.
 *
 *
 */
export function useKlerosCoreUpgradedEvent(
  config: Omit<UseContractEventConfig<typeof klerosCoreABI, 'Upgraded'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof klerosCoreAddress
  } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    eventName: 'Upgraded',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreABI, 'Upgraded'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreImplementationABI}__.
 *
 *
 */
export function useKlerosCoreImplementationRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof klerosCoreImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    ...config,
  } as UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"appealCost"`.
 *
 *
 */
export function useKlerosCoreImplementationAppealCost<
  TFunctionName extends 'appealCost',
  TSelectData = ReadContractResult<typeof klerosCoreImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'appealCost',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"appealPeriod"`.
 *
 *
 */
export function useKlerosCoreImplementationAppealPeriod<
  TFunctionName extends 'appealPeriod',
  TSelectData = ReadContractResult<typeof klerosCoreImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'appealPeriod',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"arbitrationCost"`.
 *
 *
 */
export function useKlerosCoreImplementationArbitrationCost<
  TFunctionName extends 'arbitrationCost',
  TSelectData = ReadContractResult<typeof klerosCoreImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'arbitrationCost',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"convertEthToTokenAmount"`.
 *
 *
 */
export function useKlerosCoreImplementationConvertEthToTokenAmount<
  TFunctionName extends 'convertEthToTokenAmount',
  TSelectData = ReadContractResult<typeof klerosCoreImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'convertEthToTokenAmount',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"courts"`.
 *
 *
 */
export function useKlerosCoreImplementationCourts<
  TFunctionName extends 'courts',
  TSelectData = ReadContractResult<typeof klerosCoreImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'courts',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"currencyRates"`.
 *
 *
 */
export function useKlerosCoreImplementationCurrencyRates<
  TFunctionName extends 'currencyRates',
  TSelectData = ReadContractResult<typeof klerosCoreImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'currencyRates',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"currentRuling"`.
 *
 *
 */
export function useKlerosCoreImplementationCurrentRuling<
  TFunctionName extends 'currentRuling',
  TSelectData = ReadContractResult<typeof klerosCoreImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'currentRuling',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"disputeKits"`.
 *
 *
 */
export function useKlerosCoreImplementationDisputeKits<
  TFunctionName extends 'disputeKits',
  TSelectData = ReadContractResult<typeof klerosCoreImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'disputeKits',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"disputes"`.
 *
 *
 */
export function useKlerosCoreImplementationDisputes<
  TFunctionName extends 'disputes',
  TSelectData = ReadContractResult<typeof klerosCoreImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'disputes',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"getDisputeKitsLength"`.
 *
 *
 */
export function useKlerosCoreImplementationGetDisputeKitsLength<
  TFunctionName extends 'getDisputeKitsLength',
  TSelectData = ReadContractResult<typeof klerosCoreImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'getDisputeKitsLength',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"getNumberOfRounds"`.
 *
 *
 */
export function useKlerosCoreImplementationGetNumberOfRounds<
  TFunctionName extends 'getNumberOfRounds',
  TSelectData = ReadContractResult<typeof klerosCoreImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'getNumberOfRounds',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"getNumberOfVotes"`.
 *
 *
 */
export function useKlerosCoreImplementationGetNumberOfVotes<
  TFunctionName extends 'getNumberOfVotes',
  TSelectData = ReadContractResult<typeof klerosCoreImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'getNumberOfVotes',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"getRoundInfo"`.
 *
 *
 */
export function useKlerosCoreImplementationGetRoundInfo<
  TFunctionName extends 'getRoundInfo',
  TSelectData = ReadContractResult<typeof klerosCoreImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'getRoundInfo',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"getTimesPerPeriod"`.
 *
 *
 */
export function useKlerosCoreImplementationGetTimesPerPeriod<
  TFunctionName extends 'getTimesPerPeriod',
  TSelectData = ReadContractResult<typeof klerosCoreImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'getTimesPerPeriod',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"governor"`.
 *
 *
 */
export function useKlerosCoreImplementationGovernor<
  TFunctionName extends 'governor',
  TSelectData = ReadContractResult<typeof klerosCoreImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'governor',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"isDisputeKitJumping"`.
 *
 *
 */
export function useKlerosCoreImplementationIsDisputeKitJumping<
  TFunctionName extends 'isDisputeKitJumping',
  TSelectData = ReadContractResult<typeof klerosCoreImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'isDisputeKitJumping',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"isSupported"`.
 *
 *
 */
export function useKlerosCoreImplementationIsSupported<
  TFunctionName extends 'isSupported',
  TSelectData = ReadContractResult<typeof klerosCoreImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'isSupported',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"jurorProsecutionModule"`.
 *
 *
 */
export function useKlerosCoreImplementationJurorProsecutionModule<
  TFunctionName extends 'jurorProsecutionModule',
  TSelectData = ReadContractResult<typeof klerosCoreImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'jurorProsecutionModule',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"pinakion"`.
 *
 *
 */
export function useKlerosCoreImplementationPinakion<
  TFunctionName extends 'pinakion',
  TSelectData = ReadContractResult<typeof klerosCoreImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'pinakion',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"proxiableUUID"`.
 *
 *
 */
export function useKlerosCoreImplementationProxiableUuid<
  TFunctionName extends 'proxiableUUID',
  TSelectData = ReadContractResult<typeof klerosCoreImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'proxiableUUID',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"sortitionModule"`.
 *
 *
 */
export function useKlerosCoreImplementationSortitionModule<
  TFunctionName extends 'sortitionModule',
  TSelectData = ReadContractResult<typeof klerosCoreImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'sortitionModule',
    ...config,
  } as UseContractReadConfig<typeof klerosCoreImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__.
 *
 *
 */
export function useKlerosCoreImplementationWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreImplementationABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof klerosCoreImplementationABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreImplementationABI, TFunctionName, TMode>({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"addNewDisputeKit"`.
 *
 *
 */
export function useKlerosCoreImplementationAddNewDisputeKit<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreImplementationABI, 'addNewDisputeKit'>['request']['abi'],
        'addNewDisputeKit',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'addNewDisputeKit' }
    : UseContractWriteConfig<typeof klerosCoreImplementationABI, 'addNewDisputeKit', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'addNewDisputeKit'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreImplementationABI, 'addNewDisputeKit', TMode>({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'addNewDisputeKit',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"appeal"`.
 *
 *
 */
export function useKlerosCoreImplementationAppeal<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreImplementationABI, 'appeal'>['request']['abi'],
        'appeal',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'appeal' }
    : UseContractWriteConfig<typeof klerosCoreImplementationABI, 'appeal', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'appeal'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreImplementationABI, 'appeal', TMode>({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'appeal',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"changeAcceptedFeeTokens"`.
 *
 *
 */
export function useKlerosCoreImplementationChangeAcceptedFeeTokens<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreImplementationABI, 'changeAcceptedFeeTokens'>['request']['abi'],
        'changeAcceptedFeeTokens',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeAcceptedFeeTokens' }
    : UseContractWriteConfig<typeof klerosCoreImplementationABI, 'changeAcceptedFeeTokens', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeAcceptedFeeTokens'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreImplementationABI, 'changeAcceptedFeeTokens', TMode>({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'changeAcceptedFeeTokens',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"changeCourtParameters"`.
 *
 *
 */
export function useKlerosCoreImplementationChangeCourtParameters<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreImplementationABI, 'changeCourtParameters'>['request']['abi'],
        'changeCourtParameters',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeCourtParameters' }
    : UseContractWriteConfig<typeof klerosCoreImplementationABI, 'changeCourtParameters', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeCourtParameters'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreImplementationABI, 'changeCourtParameters', TMode>({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'changeCourtParameters',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"changeCurrencyRates"`.
 *
 *
 */
export function useKlerosCoreImplementationChangeCurrencyRates<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreImplementationABI, 'changeCurrencyRates'>['request']['abi'],
        'changeCurrencyRates',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeCurrencyRates' }
    : UseContractWriteConfig<typeof klerosCoreImplementationABI, 'changeCurrencyRates', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeCurrencyRates'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreImplementationABI, 'changeCurrencyRates', TMode>({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'changeCurrencyRates',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function useKlerosCoreImplementationChangeGovernor<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreImplementationABI, 'changeGovernor'>['request']['abi'],
        'changeGovernor',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeGovernor' }
    : UseContractWriteConfig<typeof klerosCoreImplementationABI, 'changeGovernor', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeGovernor'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreImplementationABI, 'changeGovernor', TMode>({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"changeJurorProsecutionModule"`.
 *
 *
 */
export function useKlerosCoreImplementationChangeJurorProsecutionModule<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof klerosCoreImplementationABI,
          'changeJurorProsecutionModule'
        >['request']['abi'],
        'changeJurorProsecutionModule',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeJurorProsecutionModule' }
    : UseContractWriteConfig<typeof klerosCoreImplementationABI, 'changeJurorProsecutionModule', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeJurorProsecutionModule'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreImplementationABI, 'changeJurorProsecutionModule', TMode>({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'changeJurorProsecutionModule',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"changePinakion"`.
 *
 *
 */
export function useKlerosCoreImplementationChangePinakion<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreImplementationABI, 'changePinakion'>['request']['abi'],
        'changePinakion',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changePinakion' }
    : UseContractWriteConfig<typeof klerosCoreImplementationABI, 'changePinakion', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changePinakion'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreImplementationABI, 'changePinakion', TMode>({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'changePinakion',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"changeSortitionModule"`.
 *
 *
 */
export function useKlerosCoreImplementationChangeSortitionModule<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreImplementationABI, 'changeSortitionModule'>['request']['abi'],
        'changeSortitionModule',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeSortitionModule' }
    : UseContractWriteConfig<typeof klerosCoreImplementationABI, 'changeSortitionModule', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeSortitionModule'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreImplementationABI, 'changeSortitionModule', TMode>({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'changeSortitionModule',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"createCourt"`.
 *
 *
 */
export function useKlerosCoreImplementationCreateCourt<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreImplementationABI, 'createCourt'>['request']['abi'],
        'createCourt',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'createCourt' }
    : UseContractWriteConfig<typeof klerosCoreImplementationABI, 'createCourt', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'createCourt'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreImplementationABI, 'createCourt', TMode>({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'createCourt',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"createDispute"`.
 *
 *
 */
export function useKlerosCoreImplementationCreateDispute<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreImplementationABI, 'createDispute'>['request']['abi'],
        'createDispute',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'createDispute' }
    : UseContractWriteConfig<typeof klerosCoreImplementationABI, 'createDispute', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'createDispute'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreImplementationABI, 'createDispute', TMode>({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'createDispute',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"draw"`.
 *
 *
 */
export function useKlerosCoreImplementationDraw<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreImplementationABI, 'draw'>['request']['abi'],
        'draw',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'draw' }
    : UseContractWriteConfig<typeof klerosCoreImplementationABI, 'draw', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'draw'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreImplementationABI, 'draw', TMode>({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'draw',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"enableDisputeKits"`.
 *
 *
 */
export function useKlerosCoreImplementationEnableDisputeKits<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreImplementationABI, 'enableDisputeKits'>['request']['abi'],
        'enableDisputeKits',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'enableDisputeKits' }
    : UseContractWriteConfig<typeof klerosCoreImplementationABI, 'enableDisputeKits', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'enableDisputeKits'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreImplementationABI, 'enableDisputeKits', TMode>({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'enableDisputeKits',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"execute"`.
 *
 *
 */
export function useKlerosCoreImplementationExecute<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreImplementationABI, 'execute'>['request']['abi'],
        'execute',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'execute' }
    : UseContractWriteConfig<typeof klerosCoreImplementationABI, 'execute', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'execute'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreImplementationABI, 'execute', TMode>({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'execute',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"executeGovernorProposal"`.
 *
 *
 */
export function useKlerosCoreImplementationExecuteGovernorProposal<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreImplementationABI, 'executeGovernorProposal'>['request']['abi'],
        'executeGovernorProposal',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'executeGovernorProposal' }
    : UseContractWriteConfig<typeof klerosCoreImplementationABI, 'executeGovernorProposal', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'executeGovernorProposal'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreImplementationABI, 'executeGovernorProposal', TMode>({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'executeGovernorProposal',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"executeRuling"`.
 *
 *
 */
export function useKlerosCoreImplementationExecuteRuling<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreImplementationABI, 'executeRuling'>['request']['abi'],
        'executeRuling',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'executeRuling' }
    : UseContractWriteConfig<typeof klerosCoreImplementationABI, 'executeRuling', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'executeRuling'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreImplementationABI, 'executeRuling', TMode>({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'executeRuling',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function useKlerosCoreImplementationInitialize<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreImplementationABI, 'initialize'>['request']['abi'],
        'initialize',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'initialize' }
    : UseContractWriteConfig<typeof klerosCoreImplementationABI, 'initialize', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'initialize'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreImplementationABI, 'initialize', TMode>({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'initialize',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"passPeriod"`.
 *
 *
 */
export function useKlerosCoreImplementationPassPeriod<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreImplementationABI, 'passPeriod'>['request']['abi'],
        'passPeriod',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'passPeriod' }
    : UseContractWriteConfig<typeof klerosCoreImplementationABI, 'passPeriod', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'passPeriod'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreImplementationABI, 'passPeriod', TMode>({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'passPeriod',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"setStake"`.
 *
 *
 */
export function useKlerosCoreImplementationSetStake<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreImplementationABI, 'setStake'>['request']['abi'],
        'setStake',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setStake' }
    : UseContractWriteConfig<typeof klerosCoreImplementationABI, 'setStake', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setStake'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreImplementationABI, 'setStake', TMode>({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'setStake',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"setStakeBySortitionModule"`.
 *
 *
 */
export function useKlerosCoreImplementationSetStakeBySortitionModule<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreImplementationABI, 'setStakeBySortitionModule'>['request']['abi'],
        'setStakeBySortitionModule',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setStakeBySortitionModule' }
    : UseContractWriteConfig<typeof klerosCoreImplementationABI, 'setStakeBySortitionModule', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setStakeBySortitionModule'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreImplementationABI, 'setStakeBySortitionModule', TMode>({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'setStakeBySortitionModule',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function useKlerosCoreImplementationUpgradeToAndCall<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof klerosCoreImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof klerosCoreImplementationABI, 'upgradeToAndCall'>['request']['abi'],
        'upgradeToAndCall',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'upgradeToAndCall' }
    : UseContractWriteConfig<typeof klerosCoreImplementationABI, 'upgradeToAndCall', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'upgradeToAndCall'
      } = {} as any
) {
  return useContractWrite<typeof klerosCoreImplementationABI, 'upgradeToAndCall', TMode>({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__.
 *
 *
 */
export function usePrepareKlerosCoreImplementationWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof klerosCoreImplementationAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"addNewDisputeKit"`.
 *
 *
 */
export function usePrepareKlerosCoreImplementationAddNewDisputeKit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'addNewDisputeKit'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'addNewDisputeKit',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'addNewDisputeKit'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"appeal"`.
 *
 *
 */
export function usePrepareKlerosCoreImplementationAppeal(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'appeal'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'appeal',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'appeal'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"changeAcceptedFeeTokens"`.
 *
 *
 */
export function usePrepareKlerosCoreImplementationChangeAcceptedFeeTokens(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'changeAcceptedFeeTokens'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'changeAcceptedFeeTokens',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'changeAcceptedFeeTokens'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"changeCourtParameters"`.
 *
 *
 */
export function usePrepareKlerosCoreImplementationChangeCourtParameters(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'changeCourtParameters'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'changeCourtParameters',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'changeCourtParameters'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"changeCurrencyRates"`.
 *
 *
 */
export function usePrepareKlerosCoreImplementationChangeCurrencyRates(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'changeCurrencyRates'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'changeCurrencyRates',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'changeCurrencyRates'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function usePrepareKlerosCoreImplementationChangeGovernor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'changeGovernor'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'changeGovernor'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"changeJurorProsecutionModule"`.
 *
 *
 */
export function usePrepareKlerosCoreImplementationChangeJurorProsecutionModule(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'changeJurorProsecutionModule'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'changeJurorProsecutionModule',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'changeJurorProsecutionModule'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"changePinakion"`.
 *
 *
 */
export function usePrepareKlerosCoreImplementationChangePinakion(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'changePinakion'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'changePinakion',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'changePinakion'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"changeSortitionModule"`.
 *
 *
 */
export function usePrepareKlerosCoreImplementationChangeSortitionModule(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'changeSortitionModule'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'changeSortitionModule',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'changeSortitionModule'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"createCourt"`.
 *
 *
 */
export function usePrepareKlerosCoreImplementationCreateCourt(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'createCourt'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'createCourt',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'createCourt'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"createDispute"`.
 *
 *
 */
export function usePrepareKlerosCoreImplementationCreateDispute(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'createDispute'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'createDispute',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'createDispute'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"draw"`.
 *
 *
 */
export function usePrepareKlerosCoreImplementationDraw(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'draw'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'draw',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'draw'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"enableDisputeKits"`.
 *
 *
 */
export function usePrepareKlerosCoreImplementationEnableDisputeKits(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'enableDisputeKits'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'enableDisputeKits',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'enableDisputeKits'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"execute"`.
 *
 *
 */
export function usePrepareKlerosCoreImplementationExecute(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'execute'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'execute',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'execute'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"executeGovernorProposal"`.
 *
 *
 */
export function usePrepareKlerosCoreImplementationExecuteGovernorProposal(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'executeGovernorProposal'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'executeGovernorProposal',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'executeGovernorProposal'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"executeRuling"`.
 *
 *
 */
export function usePrepareKlerosCoreImplementationExecuteRuling(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'executeRuling'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'executeRuling',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'executeRuling'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function usePrepareKlerosCoreImplementationInitialize(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'initialize'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'initialize',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'initialize'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"passPeriod"`.
 *
 *
 */
export function usePrepareKlerosCoreImplementationPassPeriod(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'passPeriod'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'passPeriod',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'passPeriod'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"setStake"`.
 *
 *
 */
export function usePrepareKlerosCoreImplementationSetStake(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'setStake'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'setStake',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'setStake'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"setStakeBySortitionModule"`.
 *
 *
 */
export function usePrepareKlerosCoreImplementationSetStakeBySortitionModule(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'setStakeBySortitionModule'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'setStakeBySortitionModule',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'setStakeBySortitionModule'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function usePrepareKlerosCoreImplementationUpgradeToAndCall(
  config: Omit<
    UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'upgradeToAndCall'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as UsePrepareContractWriteConfig<typeof klerosCoreImplementationABI, 'upgradeToAndCall'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreImplementationABI}__.
 *
 *
 */
export function useKlerosCoreImplementationEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof klerosCoreImplementationABI, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof klerosCoreImplementationAddress
  } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    ...config,
  } as UseContractEventConfig<typeof klerosCoreImplementationABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `eventName` set to `"AcceptedFeeToken"`.
 *
 *
 */
export function useKlerosCoreImplementationAcceptedFeeTokenEvent(
  config: Omit<
    UseContractEventConfig<typeof klerosCoreImplementationABI, 'AcceptedFeeToken'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    eventName: 'AcceptedFeeToken',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreImplementationABI, 'AcceptedFeeToken'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `eventName` set to `"AppealDecision"`.
 *
 *
 */
export function useKlerosCoreImplementationAppealDecisionEvent(
  config: Omit<
    UseContractEventConfig<typeof klerosCoreImplementationABI, 'AppealDecision'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    eventName: 'AppealDecision',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreImplementationABI, 'AppealDecision'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `eventName` set to `"AppealPossible"`.
 *
 *
 */
export function useKlerosCoreImplementationAppealPossibleEvent(
  config: Omit<
    UseContractEventConfig<typeof klerosCoreImplementationABI, 'AppealPossible'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    eventName: 'AppealPossible',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreImplementationABI, 'AppealPossible'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `eventName` set to `"CourtCreated"`.
 *
 *
 */
export function useKlerosCoreImplementationCourtCreatedEvent(
  config: Omit<
    UseContractEventConfig<typeof klerosCoreImplementationABI, 'CourtCreated'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    eventName: 'CourtCreated',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreImplementationABI, 'CourtCreated'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `eventName` set to `"CourtJump"`.
 *
 *
 */
export function useKlerosCoreImplementationCourtJumpEvent(
  config: Omit<
    UseContractEventConfig<typeof klerosCoreImplementationABI, 'CourtJump'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    eventName: 'CourtJump',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreImplementationABI, 'CourtJump'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `eventName` set to `"CourtModified"`.
 *
 *
 */
export function useKlerosCoreImplementationCourtModifiedEvent(
  config: Omit<
    UseContractEventConfig<typeof klerosCoreImplementationABI, 'CourtModified'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    eventName: 'CourtModified',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreImplementationABI, 'CourtModified'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `eventName` set to `"DisputeCreation"`.
 *
 *
 */
export function useKlerosCoreImplementationDisputeCreationEvent(
  config: Omit<
    UseContractEventConfig<typeof klerosCoreImplementationABI, 'DisputeCreation'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    eventName: 'DisputeCreation',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreImplementationABI, 'DisputeCreation'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `eventName` set to `"DisputeKitCreated"`.
 *
 *
 */
export function useKlerosCoreImplementationDisputeKitCreatedEvent(
  config: Omit<
    UseContractEventConfig<typeof klerosCoreImplementationABI, 'DisputeKitCreated'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    eventName: 'DisputeKitCreated',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreImplementationABI, 'DisputeKitCreated'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `eventName` set to `"DisputeKitEnabled"`.
 *
 *
 */
export function useKlerosCoreImplementationDisputeKitEnabledEvent(
  config: Omit<
    UseContractEventConfig<typeof klerosCoreImplementationABI, 'DisputeKitEnabled'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    eventName: 'DisputeKitEnabled',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreImplementationABI, 'DisputeKitEnabled'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `eventName` set to `"DisputeKitJump"`.
 *
 *
 */
export function useKlerosCoreImplementationDisputeKitJumpEvent(
  config: Omit<
    UseContractEventConfig<typeof klerosCoreImplementationABI, 'DisputeKitJump'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    eventName: 'DisputeKitJump',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreImplementationABI, 'DisputeKitJump'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `eventName` set to `"Draw"`.
 *
 *
 */
export function useKlerosCoreImplementationDrawEvent(
  config: Omit<UseContractEventConfig<typeof klerosCoreImplementationABI, 'Draw'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof klerosCoreImplementationAddress
  } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    eventName: 'Draw',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreImplementationABI, 'Draw'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `eventName` set to `"Initialized"`.
 *
 *
 */
export function useKlerosCoreImplementationInitializedEvent(
  config: Omit<
    UseContractEventConfig<typeof klerosCoreImplementationABI, 'Initialized'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    eventName: 'Initialized',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreImplementationABI, 'Initialized'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `eventName` set to `"LeftoverRewardSent"`.
 *
 *
 */
export function useKlerosCoreImplementationLeftoverRewardSentEvent(
  config: Omit<
    UseContractEventConfig<typeof klerosCoreImplementationABI, 'LeftoverRewardSent'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    eventName: 'LeftoverRewardSent',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreImplementationABI, 'LeftoverRewardSent'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `eventName` set to `"NewCurrencyRate"`.
 *
 *
 */
export function useKlerosCoreImplementationNewCurrencyRateEvent(
  config: Omit<
    UseContractEventConfig<typeof klerosCoreImplementationABI, 'NewCurrencyRate'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    eventName: 'NewCurrencyRate',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreImplementationABI, 'NewCurrencyRate'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `eventName` set to `"NewPeriod"`.
 *
 *
 */
export function useKlerosCoreImplementationNewPeriodEvent(
  config: Omit<
    UseContractEventConfig<typeof klerosCoreImplementationABI, 'NewPeriod'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    eventName: 'NewPeriod',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreImplementationABI, 'NewPeriod'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `eventName` set to `"Ruling"`.
 *
 *
 */
export function useKlerosCoreImplementationRulingEvent(
  config: Omit<
    UseContractEventConfig<typeof klerosCoreImplementationABI, 'Ruling'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    eventName: 'Ruling',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreImplementationABI, 'Ruling'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `eventName` set to `"TokenAndETHShift"`.
 *
 *
 */
export function useKlerosCoreImplementationTokenAndEthShiftEvent(
  config: Omit<
    UseContractEventConfig<typeof klerosCoreImplementationABI, 'TokenAndETHShift'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    eventName: 'TokenAndETHShift',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreImplementationABI, 'TokenAndETHShift'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link klerosCoreImplementationABI}__ and `eventName` set to `"Upgraded"`.
 *
 *
 */
export function useKlerosCoreImplementationUpgradedEvent(
  config: Omit<
    UseContractEventConfig<typeof klerosCoreImplementationABI, 'Upgraded'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof klerosCoreImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    eventName: 'Upgraded',
    ...config,
  } as UseContractEventConfig<typeof klerosCoreImplementationABI, 'Upgraded'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pnkABI}__.
 *
 *
 */
export function usePnkRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof pnkABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof pnkABI, TFunctionName, TSelectData>, 'abi' | 'address'> & {
    chainId?: keyof typeof pnkAddress
  } = {} as any
) {
  return useContractRead({ abi: pnkABI, address: pnkAddress[421614], ...config } as UseContractReadConfig<
    typeof pnkABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pnkABI}__ and `functionName` set to `"allowance"`.
 *
 *
 */
export function usePnkAllowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof pnkABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof pnkABI, TFunctionName, TSelectData>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof pnkAddress
  } = {} as any
) {
  return useContractRead({
    abi: pnkABI,
    address: pnkAddress[421614],
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<typeof pnkABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pnkABI}__ and `functionName` set to `"balanceOf"`.
 *
 *
 */
export function usePnkBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof pnkABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof pnkABI, TFunctionName, TSelectData>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof pnkAddress
  } = {} as any
) {
  return useContractRead({
    abi: pnkABI,
    address: pnkAddress[421614],
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof pnkABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pnkABI}__ and `functionName` set to `"decimals"`.
 *
 *
 */
export function usePnkDecimals<
  TFunctionName extends 'decimals',
  TSelectData = ReadContractResult<typeof pnkABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof pnkABI, TFunctionName, TSelectData>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof pnkAddress
  } = {} as any
) {
  return useContractRead({
    abi: pnkABI,
    address: pnkAddress[421614],
    functionName: 'decimals',
    ...config,
  } as UseContractReadConfig<typeof pnkABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pnkABI}__ and `functionName` set to `"name"`.
 *
 *
 */
export function usePnkName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof pnkABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof pnkABI, TFunctionName, TSelectData>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof pnkAddress
  } = {} as any
) {
  return useContractRead({
    abi: pnkABI,
    address: pnkAddress[421614],
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof pnkABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pnkABI}__ and `functionName` set to `"symbol"`.
 *
 *
 */
export function usePnkSymbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof pnkABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof pnkABI, TFunctionName, TSelectData>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof pnkAddress
  } = {} as any
) {
  return useContractRead({
    abi: pnkABI,
    address: pnkAddress[421614],
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<typeof pnkABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pnkABI}__ and `functionName` set to `"totalSupply"`.
 *
 *
 */
export function usePnkTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof pnkABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof pnkABI, TFunctionName, TSelectData>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof pnkAddress
  } = {} as any
) {
  return useContractRead({
    abi: pnkABI,
    address: pnkAddress[421614],
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof pnkABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pnkABI}__.
 *
 *
 */
export function usePnkWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof pnkAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof pnkABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof pnkABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof pnkABI, TFunctionName, TMode>({
    abi: pnkABI,
    address: pnkAddress[421614],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pnkABI}__ and `functionName` set to `"approve"`.
 *
 *
 */
export function usePnkApprove<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof pnkAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof pnkABI, 'approve'>['request']['abi'],
        'approve',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'approve' }
    : UseContractWriteConfig<typeof pnkABI, 'approve', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'approve'
      } = {} as any
) {
  return useContractWrite<typeof pnkABI, 'approve', TMode>({
    abi: pnkABI,
    address: pnkAddress[421614],
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pnkABI}__ and `functionName` set to `"decreaseAllowance"`.
 *
 *
 */
export function usePnkDecreaseAllowance<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof pnkAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof pnkABI, 'decreaseAllowance'>['request']['abi'],
        'decreaseAllowance',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'decreaseAllowance' }
    : UseContractWriteConfig<typeof pnkABI, 'decreaseAllowance', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'decreaseAllowance'
      } = {} as any
) {
  return useContractWrite<typeof pnkABI, 'decreaseAllowance', TMode>({
    abi: pnkABI,
    address: pnkAddress[421614],
    functionName: 'decreaseAllowance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pnkABI}__ and `functionName` set to `"increaseAllowance"`.
 *
 *
 */
export function usePnkIncreaseAllowance<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof pnkAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof pnkABI, 'increaseAllowance'>['request']['abi'],
        'increaseAllowance',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'increaseAllowance' }
    : UseContractWriteConfig<typeof pnkABI, 'increaseAllowance', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'increaseAllowance'
      } = {} as any
) {
  return useContractWrite<typeof pnkABI, 'increaseAllowance', TMode>({
    abi: pnkABI,
    address: pnkAddress[421614],
    functionName: 'increaseAllowance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pnkABI}__ and `functionName` set to `"transfer"`.
 *
 *
 */
export function usePnkTransfer<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof pnkAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof pnkABI, 'transfer'>['request']['abi'],
        'transfer',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'transfer' }
    : UseContractWriteConfig<typeof pnkABI, 'transfer', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'transfer'
      } = {} as any
) {
  return useContractWrite<typeof pnkABI, 'transfer', TMode>({
    abi: pnkABI,
    address: pnkAddress[421614],
    functionName: 'transfer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pnkABI}__ and `functionName` set to `"transferFrom"`.
 *
 *
 */
export function usePnkTransferFrom<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof pnkAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof pnkABI, 'transferFrom'>['request']['abi'],
        'transferFrom',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'transferFrom' }
    : UseContractWriteConfig<typeof pnkABI, 'transferFrom', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'transferFrom'
      } = {} as any
) {
  return useContractWrite<typeof pnkABI, 'transferFrom', TMode>({
    abi: pnkABI,
    address: pnkAddress[421614],
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link pnkABI}__.
 *
 *
 */
export function usePreparePnkWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof pnkABI, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof pnkAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: pnkABI,
    address: pnkAddress[421614],
    ...config,
  } as UsePrepareContractWriteConfig<typeof pnkABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link pnkABI}__ and `functionName` set to `"approve"`.
 *
 *
 */
export function usePreparePnkApprove(
  config: Omit<UsePrepareContractWriteConfig<typeof pnkABI, 'approve'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof pnkAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: pnkABI,
    address: pnkAddress[421614],
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof pnkABI, 'approve'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link pnkABI}__ and `functionName` set to `"decreaseAllowance"`.
 *
 *
 */
export function usePreparePnkDecreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof pnkABI, 'decreaseAllowance'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof pnkAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: pnkABI,
    address: pnkAddress[421614],
    functionName: 'decreaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof pnkABI, 'decreaseAllowance'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link pnkABI}__ and `functionName` set to `"increaseAllowance"`.
 *
 *
 */
export function usePreparePnkIncreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof pnkABI, 'increaseAllowance'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof pnkAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: pnkABI,
    address: pnkAddress[421614],
    functionName: 'increaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof pnkABI, 'increaseAllowance'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link pnkABI}__ and `functionName` set to `"transfer"`.
 *
 *
 */
export function usePreparePnkTransfer(
  config: Omit<UsePrepareContractWriteConfig<typeof pnkABI, 'transfer'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof pnkAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: pnkABI,
    address: pnkAddress[421614],
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof pnkABI, 'transfer'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link pnkABI}__ and `functionName` set to `"transferFrom"`.
 *
 *
 */
export function usePreparePnkTransferFrom(
  config: Omit<UsePrepareContractWriteConfig<typeof pnkABI, 'transferFrom'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof pnkAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: pnkABI,
    address: pnkAddress[421614],
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof pnkABI, 'transferFrom'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link pnkABI}__.
 *
 *
 */
export function usePnkEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof pnkABI, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof pnkAddress
  } = {} as any
) {
  return useContractEvent({ abi: pnkABI, address: pnkAddress[421614], ...config } as UseContractEventConfig<
    typeof pnkABI,
    TEventName
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link pnkABI}__ and `eventName` set to `"Approval"`.
 *
 *
 */
export function usePnkApprovalEvent(
  config: Omit<UseContractEventConfig<typeof pnkABI, 'Approval'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof pnkAddress
  } = {} as any
) {
  return useContractEvent({
    abi: pnkABI,
    address: pnkAddress[421614],
    eventName: 'Approval',
    ...config,
  } as UseContractEventConfig<typeof pnkABI, 'Approval'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link pnkABI}__ and `eventName` set to `"Transfer"`.
 *
 *
 */
export function usePnkTransferEvent(
  config: Omit<UseContractEventConfig<typeof pnkABI, 'Transfer'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof pnkAddress
  } = {} as any
) {
  return useContractEvent({
    abi: pnkABI,
    address: pnkAddress[421614],
    eventName: 'Transfer',
    ...config,
  } as UseContractEventConfig<typeof pnkABI, 'Transfer'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pnkFaucetABI}__.
 *
 *
 */
export function usePnkFaucetRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof pnkFaucetABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof pnkFaucetABI, TFunctionName, TSelectData>, 'abi' | 'address'> & {
    chainId?: keyof typeof pnkFaucetAddress
  } = {} as any
) {
  return useContractRead({ abi: pnkFaucetABI, address: pnkFaucetAddress[421614], ...config } as UseContractReadConfig<
    typeof pnkFaucetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pnkFaucetABI}__ and `functionName` set to `"amount"`.
 *
 *
 */
export function usePnkFaucetAmount<
  TFunctionName extends 'amount',
  TSelectData = ReadContractResult<typeof pnkFaucetABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof pnkFaucetABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof pnkFaucetAddress } = {} as any
) {
  return useContractRead({
    abi: pnkFaucetABI,
    address: pnkFaucetAddress[421614],
    functionName: 'amount',
    ...config,
  } as UseContractReadConfig<typeof pnkFaucetABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pnkFaucetABI}__ and `functionName` set to `"balance"`.
 *
 *
 */
export function usePnkFaucetBalance<
  TFunctionName extends 'balance',
  TSelectData = ReadContractResult<typeof pnkFaucetABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof pnkFaucetABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof pnkFaucetAddress } = {} as any
) {
  return useContractRead({
    abi: pnkFaucetABI,
    address: pnkFaucetAddress[421614],
    functionName: 'balance',
    ...config,
  } as UseContractReadConfig<typeof pnkFaucetABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pnkFaucetABI}__ and `functionName` set to `"governor"`.
 *
 *
 */
export function usePnkFaucetGovernor<
  TFunctionName extends 'governor',
  TSelectData = ReadContractResult<typeof pnkFaucetABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof pnkFaucetABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof pnkFaucetAddress } = {} as any
) {
  return useContractRead({
    abi: pnkFaucetABI,
    address: pnkFaucetAddress[421614],
    functionName: 'governor',
    ...config,
  } as UseContractReadConfig<typeof pnkFaucetABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pnkFaucetABI}__ and `functionName` set to `"token"`.
 *
 *
 */
export function usePnkFaucetToken<
  TFunctionName extends 'token',
  TSelectData = ReadContractResult<typeof pnkFaucetABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof pnkFaucetABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof pnkFaucetAddress } = {} as any
) {
  return useContractRead({
    abi: pnkFaucetABI,
    address: pnkFaucetAddress[421614],
    functionName: 'token',
    ...config,
  } as UseContractReadConfig<typeof pnkFaucetABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pnkFaucetABI}__ and `functionName` set to `"withdrewAlready"`.
 *
 *
 */
export function usePnkFaucetWithdrewAlready<
  TFunctionName extends 'withdrewAlready',
  TSelectData = ReadContractResult<typeof pnkFaucetABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof pnkFaucetABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof pnkFaucetAddress } = {} as any
) {
  return useContractRead({
    abi: pnkFaucetABI,
    address: pnkFaucetAddress[421614],
    functionName: 'withdrewAlready',
    ...config,
  } as UseContractReadConfig<typeof pnkFaucetABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pnkFaucetABI}__.
 *
 *
 */
export function usePnkFaucetWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof pnkFaucetAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof pnkFaucetABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof pnkFaucetABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof pnkFaucetABI, TFunctionName, TMode>({
    abi: pnkFaucetABI,
    address: pnkFaucetAddress[421614],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pnkFaucetABI}__ and `functionName` set to `"changeAmount"`.
 *
 *
 */
export function usePnkFaucetChangeAmount<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof pnkFaucetAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof pnkFaucetABI, 'changeAmount'>['request']['abi'],
        'changeAmount',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeAmount' }
    : UseContractWriteConfig<typeof pnkFaucetABI, 'changeAmount', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeAmount'
      } = {} as any
) {
  return useContractWrite<typeof pnkFaucetABI, 'changeAmount', TMode>({
    abi: pnkFaucetABI,
    address: pnkFaucetAddress[421614],
    functionName: 'changeAmount',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pnkFaucetABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function usePnkFaucetChangeGovernor<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof pnkFaucetAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof pnkFaucetABI, 'changeGovernor'>['request']['abi'],
        'changeGovernor',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeGovernor' }
    : UseContractWriteConfig<typeof pnkFaucetABI, 'changeGovernor', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeGovernor'
      } = {} as any
) {
  return useContractWrite<typeof pnkFaucetABI, 'changeGovernor', TMode>({
    abi: pnkFaucetABI,
    address: pnkFaucetAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pnkFaucetABI}__ and `functionName` set to `"request"`.
 *
 *
 */
export function usePnkFaucetRequest<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof pnkFaucetAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof pnkFaucetABI, 'request'>['request']['abi'],
        'request',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'request' }
    : UseContractWriteConfig<typeof pnkFaucetABI, 'request', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'request'
      } = {} as any
) {
  return useContractWrite<typeof pnkFaucetABI, 'request', TMode>({
    abi: pnkFaucetABI,
    address: pnkFaucetAddress[421614],
    functionName: 'request',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pnkFaucetABI}__ and `functionName` set to `"withdraw"`.
 *
 *
 */
export function usePnkFaucetWithdraw<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof pnkFaucetAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof pnkFaucetABI, 'withdraw'>['request']['abi'],
        'withdraw',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'withdraw' }
    : UseContractWriteConfig<typeof pnkFaucetABI, 'withdraw', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'withdraw'
      } = {} as any
) {
  return useContractWrite<typeof pnkFaucetABI, 'withdraw', TMode>({
    abi: pnkFaucetABI,
    address: pnkFaucetAddress[421614],
    functionName: 'withdraw',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link pnkFaucetABI}__.
 *
 *
 */
export function usePreparePnkFaucetWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof pnkFaucetABI, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof pnkFaucetAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: pnkFaucetABI,
    address: pnkFaucetAddress[421614],
    ...config,
  } as UsePrepareContractWriteConfig<typeof pnkFaucetABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link pnkFaucetABI}__ and `functionName` set to `"changeAmount"`.
 *
 *
 */
export function usePreparePnkFaucetChangeAmount(
  config: Omit<
    UsePrepareContractWriteConfig<typeof pnkFaucetABI, 'changeAmount'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof pnkFaucetAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: pnkFaucetABI,
    address: pnkFaucetAddress[421614],
    functionName: 'changeAmount',
    ...config,
  } as UsePrepareContractWriteConfig<typeof pnkFaucetABI, 'changeAmount'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link pnkFaucetABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function usePreparePnkFaucetChangeGovernor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof pnkFaucetABI, 'changeGovernor'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof pnkFaucetAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: pnkFaucetABI,
    address: pnkFaucetAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as UsePrepareContractWriteConfig<typeof pnkFaucetABI, 'changeGovernor'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link pnkFaucetABI}__ and `functionName` set to `"request"`.
 *
 *
 */
export function usePreparePnkFaucetRequest(
  config: Omit<UsePrepareContractWriteConfig<typeof pnkFaucetABI, 'request'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof pnkFaucetAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: pnkFaucetABI,
    address: pnkFaucetAddress[421614],
    functionName: 'request',
    ...config,
  } as UsePrepareContractWriteConfig<typeof pnkFaucetABI, 'request'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link pnkFaucetABI}__ and `functionName` set to `"withdraw"`.
 *
 *
 */
export function usePreparePnkFaucetWithdraw(
  config: Omit<UsePrepareContractWriteConfig<typeof pnkFaucetABI, 'withdraw'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof pnkFaucetAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: pnkFaucetABI,
    address: pnkFaucetAddress[421614],
    functionName: 'withdraw',
    ...config,
  } as UsePrepareContractWriteConfig<typeof pnkFaucetABI, 'withdraw'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pinakionV2ABI}__.
 *
 *
 */
export function usePinakionV2Read<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof pinakionV2ABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof pinakionV2ABI, TFunctionName, TSelectData>, 'abi' | 'address'> & {
    chainId?: keyof typeof pinakionV2Address
  } = {} as any
) {
  return useContractRead({ abi: pinakionV2ABI, address: pinakionV2Address[421614], ...config } as UseContractReadConfig<
    typeof pinakionV2ABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pinakionV2ABI}__ and `functionName` set to `"allowance"`.
 *
 *
 */
export function usePinakionV2Allowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof pinakionV2ABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof pinakionV2ABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof pinakionV2Address } = {} as any
) {
  return useContractRead({
    abi: pinakionV2ABI,
    address: pinakionV2Address[421614],
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<typeof pinakionV2ABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pinakionV2ABI}__ and `functionName` set to `"balanceOf"`.
 *
 *
 */
export function usePinakionV2BalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof pinakionV2ABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof pinakionV2ABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof pinakionV2Address } = {} as any
) {
  return useContractRead({
    abi: pinakionV2ABI,
    address: pinakionV2Address[421614],
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof pinakionV2ABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pinakionV2ABI}__ and `functionName` set to `"decimals"`.
 *
 *
 */
export function usePinakionV2Decimals<
  TFunctionName extends 'decimals',
  TSelectData = ReadContractResult<typeof pinakionV2ABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof pinakionV2ABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof pinakionV2Address } = {} as any
) {
  return useContractRead({
    abi: pinakionV2ABI,
    address: pinakionV2Address[421614],
    functionName: 'decimals',
    ...config,
  } as UseContractReadConfig<typeof pinakionV2ABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pinakionV2ABI}__ and `functionName` set to `"name"`.
 *
 *
 */
export function usePinakionV2Name<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof pinakionV2ABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof pinakionV2ABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof pinakionV2Address } = {} as any
) {
  return useContractRead({
    abi: pinakionV2ABI,
    address: pinakionV2Address[421614],
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof pinakionV2ABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pinakionV2ABI}__ and `functionName` set to `"symbol"`.
 *
 *
 */
export function usePinakionV2Symbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof pinakionV2ABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof pinakionV2ABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof pinakionV2Address } = {} as any
) {
  return useContractRead({
    abi: pinakionV2ABI,
    address: pinakionV2Address[421614],
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<typeof pinakionV2ABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pinakionV2ABI}__ and `functionName` set to `"totalSupply"`.
 *
 *
 */
export function usePinakionV2TotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof pinakionV2ABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof pinakionV2ABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof pinakionV2Address } = {} as any
) {
  return useContractRead({
    abi: pinakionV2ABI,
    address: pinakionV2Address[421614],
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof pinakionV2ABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pinakionV2ABI}__.
 *
 *
 */
export function usePinakionV2Write<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof pinakionV2Address
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof pinakionV2ABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof pinakionV2ABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof pinakionV2ABI, TFunctionName, TMode>({
    abi: pinakionV2ABI,
    address: pinakionV2Address[421614],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pinakionV2ABI}__ and `functionName` set to `"approve"`.
 *
 *
 */
export function usePinakionV2Approve<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof pinakionV2Address
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof pinakionV2ABI, 'approve'>['request']['abi'],
        'approve',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'approve' }
    : UseContractWriteConfig<typeof pinakionV2ABI, 'approve', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'approve'
      } = {} as any
) {
  return useContractWrite<typeof pinakionV2ABI, 'approve', TMode>({
    abi: pinakionV2ABI,
    address: pinakionV2Address[421614],
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pinakionV2ABI}__ and `functionName` set to `"decreaseAllowance"`.
 *
 *
 */
export function usePinakionV2DecreaseAllowance<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof pinakionV2Address
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof pinakionV2ABI, 'decreaseAllowance'>['request']['abi'],
        'decreaseAllowance',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'decreaseAllowance' }
    : UseContractWriteConfig<typeof pinakionV2ABI, 'decreaseAllowance', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'decreaseAllowance'
      } = {} as any
) {
  return useContractWrite<typeof pinakionV2ABI, 'decreaseAllowance', TMode>({
    abi: pinakionV2ABI,
    address: pinakionV2Address[421614],
    functionName: 'decreaseAllowance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pinakionV2ABI}__ and `functionName` set to `"increaseAllowance"`.
 *
 *
 */
export function usePinakionV2IncreaseAllowance<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof pinakionV2Address
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof pinakionV2ABI, 'increaseAllowance'>['request']['abi'],
        'increaseAllowance',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'increaseAllowance' }
    : UseContractWriteConfig<typeof pinakionV2ABI, 'increaseAllowance', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'increaseAllowance'
      } = {} as any
) {
  return useContractWrite<typeof pinakionV2ABI, 'increaseAllowance', TMode>({
    abi: pinakionV2ABI,
    address: pinakionV2Address[421614],
    functionName: 'increaseAllowance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pinakionV2ABI}__ and `functionName` set to `"transfer"`.
 *
 *
 */
export function usePinakionV2Transfer<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof pinakionV2Address
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof pinakionV2ABI, 'transfer'>['request']['abi'],
        'transfer',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'transfer' }
    : UseContractWriteConfig<typeof pinakionV2ABI, 'transfer', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'transfer'
      } = {} as any
) {
  return useContractWrite<typeof pinakionV2ABI, 'transfer', TMode>({
    abi: pinakionV2ABI,
    address: pinakionV2Address[421614],
    functionName: 'transfer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pinakionV2ABI}__ and `functionName` set to `"transferFrom"`.
 *
 *
 */
export function usePinakionV2TransferFrom<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof pinakionV2Address
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof pinakionV2ABI, 'transferFrom'>['request']['abi'],
        'transferFrom',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'transferFrom' }
    : UseContractWriteConfig<typeof pinakionV2ABI, 'transferFrom', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'transferFrom'
      } = {} as any
) {
  return useContractWrite<typeof pinakionV2ABI, 'transferFrom', TMode>({
    abi: pinakionV2ABI,
    address: pinakionV2Address[421614],
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link pinakionV2ABI}__.
 *
 *
 */
export function usePreparePinakionV2Write<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof pinakionV2ABI, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof pinakionV2Address
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: pinakionV2ABI,
    address: pinakionV2Address[421614],
    ...config,
  } as UsePrepareContractWriteConfig<typeof pinakionV2ABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link pinakionV2ABI}__ and `functionName` set to `"approve"`.
 *
 *
 */
export function usePreparePinakionV2Approve(
  config: Omit<UsePrepareContractWriteConfig<typeof pinakionV2ABI, 'approve'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof pinakionV2Address
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: pinakionV2ABI,
    address: pinakionV2Address[421614],
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof pinakionV2ABI, 'approve'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link pinakionV2ABI}__ and `functionName` set to `"decreaseAllowance"`.
 *
 *
 */
export function usePreparePinakionV2DecreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof pinakionV2ABI, 'decreaseAllowance'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof pinakionV2Address } = {} as any
) {
  return usePrepareContractWrite({
    abi: pinakionV2ABI,
    address: pinakionV2Address[421614],
    functionName: 'decreaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof pinakionV2ABI, 'decreaseAllowance'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link pinakionV2ABI}__ and `functionName` set to `"increaseAllowance"`.
 *
 *
 */
export function usePreparePinakionV2IncreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof pinakionV2ABI, 'increaseAllowance'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof pinakionV2Address } = {} as any
) {
  return usePrepareContractWrite({
    abi: pinakionV2ABI,
    address: pinakionV2Address[421614],
    functionName: 'increaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof pinakionV2ABI, 'increaseAllowance'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link pinakionV2ABI}__ and `functionName` set to `"transfer"`.
 *
 *
 */
export function usePreparePinakionV2Transfer(
  config: Omit<UsePrepareContractWriteConfig<typeof pinakionV2ABI, 'transfer'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof pinakionV2Address
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: pinakionV2ABI,
    address: pinakionV2Address[421614],
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof pinakionV2ABI, 'transfer'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link pinakionV2ABI}__ and `functionName` set to `"transferFrom"`.
 *
 *
 */
export function usePreparePinakionV2TransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof pinakionV2ABI, 'transferFrom'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof pinakionV2Address } = {} as any
) {
  return usePrepareContractWrite({
    abi: pinakionV2ABI,
    address: pinakionV2Address[421614],
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof pinakionV2ABI, 'transferFrom'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link pinakionV2ABI}__.
 *
 *
 */
export function usePinakionV2Event<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof pinakionV2ABI, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof pinakionV2Address
  } = {} as any
) {
  return useContractEvent({
    abi: pinakionV2ABI,
    address: pinakionV2Address[421614],
    ...config,
  } as UseContractEventConfig<typeof pinakionV2ABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link pinakionV2ABI}__ and `eventName` set to `"Approval"`.
 *
 *
 */
export function usePinakionV2ApprovalEvent(
  config: Omit<UseContractEventConfig<typeof pinakionV2ABI, 'Approval'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof pinakionV2Address
  } = {} as any
) {
  return useContractEvent({
    abi: pinakionV2ABI,
    address: pinakionV2Address[421614],
    eventName: 'Approval',
    ...config,
  } as UseContractEventConfig<typeof pinakionV2ABI, 'Approval'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link pinakionV2ABI}__ and `eventName` set to `"Transfer"`.
 *
 *
 */
export function usePinakionV2TransferEvent(
  config: Omit<UseContractEventConfig<typeof pinakionV2ABI, 'Transfer'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof pinakionV2Address
  } = {} as any
) {
  return useContractEvent({
    abi: pinakionV2ABI,
    address: pinakionV2Address[421614],
    eventName: 'Transfer',
    ...config,
  } as UseContractEventConfig<typeof pinakionV2ABI, 'Transfer'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link policyRegistryABI}__.
 *
 *
 */
export function usePolicyRegistryRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof policyRegistryABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof policyRegistryABI, TFunctionName, TSelectData>, 'abi' | 'address'> & {
    chainId?: keyof typeof policyRegistryAddress
  } = {} as any
) {
  return useContractRead({
    abi: policyRegistryABI,
    address: policyRegistryAddress[421614],
    ...config,
  } as UseContractReadConfig<typeof policyRegistryABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link policyRegistryABI}__ and `functionName` set to `"governor"`.
 *
 *
 */
export function usePolicyRegistryGovernor<
  TFunctionName extends 'governor',
  TSelectData = ReadContractResult<typeof policyRegistryABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof policyRegistryABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof policyRegistryAddress } = {} as any
) {
  return useContractRead({
    abi: policyRegistryABI,
    address: policyRegistryAddress[421614],
    functionName: 'governor',
    ...config,
  } as UseContractReadConfig<typeof policyRegistryABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link policyRegistryABI}__ and `functionName` set to `"policies"`.
 *
 *
 */
export function usePolicyRegistryPolicies<
  TFunctionName extends 'policies',
  TSelectData = ReadContractResult<typeof policyRegistryABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof policyRegistryABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof policyRegistryAddress } = {} as any
) {
  return useContractRead({
    abi: policyRegistryABI,
    address: policyRegistryAddress[421614],
    functionName: 'policies',
    ...config,
  } as UseContractReadConfig<typeof policyRegistryABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link policyRegistryABI}__ and `functionName` set to `"proxiableUUID"`.
 *
 *
 */
export function usePolicyRegistryProxiableUuid<
  TFunctionName extends 'proxiableUUID',
  TSelectData = ReadContractResult<typeof policyRegistryABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof policyRegistryABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof policyRegistryAddress } = {} as any
) {
  return useContractRead({
    abi: policyRegistryABI,
    address: policyRegistryAddress[421614],
    functionName: 'proxiableUUID',
    ...config,
  } as UseContractReadConfig<typeof policyRegistryABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link policyRegistryABI}__.
 *
 *
 */
export function usePolicyRegistryWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof policyRegistryAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof policyRegistryABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof policyRegistryABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof policyRegistryABI, TFunctionName, TMode>({
    abi: policyRegistryABI,
    address: policyRegistryAddress[421614],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link policyRegistryABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function usePolicyRegistryChangeGovernor<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof policyRegistryAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof policyRegistryABI, 'changeGovernor'>['request']['abi'],
        'changeGovernor',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeGovernor' }
    : UseContractWriteConfig<typeof policyRegistryABI, 'changeGovernor', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeGovernor'
      } = {} as any
) {
  return useContractWrite<typeof policyRegistryABI, 'changeGovernor', TMode>({
    abi: policyRegistryABI,
    address: policyRegistryAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link policyRegistryABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function usePolicyRegistryInitialize<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof policyRegistryAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof policyRegistryABI, 'initialize'>['request']['abi'],
        'initialize',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'initialize' }
    : UseContractWriteConfig<typeof policyRegistryABI, 'initialize', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'initialize'
      } = {} as any
) {
  return useContractWrite<typeof policyRegistryABI, 'initialize', TMode>({
    abi: policyRegistryABI,
    address: policyRegistryAddress[421614],
    functionName: 'initialize',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link policyRegistryABI}__ and `functionName` set to `"setPolicy"`.
 *
 *
 */
export function usePolicyRegistrySetPolicy<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof policyRegistryAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof policyRegistryABI, 'setPolicy'>['request']['abi'],
        'setPolicy',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setPolicy' }
    : UseContractWriteConfig<typeof policyRegistryABI, 'setPolicy', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setPolicy'
      } = {} as any
) {
  return useContractWrite<typeof policyRegistryABI, 'setPolicy', TMode>({
    abi: policyRegistryABI,
    address: policyRegistryAddress[421614],
    functionName: 'setPolicy',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link policyRegistryABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function usePolicyRegistryUpgradeToAndCall<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof policyRegistryAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof policyRegistryABI, 'upgradeToAndCall'>['request']['abi'],
        'upgradeToAndCall',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'upgradeToAndCall' }
    : UseContractWriteConfig<typeof policyRegistryABI, 'upgradeToAndCall', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'upgradeToAndCall'
      } = {} as any
) {
  return useContractWrite<typeof policyRegistryABI, 'upgradeToAndCall', TMode>({
    abi: policyRegistryABI,
    address: policyRegistryAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link policyRegistryABI}__.
 *
 *
 */
export function usePreparePolicyRegistryWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof policyRegistryABI, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof policyRegistryAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: policyRegistryABI,
    address: policyRegistryAddress[421614],
    ...config,
  } as UsePrepareContractWriteConfig<typeof policyRegistryABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link policyRegistryABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function usePreparePolicyRegistryChangeGovernor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof policyRegistryABI, 'changeGovernor'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof policyRegistryAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: policyRegistryABI,
    address: policyRegistryAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as UsePrepareContractWriteConfig<typeof policyRegistryABI, 'changeGovernor'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link policyRegistryABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function usePreparePolicyRegistryInitialize(
  config: Omit<
    UsePrepareContractWriteConfig<typeof policyRegistryABI, 'initialize'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof policyRegistryAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: policyRegistryABI,
    address: policyRegistryAddress[421614],
    functionName: 'initialize',
    ...config,
  } as UsePrepareContractWriteConfig<typeof policyRegistryABI, 'initialize'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link policyRegistryABI}__ and `functionName` set to `"setPolicy"`.
 *
 *
 */
export function usePreparePolicyRegistrySetPolicy(
  config: Omit<
    UsePrepareContractWriteConfig<typeof policyRegistryABI, 'setPolicy'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof policyRegistryAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: policyRegistryABI,
    address: policyRegistryAddress[421614],
    functionName: 'setPolicy',
    ...config,
  } as UsePrepareContractWriteConfig<typeof policyRegistryABI, 'setPolicy'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link policyRegistryABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function usePreparePolicyRegistryUpgradeToAndCall(
  config: Omit<
    UsePrepareContractWriteConfig<typeof policyRegistryABI, 'upgradeToAndCall'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof policyRegistryAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: policyRegistryABI,
    address: policyRegistryAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as UsePrepareContractWriteConfig<typeof policyRegistryABI, 'upgradeToAndCall'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link policyRegistryABI}__.
 *
 *
 */
export function usePolicyRegistryEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof policyRegistryABI, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof policyRegistryAddress
  } = {} as any
) {
  return useContractEvent({
    abi: policyRegistryABI,
    address: policyRegistryAddress[421614],
    ...config,
  } as UseContractEventConfig<typeof policyRegistryABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link policyRegistryABI}__ and `eventName` set to `"Initialized"`.
 *
 *
 */
export function usePolicyRegistryInitializedEvent(
  config: Omit<UseContractEventConfig<typeof policyRegistryABI, 'Initialized'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof policyRegistryAddress
  } = {} as any
) {
  return useContractEvent({
    abi: policyRegistryABI,
    address: policyRegistryAddress[421614],
    eventName: 'Initialized',
    ...config,
  } as UseContractEventConfig<typeof policyRegistryABI, 'Initialized'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link policyRegistryABI}__ and `eventName` set to `"PolicyUpdate"`.
 *
 *
 */
export function usePolicyRegistryPolicyUpdateEvent(
  config: Omit<UseContractEventConfig<typeof policyRegistryABI, 'PolicyUpdate'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof policyRegistryAddress
  } = {} as any
) {
  return useContractEvent({
    abi: policyRegistryABI,
    address: policyRegistryAddress[421614],
    eventName: 'PolicyUpdate',
    ...config,
  } as UseContractEventConfig<typeof policyRegistryABI, 'PolicyUpdate'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link policyRegistryABI}__ and `eventName` set to `"Upgraded"`.
 *
 *
 */
export function usePolicyRegistryUpgradedEvent(
  config: Omit<UseContractEventConfig<typeof policyRegistryABI, 'Upgraded'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof policyRegistryAddress
  } = {} as any
) {
  return useContractEvent({
    abi: policyRegistryABI,
    address: policyRegistryAddress[421614],
    eventName: 'Upgraded',
    ...config,
  } as UseContractEventConfig<typeof policyRegistryABI, 'Upgraded'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link policyRegistryImplementationABI}__.
 *
 *
 */
export function usePolicyRegistryImplementationRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof policyRegistryImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof policyRegistryImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof policyRegistryImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: policyRegistryImplementationABI,
    address: policyRegistryImplementationAddress[421614],
    ...config,
  } as UseContractReadConfig<typeof policyRegistryImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link policyRegistryImplementationABI}__ and `functionName` set to `"governor"`.
 *
 *
 */
export function usePolicyRegistryImplementationGovernor<
  TFunctionName extends 'governor',
  TSelectData = ReadContractResult<typeof policyRegistryImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof policyRegistryImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof policyRegistryImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: policyRegistryImplementationABI,
    address: policyRegistryImplementationAddress[421614],
    functionName: 'governor',
    ...config,
  } as UseContractReadConfig<typeof policyRegistryImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link policyRegistryImplementationABI}__ and `functionName` set to `"policies"`.
 *
 *
 */
export function usePolicyRegistryImplementationPolicies<
  TFunctionName extends 'policies',
  TSelectData = ReadContractResult<typeof policyRegistryImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof policyRegistryImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof policyRegistryImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: policyRegistryImplementationABI,
    address: policyRegistryImplementationAddress[421614],
    functionName: 'policies',
    ...config,
  } as UseContractReadConfig<typeof policyRegistryImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link policyRegistryImplementationABI}__ and `functionName` set to `"proxiableUUID"`.
 *
 *
 */
export function usePolicyRegistryImplementationProxiableUuid<
  TFunctionName extends 'proxiableUUID',
  TSelectData = ReadContractResult<typeof policyRegistryImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof policyRegistryImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof policyRegistryImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: policyRegistryImplementationABI,
    address: policyRegistryImplementationAddress[421614],
    functionName: 'proxiableUUID',
    ...config,
  } as UseContractReadConfig<typeof policyRegistryImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link policyRegistryImplementationABI}__.
 *
 *
 */
export function usePolicyRegistryImplementationWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof policyRegistryImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof policyRegistryImplementationABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof policyRegistryImplementationABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof policyRegistryImplementationABI, TFunctionName, TMode>({
    abi: policyRegistryImplementationABI,
    address: policyRegistryImplementationAddress[421614],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link policyRegistryImplementationABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function usePolicyRegistryImplementationChangeGovernor<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof policyRegistryImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof policyRegistryImplementationABI, 'changeGovernor'>['request']['abi'],
        'changeGovernor',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeGovernor' }
    : UseContractWriteConfig<typeof policyRegistryImplementationABI, 'changeGovernor', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeGovernor'
      } = {} as any
) {
  return useContractWrite<typeof policyRegistryImplementationABI, 'changeGovernor', TMode>({
    abi: policyRegistryImplementationABI,
    address: policyRegistryImplementationAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link policyRegistryImplementationABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function usePolicyRegistryImplementationInitialize<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof policyRegistryImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof policyRegistryImplementationABI, 'initialize'>['request']['abi'],
        'initialize',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'initialize' }
    : UseContractWriteConfig<typeof policyRegistryImplementationABI, 'initialize', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'initialize'
      } = {} as any
) {
  return useContractWrite<typeof policyRegistryImplementationABI, 'initialize', TMode>({
    abi: policyRegistryImplementationABI,
    address: policyRegistryImplementationAddress[421614],
    functionName: 'initialize',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link policyRegistryImplementationABI}__ and `functionName` set to `"setPolicy"`.
 *
 *
 */
export function usePolicyRegistryImplementationSetPolicy<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof policyRegistryImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof policyRegistryImplementationABI, 'setPolicy'>['request']['abi'],
        'setPolicy',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setPolicy' }
    : UseContractWriteConfig<typeof policyRegistryImplementationABI, 'setPolicy', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setPolicy'
      } = {} as any
) {
  return useContractWrite<typeof policyRegistryImplementationABI, 'setPolicy', TMode>({
    abi: policyRegistryImplementationABI,
    address: policyRegistryImplementationAddress[421614],
    functionName: 'setPolicy',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link policyRegistryImplementationABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function usePolicyRegistryImplementationUpgradeToAndCall<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof policyRegistryImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof policyRegistryImplementationABI, 'upgradeToAndCall'>['request']['abi'],
        'upgradeToAndCall',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'upgradeToAndCall' }
    : UseContractWriteConfig<typeof policyRegistryImplementationABI, 'upgradeToAndCall', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'upgradeToAndCall'
      } = {} as any
) {
  return useContractWrite<typeof policyRegistryImplementationABI, 'upgradeToAndCall', TMode>({
    abi: policyRegistryImplementationABI,
    address: policyRegistryImplementationAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link policyRegistryImplementationABI}__.
 *
 *
 */
export function usePreparePolicyRegistryImplementationWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof policyRegistryImplementationABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof policyRegistryImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: policyRegistryImplementationABI,
    address: policyRegistryImplementationAddress[421614],
    ...config,
  } as UsePrepareContractWriteConfig<typeof policyRegistryImplementationABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link policyRegistryImplementationABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function usePreparePolicyRegistryImplementationChangeGovernor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof policyRegistryImplementationABI, 'changeGovernor'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof policyRegistryImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: policyRegistryImplementationABI,
    address: policyRegistryImplementationAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as UsePrepareContractWriteConfig<typeof policyRegistryImplementationABI, 'changeGovernor'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link policyRegistryImplementationABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function usePreparePolicyRegistryImplementationInitialize(
  config: Omit<
    UsePrepareContractWriteConfig<typeof policyRegistryImplementationABI, 'initialize'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof policyRegistryImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: policyRegistryImplementationABI,
    address: policyRegistryImplementationAddress[421614],
    functionName: 'initialize',
    ...config,
  } as UsePrepareContractWriteConfig<typeof policyRegistryImplementationABI, 'initialize'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link policyRegistryImplementationABI}__ and `functionName` set to `"setPolicy"`.
 *
 *
 */
export function usePreparePolicyRegistryImplementationSetPolicy(
  config: Omit<
    UsePrepareContractWriteConfig<typeof policyRegistryImplementationABI, 'setPolicy'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof policyRegistryImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: policyRegistryImplementationABI,
    address: policyRegistryImplementationAddress[421614],
    functionName: 'setPolicy',
    ...config,
  } as UsePrepareContractWriteConfig<typeof policyRegistryImplementationABI, 'setPolicy'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link policyRegistryImplementationABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function usePreparePolicyRegistryImplementationUpgradeToAndCall(
  config: Omit<
    UsePrepareContractWriteConfig<typeof policyRegistryImplementationABI, 'upgradeToAndCall'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof policyRegistryImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: policyRegistryImplementationABI,
    address: policyRegistryImplementationAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as UsePrepareContractWriteConfig<typeof policyRegistryImplementationABI, 'upgradeToAndCall'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link policyRegistryImplementationABI}__.
 *
 *
 */
export function usePolicyRegistryImplementationEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof policyRegistryImplementationABI, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof policyRegistryImplementationAddress
  } = {} as any
) {
  return useContractEvent({
    abi: policyRegistryImplementationABI,
    address: policyRegistryImplementationAddress[421614],
    ...config,
  } as UseContractEventConfig<typeof policyRegistryImplementationABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link policyRegistryImplementationABI}__ and `eventName` set to `"Initialized"`.
 *
 *
 */
export function usePolicyRegistryImplementationInitializedEvent(
  config: Omit<
    UseContractEventConfig<typeof policyRegistryImplementationABI, 'Initialized'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof policyRegistryImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: policyRegistryImplementationABI,
    address: policyRegistryImplementationAddress[421614],
    eventName: 'Initialized',
    ...config,
  } as UseContractEventConfig<typeof policyRegistryImplementationABI, 'Initialized'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link policyRegistryImplementationABI}__ and `eventName` set to `"PolicyUpdate"`.
 *
 *
 */
export function usePolicyRegistryImplementationPolicyUpdateEvent(
  config: Omit<
    UseContractEventConfig<typeof policyRegistryImplementationABI, 'PolicyUpdate'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof policyRegistryImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: policyRegistryImplementationABI,
    address: policyRegistryImplementationAddress[421614],
    eventName: 'PolicyUpdate',
    ...config,
  } as UseContractEventConfig<typeof policyRegistryImplementationABI, 'PolicyUpdate'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link policyRegistryImplementationABI}__ and `eventName` set to `"Upgraded"`.
 *
 *
 */
export function usePolicyRegistryImplementationUpgradedEvent(
  config: Omit<
    UseContractEventConfig<typeof policyRegistryImplementationABI, 'Upgraded'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof policyRegistryImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: policyRegistryImplementationABI,
    address: policyRegistryImplementationAddress[421614],
    eventName: 'Upgraded',
    ...config,
  } as UseContractEventConfig<typeof policyRegistryImplementationABI, 'Upgraded'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link randomizerRngABI}__.
 *
 *
 */
export function useRandomizerRngRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof randomizerRngABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof randomizerRngABI, TFunctionName, TSelectData>, 'abi' | 'address'> & {
    chainId?: keyof typeof randomizerRngAddress
  } = {} as any
) {
  return useContractRead({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    ...config,
  } as UseContractReadConfig<typeof randomizerRngABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link randomizerRngABI}__ and `functionName` set to `"callbackGasLimit"`.
 *
 *
 */
export function useRandomizerRngCallbackGasLimit<
  TFunctionName extends 'callbackGasLimit',
  TSelectData = ReadContractResult<typeof randomizerRngABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof randomizerRngABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngAddress } = {} as any
) {
  return useContractRead({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    functionName: 'callbackGasLimit',
    ...config,
  } as UseContractReadConfig<typeof randomizerRngABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link randomizerRngABI}__ and `functionName` set to `"governor"`.
 *
 *
 */
export function useRandomizerRngGovernor<
  TFunctionName extends 'governor',
  TSelectData = ReadContractResult<typeof randomizerRngABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof randomizerRngABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngAddress } = {} as any
) {
  return useContractRead({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    functionName: 'governor',
    ...config,
  } as UseContractReadConfig<typeof randomizerRngABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link randomizerRngABI}__ and `functionName` set to `"proxiableUUID"`.
 *
 *
 */
export function useRandomizerRngProxiableUuid<
  TFunctionName extends 'proxiableUUID',
  TSelectData = ReadContractResult<typeof randomizerRngABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof randomizerRngABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngAddress } = {} as any
) {
  return useContractRead({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    functionName: 'proxiableUUID',
    ...config,
  } as UseContractReadConfig<typeof randomizerRngABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link randomizerRngABI}__ and `functionName` set to `"randomNumbers"`.
 *
 *
 */
export function useRandomizerRngRandomNumbers<
  TFunctionName extends 'randomNumbers',
  TSelectData = ReadContractResult<typeof randomizerRngABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof randomizerRngABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngAddress } = {} as any
) {
  return useContractRead({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    functionName: 'randomNumbers',
    ...config,
  } as UseContractReadConfig<typeof randomizerRngABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link randomizerRngABI}__ and `functionName` set to `"randomizer"`.
 *
 *
 */
export function useRandomizerRngRandomizer<
  TFunctionName extends 'randomizer',
  TSelectData = ReadContractResult<typeof randomizerRngABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof randomizerRngABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngAddress } = {} as any
) {
  return useContractRead({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    functionName: 'randomizer',
    ...config,
  } as UseContractReadConfig<typeof randomizerRngABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link randomizerRngABI}__ and `functionName` set to `"receiveRandomness"`.
 *
 *
 */
export function useRandomizerRngReceiveRandomness<
  TFunctionName extends 'receiveRandomness',
  TSelectData = ReadContractResult<typeof randomizerRngABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof randomizerRngABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngAddress } = {} as any
) {
  return useContractRead({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    functionName: 'receiveRandomness',
    ...config,
  } as UseContractReadConfig<typeof randomizerRngABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link randomizerRngABI}__ and `functionName` set to `"requesterToID"`.
 *
 *
 */
export function useRandomizerRngRequesterToId<
  TFunctionName extends 'requesterToID',
  TSelectData = ReadContractResult<typeof randomizerRngABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof randomizerRngABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngAddress } = {} as any
) {
  return useContractRead({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    functionName: 'requesterToID',
    ...config,
  } as UseContractReadConfig<typeof randomizerRngABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link randomizerRngABI}__.
 *
 *
 */
export function useRandomizerRngWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof randomizerRngAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof randomizerRngABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof randomizerRngABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof randomizerRngABI, TFunctionName, TMode>({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link randomizerRngABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function useRandomizerRngChangeGovernor<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof randomizerRngAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof randomizerRngABI, 'changeGovernor'>['request']['abi'],
        'changeGovernor',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeGovernor' }
    : UseContractWriteConfig<typeof randomizerRngABI, 'changeGovernor', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeGovernor'
      } = {} as any
) {
  return useContractWrite<typeof randomizerRngABI, 'changeGovernor', TMode>({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link randomizerRngABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function useRandomizerRngInitialize<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof randomizerRngAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof randomizerRngABI, 'initialize'>['request']['abi'],
        'initialize',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'initialize' }
    : UseContractWriteConfig<typeof randomizerRngABI, 'initialize', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'initialize'
      } = {} as any
) {
  return useContractWrite<typeof randomizerRngABI, 'initialize', TMode>({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    functionName: 'initialize',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link randomizerRngABI}__ and `functionName` set to `"randomizerCallback"`.
 *
 *
 */
export function useRandomizerRngRandomizerCallback<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof randomizerRngAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof randomizerRngABI, 'randomizerCallback'>['request']['abi'],
        'randomizerCallback',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'randomizerCallback' }
    : UseContractWriteConfig<typeof randomizerRngABI, 'randomizerCallback', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'randomizerCallback'
      } = {} as any
) {
  return useContractWrite<typeof randomizerRngABI, 'randomizerCallback', TMode>({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    functionName: 'randomizerCallback',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link randomizerRngABI}__ and `functionName` set to `"randomizerWithdraw"`.
 *
 *
 */
export function useRandomizerRngRandomizerWithdraw<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof randomizerRngAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof randomizerRngABI, 'randomizerWithdraw'>['request']['abi'],
        'randomizerWithdraw',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'randomizerWithdraw' }
    : UseContractWriteConfig<typeof randomizerRngABI, 'randomizerWithdraw', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'randomizerWithdraw'
      } = {} as any
) {
  return useContractWrite<typeof randomizerRngABI, 'randomizerWithdraw', TMode>({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    functionName: 'randomizerWithdraw',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link randomizerRngABI}__ and `functionName` set to `"requestRandomness"`.
 *
 *
 */
export function useRandomizerRngRequestRandomness<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof randomizerRngAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof randomizerRngABI, 'requestRandomness'>['request']['abi'],
        'requestRandomness',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'requestRandomness' }
    : UseContractWriteConfig<typeof randomizerRngABI, 'requestRandomness', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'requestRandomness'
      } = {} as any
) {
  return useContractWrite<typeof randomizerRngABI, 'requestRandomness', TMode>({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    functionName: 'requestRandomness',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link randomizerRngABI}__ and `functionName` set to `"setCallbackGasLimit"`.
 *
 *
 */
export function useRandomizerRngSetCallbackGasLimit<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof randomizerRngAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof randomizerRngABI, 'setCallbackGasLimit'>['request']['abi'],
        'setCallbackGasLimit',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setCallbackGasLimit' }
    : UseContractWriteConfig<typeof randomizerRngABI, 'setCallbackGasLimit', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setCallbackGasLimit'
      } = {} as any
) {
  return useContractWrite<typeof randomizerRngABI, 'setCallbackGasLimit', TMode>({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    functionName: 'setCallbackGasLimit',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link randomizerRngABI}__ and `functionName` set to `"setRandomizer"`.
 *
 *
 */
export function useRandomizerRngSetRandomizer<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof randomizerRngAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof randomizerRngABI, 'setRandomizer'>['request']['abi'],
        'setRandomizer',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setRandomizer' }
    : UseContractWriteConfig<typeof randomizerRngABI, 'setRandomizer', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setRandomizer'
      } = {} as any
) {
  return useContractWrite<typeof randomizerRngABI, 'setRandomizer', TMode>({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    functionName: 'setRandomizer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link randomizerRngABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function useRandomizerRngUpgradeToAndCall<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof randomizerRngAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof randomizerRngABI, 'upgradeToAndCall'>['request']['abi'],
        'upgradeToAndCall',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'upgradeToAndCall' }
    : UseContractWriteConfig<typeof randomizerRngABI, 'upgradeToAndCall', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'upgradeToAndCall'
      } = {} as any
) {
  return useContractWrite<typeof randomizerRngABI, 'upgradeToAndCall', TMode>({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link randomizerRngABI}__.
 *
 *
 */
export function usePrepareRandomizerRngWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof randomizerRngABI, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof randomizerRngAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    ...config,
  } as UsePrepareContractWriteConfig<typeof randomizerRngABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link randomizerRngABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function usePrepareRandomizerRngChangeGovernor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof randomizerRngABI, 'changeGovernor'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as UsePrepareContractWriteConfig<typeof randomizerRngABI, 'changeGovernor'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link randomizerRngABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function usePrepareRandomizerRngInitialize(
  config: Omit<
    UsePrepareContractWriteConfig<typeof randomizerRngABI, 'initialize'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    functionName: 'initialize',
    ...config,
  } as UsePrepareContractWriteConfig<typeof randomizerRngABI, 'initialize'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link randomizerRngABI}__ and `functionName` set to `"randomizerCallback"`.
 *
 *
 */
export function usePrepareRandomizerRngRandomizerCallback(
  config: Omit<
    UsePrepareContractWriteConfig<typeof randomizerRngABI, 'randomizerCallback'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    functionName: 'randomizerCallback',
    ...config,
  } as UsePrepareContractWriteConfig<typeof randomizerRngABI, 'randomizerCallback'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link randomizerRngABI}__ and `functionName` set to `"randomizerWithdraw"`.
 *
 *
 */
export function usePrepareRandomizerRngRandomizerWithdraw(
  config: Omit<
    UsePrepareContractWriteConfig<typeof randomizerRngABI, 'randomizerWithdraw'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    functionName: 'randomizerWithdraw',
    ...config,
  } as UsePrepareContractWriteConfig<typeof randomizerRngABI, 'randomizerWithdraw'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link randomizerRngABI}__ and `functionName` set to `"requestRandomness"`.
 *
 *
 */
export function usePrepareRandomizerRngRequestRandomness(
  config: Omit<
    UsePrepareContractWriteConfig<typeof randomizerRngABI, 'requestRandomness'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    functionName: 'requestRandomness',
    ...config,
  } as UsePrepareContractWriteConfig<typeof randomizerRngABI, 'requestRandomness'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link randomizerRngABI}__ and `functionName` set to `"setCallbackGasLimit"`.
 *
 *
 */
export function usePrepareRandomizerRngSetCallbackGasLimit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof randomizerRngABI, 'setCallbackGasLimit'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    functionName: 'setCallbackGasLimit',
    ...config,
  } as UsePrepareContractWriteConfig<typeof randomizerRngABI, 'setCallbackGasLimit'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link randomizerRngABI}__ and `functionName` set to `"setRandomizer"`.
 *
 *
 */
export function usePrepareRandomizerRngSetRandomizer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof randomizerRngABI, 'setRandomizer'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    functionName: 'setRandomizer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof randomizerRngABI, 'setRandomizer'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link randomizerRngABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function usePrepareRandomizerRngUpgradeToAndCall(
  config: Omit<
    UsePrepareContractWriteConfig<typeof randomizerRngABI, 'upgradeToAndCall'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as UsePrepareContractWriteConfig<typeof randomizerRngABI, 'upgradeToAndCall'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link randomizerRngABI}__.
 *
 *
 */
export function useRandomizerRngEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof randomizerRngABI, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof randomizerRngAddress
  } = {} as any
) {
  return useContractEvent({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    ...config,
  } as UseContractEventConfig<typeof randomizerRngABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link randomizerRngABI}__ and `eventName` set to `"Initialized"`.
 *
 *
 */
export function useRandomizerRngInitializedEvent(
  config: Omit<UseContractEventConfig<typeof randomizerRngABI, 'Initialized'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof randomizerRngAddress
  } = {} as any
) {
  return useContractEvent({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    eventName: 'Initialized',
    ...config,
  } as UseContractEventConfig<typeof randomizerRngABI, 'Initialized'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link randomizerRngABI}__ and `eventName` set to `"Upgraded"`.
 *
 *
 */
export function useRandomizerRngUpgradedEvent(
  config: Omit<UseContractEventConfig<typeof randomizerRngABI, 'Upgraded'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof randomizerRngAddress
  } = {} as any
) {
  return useContractEvent({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    eventName: 'Upgraded',
    ...config,
  } as UseContractEventConfig<typeof randomizerRngABI, 'Upgraded'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link randomizerRngImplementationABI}__.
 *
 *
 */
export function useRandomizerRngImplementationRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof randomizerRngImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof randomizerRngImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof randomizerRngImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    ...config,
  } as UseContractReadConfig<typeof randomizerRngImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link randomizerRngImplementationABI}__ and `functionName` set to `"callbackGasLimit"`.
 *
 *
 */
export function useRandomizerRngImplementationCallbackGasLimit<
  TFunctionName extends 'callbackGasLimit',
  TSelectData = ReadContractResult<typeof randomizerRngImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof randomizerRngImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    functionName: 'callbackGasLimit',
    ...config,
  } as UseContractReadConfig<typeof randomizerRngImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link randomizerRngImplementationABI}__ and `functionName` set to `"governor"`.
 *
 *
 */
export function useRandomizerRngImplementationGovernor<
  TFunctionName extends 'governor',
  TSelectData = ReadContractResult<typeof randomizerRngImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof randomizerRngImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    functionName: 'governor',
    ...config,
  } as UseContractReadConfig<typeof randomizerRngImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link randomizerRngImplementationABI}__ and `functionName` set to `"proxiableUUID"`.
 *
 *
 */
export function useRandomizerRngImplementationProxiableUuid<
  TFunctionName extends 'proxiableUUID',
  TSelectData = ReadContractResult<typeof randomizerRngImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof randomizerRngImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    functionName: 'proxiableUUID',
    ...config,
  } as UseContractReadConfig<typeof randomizerRngImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link randomizerRngImplementationABI}__ and `functionName` set to `"randomNumbers"`.
 *
 *
 */
export function useRandomizerRngImplementationRandomNumbers<
  TFunctionName extends 'randomNumbers',
  TSelectData = ReadContractResult<typeof randomizerRngImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof randomizerRngImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    functionName: 'randomNumbers',
    ...config,
  } as UseContractReadConfig<typeof randomizerRngImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link randomizerRngImplementationABI}__ and `functionName` set to `"randomizer"`.
 *
 *
 */
export function useRandomizerRngImplementationRandomizer<
  TFunctionName extends 'randomizer',
  TSelectData = ReadContractResult<typeof randomizerRngImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof randomizerRngImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    functionName: 'randomizer',
    ...config,
  } as UseContractReadConfig<typeof randomizerRngImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link randomizerRngImplementationABI}__ and `functionName` set to `"receiveRandomness"`.
 *
 *
 */
export function useRandomizerRngImplementationReceiveRandomness<
  TFunctionName extends 'receiveRandomness',
  TSelectData = ReadContractResult<typeof randomizerRngImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof randomizerRngImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    functionName: 'receiveRandomness',
    ...config,
  } as UseContractReadConfig<typeof randomizerRngImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link randomizerRngImplementationABI}__ and `functionName` set to `"requesterToID"`.
 *
 *
 */
export function useRandomizerRngImplementationRequesterToId<
  TFunctionName extends 'requesterToID',
  TSelectData = ReadContractResult<typeof randomizerRngImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof randomizerRngImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    functionName: 'requesterToID',
    ...config,
  } as UseContractReadConfig<typeof randomizerRngImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link randomizerRngImplementationABI}__.
 *
 *
 */
export function useRandomizerRngImplementationWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof randomizerRngImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof randomizerRngImplementationABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof randomizerRngImplementationABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof randomizerRngImplementationABI, TFunctionName, TMode>({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link randomizerRngImplementationABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function useRandomizerRngImplementationChangeGovernor<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof randomizerRngImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof randomizerRngImplementationABI, 'changeGovernor'>['request']['abi'],
        'changeGovernor',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeGovernor' }
    : UseContractWriteConfig<typeof randomizerRngImplementationABI, 'changeGovernor', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeGovernor'
      } = {} as any
) {
  return useContractWrite<typeof randomizerRngImplementationABI, 'changeGovernor', TMode>({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link randomizerRngImplementationABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function useRandomizerRngImplementationInitialize<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof randomizerRngImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof randomizerRngImplementationABI, 'initialize'>['request']['abi'],
        'initialize',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'initialize' }
    : UseContractWriteConfig<typeof randomizerRngImplementationABI, 'initialize', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'initialize'
      } = {} as any
) {
  return useContractWrite<typeof randomizerRngImplementationABI, 'initialize', TMode>({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    functionName: 'initialize',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link randomizerRngImplementationABI}__ and `functionName` set to `"randomizerCallback"`.
 *
 *
 */
export function useRandomizerRngImplementationRandomizerCallback<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof randomizerRngImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof randomizerRngImplementationABI, 'randomizerCallback'>['request']['abi'],
        'randomizerCallback',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'randomizerCallback' }
    : UseContractWriteConfig<typeof randomizerRngImplementationABI, 'randomizerCallback', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'randomizerCallback'
      } = {} as any
) {
  return useContractWrite<typeof randomizerRngImplementationABI, 'randomizerCallback', TMode>({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    functionName: 'randomizerCallback',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link randomizerRngImplementationABI}__ and `functionName` set to `"randomizerWithdraw"`.
 *
 *
 */
export function useRandomizerRngImplementationRandomizerWithdraw<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof randomizerRngImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof randomizerRngImplementationABI, 'randomizerWithdraw'>['request']['abi'],
        'randomizerWithdraw',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'randomizerWithdraw' }
    : UseContractWriteConfig<typeof randomizerRngImplementationABI, 'randomizerWithdraw', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'randomizerWithdraw'
      } = {} as any
) {
  return useContractWrite<typeof randomizerRngImplementationABI, 'randomizerWithdraw', TMode>({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    functionName: 'randomizerWithdraw',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link randomizerRngImplementationABI}__ and `functionName` set to `"requestRandomness"`.
 *
 *
 */
export function useRandomizerRngImplementationRequestRandomness<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof randomizerRngImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof randomizerRngImplementationABI, 'requestRandomness'>['request']['abi'],
        'requestRandomness',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'requestRandomness' }
    : UseContractWriteConfig<typeof randomizerRngImplementationABI, 'requestRandomness', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'requestRandomness'
      } = {} as any
) {
  return useContractWrite<typeof randomizerRngImplementationABI, 'requestRandomness', TMode>({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    functionName: 'requestRandomness',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link randomizerRngImplementationABI}__ and `functionName` set to `"setCallbackGasLimit"`.
 *
 *
 */
export function useRandomizerRngImplementationSetCallbackGasLimit<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof randomizerRngImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof randomizerRngImplementationABI, 'setCallbackGasLimit'>['request']['abi'],
        'setCallbackGasLimit',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setCallbackGasLimit' }
    : UseContractWriteConfig<typeof randomizerRngImplementationABI, 'setCallbackGasLimit', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setCallbackGasLimit'
      } = {} as any
) {
  return useContractWrite<typeof randomizerRngImplementationABI, 'setCallbackGasLimit', TMode>({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    functionName: 'setCallbackGasLimit',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link randomizerRngImplementationABI}__ and `functionName` set to `"setRandomizer"`.
 *
 *
 */
export function useRandomizerRngImplementationSetRandomizer<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof randomizerRngImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof randomizerRngImplementationABI, 'setRandomizer'>['request']['abi'],
        'setRandomizer',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setRandomizer' }
    : UseContractWriteConfig<typeof randomizerRngImplementationABI, 'setRandomizer', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setRandomizer'
      } = {} as any
) {
  return useContractWrite<typeof randomizerRngImplementationABI, 'setRandomizer', TMode>({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    functionName: 'setRandomizer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link randomizerRngImplementationABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function useRandomizerRngImplementationUpgradeToAndCall<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof randomizerRngImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof randomizerRngImplementationABI, 'upgradeToAndCall'>['request']['abi'],
        'upgradeToAndCall',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'upgradeToAndCall' }
    : UseContractWriteConfig<typeof randomizerRngImplementationABI, 'upgradeToAndCall', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'upgradeToAndCall'
      } = {} as any
) {
  return useContractWrite<typeof randomizerRngImplementationABI, 'upgradeToAndCall', TMode>({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link randomizerRngImplementationABI}__.
 *
 *
 */
export function usePrepareRandomizerRngImplementationWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof randomizerRngImplementationABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof randomizerRngImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    ...config,
  } as UsePrepareContractWriteConfig<typeof randomizerRngImplementationABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link randomizerRngImplementationABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function usePrepareRandomizerRngImplementationChangeGovernor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof randomizerRngImplementationABI, 'changeGovernor'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as UsePrepareContractWriteConfig<typeof randomizerRngImplementationABI, 'changeGovernor'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link randomizerRngImplementationABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function usePrepareRandomizerRngImplementationInitialize(
  config: Omit<
    UsePrepareContractWriteConfig<typeof randomizerRngImplementationABI, 'initialize'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    functionName: 'initialize',
    ...config,
  } as UsePrepareContractWriteConfig<typeof randomizerRngImplementationABI, 'initialize'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link randomizerRngImplementationABI}__ and `functionName` set to `"randomizerCallback"`.
 *
 *
 */
export function usePrepareRandomizerRngImplementationRandomizerCallback(
  config: Omit<
    UsePrepareContractWriteConfig<typeof randomizerRngImplementationABI, 'randomizerCallback'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    functionName: 'randomizerCallback',
    ...config,
  } as UsePrepareContractWriteConfig<typeof randomizerRngImplementationABI, 'randomizerCallback'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link randomizerRngImplementationABI}__ and `functionName` set to `"randomizerWithdraw"`.
 *
 *
 */
export function usePrepareRandomizerRngImplementationRandomizerWithdraw(
  config: Omit<
    UsePrepareContractWriteConfig<typeof randomizerRngImplementationABI, 'randomizerWithdraw'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    functionName: 'randomizerWithdraw',
    ...config,
  } as UsePrepareContractWriteConfig<typeof randomizerRngImplementationABI, 'randomizerWithdraw'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link randomizerRngImplementationABI}__ and `functionName` set to `"requestRandomness"`.
 *
 *
 */
export function usePrepareRandomizerRngImplementationRequestRandomness(
  config: Omit<
    UsePrepareContractWriteConfig<typeof randomizerRngImplementationABI, 'requestRandomness'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    functionName: 'requestRandomness',
    ...config,
  } as UsePrepareContractWriteConfig<typeof randomizerRngImplementationABI, 'requestRandomness'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link randomizerRngImplementationABI}__ and `functionName` set to `"setCallbackGasLimit"`.
 *
 *
 */
export function usePrepareRandomizerRngImplementationSetCallbackGasLimit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof randomizerRngImplementationABI, 'setCallbackGasLimit'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    functionName: 'setCallbackGasLimit',
    ...config,
  } as UsePrepareContractWriteConfig<typeof randomizerRngImplementationABI, 'setCallbackGasLimit'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link randomizerRngImplementationABI}__ and `functionName` set to `"setRandomizer"`.
 *
 *
 */
export function usePrepareRandomizerRngImplementationSetRandomizer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof randomizerRngImplementationABI, 'setRandomizer'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    functionName: 'setRandomizer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof randomizerRngImplementationABI, 'setRandomizer'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link randomizerRngImplementationABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function usePrepareRandomizerRngImplementationUpgradeToAndCall(
  config: Omit<
    UsePrepareContractWriteConfig<typeof randomizerRngImplementationABI, 'upgradeToAndCall'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof randomizerRngImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as UsePrepareContractWriteConfig<typeof randomizerRngImplementationABI, 'upgradeToAndCall'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link randomizerRngImplementationABI}__.
 *
 *
 */
export function useRandomizerRngImplementationEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof randomizerRngImplementationABI, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof randomizerRngImplementationAddress
  } = {} as any
) {
  return useContractEvent({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    ...config,
  } as UseContractEventConfig<typeof randomizerRngImplementationABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link randomizerRngImplementationABI}__ and `eventName` set to `"Initialized"`.
 *
 *
 */
export function useRandomizerRngImplementationInitializedEvent(
  config: Omit<
    UseContractEventConfig<typeof randomizerRngImplementationABI, 'Initialized'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof randomizerRngImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    eventName: 'Initialized',
    ...config,
  } as UseContractEventConfig<typeof randomizerRngImplementationABI, 'Initialized'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link randomizerRngImplementationABI}__ and `eventName` set to `"Upgraded"`.
 *
 *
 */
export function useRandomizerRngImplementationUpgradedEvent(
  config: Omit<
    UseContractEventConfig<typeof randomizerRngImplementationABI, 'Upgraded'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof randomizerRngImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    eventName: 'Upgraded',
    ...config,
  } as UseContractEventConfig<typeof randomizerRngImplementationABI, 'Upgraded'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleABI}__.
 *
 *
 */
export function useSortitionModuleRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof sortitionModuleABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>, 'abi' | 'address'> & {
    chainId?: keyof typeof sortitionModuleAddress
  } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"DEFAULT_K"`.
 *
 *
 */
export function useSortitionModuleDefaultK<
  TFunctionName extends 'DEFAULT_K',
  TSelectData = ReadContractResult<typeof sortitionModuleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'DEFAULT_K',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"MAX_STAKE_PATHS"`.
 *
 *
 */
export function useSortitionModuleMaxStakePaths<
  TFunctionName extends 'MAX_STAKE_PATHS',
  TSelectData = ReadContractResult<typeof sortitionModuleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'MAX_STAKE_PATHS',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"core"`.
 *
 *
 */
export function useSortitionModuleCore<
  TFunctionName extends 'core',
  TSelectData = ReadContractResult<typeof sortitionModuleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'core',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"delayedStakeReadIndex"`.
 *
 *
 */
export function useSortitionModuleDelayedStakeReadIndex<
  TFunctionName extends 'delayedStakeReadIndex',
  TSelectData = ReadContractResult<typeof sortitionModuleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'delayedStakeReadIndex',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"delayedStakeWriteIndex"`.
 *
 *
 */
export function useSortitionModuleDelayedStakeWriteIndex<
  TFunctionName extends 'delayedStakeWriteIndex',
  TSelectData = ReadContractResult<typeof sortitionModuleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'delayedStakeWriteIndex',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"delayedStakes"`.
 *
 *
 */
export function useSortitionModuleDelayedStakes<
  TFunctionName extends 'delayedStakes',
  TSelectData = ReadContractResult<typeof sortitionModuleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'delayedStakes',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"disputesWithoutJurors"`.
 *
 *
 */
export function useSortitionModuleDisputesWithoutJurors<
  TFunctionName extends 'disputesWithoutJurors',
  TSelectData = ReadContractResult<typeof sortitionModuleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'disputesWithoutJurors',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"draw"`.
 *
 *
 */
export function useSortitionModuleDraw<
  TFunctionName extends 'draw',
  TSelectData = ReadContractResult<typeof sortitionModuleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'draw',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"getJurorBalance"`.
 *
 *
 */
export function useSortitionModuleGetJurorBalance<
  TFunctionName extends 'getJurorBalance',
  TSelectData = ReadContractResult<typeof sortitionModuleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'getJurorBalance',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"getJurorCourtIDs"`.
 *
 *
 */
export function useSortitionModuleGetJurorCourtIDs<
  TFunctionName extends 'getJurorCourtIDs',
  TSelectData = ReadContractResult<typeof sortitionModuleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'getJurorCourtIDs',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"governor"`.
 *
 *
 */
export function useSortitionModuleGovernor<
  TFunctionName extends 'governor',
  TSelectData = ReadContractResult<typeof sortitionModuleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'governor',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"isJurorStaked"`.
 *
 *
 */
export function useSortitionModuleIsJurorStaked<
  TFunctionName extends 'isJurorStaked',
  TSelectData = ReadContractResult<typeof sortitionModuleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'isJurorStaked',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"jurors"`.
 *
 *
 */
export function useSortitionModuleJurors<
  TFunctionName extends 'jurors',
  TSelectData = ReadContractResult<typeof sortitionModuleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'jurors',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"lastPhaseChange"`.
 *
 *
 */
export function useSortitionModuleLastPhaseChange<
  TFunctionName extends 'lastPhaseChange',
  TSelectData = ReadContractResult<typeof sortitionModuleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'lastPhaseChange',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"latestDelayedStakeIndex"`.
 *
 *
 */
export function useSortitionModuleLatestDelayedStakeIndex<
  TFunctionName extends 'latestDelayedStakeIndex',
  TSelectData = ReadContractResult<typeof sortitionModuleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'latestDelayedStakeIndex',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"maxDrawingTime"`.
 *
 *
 */
export function useSortitionModuleMaxDrawingTime<
  TFunctionName extends 'maxDrawingTime',
  TSelectData = ReadContractResult<typeof sortitionModuleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'maxDrawingTime',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"minStakingTime"`.
 *
 *
 */
export function useSortitionModuleMinStakingTime<
  TFunctionName extends 'minStakingTime',
  TSelectData = ReadContractResult<typeof sortitionModuleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'minStakingTime',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"phase"`.
 *
 *
 */
export function useSortitionModulePhase<
  TFunctionName extends 'phase',
  TSelectData = ReadContractResult<typeof sortitionModuleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'phase',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"proxiableUUID"`.
 *
 *
 */
export function useSortitionModuleProxiableUuid<
  TFunctionName extends 'proxiableUUID',
  TSelectData = ReadContractResult<typeof sortitionModuleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'proxiableUUID',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"randomNumber"`.
 *
 *
 */
export function useSortitionModuleRandomNumber<
  TFunctionName extends 'randomNumber',
  TSelectData = ReadContractResult<typeof sortitionModuleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'randomNumber',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"randomNumberRequestBlock"`.
 *
 *
 */
export function useSortitionModuleRandomNumberRequestBlock<
  TFunctionName extends 'randomNumberRequestBlock',
  TSelectData = ReadContractResult<typeof sortitionModuleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'randomNumberRequestBlock',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"rng"`.
 *
 *
 */
export function useSortitionModuleRng<
  TFunctionName extends 'rng',
  TSelectData = ReadContractResult<typeof sortitionModuleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'rng',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"rngLookahead"`.
 *
 *
 */
export function useSortitionModuleRngLookahead<
  TFunctionName extends 'rngLookahead',
  TSelectData = ReadContractResult<typeof sortitionModuleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'rngLookahead',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"stakeOf"`.
 *
 *
 */
export function useSortitionModuleStakeOf<
  TFunctionName extends 'stakeOf',
  TSelectData = ReadContractResult<typeof sortitionModuleABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'stakeOf',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__.
 *
 *
 */
export function useSortitionModuleWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof sortitionModuleABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleABI, TFunctionName, TMode>({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"changeMaxDrawingTime"`.
 *
 *
 */
export function useSortitionModuleChangeMaxDrawingTime<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleABI, 'changeMaxDrawingTime'>['request']['abi'],
        'changeMaxDrawingTime',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeMaxDrawingTime' }
    : UseContractWriteConfig<typeof sortitionModuleABI, 'changeMaxDrawingTime', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeMaxDrawingTime'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleABI, 'changeMaxDrawingTime', TMode>({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'changeMaxDrawingTime',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"changeMinStakingTime"`.
 *
 *
 */
export function useSortitionModuleChangeMinStakingTime<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleABI, 'changeMinStakingTime'>['request']['abi'],
        'changeMinStakingTime',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeMinStakingTime' }
    : UseContractWriteConfig<typeof sortitionModuleABI, 'changeMinStakingTime', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeMinStakingTime'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleABI, 'changeMinStakingTime', TMode>({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'changeMinStakingTime',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"changeRandomNumberGenerator"`.
 *
 *
 */
export function useSortitionModuleChangeRandomNumberGenerator<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleABI, 'changeRandomNumberGenerator'>['request']['abi'],
        'changeRandomNumberGenerator',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeRandomNumberGenerator' }
    : UseContractWriteConfig<typeof sortitionModuleABI, 'changeRandomNumberGenerator', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeRandomNumberGenerator'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleABI, 'changeRandomNumberGenerator', TMode>({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'changeRandomNumberGenerator',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"createDisputeHook"`.
 *
 *
 */
export function useSortitionModuleCreateDisputeHook<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleABI, 'createDisputeHook'>['request']['abi'],
        'createDisputeHook',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'createDisputeHook' }
    : UseContractWriteConfig<typeof sortitionModuleABI, 'createDisputeHook', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'createDisputeHook'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleABI, 'createDisputeHook', TMode>({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'createDisputeHook',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"createTree"`.
 *
 *
 */
export function useSortitionModuleCreateTree<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleABI, 'createTree'>['request']['abi'],
        'createTree',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'createTree' }
    : UseContractWriteConfig<typeof sortitionModuleABI, 'createTree', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'createTree'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleABI, 'createTree', TMode>({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'createTree',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"executeDelayedStakes"`.
 *
 *
 */
export function useSortitionModuleExecuteDelayedStakes<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleABI, 'executeDelayedStakes'>['request']['abi'],
        'executeDelayedStakes',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'executeDelayedStakes' }
    : UseContractWriteConfig<typeof sortitionModuleABI, 'executeDelayedStakes', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'executeDelayedStakes'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleABI, 'executeDelayedStakes', TMode>({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'executeDelayedStakes',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function useSortitionModuleInitialize<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleABI, 'initialize'>['request']['abi'],
        'initialize',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'initialize' }
    : UseContractWriteConfig<typeof sortitionModuleABI, 'initialize', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'initialize'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleABI, 'initialize', TMode>({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'initialize',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"lockStake"`.
 *
 *
 */
export function useSortitionModuleLockStake<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleABI, 'lockStake'>['request']['abi'],
        'lockStake',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'lockStake' }
    : UseContractWriteConfig<typeof sortitionModuleABI, 'lockStake', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'lockStake'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleABI, 'lockStake', TMode>({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'lockStake',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"notifyRandomNumber"`.
 *
 *
 */
export function useSortitionModuleNotifyRandomNumber<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleABI, 'notifyRandomNumber'>['request']['abi'],
        'notifyRandomNumber',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'notifyRandomNumber' }
    : UseContractWriteConfig<typeof sortitionModuleABI, 'notifyRandomNumber', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'notifyRandomNumber'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleABI, 'notifyRandomNumber', TMode>({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'notifyRandomNumber',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"passPhase"`.
 *
 *
 */
export function useSortitionModulePassPhase<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleABI, 'passPhase'>['request']['abi'],
        'passPhase',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'passPhase' }
    : UseContractWriteConfig<typeof sortitionModuleABI, 'passPhase', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'passPhase'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleABI, 'passPhase', TMode>({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'passPhase',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"penalizeStake"`.
 *
 *
 */
export function useSortitionModulePenalizeStake<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleABI, 'penalizeStake'>['request']['abi'],
        'penalizeStake',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'penalizeStake' }
    : UseContractWriteConfig<typeof sortitionModuleABI, 'penalizeStake', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'penalizeStake'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleABI, 'penalizeStake', TMode>({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'penalizeStake',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"postDrawHook"`.
 *
 *
 */
export function useSortitionModulePostDrawHook<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleABI, 'postDrawHook'>['request']['abi'],
        'postDrawHook',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'postDrawHook' }
    : UseContractWriteConfig<typeof sortitionModuleABI, 'postDrawHook', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'postDrawHook'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleABI, 'postDrawHook', TMode>({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'postDrawHook',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"setJurorInactive"`.
 *
 *
 */
export function useSortitionModuleSetJurorInactive<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleABI, 'setJurorInactive'>['request']['abi'],
        'setJurorInactive',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setJurorInactive' }
    : UseContractWriteConfig<typeof sortitionModuleABI, 'setJurorInactive', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setJurorInactive'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleABI, 'setJurorInactive', TMode>({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'setJurorInactive',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"setStake"`.
 *
 *
 */
export function useSortitionModuleSetStake<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleABI, 'setStake'>['request']['abi'],
        'setStake',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setStake' }
    : UseContractWriteConfig<typeof sortitionModuleABI, 'setStake', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setStake'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleABI, 'setStake', TMode>({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'setStake',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"unlockStake"`.
 *
 *
 */
export function useSortitionModuleUnlockStake<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleABI, 'unlockStake'>['request']['abi'],
        'unlockStake',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'unlockStake' }
    : UseContractWriteConfig<typeof sortitionModuleABI, 'unlockStake', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'unlockStake'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleABI, 'unlockStake', TMode>({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'unlockStake',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function useSortitionModuleUpgradeToAndCall<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleABI, 'upgradeToAndCall'>['request']['abi'],
        'upgradeToAndCall',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'upgradeToAndCall' }
    : UseContractWriteConfig<typeof sortitionModuleABI, 'upgradeToAndCall', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'upgradeToAndCall'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleABI, 'upgradeToAndCall', TMode>({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__.
 *
 *
 */
export function usePrepareSortitionModuleWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof sortitionModuleABI, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof sortitionModuleAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"changeMaxDrawingTime"`.
 *
 *
 */
export function usePrepareSortitionModuleChangeMaxDrawingTime(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'changeMaxDrawingTime'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'changeMaxDrawingTime',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'changeMaxDrawingTime'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"changeMinStakingTime"`.
 *
 *
 */
export function usePrepareSortitionModuleChangeMinStakingTime(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'changeMinStakingTime'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'changeMinStakingTime',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'changeMinStakingTime'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"changeRandomNumberGenerator"`.
 *
 *
 */
export function usePrepareSortitionModuleChangeRandomNumberGenerator(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'changeRandomNumberGenerator'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'changeRandomNumberGenerator',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'changeRandomNumberGenerator'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"createDisputeHook"`.
 *
 *
 */
export function usePrepareSortitionModuleCreateDisputeHook(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'createDisputeHook'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'createDisputeHook',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'createDisputeHook'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"createTree"`.
 *
 *
 */
export function usePrepareSortitionModuleCreateTree(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'createTree'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'createTree',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'createTree'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"executeDelayedStakes"`.
 *
 *
 */
export function usePrepareSortitionModuleExecuteDelayedStakes(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'executeDelayedStakes'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'executeDelayedStakes',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'executeDelayedStakes'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function usePrepareSortitionModuleInitialize(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'initialize'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'initialize',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'initialize'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"lockStake"`.
 *
 *
 */
export function usePrepareSortitionModuleLockStake(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'lockStake'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'lockStake',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'lockStake'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"notifyRandomNumber"`.
 *
 *
 */
export function usePrepareSortitionModuleNotifyRandomNumber(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'notifyRandomNumber'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'notifyRandomNumber',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'notifyRandomNumber'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"passPhase"`.
 *
 *
 */
export function usePrepareSortitionModulePassPhase(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'passPhase'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'passPhase',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'passPhase'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"penalizeStake"`.
 *
 *
 */
export function usePrepareSortitionModulePenalizeStake(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'penalizeStake'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'penalizeStake',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'penalizeStake'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"postDrawHook"`.
 *
 *
 */
export function usePrepareSortitionModulePostDrawHook(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'postDrawHook'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'postDrawHook',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'postDrawHook'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"setJurorInactive"`.
 *
 *
 */
export function usePrepareSortitionModuleSetJurorInactive(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'setJurorInactive'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'setJurorInactive',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'setJurorInactive'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"setStake"`.
 *
 *
 */
export function usePrepareSortitionModuleSetStake(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'setStake'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'setStake',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'setStake'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"unlockStake"`.
 *
 *
 */
export function usePrepareSortitionModuleUnlockStake(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'unlockStake'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'unlockStake',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'unlockStake'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function usePrepareSortitionModuleUpgradeToAndCall(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'upgradeToAndCall'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleABI, 'upgradeToAndCall'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sortitionModuleABI}__.
 *
 *
 */
export function useSortitionModuleEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof sortitionModuleABI, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof sortitionModuleAddress
  } = {} as any
) {
  return useContractEvent({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    ...config,
  } as UseContractEventConfig<typeof sortitionModuleABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sortitionModuleABI}__ and `eventName` set to `"Initialized"`.
 *
 *
 */
export function useSortitionModuleInitializedEvent(
  config: Omit<UseContractEventConfig<typeof sortitionModuleABI, 'Initialized'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof sortitionModuleAddress
  } = {} as any
) {
  return useContractEvent({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    eventName: 'Initialized',
    ...config,
  } as UseContractEventConfig<typeof sortitionModuleABI, 'Initialized'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sortitionModuleABI}__ and `eventName` set to `"NewPhase"`.
 *
 *
 */
export function useSortitionModuleNewPhaseEvent(
  config: Omit<UseContractEventConfig<typeof sortitionModuleABI, 'NewPhase'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof sortitionModuleAddress
  } = {} as any
) {
  return useContractEvent({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    eventName: 'NewPhase',
    ...config,
  } as UseContractEventConfig<typeof sortitionModuleABI, 'NewPhase'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sortitionModuleABI}__ and `eventName` set to `"StakeDelayedAlreadyTransferred"`.
 *
 *
 */
export function useSortitionModuleStakeDelayedAlreadyTransferredEvent(
  config: Omit<
    UseContractEventConfig<typeof sortitionModuleABI, 'StakeDelayedAlreadyTransferred'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractEvent({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    eventName: 'StakeDelayedAlreadyTransferred',
    ...config,
  } as UseContractEventConfig<typeof sortitionModuleABI, 'StakeDelayedAlreadyTransferred'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sortitionModuleABI}__ and `eventName` set to `"StakeDelayedAlreadyTransferredWithdrawn"`.
 *
 *
 */
export function useSortitionModuleStakeDelayedAlreadyTransferredWithdrawnEvent(
  config: Omit<
    UseContractEventConfig<typeof sortitionModuleABI, 'StakeDelayedAlreadyTransferredWithdrawn'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractEvent({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    eventName: 'StakeDelayedAlreadyTransferredWithdrawn',
    ...config,
  } as UseContractEventConfig<typeof sortitionModuleABI, 'StakeDelayedAlreadyTransferredWithdrawn'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sortitionModuleABI}__ and `eventName` set to `"StakeDelayedNotTransferred"`.
 *
 *
 */
export function useSortitionModuleStakeDelayedNotTransferredEvent(
  config: Omit<
    UseContractEventConfig<typeof sortitionModuleABI, 'StakeDelayedNotTransferred'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof sortitionModuleAddress } = {} as any
) {
  return useContractEvent({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    eventName: 'StakeDelayedNotTransferred',
    ...config,
  } as UseContractEventConfig<typeof sortitionModuleABI, 'StakeDelayedNotTransferred'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sortitionModuleABI}__ and `eventName` set to `"StakeLocked"`.
 *
 *
 */
export function useSortitionModuleStakeLockedEvent(
  config: Omit<UseContractEventConfig<typeof sortitionModuleABI, 'StakeLocked'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof sortitionModuleAddress
  } = {} as any
) {
  return useContractEvent({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    eventName: 'StakeLocked',
    ...config,
  } as UseContractEventConfig<typeof sortitionModuleABI, 'StakeLocked'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sortitionModuleABI}__ and `eventName` set to `"StakeSet"`.
 *
 *
 */
export function useSortitionModuleStakeSetEvent(
  config: Omit<UseContractEventConfig<typeof sortitionModuleABI, 'StakeSet'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof sortitionModuleAddress
  } = {} as any
) {
  return useContractEvent({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    eventName: 'StakeSet',
    ...config,
  } as UseContractEventConfig<typeof sortitionModuleABI, 'StakeSet'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sortitionModuleABI}__ and `eventName` set to `"Upgraded"`.
 *
 *
 */
export function useSortitionModuleUpgradedEvent(
  config: Omit<UseContractEventConfig<typeof sortitionModuleABI, 'Upgraded'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof sortitionModuleAddress
  } = {} as any
) {
  return useContractEvent({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    eventName: 'Upgraded',
    ...config,
  } as UseContractEventConfig<typeof sortitionModuleABI, 'Upgraded'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleImplementationABI}__.
 *
 *
 */
export function useSortitionModuleImplementationRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof sortitionModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"DEFAULT_K"`.
 *
 *
 */
export function useSortitionModuleImplementationDefaultK<
  TFunctionName extends 'DEFAULT_K',
  TSelectData = ReadContractResult<typeof sortitionModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'DEFAULT_K',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"MAX_STAKE_PATHS"`.
 *
 *
 */
export function useSortitionModuleImplementationMaxStakePaths<
  TFunctionName extends 'MAX_STAKE_PATHS',
  TSelectData = ReadContractResult<typeof sortitionModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'MAX_STAKE_PATHS',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"core"`.
 *
 *
 */
export function useSortitionModuleImplementationCore<
  TFunctionName extends 'core',
  TSelectData = ReadContractResult<typeof sortitionModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'core',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"delayedStakeReadIndex"`.
 *
 *
 */
export function useSortitionModuleImplementationDelayedStakeReadIndex<
  TFunctionName extends 'delayedStakeReadIndex',
  TSelectData = ReadContractResult<typeof sortitionModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'delayedStakeReadIndex',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"delayedStakeWriteIndex"`.
 *
 *
 */
export function useSortitionModuleImplementationDelayedStakeWriteIndex<
  TFunctionName extends 'delayedStakeWriteIndex',
  TSelectData = ReadContractResult<typeof sortitionModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'delayedStakeWriteIndex',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"delayedStakes"`.
 *
 *
 */
export function useSortitionModuleImplementationDelayedStakes<
  TFunctionName extends 'delayedStakes',
  TSelectData = ReadContractResult<typeof sortitionModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'delayedStakes',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"disputesWithoutJurors"`.
 *
 *
 */
export function useSortitionModuleImplementationDisputesWithoutJurors<
  TFunctionName extends 'disputesWithoutJurors',
  TSelectData = ReadContractResult<typeof sortitionModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'disputesWithoutJurors',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"draw"`.
 *
 *
 */
export function useSortitionModuleImplementationDraw<
  TFunctionName extends 'draw',
  TSelectData = ReadContractResult<typeof sortitionModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'draw',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"getJurorBalance"`.
 *
 *
 */
export function useSortitionModuleImplementationGetJurorBalance<
  TFunctionName extends 'getJurorBalance',
  TSelectData = ReadContractResult<typeof sortitionModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'getJurorBalance',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"getJurorCourtIDs"`.
 *
 *
 */
export function useSortitionModuleImplementationGetJurorCourtIDs<
  TFunctionName extends 'getJurorCourtIDs',
  TSelectData = ReadContractResult<typeof sortitionModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'getJurorCourtIDs',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"governor"`.
 *
 *
 */
export function useSortitionModuleImplementationGovernor<
  TFunctionName extends 'governor',
  TSelectData = ReadContractResult<typeof sortitionModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'governor',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"isJurorStaked"`.
 *
 *
 */
export function useSortitionModuleImplementationIsJurorStaked<
  TFunctionName extends 'isJurorStaked',
  TSelectData = ReadContractResult<typeof sortitionModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'isJurorStaked',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"jurors"`.
 *
 *
 */
export function useSortitionModuleImplementationJurors<
  TFunctionName extends 'jurors',
  TSelectData = ReadContractResult<typeof sortitionModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'jurors',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"lastPhaseChange"`.
 *
 *
 */
export function useSortitionModuleImplementationLastPhaseChange<
  TFunctionName extends 'lastPhaseChange',
  TSelectData = ReadContractResult<typeof sortitionModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'lastPhaseChange',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"latestDelayedStakeIndex"`.
 *
 *
 */
export function useSortitionModuleImplementationLatestDelayedStakeIndex<
  TFunctionName extends 'latestDelayedStakeIndex',
  TSelectData = ReadContractResult<typeof sortitionModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'latestDelayedStakeIndex',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"maxDrawingTime"`.
 *
 *
 */
export function useSortitionModuleImplementationMaxDrawingTime<
  TFunctionName extends 'maxDrawingTime',
  TSelectData = ReadContractResult<typeof sortitionModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'maxDrawingTime',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"minStakingTime"`.
 *
 *
 */
export function useSortitionModuleImplementationMinStakingTime<
  TFunctionName extends 'minStakingTime',
  TSelectData = ReadContractResult<typeof sortitionModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'minStakingTime',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"phase"`.
 *
 *
 */
export function useSortitionModuleImplementationPhase<
  TFunctionName extends 'phase',
  TSelectData = ReadContractResult<typeof sortitionModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'phase',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"proxiableUUID"`.
 *
 *
 */
export function useSortitionModuleImplementationProxiableUuid<
  TFunctionName extends 'proxiableUUID',
  TSelectData = ReadContractResult<typeof sortitionModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'proxiableUUID',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"randomNumber"`.
 *
 *
 */
export function useSortitionModuleImplementationRandomNumber<
  TFunctionName extends 'randomNumber',
  TSelectData = ReadContractResult<typeof sortitionModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'randomNumber',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"randomNumberRequestBlock"`.
 *
 *
 */
export function useSortitionModuleImplementationRandomNumberRequestBlock<
  TFunctionName extends 'randomNumberRequestBlock',
  TSelectData = ReadContractResult<typeof sortitionModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'randomNumberRequestBlock',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"rng"`.
 *
 *
 */
export function useSortitionModuleImplementationRng<
  TFunctionName extends 'rng',
  TSelectData = ReadContractResult<typeof sortitionModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'rng',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"rngLookahead"`.
 *
 *
 */
export function useSortitionModuleImplementationRngLookahead<
  TFunctionName extends 'rngLookahead',
  TSelectData = ReadContractResult<typeof sortitionModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'rngLookahead',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"stakeOf"`.
 *
 *
 */
export function useSortitionModuleImplementationStakeOf<
  TFunctionName extends 'stakeOf',
  TSelectData = ReadContractResult<typeof sortitionModuleImplementationABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractRead({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'stakeOf',
    ...config,
  } as UseContractReadConfig<typeof sortitionModuleImplementationABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__.
 *
 *
 */
export function useSortitionModuleImplementationWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleImplementationABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof sortitionModuleImplementationABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleImplementationABI, TFunctionName, TMode>({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"changeMaxDrawingTime"`.
 *
 *
 */
export function useSortitionModuleImplementationChangeMaxDrawingTime<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleImplementationABI, 'changeMaxDrawingTime'>['request']['abi'],
        'changeMaxDrawingTime',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeMaxDrawingTime' }
    : UseContractWriteConfig<typeof sortitionModuleImplementationABI, 'changeMaxDrawingTime', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeMaxDrawingTime'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleImplementationABI, 'changeMaxDrawingTime', TMode>({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'changeMaxDrawingTime',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"changeMinStakingTime"`.
 *
 *
 */
export function useSortitionModuleImplementationChangeMinStakingTime<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleImplementationABI, 'changeMinStakingTime'>['request']['abi'],
        'changeMinStakingTime',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeMinStakingTime' }
    : UseContractWriteConfig<typeof sortitionModuleImplementationABI, 'changeMinStakingTime', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeMinStakingTime'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleImplementationABI, 'changeMinStakingTime', TMode>({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'changeMinStakingTime',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"changeRandomNumberGenerator"`.
 *
 *
 */
export function useSortitionModuleImplementationChangeRandomNumberGenerator<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof sortitionModuleImplementationABI,
          'changeRandomNumberGenerator'
        >['request']['abi'],
        'changeRandomNumberGenerator',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeRandomNumberGenerator' }
    : UseContractWriteConfig<typeof sortitionModuleImplementationABI, 'changeRandomNumberGenerator', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeRandomNumberGenerator'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleImplementationABI, 'changeRandomNumberGenerator', TMode>({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'changeRandomNumberGenerator',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"createDisputeHook"`.
 *
 *
 */
export function useSortitionModuleImplementationCreateDisputeHook<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleImplementationABI, 'createDisputeHook'>['request']['abi'],
        'createDisputeHook',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'createDisputeHook' }
    : UseContractWriteConfig<typeof sortitionModuleImplementationABI, 'createDisputeHook', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'createDisputeHook'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleImplementationABI, 'createDisputeHook', TMode>({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'createDisputeHook',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"createTree"`.
 *
 *
 */
export function useSortitionModuleImplementationCreateTree<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleImplementationABI, 'createTree'>['request']['abi'],
        'createTree',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'createTree' }
    : UseContractWriteConfig<typeof sortitionModuleImplementationABI, 'createTree', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'createTree'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleImplementationABI, 'createTree', TMode>({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'createTree',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"executeDelayedStakes"`.
 *
 *
 */
export function useSortitionModuleImplementationExecuteDelayedStakes<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleImplementationABI, 'executeDelayedStakes'>['request']['abi'],
        'executeDelayedStakes',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'executeDelayedStakes' }
    : UseContractWriteConfig<typeof sortitionModuleImplementationABI, 'executeDelayedStakes', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'executeDelayedStakes'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleImplementationABI, 'executeDelayedStakes', TMode>({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'executeDelayedStakes',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function useSortitionModuleImplementationInitialize<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleImplementationABI, 'initialize'>['request']['abi'],
        'initialize',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'initialize' }
    : UseContractWriteConfig<typeof sortitionModuleImplementationABI, 'initialize', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'initialize'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleImplementationABI, 'initialize', TMode>({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'initialize',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"lockStake"`.
 *
 *
 */
export function useSortitionModuleImplementationLockStake<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleImplementationABI, 'lockStake'>['request']['abi'],
        'lockStake',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'lockStake' }
    : UseContractWriteConfig<typeof sortitionModuleImplementationABI, 'lockStake', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'lockStake'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleImplementationABI, 'lockStake', TMode>({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'lockStake',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"notifyRandomNumber"`.
 *
 *
 */
export function useSortitionModuleImplementationNotifyRandomNumber<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleImplementationABI, 'notifyRandomNumber'>['request']['abi'],
        'notifyRandomNumber',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'notifyRandomNumber' }
    : UseContractWriteConfig<typeof sortitionModuleImplementationABI, 'notifyRandomNumber', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'notifyRandomNumber'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleImplementationABI, 'notifyRandomNumber', TMode>({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'notifyRandomNumber',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"passPhase"`.
 *
 *
 */
export function useSortitionModuleImplementationPassPhase<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleImplementationABI, 'passPhase'>['request']['abi'],
        'passPhase',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'passPhase' }
    : UseContractWriteConfig<typeof sortitionModuleImplementationABI, 'passPhase', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'passPhase'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleImplementationABI, 'passPhase', TMode>({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'passPhase',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"penalizeStake"`.
 *
 *
 */
export function useSortitionModuleImplementationPenalizeStake<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleImplementationABI, 'penalizeStake'>['request']['abi'],
        'penalizeStake',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'penalizeStake' }
    : UseContractWriteConfig<typeof sortitionModuleImplementationABI, 'penalizeStake', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'penalizeStake'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleImplementationABI, 'penalizeStake', TMode>({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'penalizeStake',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"postDrawHook"`.
 *
 *
 */
export function useSortitionModuleImplementationPostDrawHook<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleImplementationABI, 'postDrawHook'>['request']['abi'],
        'postDrawHook',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'postDrawHook' }
    : UseContractWriteConfig<typeof sortitionModuleImplementationABI, 'postDrawHook', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'postDrawHook'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleImplementationABI, 'postDrawHook', TMode>({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'postDrawHook',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"setJurorInactive"`.
 *
 *
 */
export function useSortitionModuleImplementationSetJurorInactive<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleImplementationABI, 'setJurorInactive'>['request']['abi'],
        'setJurorInactive',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setJurorInactive' }
    : UseContractWriteConfig<typeof sortitionModuleImplementationABI, 'setJurorInactive', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setJurorInactive'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleImplementationABI, 'setJurorInactive', TMode>({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'setJurorInactive',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"setStake"`.
 *
 *
 */
export function useSortitionModuleImplementationSetStake<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleImplementationABI, 'setStake'>['request']['abi'],
        'setStake',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setStake' }
    : UseContractWriteConfig<typeof sortitionModuleImplementationABI, 'setStake', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setStake'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleImplementationABI, 'setStake', TMode>({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'setStake',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"unlockStake"`.
 *
 *
 */
export function useSortitionModuleImplementationUnlockStake<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleImplementationABI, 'unlockStake'>['request']['abi'],
        'unlockStake',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'unlockStake' }
    : UseContractWriteConfig<typeof sortitionModuleImplementationABI, 'unlockStake', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'unlockStake'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleImplementationABI, 'unlockStake', TMode>({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'unlockStake',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function useSortitionModuleImplementationUpgradeToAndCall<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof sortitionModuleImplementationAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof sortitionModuleImplementationABI, 'upgradeToAndCall'>['request']['abi'],
        'upgradeToAndCall',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'upgradeToAndCall' }
    : UseContractWriteConfig<typeof sortitionModuleImplementationABI, 'upgradeToAndCall', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'upgradeToAndCall'
      } = {} as any
) {
  return useContractWrite<typeof sortitionModuleImplementationABI, 'upgradeToAndCall', TMode>({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__.
 *
 *
 */
export function usePrepareSortitionModuleImplementationWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"changeMaxDrawingTime"`.
 *
 *
 */
export function usePrepareSortitionModuleImplementationChangeMaxDrawingTime(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'changeMaxDrawingTime'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'changeMaxDrawingTime',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'changeMaxDrawingTime'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"changeMinStakingTime"`.
 *
 *
 */
export function usePrepareSortitionModuleImplementationChangeMinStakingTime(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'changeMinStakingTime'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'changeMinStakingTime',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'changeMinStakingTime'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"changeRandomNumberGenerator"`.
 *
 *
 */
export function usePrepareSortitionModuleImplementationChangeRandomNumberGenerator(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'changeRandomNumberGenerator'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'changeRandomNumberGenerator',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'changeRandomNumberGenerator'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"createDisputeHook"`.
 *
 *
 */
export function usePrepareSortitionModuleImplementationCreateDisputeHook(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'createDisputeHook'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'createDisputeHook',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'createDisputeHook'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"createTree"`.
 *
 *
 */
export function usePrepareSortitionModuleImplementationCreateTree(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'createTree'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'createTree',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'createTree'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"executeDelayedStakes"`.
 *
 *
 */
export function usePrepareSortitionModuleImplementationExecuteDelayedStakes(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'executeDelayedStakes'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'executeDelayedStakes',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'executeDelayedStakes'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"initialize"`.
 *
 *
 */
export function usePrepareSortitionModuleImplementationInitialize(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'initialize'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'initialize',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'initialize'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"lockStake"`.
 *
 *
 */
export function usePrepareSortitionModuleImplementationLockStake(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'lockStake'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'lockStake',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'lockStake'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"notifyRandomNumber"`.
 *
 *
 */
export function usePrepareSortitionModuleImplementationNotifyRandomNumber(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'notifyRandomNumber'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'notifyRandomNumber',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'notifyRandomNumber'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"passPhase"`.
 *
 *
 */
export function usePrepareSortitionModuleImplementationPassPhase(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'passPhase'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'passPhase',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'passPhase'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"penalizeStake"`.
 *
 *
 */
export function usePrepareSortitionModuleImplementationPenalizeStake(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'penalizeStake'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'penalizeStake',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'penalizeStake'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"postDrawHook"`.
 *
 *
 */
export function usePrepareSortitionModuleImplementationPostDrawHook(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'postDrawHook'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'postDrawHook',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'postDrawHook'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"setJurorInactive"`.
 *
 *
 */
export function usePrepareSortitionModuleImplementationSetJurorInactive(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'setJurorInactive'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'setJurorInactive',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'setJurorInactive'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"setStake"`.
 *
 *
 */
export function usePrepareSortitionModuleImplementationSetStake(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'setStake'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'setStake',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'setStake'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"unlockStake"`.
 *
 *
 */
export function usePrepareSortitionModuleImplementationUnlockStake(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'unlockStake'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'unlockStake',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'unlockStake'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 *
 */
export function usePrepareSortitionModuleImplementationUpgradeToAndCall(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'upgradeToAndCall'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    functionName: 'upgradeToAndCall',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sortitionModuleImplementationABI, 'upgradeToAndCall'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sortitionModuleImplementationABI}__.
 *
 *
 */
export function useSortitionModuleImplementationEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof sortitionModuleImplementationABI, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof sortitionModuleImplementationAddress
  } = {} as any
) {
  return useContractEvent({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    ...config,
  } as UseContractEventConfig<typeof sortitionModuleImplementationABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `eventName` set to `"Initialized"`.
 *
 *
 */
export function useSortitionModuleImplementationInitializedEvent(
  config: Omit<
    UseContractEventConfig<typeof sortitionModuleImplementationABI, 'Initialized'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    eventName: 'Initialized',
    ...config,
  } as UseContractEventConfig<typeof sortitionModuleImplementationABI, 'Initialized'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `eventName` set to `"NewPhase"`.
 *
 *
 */
export function useSortitionModuleImplementationNewPhaseEvent(
  config: Omit<
    UseContractEventConfig<typeof sortitionModuleImplementationABI, 'NewPhase'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    eventName: 'NewPhase',
    ...config,
  } as UseContractEventConfig<typeof sortitionModuleImplementationABI, 'NewPhase'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `eventName` set to `"StakeDelayedAlreadyTransferred"`.
 *
 *
 */
export function useSortitionModuleImplementationStakeDelayedAlreadyTransferredEvent(
  config: Omit<
    UseContractEventConfig<typeof sortitionModuleImplementationABI, 'StakeDelayedAlreadyTransferred'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    eventName: 'StakeDelayedAlreadyTransferred',
    ...config,
  } as UseContractEventConfig<typeof sortitionModuleImplementationABI, 'StakeDelayedAlreadyTransferred'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `eventName` set to `"StakeDelayedAlreadyTransferredWithdrawn"`.
 *
 *
 */
export function useSortitionModuleImplementationStakeDelayedAlreadyTransferredWithdrawnEvent(
  config: Omit<
    UseContractEventConfig<typeof sortitionModuleImplementationABI, 'StakeDelayedAlreadyTransferredWithdrawn'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    eventName: 'StakeDelayedAlreadyTransferredWithdrawn',
    ...config,
  } as UseContractEventConfig<typeof sortitionModuleImplementationABI, 'StakeDelayedAlreadyTransferredWithdrawn'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `eventName` set to `"StakeDelayedNotTransferred"`.
 *
 *
 */
export function useSortitionModuleImplementationStakeDelayedNotTransferredEvent(
  config: Omit<
    UseContractEventConfig<typeof sortitionModuleImplementationABI, 'StakeDelayedNotTransferred'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    eventName: 'StakeDelayedNotTransferred',
    ...config,
  } as UseContractEventConfig<typeof sortitionModuleImplementationABI, 'StakeDelayedNotTransferred'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `eventName` set to `"StakeLocked"`.
 *
 *
 */
export function useSortitionModuleImplementationStakeLockedEvent(
  config: Omit<
    UseContractEventConfig<typeof sortitionModuleImplementationABI, 'StakeLocked'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    eventName: 'StakeLocked',
    ...config,
  } as UseContractEventConfig<typeof sortitionModuleImplementationABI, 'StakeLocked'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `eventName` set to `"StakeSet"`.
 *
 *
 */
export function useSortitionModuleImplementationStakeSetEvent(
  config: Omit<
    UseContractEventConfig<typeof sortitionModuleImplementationABI, 'StakeSet'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    eventName: 'StakeSet',
    ...config,
  } as UseContractEventConfig<typeof sortitionModuleImplementationABI, 'StakeSet'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sortitionModuleImplementationABI}__ and `eventName` set to `"Upgraded"`.
 *
 *
 */
export function useSortitionModuleImplementationUpgradedEvent(
  config: Omit<
    UseContractEventConfig<typeof sortitionModuleImplementationABI, 'Upgraded'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof sortitionModuleImplementationAddress } = {} as any
) {
  return useContractEvent({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    eventName: 'Upgraded',
    ...config,
  } as UseContractEventConfig<typeof sortitionModuleImplementationABI, 'Upgraded'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wethABI}__.
 *
 *
 */
export function useWethRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof wethABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof wethABI, TFunctionName, TSelectData>, 'abi' | 'address'> & {
    chainId?: keyof typeof wethAddress
  } = {} as any
) {
  return useContractRead({ abi: wethABI, address: wethAddress[421614], ...config } as UseContractReadConfig<
    typeof wethABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wethABI}__ and `functionName` set to `"allowance"`.
 *
 *
 */
export function useWethAllowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof wethABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof wethABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wethAddress } = {} as any
) {
  return useContractRead({
    abi: wethABI,
    address: wethAddress[421614],
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<typeof wethABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wethABI}__ and `functionName` set to `"balanceOf"`.
 *
 *
 */
export function useWethBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof wethABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof wethABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wethAddress } = {} as any
) {
  return useContractRead({
    abi: wethABI,
    address: wethAddress[421614],
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof wethABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wethABI}__ and `functionName` set to `"decimals"`.
 *
 *
 */
export function useWethDecimals<
  TFunctionName extends 'decimals',
  TSelectData = ReadContractResult<typeof wethABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof wethABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wethAddress } = {} as any
) {
  return useContractRead({
    abi: wethABI,
    address: wethAddress[421614],
    functionName: 'decimals',
    ...config,
  } as UseContractReadConfig<typeof wethABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wethABI}__ and `functionName` set to `"name"`.
 *
 *
 */
export function useWethName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof wethABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof wethABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wethAddress } = {} as any
) {
  return useContractRead({
    abi: wethABI,
    address: wethAddress[421614],
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof wethABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wethABI}__ and `functionName` set to `"symbol"`.
 *
 *
 */
export function useWethSymbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof wethABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof wethABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wethAddress } = {} as any
) {
  return useContractRead({
    abi: wethABI,
    address: wethAddress[421614],
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<typeof wethABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wethABI}__ and `functionName` set to `"totalSupply"`.
 *
 *
 */
export function useWethTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof wethABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof wethABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wethAddress } = {} as any
) {
  return useContractRead({
    abi: wethABI,
    address: wethAddress[421614],
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof wethABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link wethABI}__.
 *
 *
 */
export function useWethWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof wethAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof wethABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof wethABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof wethABI, TFunctionName, TMode>({
    abi: wethABI,
    address: wethAddress[421614],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link wethABI}__ and `functionName` set to `"approve"`.
 *
 *
 */
export function useWethApprove<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof wethAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof wethABI, 'approve'>['request']['abi'],
        'approve',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'approve' }
    : UseContractWriteConfig<typeof wethABI, 'approve', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'approve'
      } = {} as any
) {
  return useContractWrite<typeof wethABI, 'approve', TMode>({
    abi: wethABI,
    address: wethAddress[421614],
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link wethABI}__ and `functionName` set to `"decreaseAllowance"`.
 *
 *
 */
export function useWethDecreaseAllowance<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof wethAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof wethABI, 'decreaseAllowance'>['request']['abi'],
        'decreaseAllowance',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'decreaseAllowance' }
    : UseContractWriteConfig<typeof wethABI, 'decreaseAllowance', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'decreaseAllowance'
      } = {} as any
) {
  return useContractWrite<typeof wethABI, 'decreaseAllowance', TMode>({
    abi: wethABI,
    address: wethAddress[421614],
    functionName: 'decreaseAllowance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link wethABI}__ and `functionName` set to `"increaseAllowance"`.
 *
 *
 */
export function useWethIncreaseAllowance<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof wethAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof wethABI, 'increaseAllowance'>['request']['abi'],
        'increaseAllowance',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'increaseAllowance' }
    : UseContractWriteConfig<typeof wethABI, 'increaseAllowance', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'increaseAllowance'
      } = {} as any
) {
  return useContractWrite<typeof wethABI, 'increaseAllowance', TMode>({
    abi: wethABI,
    address: wethAddress[421614],
    functionName: 'increaseAllowance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link wethABI}__ and `functionName` set to `"transfer"`.
 *
 *
 */
export function useWethTransfer<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof wethAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof wethABI, 'transfer'>['request']['abi'],
        'transfer',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'transfer' }
    : UseContractWriteConfig<typeof wethABI, 'transfer', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'transfer'
      } = {} as any
) {
  return useContractWrite<typeof wethABI, 'transfer', TMode>({
    abi: wethABI,
    address: wethAddress[421614],
    functionName: 'transfer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link wethABI}__ and `functionName` set to `"transferFrom"`.
 *
 *
 */
export function useWethTransferFrom<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof wethAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof wethABI, 'transferFrom'>['request']['abi'],
        'transferFrom',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'transferFrom' }
    : UseContractWriteConfig<typeof wethABI, 'transferFrom', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'transferFrom'
      } = {} as any
) {
  return useContractWrite<typeof wethABI, 'transferFrom', TMode>({
    abi: wethABI,
    address: wethAddress[421614],
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link wethABI}__.
 *
 *
 */
export function usePrepareWethWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof wethABI, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof wethAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: wethABI,
    address: wethAddress[421614],
    ...config,
  } as UsePrepareContractWriteConfig<typeof wethABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link wethABI}__ and `functionName` set to `"approve"`.
 *
 *
 */
export function usePrepareWethApprove(
  config: Omit<UsePrepareContractWriteConfig<typeof wethABI, 'approve'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof wethAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: wethABI,
    address: wethAddress[421614],
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof wethABI, 'approve'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link wethABI}__ and `functionName` set to `"decreaseAllowance"`.
 *
 *
 */
export function usePrepareWethDecreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof wethABI, 'decreaseAllowance'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wethAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: wethABI,
    address: wethAddress[421614],
    functionName: 'decreaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof wethABI, 'decreaseAllowance'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link wethABI}__ and `functionName` set to `"increaseAllowance"`.
 *
 *
 */
export function usePrepareWethIncreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof wethABI, 'increaseAllowance'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wethAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: wethABI,
    address: wethAddress[421614],
    functionName: 'increaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof wethABI, 'increaseAllowance'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link wethABI}__ and `functionName` set to `"transfer"`.
 *
 *
 */
export function usePrepareWethTransfer(
  config: Omit<UsePrepareContractWriteConfig<typeof wethABI, 'transfer'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof wethAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: wethABI,
    address: wethAddress[421614],
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof wethABI, 'transfer'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link wethABI}__ and `functionName` set to `"transferFrom"`.
 *
 *
 */
export function usePrepareWethTransferFrom(
  config: Omit<UsePrepareContractWriteConfig<typeof wethABI, 'transferFrom'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof wethAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: wethABI,
    address: wethAddress[421614],
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof wethABI, 'transferFrom'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link wethABI}__.
 *
 *
 */
export function useWethEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof wethABI, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof wethAddress
  } = {} as any
) {
  return useContractEvent({ abi: wethABI, address: wethAddress[421614], ...config } as UseContractEventConfig<
    typeof wethABI,
    TEventName
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link wethABI}__ and `eventName` set to `"Approval"`.
 *
 *
 */
export function useWethApprovalEvent(
  config: Omit<UseContractEventConfig<typeof wethABI, 'Approval'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof wethAddress
  } = {} as any
) {
  return useContractEvent({
    abi: wethABI,
    address: wethAddress[421614],
    eventName: 'Approval',
    ...config,
  } as UseContractEventConfig<typeof wethABI, 'Approval'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link wethABI}__ and `eventName` set to `"Transfer"`.
 *
 *
 */
export function useWethTransferEvent(
  config: Omit<UseContractEventConfig<typeof wethABI, 'Transfer'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof wethAddress
  } = {} as any
) {
  return useContractEvent({
    abi: wethABI,
    address: wethAddress[421614],
    eventName: 'Transfer',
    ...config,
  } as UseContractEventConfig<typeof wethABI, 'Transfer'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wethFaucetABI}__.
 *
 *
 */
export function useWethFaucetRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof wethFaucetABI, TFunctionName>
>(
  config: Omit<UseContractReadConfig<typeof wethFaucetABI, TFunctionName, TSelectData>, 'abi' | 'address'> & {
    chainId?: keyof typeof wethFaucetAddress
  } = {} as any
) {
  return useContractRead({ abi: wethFaucetABI, address: wethFaucetAddress[421614], ...config } as UseContractReadConfig<
    typeof wethFaucetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wethFaucetABI}__ and `functionName` set to `"amount"`.
 *
 *
 */
export function useWethFaucetAmount<
  TFunctionName extends 'amount',
  TSelectData = ReadContractResult<typeof wethFaucetABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof wethFaucetABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wethFaucetAddress } = {} as any
) {
  return useContractRead({
    abi: wethFaucetABI,
    address: wethFaucetAddress[421614],
    functionName: 'amount',
    ...config,
  } as UseContractReadConfig<typeof wethFaucetABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wethFaucetABI}__ and `functionName` set to `"balance"`.
 *
 *
 */
export function useWethFaucetBalance<
  TFunctionName extends 'balance',
  TSelectData = ReadContractResult<typeof wethFaucetABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof wethFaucetABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wethFaucetAddress } = {} as any
) {
  return useContractRead({
    abi: wethFaucetABI,
    address: wethFaucetAddress[421614],
    functionName: 'balance',
    ...config,
  } as UseContractReadConfig<typeof wethFaucetABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wethFaucetABI}__ and `functionName` set to `"governor"`.
 *
 *
 */
export function useWethFaucetGovernor<
  TFunctionName extends 'governor',
  TSelectData = ReadContractResult<typeof wethFaucetABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof wethFaucetABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wethFaucetAddress } = {} as any
) {
  return useContractRead({
    abi: wethFaucetABI,
    address: wethFaucetAddress[421614],
    functionName: 'governor',
    ...config,
  } as UseContractReadConfig<typeof wethFaucetABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wethFaucetABI}__ and `functionName` set to `"token"`.
 *
 *
 */
export function useWethFaucetToken<
  TFunctionName extends 'token',
  TSelectData = ReadContractResult<typeof wethFaucetABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof wethFaucetABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wethFaucetAddress } = {} as any
) {
  return useContractRead({
    abi: wethFaucetABI,
    address: wethFaucetAddress[421614],
    functionName: 'token',
    ...config,
  } as UseContractReadConfig<typeof wethFaucetABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wethFaucetABI}__ and `functionName` set to `"withdrewAlready"`.
 *
 *
 */
export function useWethFaucetWithdrewAlready<
  TFunctionName extends 'withdrewAlready',
  TSelectData = ReadContractResult<typeof wethFaucetABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof wethFaucetABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wethFaucetAddress } = {} as any
) {
  return useContractRead({
    abi: wethFaucetABI,
    address: wethFaucetAddress[421614],
    functionName: 'withdrewAlready',
    ...config,
  } as UseContractReadConfig<typeof wethFaucetABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link wethFaucetABI}__.
 *
 *
 */
export function useWethFaucetWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof wethFaucetAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof wethFaucetABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof wethFaucetABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof wethFaucetABI, TFunctionName, TMode>({
    abi: wethFaucetABI,
    address: wethFaucetAddress[421614],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link wethFaucetABI}__ and `functionName` set to `"changeAmount"`.
 *
 *
 */
export function useWethFaucetChangeAmount<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof wethFaucetAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof wethFaucetABI, 'changeAmount'>['request']['abi'],
        'changeAmount',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeAmount' }
    : UseContractWriteConfig<typeof wethFaucetABI, 'changeAmount', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeAmount'
      } = {} as any
) {
  return useContractWrite<typeof wethFaucetABI, 'changeAmount', TMode>({
    abi: wethFaucetABI,
    address: wethFaucetAddress[421614],
    functionName: 'changeAmount',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link wethFaucetABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function useWethFaucetChangeGovernor<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof wethFaucetAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof wethFaucetABI, 'changeGovernor'>['request']['abi'],
        'changeGovernor',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeGovernor' }
    : UseContractWriteConfig<typeof wethFaucetABI, 'changeGovernor', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeGovernor'
      } = {} as any
) {
  return useContractWrite<typeof wethFaucetABI, 'changeGovernor', TMode>({
    abi: wethFaucetABI,
    address: wethFaucetAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link wethFaucetABI}__ and `functionName` set to `"request"`.
 *
 *
 */
export function useWethFaucetRequest<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof wethFaucetAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof wethFaucetABI, 'request'>['request']['abi'],
        'request',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'request' }
    : UseContractWriteConfig<typeof wethFaucetABI, 'request', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'request'
      } = {} as any
) {
  return useContractWrite<typeof wethFaucetABI, 'request', TMode>({
    abi: wethFaucetABI,
    address: wethFaucetAddress[421614],
    functionName: 'request',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link wethFaucetABI}__ and `functionName` set to `"withdraw"`.
 *
 *
 */
export function useWethFaucetWithdraw<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof wethFaucetAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof wethFaucetABI, 'withdraw'>['request']['abi'],
        'withdraw',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'withdraw' }
    : UseContractWriteConfig<typeof wethFaucetABI, 'withdraw', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'withdraw'
      } = {} as any
) {
  return useContractWrite<typeof wethFaucetABI, 'withdraw', TMode>({
    abi: wethFaucetABI,
    address: wethFaucetAddress[421614],
    functionName: 'withdraw',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link wethFaucetABI}__.
 *
 *
 */
export function usePrepareWethFaucetWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof wethFaucetABI, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof wethFaucetAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: wethFaucetABI,
    address: wethFaucetAddress[421614],
    ...config,
  } as UsePrepareContractWriteConfig<typeof wethFaucetABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link wethFaucetABI}__ and `functionName` set to `"changeAmount"`.
 *
 *
 */
export function usePrepareWethFaucetChangeAmount(
  config: Omit<
    UsePrepareContractWriteConfig<typeof wethFaucetABI, 'changeAmount'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wethFaucetAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: wethFaucetABI,
    address: wethFaucetAddress[421614],
    functionName: 'changeAmount',
    ...config,
  } as UsePrepareContractWriteConfig<typeof wethFaucetABI, 'changeAmount'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link wethFaucetABI}__ and `functionName` set to `"changeGovernor"`.
 *
 *
 */
export function usePrepareWethFaucetChangeGovernor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof wethFaucetABI, 'changeGovernor'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wethFaucetAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: wethFaucetABI,
    address: wethFaucetAddress[421614],
    functionName: 'changeGovernor',
    ...config,
  } as UsePrepareContractWriteConfig<typeof wethFaucetABI, 'changeGovernor'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link wethFaucetABI}__ and `functionName` set to `"request"`.
 *
 *
 */
export function usePrepareWethFaucetRequest(
  config: Omit<UsePrepareContractWriteConfig<typeof wethFaucetABI, 'request'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof wethFaucetAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: wethFaucetABI,
    address: wethFaucetAddress[421614],
    functionName: 'request',
    ...config,
  } as UsePrepareContractWriteConfig<typeof wethFaucetABI, 'request'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link wethFaucetABI}__ and `functionName` set to `"withdraw"`.
 *
 *
 */
export function usePrepareWethFaucetWithdraw(
  config: Omit<UsePrepareContractWriteConfig<typeof wethFaucetABI, 'withdraw'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof wethFaucetAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: wethFaucetABI,
    address: wethFaucetAddress[421614],
    functionName: 'withdraw',
    ...config,
  } as UsePrepareContractWriteConfig<typeof wethFaucetABI, 'withdraw'>)
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Core
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link arbitrableExampleABI}__.
 *
 *
 */
export function getArbitrableExample(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof arbitrableExampleAddress }
) {
  return getContract({ abi: arbitrableExampleABI, address: arbitrableExampleAddress[421614], ...config })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link arbitrableExampleABI}__.
 *
 *
 */
export function readArbitrableExample<
  TAbi extends readonly unknown[] = typeof arbitrableExampleABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof arbitrableExampleAddress
  }
) {
  return readContract({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link arbitrableExampleABI}__.
 *
 *
 */
export function writeArbitrableExample<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof arbitrableExampleAddress
>(
  config:
    | (Omit<WriteContractPreparedArgs<typeof arbitrableExampleABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof arbitrableExampleAddress
      })
    | (Omit<WriteContractUnpreparedArgs<typeof arbitrableExampleABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof arbitrableExampleAddress
      })
) {
  return writeContract({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    ...config,
  } as unknown as WriteContractArgs<typeof arbitrableExampleABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link arbitrableExampleABI}__.
 *
 *
 */
export function prepareWriteArbitrableExample<
  TAbi extends readonly unknown[] = typeof arbitrableExampleABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof arbitrableExampleAddress
  }
) {
  return prepareWriteContract({
    abi: arbitrableExampleABI,
    address: arbitrableExampleAddress[421614],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link watchContractEvent}__ with `abi` set to __{@link arbitrableExampleABI}__.
 *
 *
 */
export function watchArbitrableExampleEvent<
  TAbi extends readonly unknown[] = typeof arbitrableExampleABI,
  TEventName extends string = string
>(
  config: Omit<WatchContractEventConfig<TAbi, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof arbitrableExampleAddress
  },
  callback: WatchContractEventCallback<TAbi, TEventName>
) {
  return watchContractEvent(
    { abi: arbitrableExampleABI, address: arbitrableExampleAddress[421614], ...config } as WatchContractEventConfig<
      TAbi,
      TEventName
    >,
    callback
  )
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link blockHashRngABI}__.
 *
 *
 */
export function getBlockHashRng(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof blockHashRngAddress }
) {
  return getContract({ abi: blockHashRngABI, address: blockHashRngAddress[421614], ...config })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link blockHashRngABI}__.
 *
 *
 */
export function readBlockHashRng<
  TAbi extends readonly unknown[] = typeof blockHashRngABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof blockHashRngAddress
  }
) {
  return readContract({
    abi: blockHashRngABI,
    address: blockHashRngAddress[421614],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link blockHashRngABI}__.
 *
 *
 */
export function writeBlockHashRng<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof blockHashRngAddress
>(
  config:
    | (Omit<WriteContractPreparedArgs<typeof blockHashRngABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof blockHashRngAddress
      })
    | (Omit<WriteContractUnpreparedArgs<typeof blockHashRngABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof blockHashRngAddress
      })
) {
  return writeContract({
    abi: blockHashRngABI,
    address: blockHashRngAddress[421614],
    ...config,
  } as unknown as WriteContractArgs<typeof blockHashRngABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link blockHashRngABI}__.
 *
 *
 */
export function prepareWriteBlockHashRng<
  TAbi extends readonly unknown[] = typeof blockHashRngABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof blockHashRngAddress
  }
) {
  return prepareWriteContract({
    abi: blockHashRngABI,
    address: blockHashRngAddress[421614],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link daiABI}__.
 *
 *
 */
export function getDai(config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof daiAddress }) {
  return getContract({ abi: daiABI, address: daiAddress[421614], ...config })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link daiABI}__.
 *
 *
 */
export function readDai<TAbi extends readonly unknown[] = typeof daiABI, TFunctionName extends string = string>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & { chainId?: keyof typeof daiAddress }
) {
  return readContract({ abi: daiABI, address: daiAddress[421614], ...config } as unknown as ReadContractConfig<
    TAbi,
    TFunctionName
  >)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link daiABI}__.
 *
 *
 */
export function writeDai<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof daiAddress
>(
  config:
    | (Omit<WriteContractPreparedArgs<typeof daiABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof daiAddress
      })
    | (Omit<WriteContractUnpreparedArgs<typeof daiABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof daiAddress
      })
) {
  return writeContract({ abi: daiABI, address: daiAddress[421614], ...config } as unknown as WriteContractArgs<
    typeof daiABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link daiABI}__.
 *
 *
 */
export function prepareWriteDai<TAbi extends readonly unknown[] = typeof daiABI, TFunctionName extends string = string>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof daiAddress
  }
) {
  return prepareWriteContract({
    abi: daiABI,
    address: daiAddress[421614],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link watchContractEvent}__ with `abi` set to __{@link daiABI}__.
 *
 *
 */
export function watchDaiEvent<TAbi extends readonly unknown[] = typeof daiABI, TEventName extends string = string>(
  config: Omit<WatchContractEventConfig<TAbi, TEventName>, 'abi' | 'address'> & { chainId?: keyof typeof daiAddress },
  callback: WatchContractEventCallback<TAbi, TEventName>
) {
  return watchContractEvent(
    { abi: daiABI, address: daiAddress[421614], ...config } as WatchContractEventConfig<TAbi, TEventName>,
    callback
  )
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link daiFaucetABI}__.
 *
 *
 */
export function getDaiFaucet(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof daiFaucetAddress }
) {
  return getContract({ abi: daiFaucetABI, address: daiFaucetAddress[421614], ...config })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link daiFaucetABI}__.
 *
 *
 */
export function readDaiFaucet<
  TAbi extends readonly unknown[] = typeof daiFaucetABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & { chainId?: keyof typeof daiFaucetAddress }
) {
  return readContract({
    abi: daiFaucetABI,
    address: daiFaucetAddress[421614],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link daiFaucetABI}__.
 *
 *
 */
export function writeDaiFaucet<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof daiFaucetAddress
>(
  config:
    | (Omit<WriteContractPreparedArgs<typeof daiFaucetABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof daiFaucetAddress
      })
    | (Omit<WriteContractUnpreparedArgs<typeof daiFaucetABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof daiFaucetAddress
      })
) {
  return writeContract({
    abi: daiFaucetABI,
    address: daiFaucetAddress[421614],
    ...config,
  } as unknown as WriteContractArgs<typeof daiFaucetABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link daiFaucetABI}__.
 *
 *
 */
export function prepareWriteDaiFaucet<
  TAbi extends readonly unknown[] = typeof daiFaucetABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof daiFaucetAddress
  }
) {
  return prepareWriteContract({
    abi: daiFaucetABI,
    address: daiFaucetAddress[421614],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link disputeKitClassicABI}__.
 *
 *
 */
export function getDisputeKitClassic(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof disputeKitClassicAddress }
) {
  return getContract({ abi: disputeKitClassicABI, address: disputeKitClassicAddress[421614], ...config })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link disputeKitClassicABI}__.
 *
 *
 */
export function readDisputeKitClassic<
  TAbi extends readonly unknown[] = typeof disputeKitClassicABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof disputeKitClassicAddress
  }
) {
  return readContract({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link disputeKitClassicABI}__.
 *
 *
 */
export function writeDisputeKitClassic<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof disputeKitClassicAddress
>(
  config:
    | (Omit<WriteContractPreparedArgs<typeof disputeKitClassicABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof disputeKitClassicAddress
      })
    | (Omit<WriteContractUnpreparedArgs<typeof disputeKitClassicABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof disputeKitClassicAddress
      })
) {
  return writeContract({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    ...config,
  } as unknown as WriteContractArgs<typeof disputeKitClassicABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link disputeKitClassicABI}__.
 *
 *
 */
export function prepareWriteDisputeKitClassic<
  TAbi extends readonly unknown[] = typeof disputeKitClassicABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof disputeKitClassicAddress
  }
) {
  return prepareWriteContract({
    abi: disputeKitClassicABI,
    address: disputeKitClassicAddress[421614],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link watchContractEvent}__ with `abi` set to __{@link disputeKitClassicABI}__.
 *
 *
 */
export function watchDisputeKitClassicEvent<
  TAbi extends readonly unknown[] = typeof disputeKitClassicABI,
  TEventName extends string = string
>(
  config: Omit<WatchContractEventConfig<TAbi, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof disputeKitClassicAddress
  },
  callback: WatchContractEventCallback<TAbi, TEventName>
) {
  return watchContractEvent(
    { abi: disputeKitClassicABI, address: disputeKitClassicAddress[421614], ...config } as WatchContractEventConfig<
      TAbi,
      TEventName
    >,
    callback
  )
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__.
 *
 *
 */
export function getDisputeKitClassicImplementation(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof disputeKitClassicImplementationAddress }
) {
  return getContract({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    ...config,
  })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__.
 *
 *
 */
export function readDisputeKitClassicImplementation<
  TAbi extends readonly unknown[] = typeof disputeKitClassicImplementationABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof disputeKitClassicImplementationAddress
  }
) {
  return readContract({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__.
 *
 *
 */
export function writeDisputeKitClassicImplementation<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof disputeKitClassicImplementationAddress
>(
  config:
    | (Omit<WriteContractPreparedArgs<typeof disputeKitClassicImplementationABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof disputeKitClassicImplementationAddress
      })
    | (Omit<
        WriteContractUnpreparedArgs<typeof disputeKitClassicImplementationABI, TFunctionName>,
        'abi' | 'address'
      > & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof disputeKitClassicImplementationAddress
      })
) {
  return writeContract({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    ...config,
  } as unknown as WriteContractArgs<typeof disputeKitClassicImplementationABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__.
 *
 *
 */
export function prepareWriteDisputeKitClassicImplementation<
  TAbi extends readonly unknown[] = typeof disputeKitClassicImplementationABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof disputeKitClassicImplementationAddress
  }
) {
  return prepareWriteContract({
    abi: disputeKitClassicImplementationABI,
    address: disputeKitClassicImplementationAddress[421614],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link watchContractEvent}__ with `abi` set to __{@link disputeKitClassicImplementationABI}__.
 *
 *
 */
export function watchDisputeKitClassicImplementationEvent<
  TAbi extends readonly unknown[] = typeof disputeKitClassicImplementationABI,
  TEventName extends string = string
>(
  config: Omit<WatchContractEventConfig<TAbi, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof disputeKitClassicImplementationAddress
  },
  callback: WatchContractEventCallback<TAbi, TEventName>
) {
  return watchContractEvent(
    {
      abi: disputeKitClassicImplementationABI,
      address: disputeKitClassicImplementationAddress[421614],
      ...config,
    } as WatchContractEventConfig<TAbi, TEventName>,
    callback
  )
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link disputeKitClassicProxyABI}__.
 *
 *
 */
export function getDisputeKitClassicProxy(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof disputeKitClassicProxyAddress }
) {
  return getContract({ abi: disputeKitClassicProxyABI, address: disputeKitClassicProxyAddress[421614], ...config })
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link disputeResolverABI}__.
 *
 *
 */
export function getDisputeResolver(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof disputeResolverAddress }
) {
  return getContract({ abi: disputeResolverABI, address: disputeResolverAddress[421614], ...config })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link disputeResolverABI}__.
 *
 *
 */
export function readDisputeResolver<
  TAbi extends readonly unknown[] = typeof disputeResolverABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof disputeResolverAddress
  }
) {
  return readContract({
    abi: disputeResolverABI,
    address: disputeResolverAddress[421614],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link disputeResolverABI}__.
 *
 *
 */
export function writeDisputeResolver<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof disputeResolverAddress
>(
  config:
    | (Omit<WriteContractPreparedArgs<typeof disputeResolverABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof disputeResolverAddress
      })
    | (Omit<WriteContractUnpreparedArgs<typeof disputeResolverABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof disputeResolverAddress
      })
) {
  return writeContract({
    abi: disputeResolverABI,
    address: disputeResolverAddress[421614],
    ...config,
  } as unknown as WriteContractArgs<typeof disputeResolverABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link disputeResolverABI}__.
 *
 *
 */
export function prepareWriteDisputeResolver<
  TAbi extends readonly unknown[] = typeof disputeResolverABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof disputeResolverAddress
  }
) {
  return prepareWriteContract({
    abi: disputeResolverABI,
    address: disputeResolverAddress[421614],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link watchContractEvent}__ with `abi` set to __{@link disputeResolverABI}__.
 *
 *
 */
export function watchDisputeResolverEvent<
  TAbi extends readonly unknown[] = typeof disputeResolverABI,
  TEventName extends string = string
>(
  config: Omit<WatchContractEventConfig<TAbi, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof disputeResolverAddress
  },
  callback: WatchContractEventCallback<TAbi, TEventName>
) {
  return watchContractEvent(
    { abi: disputeResolverABI, address: disputeResolverAddress[421614], ...config } as WatchContractEventConfig<
      TAbi,
      TEventName
    >,
    callback
  )
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link disputeTemplateRegistryABI}__.
 *
 *
 */
export function getDisputeTemplateRegistry(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof disputeTemplateRegistryAddress }
) {
  return getContract({ abi: disputeTemplateRegistryABI, address: disputeTemplateRegistryAddress[421614], ...config })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link disputeTemplateRegistryABI}__.
 *
 *
 */
export function readDisputeTemplateRegistry<
  TAbi extends readonly unknown[] = typeof disputeTemplateRegistryABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof disputeTemplateRegistryAddress
  }
) {
  return readContract({
    abi: disputeTemplateRegistryABI,
    address: disputeTemplateRegistryAddress[421614],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link disputeTemplateRegistryABI}__.
 *
 *
 */
export function writeDisputeTemplateRegistry<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof disputeTemplateRegistryAddress
>(
  config:
    | (Omit<WriteContractPreparedArgs<typeof disputeTemplateRegistryABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof disputeTemplateRegistryAddress
      })
    | (Omit<WriteContractUnpreparedArgs<typeof disputeTemplateRegistryABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof disputeTemplateRegistryAddress
      })
) {
  return writeContract({
    abi: disputeTemplateRegistryABI,
    address: disputeTemplateRegistryAddress[421614],
    ...config,
  } as unknown as WriteContractArgs<typeof disputeTemplateRegistryABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link disputeTemplateRegistryABI}__.
 *
 *
 */
export function prepareWriteDisputeTemplateRegistry<
  TAbi extends readonly unknown[] = typeof disputeTemplateRegistryABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof disputeTemplateRegistryAddress
  }
) {
  return prepareWriteContract({
    abi: disputeTemplateRegistryABI,
    address: disputeTemplateRegistryAddress[421614],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link watchContractEvent}__ with `abi` set to __{@link disputeTemplateRegistryABI}__.
 *
 *
 */
export function watchDisputeTemplateRegistryEvent<
  TAbi extends readonly unknown[] = typeof disputeTemplateRegistryABI,
  TEventName extends string = string
>(
  config: Omit<WatchContractEventConfig<TAbi, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof disputeTemplateRegistryAddress
  },
  callback: WatchContractEventCallback<TAbi, TEventName>
) {
  return watchContractEvent(
    {
      abi: disputeTemplateRegistryABI,
      address: disputeTemplateRegistryAddress[421614],
      ...config,
    } as WatchContractEventConfig<TAbi, TEventName>,
    callback
  )
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link disputeTemplateRegistryImplementationABI}__.
 *
 *
 */
export function getDisputeTemplateRegistryImplementation(
  config: Omit<GetContractArgs, 'abi' | 'address'> & {
    chainId?: keyof typeof disputeTemplateRegistryImplementationAddress
  }
) {
  return getContract({
    abi: disputeTemplateRegistryImplementationABI,
    address: disputeTemplateRegistryImplementationAddress[421614],
    ...config,
  })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link disputeTemplateRegistryImplementationABI}__.
 *
 *
 */
export function readDisputeTemplateRegistryImplementation<
  TAbi extends readonly unknown[] = typeof disputeTemplateRegistryImplementationABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof disputeTemplateRegistryImplementationAddress
  }
) {
  return readContract({
    abi: disputeTemplateRegistryImplementationABI,
    address: disputeTemplateRegistryImplementationAddress[421614],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link disputeTemplateRegistryImplementationABI}__.
 *
 *
 */
export function writeDisputeTemplateRegistryImplementation<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof disputeTemplateRegistryImplementationAddress
>(
  config:
    | (Omit<
        WriteContractPreparedArgs<typeof disputeTemplateRegistryImplementationABI, TFunctionName>,
        'abi' | 'address'
      > & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof disputeTemplateRegistryImplementationAddress
      })
    | (Omit<
        WriteContractUnpreparedArgs<typeof disputeTemplateRegistryImplementationABI, TFunctionName>,
        'abi' | 'address'
      > & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof disputeTemplateRegistryImplementationAddress
      })
) {
  return writeContract({
    abi: disputeTemplateRegistryImplementationABI,
    address: disputeTemplateRegistryImplementationAddress[421614],
    ...config,
  } as unknown as WriteContractArgs<typeof disputeTemplateRegistryImplementationABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link disputeTemplateRegistryImplementationABI}__.
 *
 *
 */
export function prepareWriteDisputeTemplateRegistryImplementation<
  TAbi extends readonly unknown[] = typeof disputeTemplateRegistryImplementationABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof disputeTemplateRegistryImplementationAddress
  }
) {
  return prepareWriteContract({
    abi: disputeTemplateRegistryImplementationABI,
    address: disputeTemplateRegistryImplementationAddress[421614],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link watchContractEvent}__ with `abi` set to __{@link disputeTemplateRegistryImplementationABI}__.
 *
 *
 */
export function watchDisputeTemplateRegistryImplementationEvent<
  TAbi extends readonly unknown[] = typeof disputeTemplateRegistryImplementationABI,
  TEventName extends string = string
>(
  config: Omit<WatchContractEventConfig<TAbi, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof disputeTemplateRegistryImplementationAddress
  },
  callback: WatchContractEventCallback<TAbi, TEventName>
) {
  return watchContractEvent(
    {
      abi: disputeTemplateRegistryImplementationABI,
      address: disputeTemplateRegistryImplementationAddress[421614],
      ...config,
    } as WatchContractEventConfig<TAbi, TEventName>,
    callback
  )
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link disputeTemplateRegistryProxyABI}__.
 *
 *
 */
export function getDisputeTemplateRegistryProxy(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof disputeTemplateRegistryProxyAddress }
) {
  return getContract({
    abi: disputeTemplateRegistryProxyABI,
    address: disputeTemplateRegistryProxyAddress[421614],
    ...config,
  })
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link escrowABI}__.
 *
 *
 */
export function getEscrow(config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof escrowAddress }) {
  return getContract({ abi: escrowABI, address: escrowAddress[421614], ...config })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link escrowABI}__.
 *
 *
 */
export function readEscrow<TAbi extends readonly unknown[] = typeof escrowABI, TFunctionName extends string = string>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & { chainId?: keyof typeof escrowAddress }
) {
  return readContract({ abi: escrowABI, address: escrowAddress[421614], ...config } as unknown as ReadContractConfig<
    TAbi,
    TFunctionName
  >)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link escrowABI}__.
 *
 *
 */
export function writeEscrow<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof escrowAddress
>(
  config:
    | (Omit<WriteContractPreparedArgs<typeof escrowABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof escrowAddress
      })
    | (Omit<WriteContractUnpreparedArgs<typeof escrowABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof escrowAddress
      })
) {
  return writeContract({ abi: escrowABI, address: escrowAddress[421614], ...config } as unknown as WriteContractArgs<
    typeof escrowABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link escrowABI}__.
 *
 *
 */
export function prepareWriteEscrow<
  TAbi extends readonly unknown[] = typeof escrowABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof escrowAddress
  }
) {
  return prepareWriteContract({
    abi: escrowABI,
    address: escrowAddress[421614],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link watchContractEvent}__ with `abi` set to __{@link escrowABI}__.
 *
 *
 */
export function watchEscrowEvent<
  TAbi extends readonly unknown[] = typeof escrowABI,
  TEventName extends string = string
>(
  config: Omit<WatchContractEventConfig<TAbi, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof escrowAddress
  },
  callback: WatchContractEventCallback<TAbi, TEventName>
) {
  return watchContractEvent(
    { abi: escrowABI, address: escrowAddress[421614], ...config } as WatchContractEventConfig<TAbi, TEventName>,
    callback
  )
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link evidenceModuleABI}__.
 *
 *
 */
export function getEvidenceModule(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof evidenceModuleAddress }
) {
  return getContract({ abi: evidenceModuleABI, address: evidenceModuleAddress[421614], ...config })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link evidenceModuleABI}__.
 *
 *
 */
export function readEvidenceModule<
  TAbi extends readonly unknown[] = typeof evidenceModuleABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof evidenceModuleAddress
  }
) {
  return readContract({
    abi: evidenceModuleABI,
    address: evidenceModuleAddress[421614],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link evidenceModuleABI}__.
 *
 *
 */
export function writeEvidenceModule<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof evidenceModuleAddress
>(
  config:
    | (Omit<WriteContractPreparedArgs<typeof evidenceModuleABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof evidenceModuleAddress
      })
    | (Omit<WriteContractUnpreparedArgs<typeof evidenceModuleABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof evidenceModuleAddress
      })
) {
  return writeContract({
    abi: evidenceModuleABI,
    address: evidenceModuleAddress[421614],
    ...config,
  } as unknown as WriteContractArgs<typeof evidenceModuleABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link evidenceModuleABI}__.
 *
 *
 */
export function prepareWriteEvidenceModule<
  TAbi extends readonly unknown[] = typeof evidenceModuleABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof evidenceModuleAddress
  }
) {
  return prepareWriteContract({
    abi: evidenceModuleABI,
    address: evidenceModuleAddress[421614],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link watchContractEvent}__ with `abi` set to __{@link evidenceModuleABI}__.
 *
 *
 */
export function watchEvidenceModuleEvent<
  TAbi extends readonly unknown[] = typeof evidenceModuleABI,
  TEventName extends string = string
>(
  config: Omit<WatchContractEventConfig<TAbi, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof evidenceModuleAddress
  },
  callback: WatchContractEventCallback<TAbi, TEventName>
) {
  return watchContractEvent(
    { abi: evidenceModuleABI, address: evidenceModuleAddress[421614], ...config } as WatchContractEventConfig<
      TAbi,
      TEventName
    >,
    callback
  )
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link evidenceModuleImplementationABI}__.
 *
 *
 */
export function getEvidenceModuleImplementation(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof evidenceModuleImplementationAddress }
) {
  return getContract({
    abi: evidenceModuleImplementationABI,
    address: evidenceModuleImplementationAddress[421614],
    ...config,
  })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link evidenceModuleImplementationABI}__.
 *
 *
 */
export function readEvidenceModuleImplementation<
  TAbi extends readonly unknown[] = typeof evidenceModuleImplementationABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof evidenceModuleImplementationAddress
  }
) {
  return readContract({
    abi: evidenceModuleImplementationABI,
    address: evidenceModuleImplementationAddress[421614],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link evidenceModuleImplementationABI}__.
 *
 *
 */
export function writeEvidenceModuleImplementation<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof evidenceModuleImplementationAddress
>(
  config:
    | (Omit<WriteContractPreparedArgs<typeof evidenceModuleImplementationABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof evidenceModuleImplementationAddress
      })
    | (Omit<WriteContractUnpreparedArgs<typeof evidenceModuleImplementationABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof evidenceModuleImplementationAddress
      })
) {
  return writeContract({
    abi: evidenceModuleImplementationABI,
    address: evidenceModuleImplementationAddress[421614],
    ...config,
  } as unknown as WriteContractArgs<typeof evidenceModuleImplementationABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link evidenceModuleImplementationABI}__.
 *
 *
 */
export function prepareWriteEvidenceModuleImplementation<
  TAbi extends readonly unknown[] = typeof evidenceModuleImplementationABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof evidenceModuleImplementationAddress
  }
) {
  return prepareWriteContract({
    abi: evidenceModuleImplementationABI,
    address: evidenceModuleImplementationAddress[421614],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link watchContractEvent}__ with `abi` set to __{@link evidenceModuleImplementationABI}__.
 *
 *
 */
export function watchEvidenceModuleImplementationEvent<
  TAbi extends readonly unknown[] = typeof evidenceModuleImplementationABI,
  TEventName extends string = string
>(
  config: Omit<WatchContractEventConfig<TAbi, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof evidenceModuleImplementationAddress
  },
  callback: WatchContractEventCallback<TAbi, TEventName>
) {
  return watchContractEvent(
    {
      abi: evidenceModuleImplementationABI,
      address: evidenceModuleImplementationAddress[421614],
      ...config,
    } as WatchContractEventConfig<TAbi, TEventName>,
    callback
  )
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link evidenceModuleProxyABI}__.
 *
 *
 */
export function getEvidenceModuleProxy(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof evidenceModuleProxyAddress }
) {
  return getContract({ abi: evidenceModuleProxyABI, address: evidenceModuleProxyAddress[421614], ...config })
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link iArbitrableV2ABI}__.
 */
export function getIArbitrableV2(config: Omit<GetContractArgs, 'abi'>) {
  return getContract({ abi: iArbitrableV2ABI, ...config })
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link iArbitrableV2ABI}__.
 */
export function writeIArbitrableV2<TFunctionName extends string>(
  config:
    | Omit<WriteContractPreparedArgs<typeof iArbitrableV2ABI, TFunctionName>, 'abi'>
    | Omit<WriteContractUnpreparedArgs<typeof iArbitrableV2ABI, TFunctionName>, 'abi'>
) {
  return writeContract({ abi: iArbitrableV2ABI, ...config } as unknown as WriteContractArgs<
    typeof iArbitrableV2ABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link iArbitrableV2ABI}__.
 */
export function prepareWriteIArbitrableV2<
  TAbi extends readonly unknown[] = typeof iArbitrableV2ABI,
  TFunctionName extends string = string
>(config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi'>) {
  return prepareWriteContract({ abi: iArbitrableV2ABI, ...config } as unknown as PrepareWriteContractConfig<
    TAbi,
    TFunctionName
  >)
}

/**
 * Wraps __{@link watchContractEvent}__ with `abi` set to __{@link iArbitrableV2ABI}__.
 */
export function watchIArbitrableV2Event<
  TAbi extends readonly unknown[] = typeof iArbitrableV2ABI,
  TEventName extends string = string
>(
  config: Omit<WatchContractEventConfig<TAbi, TEventName>, 'abi'>,
  callback: WatchContractEventCallback<TAbi, TEventName>
) {
  return watchContractEvent(
    { abi: iArbitrableV2ABI, ...config } as WatchContractEventConfig<TAbi, TEventName>,
    callback
  )
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link iHomeGatewayABI}__.
 */
export function getIHomeGateway(config: Omit<GetContractArgs, 'abi'>) {
  return getContract({ abi: iHomeGatewayABI, ...config })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link iHomeGatewayABI}__.
 */
export function readIHomeGateway<
  TAbi extends readonly unknown[] = typeof iHomeGatewayABI,
  TFunctionName extends string = string
>(config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi'>) {
  return readContract({ abi: iHomeGatewayABI, ...config } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link iHomeGatewayABI}__.
 */
export function writeIHomeGateway<TFunctionName extends string>(
  config:
    | Omit<WriteContractPreparedArgs<typeof iHomeGatewayABI, TFunctionName>, 'abi'>
    | Omit<WriteContractUnpreparedArgs<typeof iHomeGatewayABI, TFunctionName>, 'abi'>
) {
  return writeContract({ abi: iHomeGatewayABI, ...config } as unknown as WriteContractArgs<
    typeof iHomeGatewayABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link iHomeGatewayABI}__.
 */
export function prepareWriteIHomeGateway<
  TAbi extends readonly unknown[] = typeof iHomeGatewayABI,
  TFunctionName extends string = string
>(config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi'>) {
  return prepareWriteContract({ abi: iHomeGatewayABI, ...config } as unknown as PrepareWriteContractConfig<
    TAbi,
    TFunctionName
  >)
}

/**
 * Wraps __{@link watchContractEvent}__ with `abi` set to __{@link iHomeGatewayABI}__.
 */
export function watchIHomeGatewayEvent<
  TAbi extends readonly unknown[] = typeof iHomeGatewayABI,
  TEventName extends string = string
>(
  config: Omit<WatchContractEventConfig<TAbi, TEventName>, 'abi'>,
  callback: WatchContractEventCallback<TAbi, TEventName>
) {
  return watchContractEvent({ abi: iHomeGatewayABI, ...config } as WatchContractEventConfig<TAbi, TEventName>, callback)
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link klerosCoreABI}__.
 *
 *
 */
export function getKlerosCore(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof klerosCoreAddress }
) {
  return getContract({ abi: klerosCoreABI, address: klerosCoreAddress[421614], ...config })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link klerosCoreABI}__.
 *
 *
 */
export function readKlerosCore<
  TAbi extends readonly unknown[] = typeof klerosCoreABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof klerosCoreAddress
  }
) {
  return readContract({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link klerosCoreABI}__.
 *
 *
 */
export function writeKlerosCore<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof klerosCoreAddress
>(
  config:
    | (Omit<WriteContractPreparedArgs<typeof klerosCoreABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof klerosCoreAddress
      })
    | (Omit<WriteContractUnpreparedArgs<typeof klerosCoreABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof klerosCoreAddress
      })
) {
  return writeContract({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    ...config,
  } as unknown as WriteContractArgs<typeof klerosCoreABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link klerosCoreABI}__.
 *
 *
 */
export function prepareWriteKlerosCore<
  TAbi extends readonly unknown[] = typeof klerosCoreABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof klerosCoreAddress
  }
) {
  return prepareWriteContract({
    abi: klerosCoreABI,
    address: klerosCoreAddress[421614],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link watchContractEvent}__ with `abi` set to __{@link klerosCoreABI}__.
 *
 *
 */
export function watchKlerosCoreEvent<
  TAbi extends readonly unknown[] = typeof klerosCoreABI,
  TEventName extends string = string
>(
  config: Omit<WatchContractEventConfig<TAbi, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof klerosCoreAddress
  },
  callback: WatchContractEventCallback<TAbi, TEventName>
) {
  return watchContractEvent(
    { abi: klerosCoreABI, address: klerosCoreAddress[421614], ...config } as WatchContractEventConfig<TAbi, TEventName>,
    callback
  )
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link klerosCoreImplementationABI}__.
 *
 *
 */
export function getKlerosCoreImplementation(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof klerosCoreImplementationAddress }
) {
  return getContract({ abi: klerosCoreImplementationABI, address: klerosCoreImplementationAddress[421614], ...config })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link klerosCoreImplementationABI}__.
 *
 *
 */
export function readKlerosCoreImplementation<
  TAbi extends readonly unknown[] = typeof klerosCoreImplementationABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof klerosCoreImplementationAddress
  }
) {
  return readContract({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link klerosCoreImplementationABI}__.
 *
 *
 */
export function writeKlerosCoreImplementation<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof klerosCoreImplementationAddress
>(
  config:
    | (Omit<WriteContractPreparedArgs<typeof klerosCoreImplementationABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof klerosCoreImplementationAddress
      })
    | (Omit<WriteContractUnpreparedArgs<typeof klerosCoreImplementationABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof klerosCoreImplementationAddress
      })
) {
  return writeContract({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    ...config,
  } as unknown as WriteContractArgs<typeof klerosCoreImplementationABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link klerosCoreImplementationABI}__.
 *
 *
 */
export function prepareWriteKlerosCoreImplementation<
  TAbi extends readonly unknown[] = typeof klerosCoreImplementationABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof klerosCoreImplementationAddress
  }
) {
  return prepareWriteContract({
    abi: klerosCoreImplementationABI,
    address: klerosCoreImplementationAddress[421614],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link watchContractEvent}__ with `abi` set to __{@link klerosCoreImplementationABI}__.
 *
 *
 */
export function watchKlerosCoreImplementationEvent<
  TAbi extends readonly unknown[] = typeof klerosCoreImplementationABI,
  TEventName extends string = string
>(
  config: Omit<WatchContractEventConfig<TAbi, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof klerosCoreImplementationAddress
  },
  callback: WatchContractEventCallback<TAbi, TEventName>
) {
  return watchContractEvent(
    {
      abi: klerosCoreImplementationABI,
      address: klerosCoreImplementationAddress[421614],
      ...config,
    } as WatchContractEventConfig<TAbi, TEventName>,
    callback
  )
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link klerosCoreProxyABI}__.
 *
 *
 */
export function getKlerosCoreProxy(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof klerosCoreProxyAddress }
) {
  return getContract({ abi: klerosCoreProxyABI, address: klerosCoreProxyAddress[421614], ...config })
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link pnkABI}__.
 *
 *
 */
export function getPnk(config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof pnkAddress }) {
  return getContract({ abi: pnkABI, address: pnkAddress[421614], ...config })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link pnkABI}__.
 *
 *
 */
export function readPnk<TAbi extends readonly unknown[] = typeof pnkABI, TFunctionName extends string = string>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & { chainId?: keyof typeof pnkAddress }
) {
  return readContract({ abi: pnkABI, address: pnkAddress[421614], ...config } as unknown as ReadContractConfig<
    TAbi,
    TFunctionName
  >)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link pnkABI}__.
 *
 *
 */
export function writePnk<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof pnkAddress
>(
  config:
    | (Omit<WriteContractPreparedArgs<typeof pnkABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof pnkAddress
      })
    | (Omit<WriteContractUnpreparedArgs<typeof pnkABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof pnkAddress
      })
) {
  return writeContract({ abi: pnkABI, address: pnkAddress[421614], ...config } as unknown as WriteContractArgs<
    typeof pnkABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link pnkABI}__.
 *
 *
 */
export function prepareWritePnk<TAbi extends readonly unknown[] = typeof pnkABI, TFunctionName extends string = string>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof pnkAddress
  }
) {
  return prepareWriteContract({
    abi: pnkABI,
    address: pnkAddress[421614],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link watchContractEvent}__ with `abi` set to __{@link pnkABI}__.
 *
 *
 */
export function watchPnkEvent<TAbi extends readonly unknown[] = typeof pnkABI, TEventName extends string = string>(
  config: Omit<WatchContractEventConfig<TAbi, TEventName>, 'abi' | 'address'> & { chainId?: keyof typeof pnkAddress },
  callback: WatchContractEventCallback<TAbi, TEventName>
) {
  return watchContractEvent(
    { abi: pnkABI, address: pnkAddress[421614], ...config } as WatchContractEventConfig<TAbi, TEventName>,
    callback
  )
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link pnkFaucetABI}__.
 *
 *
 */
export function getPnkFaucet(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof pnkFaucetAddress }
) {
  return getContract({ abi: pnkFaucetABI, address: pnkFaucetAddress[421614], ...config })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link pnkFaucetABI}__.
 *
 *
 */
export function readPnkFaucet<
  TAbi extends readonly unknown[] = typeof pnkFaucetABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & { chainId?: keyof typeof pnkFaucetAddress }
) {
  return readContract({
    abi: pnkFaucetABI,
    address: pnkFaucetAddress[421614],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link pnkFaucetABI}__.
 *
 *
 */
export function writePnkFaucet<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof pnkFaucetAddress
>(
  config:
    | (Omit<WriteContractPreparedArgs<typeof pnkFaucetABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof pnkFaucetAddress
      })
    | (Omit<WriteContractUnpreparedArgs<typeof pnkFaucetABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof pnkFaucetAddress
      })
) {
  return writeContract({
    abi: pnkFaucetABI,
    address: pnkFaucetAddress[421614],
    ...config,
  } as unknown as WriteContractArgs<typeof pnkFaucetABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link pnkFaucetABI}__.
 *
 *
 */
export function prepareWritePnkFaucet<
  TAbi extends readonly unknown[] = typeof pnkFaucetABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof pnkFaucetAddress
  }
) {
  return prepareWriteContract({
    abi: pnkFaucetABI,
    address: pnkFaucetAddress[421614],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link pinakionV2ABI}__.
 *
 *
 */
export function getPinakionV2(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof pinakionV2Address }
) {
  return getContract({ abi: pinakionV2ABI, address: pinakionV2Address[421614], ...config })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link pinakionV2ABI}__.
 *
 *
 */
export function readPinakionV2<
  TAbi extends readonly unknown[] = typeof pinakionV2ABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof pinakionV2Address
  }
) {
  return readContract({
    abi: pinakionV2ABI,
    address: pinakionV2Address[421614],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link pinakionV2ABI}__.
 *
 *
 */
export function writePinakionV2<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof pinakionV2Address
>(
  config:
    | (Omit<WriteContractPreparedArgs<typeof pinakionV2ABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof pinakionV2Address
      })
    | (Omit<WriteContractUnpreparedArgs<typeof pinakionV2ABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof pinakionV2Address
      })
) {
  return writeContract({
    abi: pinakionV2ABI,
    address: pinakionV2Address[421614],
    ...config,
  } as unknown as WriteContractArgs<typeof pinakionV2ABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link pinakionV2ABI}__.
 *
 *
 */
export function prepareWritePinakionV2<
  TAbi extends readonly unknown[] = typeof pinakionV2ABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof pinakionV2Address
  }
) {
  return prepareWriteContract({
    abi: pinakionV2ABI,
    address: pinakionV2Address[421614],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link watchContractEvent}__ with `abi` set to __{@link pinakionV2ABI}__.
 *
 *
 */
export function watchPinakionV2Event<
  TAbi extends readonly unknown[] = typeof pinakionV2ABI,
  TEventName extends string = string
>(
  config: Omit<WatchContractEventConfig<TAbi, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof pinakionV2Address
  },
  callback: WatchContractEventCallback<TAbi, TEventName>
) {
  return watchContractEvent(
    { abi: pinakionV2ABI, address: pinakionV2Address[421614], ...config } as WatchContractEventConfig<TAbi, TEventName>,
    callback
  )
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link policyRegistryABI}__.
 *
 *
 */
export function getPolicyRegistry(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof policyRegistryAddress }
) {
  return getContract({ abi: policyRegistryABI, address: policyRegistryAddress[421614], ...config })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link policyRegistryABI}__.
 *
 *
 */
export function readPolicyRegistry<
  TAbi extends readonly unknown[] = typeof policyRegistryABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof policyRegistryAddress
  }
) {
  return readContract({
    abi: policyRegistryABI,
    address: policyRegistryAddress[421614],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link policyRegistryABI}__.
 *
 *
 */
export function writePolicyRegistry<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof policyRegistryAddress
>(
  config:
    | (Omit<WriteContractPreparedArgs<typeof policyRegistryABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof policyRegistryAddress
      })
    | (Omit<WriteContractUnpreparedArgs<typeof policyRegistryABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof policyRegistryAddress
      })
) {
  return writeContract({
    abi: policyRegistryABI,
    address: policyRegistryAddress[421614],
    ...config,
  } as unknown as WriteContractArgs<typeof policyRegistryABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link policyRegistryABI}__.
 *
 *
 */
export function prepareWritePolicyRegistry<
  TAbi extends readonly unknown[] = typeof policyRegistryABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof policyRegistryAddress
  }
) {
  return prepareWriteContract({
    abi: policyRegistryABI,
    address: policyRegistryAddress[421614],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link watchContractEvent}__ with `abi` set to __{@link policyRegistryABI}__.
 *
 *
 */
export function watchPolicyRegistryEvent<
  TAbi extends readonly unknown[] = typeof policyRegistryABI,
  TEventName extends string = string
>(
  config: Omit<WatchContractEventConfig<TAbi, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof policyRegistryAddress
  },
  callback: WatchContractEventCallback<TAbi, TEventName>
) {
  return watchContractEvent(
    { abi: policyRegistryABI, address: policyRegistryAddress[421614], ...config } as WatchContractEventConfig<
      TAbi,
      TEventName
    >,
    callback
  )
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link policyRegistryImplementationABI}__.
 *
 *
 */
export function getPolicyRegistryImplementation(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof policyRegistryImplementationAddress }
) {
  return getContract({
    abi: policyRegistryImplementationABI,
    address: policyRegistryImplementationAddress[421614],
    ...config,
  })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link policyRegistryImplementationABI}__.
 *
 *
 */
export function readPolicyRegistryImplementation<
  TAbi extends readonly unknown[] = typeof policyRegistryImplementationABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof policyRegistryImplementationAddress
  }
) {
  return readContract({
    abi: policyRegistryImplementationABI,
    address: policyRegistryImplementationAddress[421614],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link policyRegistryImplementationABI}__.
 *
 *
 */
export function writePolicyRegistryImplementation<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof policyRegistryImplementationAddress
>(
  config:
    | (Omit<WriteContractPreparedArgs<typeof policyRegistryImplementationABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof policyRegistryImplementationAddress
      })
    | (Omit<WriteContractUnpreparedArgs<typeof policyRegistryImplementationABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof policyRegistryImplementationAddress
      })
) {
  return writeContract({
    abi: policyRegistryImplementationABI,
    address: policyRegistryImplementationAddress[421614],
    ...config,
  } as unknown as WriteContractArgs<typeof policyRegistryImplementationABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link policyRegistryImplementationABI}__.
 *
 *
 */
export function prepareWritePolicyRegistryImplementation<
  TAbi extends readonly unknown[] = typeof policyRegistryImplementationABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof policyRegistryImplementationAddress
  }
) {
  return prepareWriteContract({
    abi: policyRegistryImplementationABI,
    address: policyRegistryImplementationAddress[421614],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link watchContractEvent}__ with `abi` set to __{@link policyRegistryImplementationABI}__.
 *
 *
 */
export function watchPolicyRegistryImplementationEvent<
  TAbi extends readonly unknown[] = typeof policyRegistryImplementationABI,
  TEventName extends string = string
>(
  config: Omit<WatchContractEventConfig<TAbi, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof policyRegistryImplementationAddress
  },
  callback: WatchContractEventCallback<TAbi, TEventName>
) {
  return watchContractEvent(
    {
      abi: policyRegistryImplementationABI,
      address: policyRegistryImplementationAddress[421614],
      ...config,
    } as WatchContractEventConfig<TAbi, TEventName>,
    callback
  )
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link policyRegistryProxyABI}__.
 *
 *
 */
export function getPolicyRegistryProxy(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof policyRegistryProxyAddress }
) {
  return getContract({ abi: policyRegistryProxyABI, address: policyRegistryProxyAddress[421614], ...config })
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link randomizerOracleABI}__.
 *
 *
 */
export function getRandomizerOracle(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof randomizerOracleAddress }
) {
  return getContract({ abi: randomizerOracleABI, address: randomizerOracleAddress[421614], ...config })
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link randomizerRngABI}__.
 *
 *
 */
export function getRandomizerRng(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof randomizerRngAddress }
) {
  return getContract({ abi: randomizerRngABI, address: randomizerRngAddress[421614], ...config })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link randomizerRngABI}__.
 *
 *
 */
export function readRandomizerRng<
  TAbi extends readonly unknown[] = typeof randomizerRngABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof randomizerRngAddress
  }
) {
  return readContract({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link randomizerRngABI}__.
 *
 *
 */
export function writeRandomizerRng<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof randomizerRngAddress
>(
  config:
    | (Omit<WriteContractPreparedArgs<typeof randomizerRngABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof randomizerRngAddress
      })
    | (Omit<WriteContractUnpreparedArgs<typeof randomizerRngABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof randomizerRngAddress
      })
) {
  return writeContract({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    ...config,
  } as unknown as WriteContractArgs<typeof randomizerRngABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link randomizerRngABI}__.
 *
 *
 */
export function prepareWriteRandomizerRng<
  TAbi extends readonly unknown[] = typeof randomizerRngABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof randomizerRngAddress
  }
) {
  return prepareWriteContract({
    abi: randomizerRngABI,
    address: randomizerRngAddress[421614],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link watchContractEvent}__ with `abi` set to __{@link randomizerRngABI}__.
 *
 *
 */
export function watchRandomizerRngEvent<
  TAbi extends readonly unknown[] = typeof randomizerRngABI,
  TEventName extends string = string
>(
  config: Omit<WatchContractEventConfig<TAbi, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof randomizerRngAddress
  },
  callback: WatchContractEventCallback<TAbi, TEventName>
) {
  return watchContractEvent(
    { abi: randomizerRngABI, address: randomizerRngAddress[421614], ...config } as WatchContractEventConfig<
      TAbi,
      TEventName
    >,
    callback
  )
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link randomizerRngImplementationABI}__.
 *
 *
 */
export function getRandomizerRngImplementation(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof randomizerRngImplementationAddress }
) {
  return getContract({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    ...config,
  })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link randomizerRngImplementationABI}__.
 *
 *
 */
export function readRandomizerRngImplementation<
  TAbi extends readonly unknown[] = typeof randomizerRngImplementationABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof randomizerRngImplementationAddress
  }
) {
  return readContract({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link randomizerRngImplementationABI}__.
 *
 *
 */
export function writeRandomizerRngImplementation<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof randomizerRngImplementationAddress
>(
  config:
    | (Omit<WriteContractPreparedArgs<typeof randomizerRngImplementationABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof randomizerRngImplementationAddress
      })
    | (Omit<WriteContractUnpreparedArgs<typeof randomizerRngImplementationABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof randomizerRngImplementationAddress
      })
) {
  return writeContract({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    ...config,
  } as unknown as WriteContractArgs<typeof randomizerRngImplementationABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link randomizerRngImplementationABI}__.
 *
 *
 */
export function prepareWriteRandomizerRngImplementation<
  TAbi extends readonly unknown[] = typeof randomizerRngImplementationABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof randomizerRngImplementationAddress
  }
) {
  return prepareWriteContract({
    abi: randomizerRngImplementationABI,
    address: randomizerRngImplementationAddress[421614],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link watchContractEvent}__ with `abi` set to __{@link randomizerRngImplementationABI}__.
 *
 *
 */
export function watchRandomizerRngImplementationEvent<
  TAbi extends readonly unknown[] = typeof randomizerRngImplementationABI,
  TEventName extends string = string
>(
  config: Omit<WatchContractEventConfig<TAbi, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof randomizerRngImplementationAddress
  },
  callback: WatchContractEventCallback<TAbi, TEventName>
) {
  return watchContractEvent(
    {
      abi: randomizerRngImplementationABI,
      address: randomizerRngImplementationAddress[421614],
      ...config,
    } as WatchContractEventConfig<TAbi, TEventName>,
    callback
  )
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link randomizerRngProxyABI}__.
 *
 *
 */
export function getRandomizerRngProxy(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof randomizerRngProxyAddress }
) {
  return getContract({ abi: randomizerRngProxyABI, address: randomizerRngProxyAddress[421614], ...config })
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link sortitionModuleABI}__.
 *
 *
 */
export function getSortitionModule(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof sortitionModuleAddress }
) {
  return getContract({ abi: sortitionModuleABI, address: sortitionModuleAddress[421614], ...config })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link sortitionModuleABI}__.
 *
 *
 */
export function readSortitionModule<
  TAbi extends readonly unknown[] = typeof sortitionModuleABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof sortitionModuleAddress
  }
) {
  return readContract({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link sortitionModuleABI}__.
 *
 *
 */
export function writeSortitionModule<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof sortitionModuleAddress
>(
  config:
    | (Omit<WriteContractPreparedArgs<typeof sortitionModuleABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof sortitionModuleAddress
      })
    | (Omit<WriteContractUnpreparedArgs<typeof sortitionModuleABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof sortitionModuleAddress
      })
) {
  return writeContract({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    ...config,
  } as unknown as WriteContractArgs<typeof sortitionModuleABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link sortitionModuleABI}__.
 *
 *
 */
export function prepareWriteSortitionModule<
  TAbi extends readonly unknown[] = typeof sortitionModuleABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof sortitionModuleAddress
  }
) {
  return prepareWriteContract({
    abi: sortitionModuleABI,
    address: sortitionModuleAddress[421614],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link watchContractEvent}__ with `abi` set to __{@link sortitionModuleABI}__.
 *
 *
 */
export function watchSortitionModuleEvent<
  TAbi extends readonly unknown[] = typeof sortitionModuleABI,
  TEventName extends string = string
>(
  config: Omit<WatchContractEventConfig<TAbi, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof sortitionModuleAddress
  },
  callback: WatchContractEventCallback<TAbi, TEventName>
) {
  return watchContractEvent(
    { abi: sortitionModuleABI, address: sortitionModuleAddress[421614], ...config } as WatchContractEventConfig<
      TAbi,
      TEventName
    >,
    callback
  )
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link sortitionModuleImplementationABI}__.
 *
 *
 */
export function getSortitionModuleImplementation(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof sortitionModuleImplementationAddress }
) {
  return getContract({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    ...config,
  })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link sortitionModuleImplementationABI}__.
 *
 *
 */
export function readSortitionModuleImplementation<
  TAbi extends readonly unknown[] = typeof sortitionModuleImplementationABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof sortitionModuleImplementationAddress
  }
) {
  return readContract({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link sortitionModuleImplementationABI}__.
 *
 *
 */
export function writeSortitionModuleImplementation<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof sortitionModuleImplementationAddress
>(
  config:
    | (Omit<WriteContractPreparedArgs<typeof sortitionModuleImplementationABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof sortitionModuleImplementationAddress
      })
    | (Omit<WriteContractUnpreparedArgs<typeof sortitionModuleImplementationABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof sortitionModuleImplementationAddress
      })
) {
  return writeContract({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    ...config,
  } as unknown as WriteContractArgs<typeof sortitionModuleImplementationABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link sortitionModuleImplementationABI}__.
 *
 *
 */
export function prepareWriteSortitionModuleImplementation<
  TAbi extends readonly unknown[] = typeof sortitionModuleImplementationABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof sortitionModuleImplementationAddress
  }
) {
  return prepareWriteContract({
    abi: sortitionModuleImplementationABI,
    address: sortitionModuleImplementationAddress[421614],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link watchContractEvent}__ with `abi` set to __{@link sortitionModuleImplementationABI}__.
 *
 *
 */
export function watchSortitionModuleImplementationEvent<
  TAbi extends readonly unknown[] = typeof sortitionModuleImplementationABI,
  TEventName extends string = string
>(
  config: Omit<WatchContractEventConfig<TAbi, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof sortitionModuleImplementationAddress
  },
  callback: WatchContractEventCallback<TAbi, TEventName>
) {
  return watchContractEvent(
    {
      abi: sortitionModuleImplementationABI,
      address: sortitionModuleImplementationAddress[421614],
      ...config,
    } as WatchContractEventConfig<TAbi, TEventName>,
    callback
  )
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link sortitionModuleProxyABI}__.
 *
 *
 */
export function getSortitionModuleProxy(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof sortitionModuleProxyAddress }
) {
  return getContract({ abi: sortitionModuleProxyABI, address: sortitionModuleProxyAddress[421614], ...config })
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link wethABI}__.
 *
 *
 */
export function getWeth(config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof wethAddress }) {
  return getContract({ abi: wethABI, address: wethAddress[421614], ...config })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link wethABI}__.
 *
 *
 */
export function readWeth<TAbi extends readonly unknown[] = typeof wethABI, TFunctionName extends string = string>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & { chainId?: keyof typeof wethAddress }
) {
  return readContract({ abi: wethABI, address: wethAddress[421614], ...config } as unknown as ReadContractConfig<
    TAbi,
    TFunctionName
  >)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link wethABI}__.
 *
 *
 */
export function writeWeth<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof wethAddress
>(
  config:
    | (Omit<WriteContractPreparedArgs<typeof wethABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof wethAddress
      })
    | (Omit<WriteContractUnpreparedArgs<typeof wethABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof wethAddress
      })
) {
  return writeContract({ abi: wethABI, address: wethAddress[421614], ...config } as unknown as WriteContractArgs<
    typeof wethABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link wethABI}__.
 *
 *
 */
export function prepareWriteWeth<
  TAbi extends readonly unknown[] = typeof wethABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof wethAddress
  }
) {
  return prepareWriteContract({
    abi: wethABI,
    address: wethAddress[421614],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link watchContractEvent}__ with `abi` set to __{@link wethABI}__.
 *
 *
 */
export function watchWethEvent<TAbi extends readonly unknown[] = typeof wethABI, TEventName extends string = string>(
  config: Omit<WatchContractEventConfig<TAbi, TEventName>, 'abi' | 'address'> & { chainId?: keyof typeof wethAddress },
  callback: WatchContractEventCallback<TAbi, TEventName>
) {
  return watchContractEvent(
    { abi: wethABI, address: wethAddress[421614], ...config } as WatchContractEventConfig<TAbi, TEventName>,
    callback
  )
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link wethFaucetABI}__.
 *
 *
 */
export function getWethFaucet(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof wethFaucetAddress }
) {
  return getContract({ abi: wethFaucetABI, address: wethFaucetAddress[421614], ...config })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link wethFaucetABI}__.
 *
 *
 */
export function readWethFaucet<
  TAbi extends readonly unknown[] = typeof wethFaucetABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof wethFaucetAddress
  }
) {
  return readContract({
    abi: wethFaucetABI,
    address: wethFaucetAddress[421614],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link wethFaucetABI}__.
 *
 *
 */
export function writeWethFaucet<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof wethFaucetAddress
>(
  config:
    | (Omit<WriteContractPreparedArgs<typeof wethFaucetABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof wethFaucetAddress
      })
    | (Omit<WriteContractUnpreparedArgs<typeof wethFaucetABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof wethFaucetAddress
      })
) {
  return writeContract({
    abi: wethFaucetABI,
    address: wethFaucetAddress[421614],
    ...config,
  } as unknown as WriteContractArgs<typeof wethFaucetABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link wethFaucetABI}__.
 *
 *
 */
export function prepareWriteWethFaucet<
  TAbi extends readonly unknown[] = typeof wethFaucetABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof wethFaucetAddress
  }
) {
  return prepareWriteContract({
    abi: wethFaucetABI,
    address: wethFaucetAddress[421614],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}
